import StageBuilder from '../../../stageBuilder/StageBuilder';
import { LoggedInHandleParamsType } from '../types';
import AbstractLoggedInHandler from './AbstractLoggedInHandler';
import { loginScene } from '../../LoginPathOperation/constants';

export default class LoggedinErrorHandler extends AbstractLoggedInHandler {
  private _errorQueryParam = 'error_type';

  protected processToLogin(
    stateBuilder: StageBuilder,
    assetReference = '@jarvis/react-shell-login'
  ): void {
    stateBuilder.setContent({ ...loginScene, assetReference });
    stateBuilder.setLayoutByKey(false);
    stateBuilder.setEndProcessChain(true);
  }

  public async handle(params: LoggedInHandleParamsType): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const errorSearchParam = urlParams.get(this._errorQueryParam);

    if (!errorSearchParam) {
      return super.handle(params);
    }

    const { decodedState, stateBuilder, manifest } = params;
    const skipAuthErrorScreen = !!decodedState?.skipAuthErrorScreen;
    if (skipAuthErrorScreen) {
      const { proceedToShell } = params;
      const additionalParams = new URLSearchParams({
        [this._errorQueryParam]: errorSearchParam
      });

      return proceedToShell(manifest, decodedState, additionalParams);
    }

    this.processToLogin(
      stateBuilder,
      params?.manifest?.services?.login?.assetReference
    );
  }
}
