import * as T from './types';
import authProvider from '../../../interface/v2/auth';
import TenantStrategy from './TenantStrategy';
import { AuthContextEnum } from '../../../services/authTokenService';
import AccountsClient from '../../../clients/stratus/accountmgtsvc/AccountsClient';
import { ISessionService } from '../../../services/session';
import TenantUtils from '../TenantUtils';
import BaseStratusTenantHandler from './BaseTenantHandler';
import { TenantStrategyEnum } from './strategy';
import { IAuthProviderService } from '../../../services/authProviderService';
import TenantFilterBuilder from '../tenantFilterBuilder/TenantFilterBuilder';
import UserMgtClient from '../../../clients/stratus/usersMgt/UserMgtClient';
import { ResourceListType } from '../../../clients/stratus/tntCommonResourceRegistry/types';
import UserTenantDetailsClient from '../../../clients/stratus/usersMgt/UserTenantDetailsClient';

type OrganizationProperties = {
  fallback?: string;
  filter?: {
    role: 'admin' | 'user' | 'all';
    tenantType: string[];
  };
  customBehaviors?: [
    {
      filter?: {
        role: 'admin' | 'user' | 'all';
        tenantType: string[];
      };
      onSelect: {
        redirectTo?: string;
      };
    }
  ];
};

export default class OrganizationTenantHandler
  extends BaseStratusTenantHandler
  implements TenantStrategy
{
  private _strategy = TenantStrategyEnum.stratusOrganization;
  private _options: OrganizationProperties;
  private _assetReference: string;
  private _userTenantDetailsClient: UserTenantDetailsClient;
  private _accountsClient: AccountsClient;
  private _sessionService: ISessionService;
  private _userMgtClient: UserMgtClient;
  private _isNative: boolean;

  constructor({
    stack,
    options,
    parentAuthProvider,
    authProvider,
    assetReference,
    sessionService,
    isNative
  }: T.TenantHandlerStrategyType) {
    super({ stack, authProvider, parentAuthProvider });
    this._options = options;
    this._assetReference = assetReference;
    this._sessionService = sessionService;
    this._isNative = isNative;
    this._accountsClient = new AccountsClient(
      AccountsClient.getBaseUrl(this._stack),
      this._parentAuthProvider
    );
    this._userMgtClient = new UserMgtClient(
      UserMgtClient.getBaseUrl(stack),
      this._authProvider
    );
    this._userTenantDetailsClient = new UserTenantDetailsClient(
      UserTenantDetailsClient.getBaseUrl(this._stack),
      this._parentAuthProvider
    );
  }

  public getAssetReference(): string {
    return this._assetReference;
  }

  public getStrategy = (): TenantStrategyEnum => {
    return this._strategy;
  };

  public setTenant = async (
    id: string,
    authContext: AuthContextEnum
  ): Promise<void> => {
    await this._sessionService.exchangeToken(
      id,
      authContext,
      this._isNative
        ? TenantStrategyEnum.stratusNative
        : TenantStrategyEnum.stratusOrganization
    );
  };

  public async createOrganization(
    data: T.CreateNodeRequestBodyType
  ): Promise<T.TenantVisualizationType> {
    const language = data?.language?.toLowerCase?.() || 'en';
    const country = 'US';
    const idToken = await this._sessionService.getIdToken();

    const newTenantId = await this._accountsClient.createAccount({
      accountId: null,
      parentAccountResourceId: null,
      language,
      countrySet: [country],
      idToken: idToken,
      ...data
    });

    return await this.getTenantById(newTenantId.resourceId);
  }

  public async _getPaginatedTenantListData(
    options: T.GetTenantListOptionsType
  ): Promise<T.PaginatedTenantVisualizationType> {
    const { filter } = this._options || {};
    const userResourceId = await this._userMgtClient
      .getUsersMe()
      .then((response) => response.resourceId);

    const _options = {
      userResourceId,
      ...options
    };
    const customBehaviorsList = this._options?.customBehaviors;

    const userTenantDetails =
      await this._userTenantDetailsClient.getUserTenantDetails(_options);

    const tenantList = await Promise.all<T.TenantNodeType>(
      userTenantDetails?.resourceList?.map(
        async (resource: ResourceListType) => {
          const tenant = await this._nodeClient.getNodeByTenantId({
            tenantId: resource.tenantResourceId
          });
          return { ...tenant, roleCategory: resource.roleCategory };
        }
      )
    );

    const tenants = tenantList.map((filteredTenant) =>
      TenantUtils._convertStratusTenantNodeToTenantVisualizationType(
        filteredTenant
      )
    );
    const filteredTenantList = this.getFilteredTenantList(filter, tenants);
    let unifiedTenantList = filteredTenantList;

    if (customBehaviorsList) {
      unifiedTenantList = this.addCustomBehaviorsTenantList(
        customBehaviorsList,
        unifiedTenantList,
        tenants
      );
      return {
        currentPage: 0,
        totalPages: 1,
        tenants: unifiedTenantList
      };
    } else {
      return {
        currentPage: 0,
        totalPages: 1,
        tenants: filteredTenantList
      };
    }
  }

  private getFilteredTenantList(
    filter: T.TenantHandlerFilterType,
    tenants: T.TenantVisualizationType[]
  ) {
    return new TenantFilterBuilder({
      filter,
      tenants
    })
      .filterByRegexTenantType()
      .filterByRole()
      .getTenantList();
  }

  public getAuthProvider(): IAuthProviderService {
    return authProvider().createOrgedAuthProvider();
  }

  public isRequiredToNavigate = (): boolean => {
    return true;
  };

  public getFallback = (): string => {
    return this?._options?.fallback;
  };

  public isTenantValid(
    filter: T.TenantHandlerFilterType,
    tenant: T.TenantVisualizationType
  ): boolean {
    return this.getFilteredTenantList(filter, [tenant])?.length > 0;
  }

  private addCustomBehaviorsTenantList(
    customBehaviorsList,
    filteredTenantsList: T.TenantVisualizationType[],
    allTenantsList: T.TenantVisualizationType[]
  ) {
    if (customBehaviorsList && filteredTenantsList && allTenantsList) {
      for (const customBehaviors in customBehaviorsList) {
        const filterCustomBehavior =
          customBehaviorsList[customBehaviors].filter;

        const filteredCustomBehaviors = this.getFilteredTenantList(
          filterCustomBehavior,
          allTenantsList
        );

        filteredTenantsList = this.mergeListsAndRemoveDuplicates(
          filteredTenantsList,
          filteredCustomBehaviors
        );
      }
    }
    return filteredTenantsList;
  }

  private mergeListsAndRemoveDuplicates(firstList, secondList) {
    const uniqueSet = new Set(
      [...firstList, ...secondList].map((obj) => JSON.stringify(obj))
    );

    const uniqueList = Array.from(uniqueSet).map((objString) =>
      JSON.parse(objString)
    );

    return uniqueList;
  }
}
