import * as T from './types';
import TenantObserver, { TenantEvents } from '../tenantHandler/TenantObserver';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { RoutesService } from '../RoutesService';
import bindAllMethods from '../../utils/bindAllMethods';
import ITenantHandlerService from '../tenantHandler/ITenantHandlerService';

class BreadcrumbService {
  private _defaultSettingsKey: string;
  private _settingsList: T.BreadcrumbServiceSettingsType[] = [];
  private _breadcrumbs: T.BreadcrumbServiceItemType[] = [];
  private _initialBreadcrumbs: T.BreadcrumbServiceItemType[] = [];
  private _navigation: T.BreadcrumbServiceDependenciesType['navigation'];
  private _criterion: T.BreadcrumbServiceDependenciesType['criterion'];
  private _listeners: Map<
    T.BreadcrumbServiceListenerCallbackType,
    T.BreadcrumbServiceListenerCallbackType
  > = new Map();
  private _tenantHandlerService: ITenantHandlerService;
  private _routesService: RoutesService;
  private _isEnabled = false;
  private _started = false;

  private triggerListeners() {
    const breadcrumbs = this.getBreadcrumbs();
    this._listeners.forEach((callback) => callback(breadcrumbs));
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    this._tenantHandlerService = services?.tenantHandlerService;
    this._routesService = services?.routesService;
    bindAllMethods(this);
  }

  async start(
    dependencies: T.BreadcrumbServiceDependenciesType
  ): Promise<void> {
    if (this._started) return;
    // TODO: Remove the data from commons default configuration to constructor starter.
    // TODO: Remove the interfaces that it uses as dependencies to use services instead
    this._started = true;
    this._isEnabled = !!dependencies?.enable;
    this._defaultSettingsKey = dependencies?.defaultSettingsKey;
    this._settingsList = dependencies?.settingsList || [];
    this._navigation = dependencies?.navigation;
    this._criterion = dependencies?.criterion;

    const startInitialBreadcrumbHandler = async () => {
      if (!this._isEnabled) return;
      let lastPromiseId = 0;
      const setBreadcrumb = async () => {
        const thisPromiseId = lastPromiseId + 1;
        lastPromiseId = thisPromiseId;
        const currentPath = this._navigation?.location?.pathname;
        const route =
          currentPath &&
          (await this._routesService.findRouteWithPriority(currentPath));
        const newInitialBreadcrumbs: T.BreadcrumbServiceItemType[] = [];

        if (route) {
          const initialBreadcrumbsFromSettings: T.BreadcrumbServiceItemType[] =
            await (async () => {
              const breadcrumbKey =
                route?.breadcrumbKey || route?.breadcrumbKey === false
                  ? route?.breadcrumbKey
                  : this._defaultSettingsKey;

              const settings =
                breadcrumbKey &&
                this._settingsList.find(
                  (context) => context.key === breadcrumbKey
                );

              const breadcrumbListPromise = settings?.initialBreadcrumbs?.map?.(
                async (breadcrumb, index) => {
                  const isCriterionValid = breadcrumb?.criterionKey
                    ? await this._criterion.checkByCriterionKey(
                        breadcrumb?.criterionKey
                      )
                    : true;

                  if (!isCriterionValid) {
                    return undefined;
                  }
                  let key = breadcrumb?.key;
                  let text = breadcrumb?.text;
                  let url = breadcrumb?.url;
                  let translationKey = breadcrumb?.translationKey;

                  if (breadcrumb?.routeKey) {
                    const thisRoute = this._routesService.findRouteByKey(
                      breadcrumb?.routeKey
                    );
                    const routePath = Array.isArray(thisRoute?.path)
                      ? thisRoute?.path?.[0]
                      : thisRoute?.path;

                    if (!key) key = thisRoute?.key;
                    if (!text) text = thisRoute?.label;
                    if (!translationKey)
                      translationKey = thisRoute?.translationKey;
                    if (!url) url = routePath;
                  } else if (breadcrumb?.useTenantName) {
                    const authContext =
                      this._tenantHandlerService.getCurrentContext();
                    const tenantData =
                      this._tenantHandlerService.getTenantByContext(
                        authContext
                      );
                    const tenantName = tenantData?.data?.name;

                    if (!text) text = tenantName;
                  }

                  if (!key) {
                    key = `${settings?.key}-${index}`;
                  }

                  return {
                    key,
                    text,
                    translationKey,
                    url
                  };
                }
              );

              return Promise.all(breadcrumbListPromise).then((response) =>
                response?.filter?.(
                  (responseBreadcrumb) => !!responseBreadcrumb?.key
                )
              );
            })();

          newInitialBreadcrumbs.push(...initialBreadcrumbsFromSettings);

          if (route?.key && route?.label) {
            const haveRouteOnInitialBreadcrumb = newInitialBreadcrumbs.some(
              (breadcrumb) => breadcrumb?.key === route?.key
            );

            if (!haveRouteOnInitialBreadcrumb) {
              newInitialBreadcrumbs.push({
                key: route?.key,
                translationKey: route?.translationKey,
                text: route?.label,
                url: Array.isArray(route?.path) ? route?.path?.[0] : route?.path
              });
            }
          }
        }

        const isSameInitialBreadcrumbs =
          JSON.stringify(newInitialBreadcrumbs) ===
          JSON.stringify(this._initialBreadcrumbs);

        const isLastAndCurrentPromiseEqual = thisPromiseId === lastPromiseId;

        if (!isSameInitialBreadcrumbs && isLastAndCurrentPromiseEqual) {
          this._initialBreadcrumbs = newInitialBreadcrumbs;
          this._breadcrumbs = [];
          this.triggerListeners();
        }
      };

      this._navigation.listen(setBreadcrumb);
      this._criterion.listen(setBreadcrumb);
      // TenantObserver.subscribe(TenantEvents.SET_TENANT, setBreadcrumb);
      TenantObserver.subscribe(
        TenantEvents.SET_TENANT_HANDLER_KEY,
        setBreadcrumb
      );
      await setBreadcrumb();
    };

    await startInitialBreadcrumbHandler();
  }

  isEnabled() {
    return !!this._isEnabled;
  }

  listen(callback: T.BreadcrumbServiceListenerCallbackType): () => void {
    this._listeners.set(callback, callback);

    return () => this._listeners.delete(callback);
  }

  useReactListener(React: any): T.BreadcrumbServiceItemType[] {
    const [value, setValue] = React.useState(this.getBreadcrumbs());

    React.useEffect(() => {
      const removeListener = this.listen((breadcrumbs) =>
        setValue(breadcrumbs)
      );

      return () => removeListener();
    }, []);

    return value;
  }

  getBreadcrumbs(): T.BreadcrumbServiceItemType[] {
    return [...this._initialBreadcrumbs, ...this._breadcrumbs];
  }

  add(breadcrumbItem: T.BreadcrumbServiceItemType): void {
    if (breadcrumbItem?.key && breadcrumbItem?.text) {
      const index = this._breadcrumbs.findIndex(
        (breadcrumb) => breadcrumb?.key === breadcrumbItem?.key
      );
      let newBreadcrumb = this._breadcrumbs;
      const haveBreadcrumb = index >= 0;
      const isLastBreadcrumbFromList = this._breadcrumbs?.length === index + 1;

      if (!haveBreadcrumb) {
        newBreadcrumb = [...newBreadcrumb, breadcrumbItem];
      } else if (!isLastBreadcrumbFromList) {
        newBreadcrumb = this._breadcrumbs.splice(0, index + 1);
      }

      if (this._breadcrumbs !== newBreadcrumb) {
        this._breadcrumbs = newBreadcrumb;
        this.triggerListeners();
      }
    } else {
      console.warn(
        'Need to provide key and text to add breadcrumb.',
        breadcrumbItem
      );
    }
  }

  remove(breadcrumbKey: string): void {
    if (!breadcrumbKey) {
      console.warn(`Need to provide breadcrumb key to remove it`);
      return undefined;
    }

    const index = this._breadcrumbs.findIndex(
      (breadcrumb) => breadcrumb?.key === breadcrumbKey
    );

    if (index >= 0) {
      this._breadcrumbs.length = index;
      this.triggerListeners();
    }
  }
}

// TODO: Resolve this exportation and the importation to use from services
export default BreadcrumbService;
