import { LastTenantIdsRepository } from './LastTenantIdsRepository';
import { INavigationService } from '../../../services/navigationService';
import TabHelpers from './Helpers';
import {
  HandledTenantCleaned,
  InitialTenantType,
  TenantSetterOptions
} from './types';

export default class TenantSetter {
  private _navigationService: INavigationService;
  private _repository: LastTenantIdsRepository;
  private _queryParamsEnabled: boolean;

  constructor({
    navigationService,
    repository,
    queryParamsEnabled
  }: TenantSetterOptions) {
    this._navigationService = navigationService;
    this._repository = repository;
    this._queryParamsEnabled = queryParamsEnabled ?? true;
  }

  /**
   * Updates the last tenants data with the processed tenants.
   * Multiple tenants could be received here, but we are only interested in the processed ones.
   * @param {HandledTenantCleaned[]} tenants - The list of handled tenants sent by the Tenant Notifier.
   */
  public cb_updateLastTenantData = (tenants: HandledTenantCleaned[]): void => {
    // So we are overriding the last tenants in the repository, setting ALL the valid ones.
    const validTenants = tenants
      .filter((t) => t.proccessed)
      .map((t) => ({ level: t.level, id: t.id }));

    // 1. Updating the last tenants in the Repository
    this.updateLastTenantOnRepository(validTenants);

    // 2. Updating the URL with the last tenant
    this.updateLastTenantOnURL(validTenants, true);
  };

  /**
   * Updates the last tenants in the repository.
   * @param {InitialTenantType[]} validTenants - The list of valid tenants.
   */
  public updateLastTenantOnRepository = (
    validTenants: InitialTenantType[]
  ): void => {
    this._repository.save(validTenants);
  };

  /**
   * Updates the URL with the last tenants.
   * @param {InitialTenantType[]} validTenants - The list of valid tenants.
   * @param {boolean} shouldClearParams - Whether we should clear the existing query parameters.
   */
  public updateLastTenantOnURL = (
    validTenants: InitialTenantType[],
    shouldClearParams: boolean = false
  ): void => {
    // The feature is not enabled.
    if (!this._queryParamsEnabled) return;

    // Remove any tenants from the URL
    if (shouldClearParams) TabHelpers.removeTenantsFromQueryParams();

    // Add the right tenants to the URL
    this._syncQueryParams(validTenants);
  };

  /**
   * Syncs the query parameters with the valid tenants.
   * @param {InitialTenantType[]} validTenants - The list of valid tenants.
   */
  private _syncQueryParams(validTenants: InitialTenantType[]): void {
    const urlSearchParams = new URLSearchParams(window.location.search);

    validTenants.forEach((t) => {
      const tenantQueryParam = `t${t.level}`;
      urlSearchParams.set(tenantQueryParam, t.id);
    });

    // Updates the navigation location with the new tenant information.
    this._navigationService.replace({
      ...this._navigationService.location,
      search: urlSearchParams.toString()
    });
  }
}
