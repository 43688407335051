import { ValveFilterErrorType } from '@jarvis/jweb-core';
import { DataValveFilterError } from '../../helpers/dataValveError';

enum consentType {
  strictlyNecessary = 1,
  telemetry = 2,
  personalization = 3
}

const getTelemetryConsent = (consent: string) => {
  // Extracting the groups string using a regex match
  const groupsMatch = consent.match(/groups=([^&]*)/);
  if (!groupsMatch) {
    throw new DataValveFilterError(ValveFilterErrorType.treeNotAllowed, `Groups not provided in webAppConsent "${consent}"`, 'Invalid webAppConsent');
  }
  // Define the accumulator object type
  interface ConsentObject {
    [key: string]: number;
  }
  const groupsString = groupsMatch[1]; // returns '1:1,2:1,3:1'
  const consents = groupsString.split(','); // returns ['1:1', '2:1', '3:1']
  const consentObj = consents.reduce<ConsentObject>((accum, current) => {
    const [key, value] = current.split(':');
    accum[key] = Number(value);
    return accum;
  }, {}); // returns {1: 1, 2: 1, 3: 1}

  // Checking if telemetry is allowed
  return Boolean(consentObj[consentType.telemetry]);
};
export default getTelemetryConsent;
