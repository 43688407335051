import { IBackgroundTaskManagerService } from '../../services/backgroundTaskService';

type InitBackgroundTaskManagerParams = {
  backgroundTaskManagerService: IBackgroundTaskManagerService;
};

export default class InitBackgroundTaskManager {
  private _backgroundTaskManagerService: IBackgroundTaskManagerService;

  constructor({
    backgroundTaskManagerService
  }: InitBackgroundTaskManagerParams) {
    this._backgroundTaskManagerService = backgroundTaskManagerService;
  }

  public init = async (): Promise<void> => {
    this._backgroundTaskManagerService.init();
  };
}
