// TODO: this should be as a repository.
const encodeProperty = (property) =>
  encodeURIComponent(property).replace(/[-.+*]/g, '\\$&');

/**
 * Utility functions for working with cookies.
 */

// TODO: BUG: We are always returning the encoded value
export const getCookie = (cookieName, encoded = true) => {
  const { cookie = '' } = document;
  // From https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
  // the craft routing stuff references the "gnb_locale" cookie, that will be our cookie-source of language truth
  const handledCookieName = handleCookieName(cookieName, encoded);
  const contentLanguage =
    decodeURIComponent(
      cookie.replace(
        new RegExp(
          `(?:(?:^|.*;)\\s*${handledCookieName}\\s*\\=\\s*([^;]*).*$)|^.*$`
        ),
        '$1'
      )
    ) || '';

  const cookieContent =
    Array.isArray(contentLanguage) && contentLanguage.includes(',')
      ? contentLanguage.split(',')[0]
      : contentLanguage;

  return cookieContent;
};

export const deleteCookie = (cookieName, path = '/', encoded = true) => {
  document.cookie = `${handleCookieName(
    cookieName,
    encoded
  )}=""; path=${path}; max-age=-1;`;
};

/**
 * Sets a cookie with the given name and value.
 *
 * @param cookieName - The name of the cookie to set.
 * @param cookieValue - The value to set for the cookie.
 * @param age - The maximum age of the cookie, in seconds. Defaults to one year.
 * @param encoded - Whether to URL-encode the cookie name and value. Defaults to `true`.
 */
export const setCookie = (
  cookieName: string,
  cookieValue: string,
  age: number | string = 31536000,
  encoded = true
): void => {
  if (getCookie(cookieName, encoded)) {
    deleteCookie(cookieName, undefined, encoded);
  }
  document.cookie = `${handleCookieName(
    cookieName,
    encoded
  )}=${handleCookieValue(cookieValue, encoded)}; max-age=${age}; path=/;`;
};

/**
 * Encodes a cookie value if necessary.
 *
 * @param cookieValue - The value to encode.
 * @param encoded - Whether the value is already encoded. Defaults to `true`.
 * @returns The encoded cookie value.
 */
function handleCookieValue(cookieValue: string, encoded: boolean): string {
  return encoded ? encodeProperty(cookieValue) : cookieValue;
}

/**
 * Encodes a cookie name if necessary.
 *
 * @param cookieName - The name to encode.
 * @param encoded - Whether the name is already encoded. Defaults to `true`.
 * @returns The encoded cookie name.
 */
function handleCookieName(cookieName: string, encoded: boolean): string {
  return encoded ? encodeProperty(cookieName) : cookieName;
}
