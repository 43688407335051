import { gql, GraphQLService } from '@clientos/graphql-client';

import IGraphQLService from './IGraphQLService';
import bindAllMethods from '../../utils/bindAllMethods';
import { GraphQLReactTools } from '@clientos/graphql-client/dist/react';
import { getJWeb } from '../JWeb';
import { GraphQLPlatform } from './enums';

/*
  This is the implementation of the GraphQLService for the native side.
  This service will be used only when the GraphQL Library is running in the native side.
  It uses the native webview to send and receive messages to the native side.
  The native side will handle the query and send back the result.
  The react tools are also implemented to work with the native side.
*/
class NativeGraphQLService implements IGraphQLService {
  private _graphQLClient: GraphQLService;
  private _reactTools: GraphQLReactTools;
  public gql = gql;
  private _graphQLReactMFePromise: Promise<void> | undefined;
  private _platform: string;

  constructor(platform: GraphQLPlatform) {
    this._platform = platform;
    bindAllMethods(this);
  }

  public async init(): Promise<void> {
    const JWeb = await getJWeb();
    if (!this._platform) {
      this._platform = JWeb.platform;
    }
    this._graphQLReactMFePromise = System.import('@clientos/graphql-react')
      .then(
        ({
          NativeGraphQLReactTools,
          createNativeAppleAndroidGraphQLReactTools,
          createWindowsGraphQLReactTools,
          GraphQLNativeClient
        }) => {
          if (GraphQLNativeClient) {
            this._graphQLClient = new GraphQLNativeClient(this._platform, {
              dependencies: { JWeb }
            });
          } else {
            console.error('GraphQLNativeClient is not available.');
          }
          this._reactTools =
            this._platform === GraphQLPlatform.WINDOWS
              ? createWindowsGraphQLReactTools?.({ JWeb }) ||
                NativeGraphQLReactTools
              : createNativeAppleAndroidGraphQLReactTools({ JWeb });
        }
      )
      .catch((error) => {
        console.error('Failed to load @clientos/graphql-react.');
        console.error(error);
      });
    await this._graphQLReactMFePromise;
  }

  public getReactTools = (): GraphQLReactTools => {
    return this._reactTools;
  };

  public getClient = (): GraphQLService => {
    return this._graphQLClient;
  };
}

export default NativeGraphQLService;
