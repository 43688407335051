import { getWindowValues } from './enum';

const MAX_SEQUENCE_NUMBER = 2147483647;

export const generateSequenceNumber = (category?: string) => {
  const localStorage = getWindowValues().localStorage;
  const sequenceIdentifier = category ? category : 'GlobalSequence';
  const sequenceNumber = Number(localStorage.getItem(sequenceIdentifier));
  const nextSequenceNumber = (sequenceNumber !== MAX_SEQUENCE_NUMBER) ? sequenceNumber + 1 : 1;

  if (nextSequenceNumber != null) {
    localStorage.setItem(sequenceIdentifier,nextSequenceNumber.toString());
  }

  return nextSequenceNumber;
};
