import { isIE } from '../utils/isIE';

const boostrappingBrowser = () => {
  if (isIE()) {
    console.debug('Running in IE11');
    // I regret to code it. Unfortunately, this is a awful fix for the IE11
    // Proxy-Pollyfill bug.
    Object.preventExtensions = <T>(o: T) => {
      console.debug('Removing preventExtensions.');
      return o;
    };
    Object.seal = <T>(o: T) => {
      console.debug('Removing seal.');
      return o;
    };
  }
};

boostrappingBrowser();
