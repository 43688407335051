import bindAllMethods from '../../../utils/bindAllMethods';
import { IEventService } from '../../../services/eventService';
import { INavigationService } from '../../../services/navigationService';
import { LOGIN_PATHNAME } from '../../../services/session/constants';
import { InitialNavigationStateRepository } from './InitialNavigationStateRepository';

export default class RefreshWhenNativeAuthStatusChanged {
  private _eventService: IEventService;
  private _navigationService: INavigationService;
  private _initialNavigationStateRepository: InitialNavigationStateRepository;
  private AuthPluginLoginChangeEventName =
    'com.hp.jarvis.auth.event.login.change';

  constructor({
    eventService,
    navigationService,
    initialNavigationStateRepository
  }) {
    bindAllMethods(this);
    this._eventService = eventService;
    this._navigationService = navigationService;
    this._initialNavigationStateRepository = initialNavigationStateRepository;
  }

  public init = async (): Promise<void> => {
    this._consumeSavedNavigationState();

    this._eventService.subscribe(this.AuthPluginLoginChangeEventName, () => {
      const currentPath = this._navigationService.location.pathname;
      if (currentPath !== LOGIN_PATHNAME) {
        this._reloadPageKeepingState();
      }
    });
  };

  private _reloadPageKeepingState = (): void => {
    const currentState = this._navigationService.location.state;
    const currentPath = this._navigationService.location.pathname;
    this._initialNavigationStateRepository.save(currentPath, currentState);
    window.location.reload();
  };

  private _consumeSavedNavigationState = (): void => {
    const currentPath = this._navigationService.location.pathname;
    const savedState =
      this._initialNavigationStateRepository.findOne(currentPath);
    if (savedState) {
      this._initialNavigationStateRepository.clear();
      this._navigationService.replace({
        ...this._navigationService.location,
        state: savedState
      });
    }
  };
}
