import bindAllMethods from '../../utils/bindAllMethods';
import IAnalyticsService from './IAnalyticsService';
import CustomAnalyticsService from './strategies/CustomAnalyticsService';
import DefaultAnalyticsService from './strategies/DefaultAnalyticsService';
import { AnalyticsServiceInputType } from './types';

export * from './analyticsServiceConstants';

export default class AnalyticsService implements IAnalyticsService {
  private _analyticsService: IAnalyticsService;

  constructor(analyticsProps: AnalyticsServiceInputType) {
    if (analyticsProps?.customAnalyticsSubsystem?.eventName) {
      this._analyticsService = new CustomAnalyticsService(analyticsProps);
    } else {
      this._analyticsService = new DefaultAnalyticsService(analyticsProps);
    }

    bindAllMethods(this);
  }

  setInterfaceDependencies(
    ...args: Parameters<IAnalyticsService['setInterfaceDependencies']>
  ): void {
    this._analyticsService.setInterfaceDependencies(...args);
  }

  public setDependencies(
    ...args: Parameters<IAnalyticsService['setDependencies']>
  ): void {
    this._analyticsService.setDependencies(...args);
  }

  public isEnabled(): boolean {
    return this._analyticsService.isEnabled();
  }

  public async init(): Promise<void> {
    return this._analyticsService.init();
  }

  public async publishCdmEvents(
    ...args: Parameters<IAnalyticsService['publishCdmEvents']>
  ): Promise<void> {
    return this._analyticsService.publishCdmEvents(...args);
  }
}
