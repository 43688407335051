import UserMgtClient from '../../clients/stratus/usersMgt/UserMgtClient';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import bindAllMethods from '../../utils/bindAllMethods';
import { decodeJWTPayload } from '../../utils/tokenUtils/JWTUtils';
import { ISessionService } from '../session';
import IUserService from './IUserService';
import { UserDataType } from './types';

export type StratusUserServiceParams = {
  sessionService: ISessionService;
  userMgtClient: UserMgtClient;
};

export default class StratusUserService implements IUserService {
  private _sessionService: ISessionService;
  private _userMgtClient: UserMgtClient;
  private _userData: UserDataType;

  public setDependencies({ services, clients }: SetServiceDependencies): void {
    const { sessionService } = services;

    const { stratus } = clients;
    this._sessionService = sessionService;
    this._userMgtClient = stratus.userMgtClient;
    bindAllMethods(this);
  }

  public async getEmail(): Promise<string> {
    return decodeJWTPayload(this._sessionService?.getIdToken?.())?.email;
  }

  public async getCountry(): Promise<string> {
    return (await this.get()).locale?.split('_')[1]?.toLocaleLowerCase();
  }

  public async get(): Promise<UserDataType> {
    if (!this._userData) {
      await this.refresh();
    }
    return this._userData;
  }

  private setUserDataByIdToken(): void {
    const idTokenData = decodeJWTPayload(this._sessionService?.getIdToken?.());

    this._userData = {
      idpType: idTokenData.idp_type,
      email: {
        value: idTokenData.email,
        verified: idTokenData.email_verified
      },
      phoneNumber: {
        value: idTokenData.phone_number,
        verified: idTokenData.phone_number_verified
      },
      familyName: idTokenData.family_name,
      givenName: idTokenData.given_name,
      userName: idTokenData.preferred_username,
      locale: idTokenData.locale
    };
  }

  private async setUserDataByUserMgt(): Promise<void> {
    const userApiData = await this._userMgtClient.getUsersMe();

    const {
      idpType,
      email,
      phoneNumber,
      familyName,
      givenName,
      userName,
      locale
    } = userApiData;

    this._userData = {
      idpType,
      email,
      phoneNumber,
      familyName,
      givenName,
      userName,
      locale
    };
  }

  public async refresh(): Promise<void> {
    if (!this._sessionService.isLoggedIn()) return;

    await this.setUserDataByUserMgt();

    if (!this._userData.email) {
      this.setUserDataByIdToken();
    }
  }
}
