import criterionExternalSourceCache from './criterionExternalSourceCache';
import createCriterionExternalSourceEvents from './createCriterionExternalSourceEvents';
import criterionExternalSourceGetPropertyValue from './criterionExternalSourceGetPropertyValue';
import criterionExternalSourceMatchValue from './criterionExternalSourceMatchValue';
import * as T from './types';

/**
 * This function receive informations about a external source, like a global variable or a asset reference.
 * After have access to the property, it will return compare the value with the expected value and return a boolean.
 * @param value
 */
export default async function valueFunctionExternalSource(
  value: T.ValueFunctionExternalSourceValueType
): Promise<boolean> {
  try {
    const options: T.ValueFunctionExternalSourceValueType & {
      importKey: string;
    } = {
      ...value,
      importKey: value?.importKey || 'default'
    };

    const propertyValue = await (async () => {
      const haveEvents = Array.isArray(options?.subscribeEvents);

      if (haveEvents) {
        createCriterionExternalSourceEvents(options);

        return await criterionExternalSourceCache.getExternalSourceCache(
          options,
          async () => criterionExternalSourceGetPropertyValue(options)
        );
      } else {
        return await criterionExternalSourceGetPropertyValue(options);
      }
    })();

    const expectedValue = options?.response;

    return !!criterionExternalSourceMatchValue(propertyValue, expectedValue);
  } catch (error) {
    console.error(error);

    return false;
  }
}
