import { getEventServicePlugin, isNative, JWebErrorHandler } from '../../JWeb';
import { Publisher, Subscriber } from '../../JWeb/types';
import { publisherId } from '../utils';

export const nativeEventPublisherPromise: Promise<Publisher> = (async () => {
  if (await isNative()) {
    const nativeEventService = await getEventServicePlugin();

    const nativeEventPublisher = await nativeEventService.createPublisher(
      publisherId
    );

    return JWebErrorHandler<Publisher>(nativeEventPublisher);
  }
})();

export const nativeSubscriberPromise = (async () => {
  if (await isNative()) {
    const nativeEventService = await getEventServicePlugin();

    return await nativeEventService
      .createSubscriber()
      .then((v) => JWebErrorHandler<Subscriber>(v));
  }
})();
