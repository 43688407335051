import bindAllMethods from '../../../utils/bindAllMethods';
import IIdleService from '../../../services/IdleService/IIdleService';
import {
  UserActivityInterfaceContructorType,
  UserActivityInterfaceType
} from './types';
import { SettingsType } from '../../../services/IdleService/types';

class UserActivityInterface {
  private _idleService: IIdleService;

  constructor({ idleService }: UserActivityInterfaceContructorType) {
    this._idleService = idleService;
    bindAllMethods(this);
  }

  start(options?: SettingsType): void {
    this._idleService.start(options);
  }

  public getInterface(): UserActivityInterfaceType {
    return {
      isIdle: this._idleService.isIdle
    };
  }
}

export default UserActivityInterface;
