export enum GraphQLPlatform {
  IOS = 'ios',
  MAC = 'mac',
  ANDROID = 'android',
  WINDOWS = 'windows',
  WEB = 'web'
}

export enum GraphQLStack {
  LOCAL = 'local',
  DEV = 'dev',
  PIE = 'pie',
  STAGE = 'stage',
  PROD = 'prod'
}
