import StageBuilder from '../../stageBuilder/StageBuilder';
import * as T from './types';
import OnboardingAgent from '../OnboardingAgentOperation/OnboardingAgent/OnboardingAgent';
import bindAllMethods from '../../../../utils/bindAllMethods';
export default class OnboardingAgentOperation
  implements T.RouterOperationInterface
{
  private _onboardingAgent: OnboardingAgent;

  constructor(dependencies: T.OnboardingAgentOperationDependenciesType) {
    bindAllMethods(this);
    this._onboardingAgent = new OnboardingAgent(dependencies);
  }

  async process(stateBuilder: StageBuilder): Promise<void> {
    await this._onboardingAgent.handleOnboardingState(stateBuilder);
  }
}
