import * as T from './types';
import * as E from './enums';
import operationFunctions from './operationFunctions';
import bindAllMethods from '../../../utils/bindAllMethods';
import {
  CriterionOperationFunctionMultipleCriterionType,
  CriterionOperationFunctionSingleCriterionType,
  CriterionOperationFunctionType
} from './operationFunctions/types';

export default class Criterion<
  V extends T.CriterionValueFunctionsObjectType<any, T.CriterionOptionsType>
> implements T.CriterionInterfaceType<V>
{
  private _valueFunctions: T.CriterionValueFunctionsObjectType<
    any,
    T.CriterionOptionsType
  > = {};
  private _defaultValue: boolean;
  private _defaultValueForUndefined: boolean;
  private _criterionList: T.CriterionDependenciesType<V>['criterionList'];
  private _eventList: T.CriterionDependenciesType<V>['eventList'];
  private _eventService: T.CriterionDependenciesType<V>['eventService'];
  private _startedCriterionEvent = false;
  eventName: string;

  constructor({
    valueFunctions,
    defaultValue = false,
    defaultValueForUndefined = true,
    criterionList,
    eventList,
    eventName,
    eventService
  }: T.CriterionDependenciesType<V>) {
    this.addValueFunctions(valueFunctions);
    this._defaultValue = defaultValue;
    this._defaultValueForUndefined = defaultValueForUndefined;
    this._criterionList = criterionList;
    this._eventList = eventList;
    this._eventService = eventService;
    this.eventName = eventName;

    bindAllMethods(this);

    this._startCriterionEvent();
  }

  private _startCriterionEvent() {
    if (
      Array.isArray(this?._eventList) &&
      !!this._eventService &&
      !this._startedCriterionEvent
    ) {
      this._startedCriterionEvent = true;
      this._eventList.forEach((key) =>
        this._eventService.addEventListener(key, () =>
          this._eventService.publish(this.eventName, {})
        )
      );
    }
  }

  private _handleCheckResult({
    calledFunctionReference,
    criterion,
    resultValue
  }: {
    criterion: T.CriterionType<V>;
    calledFunctionReference?: CriterionOperationFunctionType | V[string];

    resultValue: boolean;
  }): boolean {
    const type = (criterion as T.GetCriterionValuesType<V>)?.type;
    const operation = (criterion as T.CriterionOperationObjectType<V>)
      ?.operation;
    const isValidCriterion = !!type || !!operation;

    const validCriterionLog = (text: string) =>
      console.error(
        `Criterion ${operation ? 'operation' : 'type'} function for (${
          operation ? operation : type
        }) ${text}, returning default value (${this._defaultValue}) instead`
      );

    if (!isValidCriterion) {
      console.error(
        `Invalid criterion on criterion.checkAdditionalCriterionData call, returning default value (${this._defaultValue}) instead`,
        { criterion }
      );
    } else if (resultValue === undefined) {
      if (!calledFunctionReference) {
        validCriterionLog(`does not exist`);
      } else {
        validCriterionLog(`returned undefined`);
      }
    }

    return resultValue === undefined ? this._defaultValue : resultValue;
  }

  private async _checkValueFunction(
    criterion: T.GetCriterionValuesType<V>,
    options?: T.CheckOptionsType
  ): Promise<T.CheckReturnObjectType> {
    const calledFunctionReference =
      this._getValueFunctions()?.[criterion?.type];

    const criterionOptions = criterion?.options || options?.criterionOptions;

    const resultValue = await (async () => {
      try {
        const result = await calledFunctionReference?.(
          criterion?.value,
          criterionOptions
        );

        // Ass the value function could or not be a promise, need to force await to fall on catch if it throw any error
        return result;
      } catch (error) {
        console.error(error);

        // Any error here is understood as a "false" value, eg. user does not have permission to call entitlement API
        return false;
      }
    })();

    const result = this._handleCheckResult({
      calledFunctionReference,
      criterion,
      resultValue
    });

    let fallbackKey: string;

    if (!result) {
      fallbackKey = criterion?.fallbackKey || options?.fallbackKey;
    }

    return {
      result,
      fallbackKey
    };
  }

  private async _checkSingleCriterionOperationFunction(
    calledFunctionReference: CriterionOperationFunctionSingleCriterionType,
    criterion: T.CriterionOperationSingleCriterionObjectType<V>,
    criterionOptions?: T.CheckOptionsType
  ): Promise<T.CheckReturnObjectType> {
    const checkValue = await calledFunctionReference?.({
      criterion: criterion?.criterion as T.CriterionType<any>,
      // @ts-ignore
      recursion: async (thisCriterion: T.CriterionType<V>) =>
        this.checkAdditionalCriterionData(thisCriterion, {
          criterionOptions,
          fallbackKey: criterionOptions?.fallbackKey
        })
    });

    return checkValue;
  }

  private async _checkMultipleCriterionOperationFunction(
    calledFunctionReference: CriterionOperationFunctionMultipleCriterionType,
    criterion: T.CriterionOperationMultipleCriterionObjectType<V>,
    criterionOptions?: T.CheckOptionsType
  ): Promise<T.CheckReturnObjectType> {
    const checkValue = await calledFunctionReference?.({
      criterions: criterion?.criterions as T.CriterionType<any>[],
      // @ts-ignore
      recursion: async (thisCriterion: T.CriterionType<V>) =>
        this.checkAdditionalCriterionData(thisCriterion, {
          criterionOptions,
          fallbackKey: criterionOptions?.fallbackKey
        })
    });

    return checkValue;
  }

  private async _checkOperationFunction(
    criterion: T.CriterionOperationObjectType<V>,
    options?: T.CheckOptionsType
  ): Promise<T.CheckReturnObjectType> {
    const calledFunctionReference = operationFunctions?.[criterion?.operation];

    const criterionOptions = criterion?.options || options?.criterionOptions;

    let checkValue: T.CheckReturnObjectType;

    if (criterion?.operation === E.OperationsEnum.NOT) {
      checkValue = await this._checkSingleCriterionOperationFunction(
        calledFunctionReference as CriterionOperationFunctionSingleCriterionType,
        criterion as T.CriterionOperationSingleCriterionObjectType<V>,
        criterionOptions
      );
    } else {
      checkValue = await this._checkMultipleCriterionOperationFunction(
        calledFunctionReference as CriterionOperationFunctionMultipleCriterionType,
        criterion as T.CriterionOperationMultipleCriterionObjectType<V>,
        criterionOptions
      );
    }

    const result = this._handleCheckResult({
      calledFunctionReference,
      criterion,
      resultValue: checkValue?.result
    });

    let fallbackKey: string;

    if (!result) {
      fallbackKey =
        checkValue?.fallbackKey ||
        criterion?.fallbackKey ||
        options?.fallbackKey;
    }

    return {
      result,
      fallbackKey
    };
  }

  getCriterionList(): T.CriterionWithKeyType<V>[] {
    return this._criterionList;
  }

  private async checkAdditionalCriterionData(
    criterion: T.CriterionType<V>,
    options?: T.CheckOptionsType
  ): Promise<T.CheckReturnObjectType> {
    if (!criterion) {
      console.warn(
        `criterion.checkAdditionalCriterionData received a invalid criterion, returning default value for this, which is ${this._defaultValueForUndefined}.`,
        criterion
      );
      return {
        result: this._defaultValueForUndefined
      };
    }

    let checkValue: T.CheckReturnObjectType;
    const operationObject = criterion as T.CriterionOperationObjectType<V>;
    const valueObject = criterion as T.CriterionValueObjectGenericType<any>;

    if (operationObject?.operation) {
      checkValue = await this._checkOperationFunction(operationObject, options);
    } else if (valueObject?.type) {
      checkValue = await this._checkValueFunction(valueObject, options);
    }

    const result = this._handleCheckResult({
      criterion,
      resultValue: checkValue?.result
    });
    let fallbackKey: string;

    if (!result) {
      fallbackKey =
        checkValue?.fallbackKey ||
        criterion?.fallbackKey ||
        options?.fallbackKey;
    }

    return {
      result,
      fallbackKey
    };
  }

  addValueFunctions(
    valueFunctions: T.CriterionValueFunctionsObjectType<
      any,
      T.CriterionOptionsType
    >
  ): void {
    for (const key in valueFunctions) {
      if (this._valueFunctions?.[key]) {
        console.warn(`Criterion value function ${key} already exists.`);
      } else {
        this._valueFunctions[key] = valueFunctions[key];
      }
    }
  }

  private _getValueFunctions(): V {
    return this._valueFunctions as V;
  }

  async check(criterion: T.CriterionType<V>): Promise<boolean> {
    if (!criterion) {
      console.warn(
        `criterion.check received a invalid criterion, returning default value for this, which is ${this._defaultValueForUndefined}.`,
        criterion
      );

      return !!this._defaultValueForUndefined;
    }

    const checkValue = await this.checkAdditionalCriterionData(criterion);

    return !!checkValue?.result;
  }

  getCriterionByKey(key: string): T.CriterionWithKeyType<V> {
    const criterionList = this.getCriterionList();

    return criterionList?.find?.((value) => value?.key === key);
  }

  async checkByCriterionKey(key: string): Promise<boolean> {
    if (!key) {
      console.warn(
        `criterion.checkByCriterionKey received a invalid key, returning default value for this, which is ${this._defaultValueForUndefined}.`,
        key
      );
      return !!this._defaultValueForUndefined;
    }
    const criterion = this.getCriterionByKey(key);
    const checkValue = await this.checkAdditionalCriterionData(criterion);

    return !!checkValue?.result;
  }

  async checkAdditionalCriterionDataByKey(
    key: string
  ): Promise<T.CheckReturnObjectType> {
    if (!key) {
      console.warn(
        `criterion.checkAdditionalCriterionDataByKey received a invalid key, returning default value for this, which is ${this._defaultValueForUndefined}.`,
        key
      );

      return { result: this._defaultValueForUndefined };
    } else {
      const criterion = this.getCriterionByKey(key);

      return this.checkAdditionalCriterionData(criterion);
    }
  }

  listen(callback: T.CriterionListenerType): () => void {
    const callbackProxy = async () => {
      callback?.();
    };

    this._eventService?.addEventListener?.(this.eventName, callbackProxy);

    return () =>
      this._eventService?.removeEventListener?.(this.eventName, callbackProxy);
  }

  useReactCheckAdditionalCriterionDataByKey(
    React: any,
    key: string
  ): T.CheckReturnObjectType {
    const [value, setValue] = React.useState({
      result: false
    } as T.CheckReturnObjectType);

    React.useEffect(() => {
      if (key) {
        const checkData = async () => {
          this.checkAdditionalCriterionDataByKey(key).then((v) => setValue(v));
        };

        checkData();

        const removeListener = this.listen(checkData);

        return () => removeListener();
      } else {
        setValue(true);
      }
    }, [key]);

    return value;
  }

  useReactCheckAdditionalCriterionData(
    React: any,
    criterion: T.CriterionType<V>
  ): T.CheckReturnObjectType {
    const [value, setValue] = React.useState({
      result: false
    } as T.CheckReturnObjectType);
    const [memoCriterion, setMemoCriterion] = React.useState(criterion);

    React.useEffect(() => {
      setMemoCriterion((previousMemoCriterion) => {
        try {
          if (
            JSON.stringify(criterion) !== JSON.stringify(previousMemoCriterion)
          ) {
            return criterion;
          } else {
            return previousMemoCriterion;
          }
        } catch {
          return criterion;
        }
      });
    }, [criterion]);

    React.useEffect(() => {
      if (memoCriterion) {
        const checkData = async () => {
          this.checkAdditionalCriterionData(memoCriterion).then((v) =>
            setValue(v)
          );
        };

        checkData();

        const removeListener = this.listen(checkData);

        return () => removeListener();
      } else {
        setValue(true);
      }
    }, [memoCriterion]);

    return value;
  }

  useReactCheckByCriterionKey(React: any, key: string): boolean {
    const [value, setValue] = React.useState(false);

    React.useEffect(() => {
      if (key) {
        const checkData = async () => {
          this.checkByCriterionKey(key).then((v) => setValue(v));
        };

        checkData();

        const removeListener = this.listen(checkData);

        return () => removeListener();
      } else {
        setValue(true);
      }
    }, [key]);

    return value;
  }

  useReactCheck(React: any, criterion: T.CriterionType<V>): boolean {
    const [value, setValue] = React.useState(false);
    const [memoCriterion, setMemoCriterion] = React.useState(criterion);

    React.useEffect(() => {
      setMemoCriterion((previousMemoCriterion) => {
        try {
          if (
            JSON.stringify(criterion) !== JSON.stringify(previousMemoCriterion)
          ) {
            return criterion;
          } else {
            return previousMemoCriterion;
          }
        } catch {
          return criterion;
        }
      });
    }, [criterion]);

    React.useEffect(() => {
      if (memoCriterion) {
        const checkData = async () => {
          this.check(memoCriterion).then((v) => setValue(v));
        };

        checkData();

        const removeListener = this.listen(checkData);

        return () => removeListener();
      } else {
        setValue(true);
      }
    }, [memoCriterion]);

    return value;
  }
}
