import { ILayoutsService } from '../../LayoutsService';
import bindAllMethods from '../../../utils/bindAllMethods';
import { PreFetchHandler } from '../daemons/PreFetchHandler';
import { getServices } from '../../../infra/commonInitializer';
import { StageType } from '../types';

type OperationStateBaseOptionsType = {
  overwrite?: boolean;
};

export default class StageBuilder {
  private state: StageType = {};
  private _preFetchHandler;
  private _layoutService: ILayoutsService;

  constructor(options?: { state?: StageType }) {
    bindAllMethods(this);
    const { layoutsService } = getServices();

    this._layoutService = layoutsService;

    if (options?.state) {
      this.state = options?.state;
    }

    this._preFetchHandler = new PreFetchHandler();
  }

  preFetchLayoutByKey(layoutKey: string): void {
    if (layoutKey) {
      const thisLayout = this._layoutService.getLayoutByKey(layoutKey);

      this._preFetchHandler.preFetchAssetReference(thisLayout?.assetReference);
    }
  }

  getState(): StageType {
    return this.state;
  }

  setContent(
    content: StageType['content'],
    options?: OperationStateBaseOptionsType
  ): void {
    const state = this.getState();
    const isDataValid = typeof content?.enable === 'boolean';
    const isCurrentDataValid = typeof state?.content?.enable === 'boolean';

    if ((isDataValid && !isCurrentDataValid) || options?.overwrite) {
      this._preFetchHandler.preFetchAssetReference(
        content?.assetReference,
        content?.preloadAssetReferenceLocale
      );
      this.state.content = content;
    }
  }

  setLayout(
    layout: StageType['layout'],
    options?: OperationStateBaseOptionsType
  ): void {
    const state = this.getState();
    const isDataValid = typeof layout?.enable === 'boolean';
    const isCurrentDataValid = typeof state?.layout?.enable === 'boolean';

    if ((isDataValid && !isCurrentDataValid) || options?.overwrite) {
      this._preFetchHandler.preFetchAssetReference(
        layout?.assetReference,
        layout?.preloadAssetReferenceLocale
      );
      this.state.layout = layout;
    }
  }

  setModalContent(
    modalContent: StageType['modalContent'],
    options?: OperationStateBaseOptionsType
  ): void {
    const state = this.getState();
    const isDataValid = typeof modalContent?.enable === 'boolean';
    const isCurrentDataValid = typeof state?.modalContent?.enable === 'boolean';

    if ((isDataValid && !isCurrentDataValid) || options?.overwrite) {
      this._preFetchHandler.preFetchAssetReference(
        modalContent?.assetReference,
        modalContent?.preloadAssetReferenceLocale
      );
      this.state.modalContent = modalContent;
    }
  }

  setEndProcessChain(
    endProcessChain: StageType['endProcessChain'],
    options?: OperationStateBaseOptionsType
  ): void {
    const state = this.getState();
    const isDataValid = endProcessChain === true;
    const isCurrentDataValid = state?.endProcessChain === true;

    if ((isDataValid && !isCurrentDataValid) || options?.overwrite) {
      this.state.endProcessChain = endProcessChain;
    }
  }

  setLayoutByKey(
    layoutKey: string | false,
    options?: OperationStateBaseOptionsType
  ): void {
    let layout: StageType['layout'];

    if (layoutKey && typeof layoutKey === 'string') {
      const thisLayout = this._layoutService.getLayoutByKey(layoutKey);

      layout = {
        ...thisLayout,
        enable: !!thisLayout
      };
    } else if (layoutKey === false) {
      layout = {
        enable: true
      };
    }

    this.setLayout(layout, options);
  }

  setRedirectTo(
    redirectTo: StageType['redirectTo'],
    options?: OperationStateBaseOptionsType
  ): void {
    const state = this.getState();
    if ((redirectTo && !state?.redirectTo) || options?.overwrite) {
      this.state.redirectTo = redirectTo;
    }
  }
}
