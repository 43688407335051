import StageBuilder from '../../../stageBuilder/StageBuilder';

import {
  CloseServiceInstanceOptions,
  Service
} from '../../../../../services/webServiceRouting/types';
import * as T from './types';
import matchPath from '../../../../../utils/matchPath';
import getOnboardingSessionAppDetails from './utils/getOnboardingSessionAppDetails';
import { isNative } from '../../../../../services/JWeb';
import {
  closeNativeOnboardingInstance,
  getNativeServiceLaunchOptions
} from '../../../../../services/JWeb/JWebServiceRouting';
import { OnboardingInstanceSession } from './newTypes';
import { internalLogger } from '../../../../../interface/v1/logger';
import OnboardingDirector from './clients/OnboardingDirector';
import Fallback from '../../../../../interface/v1/Fallback';
import {
  createOnboardingSimpleUiEventData,
  getLastDateAndTime,
  getProductName
} from './utils/Helpers';
import {
  EventDataActionEnum,
  EventDataControlNameEnum
} from '../../../../../services/AnalyticsService/dependencies/AnalyticsEventEnums';
import EventNames from '../../../../../config/eventNames';
import { getServices } from '../../../../../infra/commonInitializer';
import { serviceLaunchOptionsCacheKey } from './OnboardingAgent';
import WebServiceRouting from '../../../../../services/webServiceRouting';
import ListenerManager from './ListenerManager';
import { PromiseReturnType } from '../../../../../types/typeHandlers';
import authProvider from '../../../../../interface/v1/auth';
import { Stack } from '@jarvis/web-stratus-client';
import bindAllMethods from '../../../../../utils/bindAllMethods';
import { ServiceOnboardingTriggerType } from './clients/OnboardingDirector/types';
import IEventService from '../../../../../services/eventService/IEventService';

interface SessionManagerConstructorProps {
  navigationInterface: T.OnboardingAgentConstructorDependencies['navigationInterface'];
  localizationInterface: T.OnboardingAgentConstructorDependencies['localizationInterface'];
  fallbackInterface: Fallback;
  tenantHandlerService: T.OnboardingAgentConstructorDependencies['tenantHandlerService'];
  onboardingDirectorClientId: string | undefined;
  onboardingTriggerList: any;
  stack: Stack;
  authProviderInterface: PromiseReturnType<typeof authProvider>;
  resumeOnTriggers?: boolean;
}

/**
 * This class provides interface for creating and updating an onboarding session.
 */
export default class SessionManager {
  private _fallbackInterface: Fallback;
  private _navigationInterface: T.OnboardingAgentConstructorDependencies['navigationInterface'];
  private _localizationInterface: T.OnboardingAgentConstructorDependencies['localizationInterface'];
  private _tenantHandler: T.OnboardingAgentConstructorDependencies['tenantHandlerService'];
  private _getServiceLaunchOptionsFromNativePromise?: Promise<T.OnboardingLaunchServiceOptions>;
  private _trigger: ServiceOnboardingTriggerType;
  private _service: Service;
  private _resumeOnTriggers = false;
  private _onboardingTriggerList: T.OnboardingTrigger[] = [];
  private _getRemoteCachedOnboardingAgentSessionPromise: Map<
    string,
    Promise<T.OnboardingInstanceSession>
  >;
  private _onboardingDirector: OnboardingDirector;
  private _onboardingDirectorClientId: string;
  private _eventService: IEventService;
  private _webServiceRouting: WebServiceRouting;
  private updateCallback: () => void;
  private listenerManager: ListenerManager;
  private _closeOnboardingInstancePromise: Promise<void>;
  stateBuilder: StageBuilder;
  existingSessionPromise: Promise<void>;
  clearExistingSessionPromise: () => void;
  session: OnboardingInstanceSession;
  getSession: () => Promise<T.OnboardingInstanceType['session']>;

  constructor(
    {
      stack,
      onboardingDirectorClientId,
      onboardingTriggerList,
      authProviderInterface,
      fallbackInterface,
      navigationInterface,
      localizationInterface,
      tenantHandlerService,
      resumeOnTriggers
    }: SessionManagerConstructorProps,
    updateCallback: () => Promise<void>,
    listenerManager: ListenerManager
  ) {
    const { webServiceRouting, eventService } = getServices();
    this._webServiceRouting = webServiceRouting;
    this._eventService = eventService;
    this._onboardingDirector = new OnboardingDirector(
      stack,
      authProviderInterface
    );
    this.listenerManager = listenerManager;
    this._navigationInterface = navigationInterface;
    this._localizationInterface = localizationInterface;
    this._fallbackInterface = fallbackInterface;
    this._tenantHandler = tenantHandlerService;
    this._onboardingDirectorClientId = onboardingDirectorClientId;
    this.updateCallback = updateCallback;
    this._resumeOnTriggers = resumeOnTriggers;
    this._getRemoteCachedOnboardingAgentSessionPromise = new Map();
    if (Array.isArray(onboardingTriggerList)) {
      this._onboardingTriggerList = onboardingTriggerList;
    }
    bindAllMethods(this);
  }

  /**
   * Interface for the parent callback function.
   * ## Anytime a session is updated the parent should be informed so it may handle the resulting state.
   */
  private update = async () => {
    // TODO: In what use cases is this applicable
    // if (this.existingSessionPromise) {
    //   internalLogger?.log?.(
    //     'onboarding-web-_setOnboardingInstanceSession-error: ',
    //     'pending promise'
    //   );
    //   return;
    // }
    await this.updateCallback();
  };

  /**
   * Onboarding Launching
   */

  /**
   * Launches a new session given no current one exists
   * @private
   */
  private async _launchOnboardingInstance(): Promise<void> {
    if (this.existingSessionPromise) {
      internalLogger?.log?.(
        'onboarding-web-_setOnboardingInstanceSession-error: ',
        'pending promise'
      );
      return;
    }
    // Create the existing promise if one does not exist
    this.createExistingSessionPromise();
    // Set the function to get the session so it is exposed
    this.getSession = this.startOnboardingDirectorSession;
    // Fetch the session
    this.session = await this.startOnboardingDirectorSession().catch(
      (error) => {
        console.error(error);
        return undefined;
      }
    );
    await this.update();
  }

  /**
   * Launches onboarding based on the type of event.
   * - Trigger: Onboarding trigger from manifest
   * - Route: Route configured in manifest
   */
  launchOnboarding = async (): Promise<void> => {
    const path = this._navigationInterface?.location?.pathname;
    this._trigger = this._getOnboardingTriggerFromPath(path);

    if (this._trigger?.path) {
      await this._handleTrigger();
    } else {
      await this._handleRoute(path);
    }
  };

  /**
   * Session Management with OD
   */

  /**
   * Launches a new session with Onboarding Director
   * @private
   */
  private async startOnboardingDirectorSession(): Promise<
    T.OnboardingInstanceType['session']
  > {
    const app = await this._getEnvironmentDetails();
    const nativeServiceLaunchOptions =
      await this.getServiceLaunchOptionsFromNative();

    let context = nativeServiceLaunchOptions?.serviceOptions?.onboardingContext;

    if (!context) {
      context = await this._onboardingDirector.post({
        data: {
          onboardingContext: {
            ...this._trigger?.onboardingContext,
            entryUrl: window.location.href
          },
          app
        }
      });
    }

    internalLogger?.log?.(`start-onboarding-agent-session-from-trigger: `, {
      trigger: this._trigger
    });

    return {
      context,
      trigger: this._trigger,
      appSessionId: nativeServiceLaunchOptions?.serviceOptions?.appSessionId
    };
  }

  /**
   * Enumerates a session with Onboarding Director by completing the current stage
   * on the provided resultUrl
   * @param resultData - Result from current executing service
   */
  async getNextOnboardingDirectorStage(resultData): Promise<void> {
    const context = await this._onboardingDirector.put({
      baseURL: this.session?.context?.nextService?.resultUrl,
      data: {
        ...resultData,
        xCorrelationId:
          resultData?.xCorrelationId ||
          this.session?.context?.sessionContext?.xCorrelationId
      }
    });

    this.session = {
      context,
      trigger: this.session.trigger,
      appSessionId: this.session.appSessionId
    };

    await this.update();
  }

  /**
   * Closes the onboarding session
   * - Native: Closes session through service routing
   * - Web: Loads the exit url provided by Onboarding Director
   * @param options
   */
  async closeOnboardingInstance(options: {
    result: CloseServiceInstanceOptions['resultData']['result'];
  }): Promise<void> {
    if (this._closeOnboardingInstancePromise)
      return this._closeOnboardingInstancePromise;
    // TODO: Enable this promise to finish, when remove navigation using href
    // let finishPromise: () => void;
    this._closeOnboardingInstancePromise = new Promise(() => {
      // finishPromise = () => {
      //   resolve();
      //   this._closeOnboardingInstacePromise = undefined;
      // };
    });

    const webServiceRoutingInstance =
      this._webServiceRouting.separateErrorObject(
        await this._webServiceRouting.getServiceInstance()
      )?.data;
    const isNativeEnvironment = await isNative();

    if (
      webServiceRoutingInstance?.state !==
      this._webServiceRouting.ServiceInstanceState?.closed
    ) {
      this._webServiceRouting.closeServiceInstance({
        resultData: { result: options.result }
      });
    }

    const resultData = {
      appSessionId: this.session?.appSessionId,
      serviceId: this.session?.context?.nextService?.serviceId,
      result: {
        result: options.result,
        xCorrelationId: this.session?.context?.sessionContext?.xCorrelationId
      }
    };

    this._eventService.publish(
      this._eventService.eventNames.webOnboardingFinished,
      resultData
    );

    window.sessionStorage.removeItem(serviceLaunchOptionsCacheKey);
    this.listenerManager.stopConfirmBeforeLeaveListener();

    if (isNativeEnvironment) {
      await closeNativeOnboardingInstance({
        resultData
      });
    } else {
      const defaultRedirect = this._navigationInterface.createHref({
        pathname: '/'
      });
      const exitUrl = this.session?.context?.nextService?.exitUrl;

      internalLogger?.log?.(
        `close-onboarding-instance-redirect-to: ${exitUrl || defaultRedirect}`
      );

      window.location.href = exitUrl || defaultRedirect;
    }

    // TODO: Enable this promise to finish, when remove navigation using href
    // finishPromise();
  }

  /**
   * State handlers
   */

  /**
   * Handles the failed state of an onboarding session
   */
  handleFailedState = async (): Promise<void> => {
    const service = await this.getServiceFromServiceId(
      this.session?.context?.nextService?.serviceId
    );
    this._updateOnboardingState(service);

    const fallbackKey =
      this._service?.fallbackKeys?.failedToLaunch ||
      this._trigger?.fallbackKeys?.failedToLaunch;

    const serviceFallback =
      this._fallbackInterface.getFallbackByKey(fallbackKey);

    const rootProperties = {
      retryUpdateOnboardingSession: async () => {
        await this.update();
      },
      closeOnboardingSession: async () =>
        this.closeOnboardingInstance({
          result: 'failed'
        })
    };
    if (serviceFallback) {
      if (serviceFallback?.modalContent?.assetReference) {
        this.stateBuilder.setModalContent({
          enable: true,
          assetReference: serviceFallback?.modalContent?.assetReference,
          properties: serviceFallback?.modalContent?.properties,
          key: serviceFallback?.modalContent?.key,
          rootProperties
        });
      }

      this.setServiceLayout(serviceFallback?.layoutKey);
    } else {
      this.stateBuilder.setContent({
        enable: true,
        loadDefaultOnboardingError: true,
        rootProperties
      });
    }
  };

  /**
   * Handles the running or launching state of an onboarding session
   */
  handleRunningOrLaunchingState = async (): Promise<void> => {
    const service = await this.getServiceFromServiceId(
      this.session?.context?.nextService?.serviceId
    );
    this._updateOnboardingState(service);
    this.setServiceLayout();
  };

  /**
   * Helpers
   */

  /**
   * Builds properties for the resume session modal
   */
  private getResumeSessionModalRootProperties() {
    const lastDateAndTime = getLastDateAndTime(this.session);
    const productName = getProductName(this.session);

    return {
      resumeSessionModalInfo: {
        lastDateAndTime,
        lastStep: this.session?.context?.nextService?.serviceId,
        productName
      },
      resumeSession: async () => {
        await this._eventService.publish(
          EventNames.shellAnalyticsSimpleUiEvent,
          createOnboardingSimpleUiEventData({
            action: EventDataActionEnum.controlButtonClicked,
            controlName: EventDataControlNameEnum.resumeButton
          })
        );

        internalLogger?.log?.(`resuming-onboarding-session`);

        // TODO: Test this, needs to use the current session in the 'update' function
        await this.update();
        // await this._onboardingAgent.resumeOnboardingInstance(
        //   onboardingInstanceSession
        // );
      },
      startNewSession: async () => {
        await this._eventService.publish(
          EventNames.shellAnalyticsSimpleUiEvent,
          createOnboardingSimpleUiEventData({
            action: EventDataActionEnum.controlButtonClicked,
            controlName: EventDataControlNameEnum.startNewSessionButton
          })
        );

        internalLogger?.log?.(
          `starting-new-onboarding-session-from-resume-modal`
        );

        await this._launchOnboardingInstance();
      }
    };
  }

  isValidLayoutKey = (layoutKey: string | false) =>
    typeof layoutKey === 'string' || layoutKey === false;

  setLayout = (layoutKey: string | false): void => {
    if (this.isValidLayoutKey(layoutKey)) {
      this.stateBuilder.setLayoutByKey(layoutKey);
      internalLogger?.log?.(`set-onboarding-by-layout-key-${layoutKey}`);
    } else {
      this.stateBuilder.setLayout({
        enable: true,
        useDefaultOnboardingLayout: true
      });
      internalLogger?.log?.(`set-default-onboarding-layout`);
    }
  };

  setServiceLayout = (customLayoutKey?: string | false) => {
    if (this.isValidLayoutKey(customLayoutKey)) {
      this.setLayout(customLayoutKey);
    } else if (this.isValidLayoutKey(this._service?.layoutKey)) {
      this.setLayout(this._service?.layoutKey);
    } else {
      this.setLayout(this._trigger?.layoutKey);
    }
  };

  private _handleTrigger = async (): Promise<void> => {
    this.stateBuilder.setEndProcessChain(true);
    if (this._trigger?.layoutKey) {
      this.stateBuilder.preFetchLayoutByKey(this._trigger?.layoutKey);
    }
    const remoteCachedOnboardingAgentSession = this._resumeOnTriggers
      ? await this.getRemoteCachedOnboardingAgentSession(this._trigger)
      : undefined;

    if (remoteCachedOnboardingAgentSession) {
      this.setLayout(this._trigger?.layoutKey);
      this.session = remoteCachedOnboardingAgentSession;
      const rootProperties = this.getResumeSessionModalRootProperties();

      this.stateBuilder.setModalContent({
        enable: true,
        rootProperties,
        showResumeSessionModal: true
      });
    } else {
      await this._launchOnboardingInstance();
    }
  };

  private _getLocalCachedOnboardingAgentSession(): T.OnboardingInstanceSession {
    try {
      const stringfiedServiceLaunchOptions = window.sessionStorage.getItem(
        serviceLaunchOptionsCacheKey
      );

      if (stringfiedServiceLaunchOptions) {
        return JSON.parse(stringfiedServiceLaunchOptions);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private _handleRoute = async (path: string): Promise<void> => {
    const cachedOnboardingInstanceSession =
      this._getLocalCachedOnboardingAgentSession();

    const serviceIdFromCache =
      cachedOnboardingInstanceSession?.context?.nextService?.serviceId;

    if (serviceIdFromCache) {
      const serviceFromPath = await this.getServiceFromPath(path);

      if (serviceFromPath?.id) {
        // Resume the session
        this.stateBuilder.setEndProcessChain(true);
        this.session = cachedOnboardingInstanceSession;
        await this.update();
      }
    }
  };

  private createExistingSessionPromise = (): void => {
    this.existingSessionPromise = new Promise((resolve) => {
      this.clearExistingSessionPromise = () => {
        resolve();
        this.existingSessionPromise = undefined;
      };
    });
  };

  /**
   * Finds a service in the current manifest
   * @param callback - Callback to execute with found service
   */
  async findService(callback: (service: Service) => boolean): Promise<Service> {
    const services = this._webServiceRouting.separateErrorObject(
      await this._webServiceRouting.getServices()
    )?.data?.services;

    return services?.find((service) => !!callback(service));
  }

  async getServiceFromServiceId(serviceId: string): Promise<Service> {
    if (serviceId) {
      return this.findService((service) => service.id === serviceId);
    }
  }

  async getServiceFromPath(path: string): Promise<Service> {
    return this.findService((service) => {
      if (service.path) {
        return matchPath(service.path, { exact: true, pathToCompare: path });
      }
      return false;
    });
  }

  private _updateOnboardingState = (service: Service) => {
    this.stateBuilder.setEndProcessChain(true);
    this._service = service;
    this._trigger = this.session?.trigger;
  };

  private async getServiceLaunchOptionsFromNative(): Promise<T.OnboardingLaunchServiceOptions> {
    if (!this._getServiceLaunchOptionsFromNativePromise) {
      this._getServiceLaunchOptionsFromNativePromise = (async () => {
        if (!(await isNative())) return undefined;
        const nativeServiceLaunchOptions =
          (await getNativeServiceLaunchOptions()) as T.OnboardingLaunchServiceOptions;

        const serviceOptions = nativeServiceLaunchOptions?.serviceOptions;
        const serviceId =
          serviceOptions?.onboardingContext?.nextService?.serviceId;

        if (serviceId) {
          return {
            serviceId,
            serviceOptions
          };
        }
      })();
    }

    return this._getServiceLaunchOptionsFromNativePromise;
  }
  private async _getEnvironmentDetails() {
    return getOnboardingSessionAppDetails({
      browserLocale: {
        country: this._localizationInterface.country?.toUpperCase() || 'US',
        language: this._localizationInterface.language?.toUpperCase() || 'EN'
      },
      clientId: this._onboardingDirectorClientId
    });
  }
  private _getOnboardingTriggerFromPath(path: string): T.OnboardingTrigger {
    return this._onboardingTriggerList.find((trigger) => {
      return matchPath(trigger.path, { exact: true, pathToCompare: path });
    });
  }

  private async resumeSession(trigger) {
    const app = await this._getEnvironmentDetails();

    const haveTenant = !!this._tenantHandler.getTenantId(1);

    if (!haveTenant) return undefined;

    const onboardingContext = {
      ...(trigger?.onboardingContext || ({} as any)),
      entryUrl: window.location.href
    };
    const limit = 1;

    const contextList = await this._onboardingDirector.get({
      data: {
        onboardingContext,
        app,
        limit
      }
    });

    const context = contextList?.[0];

    if (context?.nextService?.serviceId) {
      const appSessionId = (await this.getServiceLaunchOptionsFromNative())
        ?.serviceOptions?.appSessionId;

      return {
        trigger,
        appSessionId,
        context
      };
    }
  }

  private async getRemoteCachedOnboardingAgentSession(
    trigger: T.OnboardingTrigger
  ): Promise<T.OnboardingInstanceSession> {
    const stringfiedTrigger = JSON.stringify(trigger);
    const cachedPromise =
      this._getRemoteCachedOnboardingAgentSessionPromise.get(stringfiedTrigger);
    if (cachedPromise) return cachedPromise;

    try {
      const promise = this.resumeSession(trigger)?.catch((error) => {
        console.error(error);

        return undefined as T.OnboardingInstanceSession;
      });

      this._getRemoteCachedOnboardingAgentSessionPromise.set(
        stringfiedTrigger,
        promise
      );

      const result = await promise;

      return result;
    } finally {
      /**
       * keep cache for 10 seconds for same trigger
       */
      setTimeout(() => {
        this._getRemoteCachedOnboardingAgentSessionPromise.delete(
          stringfiedTrigger
        );
      }, 10000);
    }
  }
}
