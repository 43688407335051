import { pathToRegexp } from 'path-to-regexp';
import { NavigationInterfaceType } from '../../interface/v1/navigation/types';
import { RouteType } from '../../interface/v1/routes/types';

type GetCurrentRoutePropsType = {
  routes: RouteType[];
  navigation: NavigationInterfaceType;
};

// TODO: This is only used by composer, this method should be there or composer should use the route interface
export default function getCurrentRoute({
  navigation,
  routes
}: GetCurrentRoutePropsType): RouteType | undefined {
  return routes?.find?.((route) => {
    const path = (() => {
      const arrayConfigPath = Array.isArray(route?.path)
        ? route?.path
        : [route?.path];

      return arrayConfigPath.map((pathname) => {
        const pathWithSearch = navigation.createHref({ pathname });

        return pathWithSearch?.split('?')?.[0];
      });
    })();

    if (
      path?.some((value) => pathToRegexp(path).test(window.location.pathname))
    ) {
      return true;
    } else if (route?.subRoutes?.length > 0) {
      return getCurrentRoute({ routes: route?.subRoutes, navigation });
    } else {
      return false;
    }
  });
}
