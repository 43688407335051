import { AnalyticsInterfaceType } from './types';
import AnalyticsInterface from './AnalyticsInterface';
import { createNoopAnalyticsInterface } from './NoopInterface';
import { getServices } from '../../../infra/commonInitializer';

let analyticsInterface: AnalyticsInterface;

export function getAnalyticsInterface(): AnalyticsInterface {
  return analyticsInterface;
}

export default async function initializeAnalyticsInterface({
  isEnabled
}: {
  isEnabled: boolean;
}): Promise<AnalyticsInterfaceType> {
  if (!isEnabled) {
    return createNoopAnalyticsInterface({
      logCallback: () => console.warn('Analytics is Disabled')
    });
  }
  if (analyticsInterface) return analyticsInterface.getInterface();

  const services = getServices();
  const { analyticsService } = services;

  analyticsInterface = new AnalyticsInterface({ analyticsService });

  return analyticsInterface.getInterface();
}
