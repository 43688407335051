import { isNative, getJWeb } from '../../../../../../../services/JWeb';
import getOSDetailsFromBrowser from '../../../../../../../utils/getOSDetailsFromBrowser';
import * as T from './types';

export default async function getOnboardingSessionAppDetails(
  options: T.GetBrowserAppDetailsOptionsType
): Promise<T.OnboardingSessionAppDetailsType> {
  if (await isNative()) {
    return getNativeAppInfo({ clientId: options.clientId });
  } else {
    return getBrowserAppInfo({
      clientId: options.clientId,
      browserLocale: options.browserLocale
    });
  }
}

async function getNativeAppInfo(
  options: T.GetNativeAppDetailsOptionsType
): Promise<T.OnboardingSessionAppDetailsType> {
  const clientId = options?.clientId;
  const jweb = await getJWeb();
  const deviceInformation = await jweb.Plugins.Device.getInfo();

  const { country, language } = deviceInformation?.locale || {};

  let countryRegionIso = 'US';
  let locale = 'en_US';

  if (country && language) {
    countryRegionIso = country?.toUpperCase();

    locale = `${language.toLowerCase()}_${countryRegionIso}`;
  }

  return {
    clientId,
    osType: deviceInformation?.platform,
    osVersion: deviceInformation?.osVersion,
    appVersion: deviceInformation?.appVersion,
    countryRegionIso,
    locale,
    container: 'jarvisApp'
  };
}

async function getBrowserAppInfo(
  options: T.GetBrowserAppDetailsOptionsType
): Promise<T.OnboardingSessionAppDetailsType> {
  const clientId = options?.clientId;
  const { language, country } = options?.browserLocale || {};

  let countryRegionIso = 'US';
  let locale = 'en_US';

  if (country && language) {
    countryRegionIso = country?.toUpperCase();

    locale = `${language.toLowerCase()}_${countryRegionIso}`;
  }

  const { osType, osVersion } = getOSDetailsFromBrowserDTO();

  return {
    clientId,
    osType,
    osVersion,
    countryRegionIso,
    locale,
    container: 'browser'
  };
}

function getOSDetailsFromBrowserDTO() {
  const osList: {
    valueToFind: string[];
    newValue: string;
  }[] = [
    {
      valueToFind: ['android'],
      newValue: 'android'
    },
    {
      valueToFind: ['ios'],
      newValue: 'ios'
    },
    {
      valueToFind: ['mac'],
      newValue: 'macos'
    },
    {
      valueToFind: ['win'],
      newValue: 'windows'
    }
  ];

  const osDetails = getOSDetailsFromBrowser();
  let osType = osDetails?.name;
  const osVersion = osDetails?.version;

  osList.forEach(({ valueToFind, newValue }) => {
    if (
      valueToFind?.some((v) => osType?.toLocaleLowerCase()?.indexOf?.(v) != -1)
    ) {
      osType = newValue;
    } else {
      osType = 'windows';
    }
  });

  return {
    osType: osType?.toLocaleLowerCase?.(),
    osVersion: osVersion || 'unknown'
  };
}
