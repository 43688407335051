export type ShortRouteListItemType = {
  path?: string | string[];
  priority?: number;
  subRoutes?: ShortRouteListItemType[];
};

export default function getRouteAsFlatMap(
  list: ShortRouteListItemType[],
  priority: number = 1
): ShortRouteListItemType[] {
  const result: ShortRouteListItemType[] = [];

  list?.forEach((route) => {
    const newRoute = { ...route };
    if (newRoute?.path) {
      newRoute.priority = priority;
      result.push(newRoute);
    }
    if (newRoute?.subRoutes) {
      const subRoutes = getRouteAsFlatMap(newRoute.subRoutes, priority + 1);

      result.push(...subRoutes);
    }
  });

  return result;
}
