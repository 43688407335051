import { base64Decode } from './base64Encoder';

export type DecodedStateParamType = {
  invite?: string;
  orgId?: string;
  postLoginRedirect?: string;
  skipUserOnboarding?: string;
  error_type?: string;
  skipAuthErrorScreen?: string;
};

export default function getDecodedURLStateParam(): DecodedStateParamType {
  const urlParams = new URLSearchParams(window.location.search);
  const name = 'state';

  const encodedState = urlParams.get(name);

  const stateObject: DecodedStateParamType = (() => {
    try {
      try {
        const decodedValue = base64Decode(encodedState);

        return typeof decodedValue === 'object' ? decodedValue : {};
      } catch {
        const parsedState = JSON.parse(encodedState);

        return typeof parsedState === 'object' ? parsedState : {};
      }
    } catch {
      return {};
    }
  })();

  return stateObject;
}
