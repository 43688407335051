import { GetKeyValueOptions,
  PutKeyValueOptions,
  RemoveKeyValueOptions,
  ValueStoreError,
  ValueStoreGetResult,
  ValueStorePlugin,
  ValueStorePutResult,
  ValueStoreRemoveResult,
  WebPlugin
} from '@jarvis/jweb-core';
import { ValueStoreService } from './valueStoreService';

export class ValueStorePluginWeb extends WebPlugin implements ValueStorePlugin {

  async get(options: GetKeyValueOptions): Promise<ValueStoreGetResult | ValueStoreError> {
    return ValueStoreService.get(options);
  }

  async put(options: PutKeyValueOptions): Promise<ValueStoreError | ValueStorePutResult> {
    return ValueStoreService.put(options);
  }

  async remove(options: RemoveKeyValueOptions): Promise<ValueStoreError | ValueStoreRemoveResult> {
    return ValueStoreService.remove(options);
  }

  getValueStoreService(): typeof ValueStoreService {
    return ValueStoreService;
  }
}

export const isValueStorePluginWeb = (plugin: ValueStorePlugin | undefined): plugin is ValueStorePluginWeb => plugin !== undefined && Object.keys(plugin).length > 0 && (plugin as ValueStorePluginWeb).getValueStoreService !== undefined;
