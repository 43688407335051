import * as T from './types';

const cacheStorage = new Map<string, T.CacheStorageValueType>();
function hasExternalSourceCache(
  options: T.ExternalSourceCacheKeyObject | string
): boolean {
  const key = createExternalSourceCacheKey(options);

  return cacheStorage.has(key);
}

function setExternalSourceCache(
  options: T.ExternalSourceCacheKeyObject | string,
  value: T.CacheStorageValueType
): void {
  const key = createExternalSourceCacheKey(options);

  cacheStorage.set(key, value);
}

function removeExternalSourceCache(
  options: T.ExternalSourceCacheKeyObject | string
): void {
  const key = createExternalSourceCacheKey(options);

  if (key) {
    cacheStorage.delete(key);
  }
}

function createExternalSourceCacheKey(
  options: T.ExternalSourceCacheKeyObject | string
): string {
  if (typeof options === 'string') {
    return options;
  } else if (options?.assetReference && options?.property) {
    return JSON.stringify({
      assetReference: options.assetReference,
      property: options.property,
      arguments: options.arguments,
      importKey: options.importKey
    });
  }
}

async function getExternalSourceCache(
  options: T.ExternalSourceCacheKeyObject | string,
  /** Receive a callback to call the value check function if cache doesn't exist */
  callback?: () => T.CacheStorageValueType
): Promise<T.CacheStorageResolveValueType> {
  const key = createExternalSourceCacheKey(options);
  if (!hasExternalSourceCache(key) && callback) {
    if (typeof callback === 'function') {
      setExternalSourceCache(key, callback?.());
    } else {
      console.error(
        'Criterion - getExternalSourceCache Callback must be a function'
      );
    }
  }

  if (hasExternalSourceCache(key)) {
    return cacheStorage.get(key);
  }
}

/**
 * Used to handle cache of external sources property values.
 */
const criterionExternalSourceCache = {
  hasExternalSourceCache,
  setExternalSourceCache,
  removeExternalSourceCache,
  getExternalSourceCache
};

export default criterionExternalSourceCache;
