import { findValueUsingJsonPath } from '../helpers/findJsonPathValues';
import { logger } from '../helpers/logger';

export enum FilterType {
  EXCLUSION = 'exclusion',
  INCLUSION = 'inclusion',
}

export const applyFilter = (
  type: string,
  filterAttributes: string[],
  filteredNode: any
) => {
  if (type === FilterType.INCLUSION) {
    // take the necessary fields and put into the object
    const newFilteredObject: any = {};
    filterAttributes.forEach((path: string) => {
      const nodeValuesAndPath = findValueUsingJsonPath(filteredNode, path);
      nodeValuesAndPath.forEach((element) => {
        const slicedPath = element.path.slice(1, element.path.length);
        createNewFilterObject(newFilteredObject, slicedPath, element.value);
      });
    });
    logger.log('applyFilter:CDMFilter::Inclusion::Filtered Node', newFilteredObject);
    return newFilteredObject;
  } else {
    // need to remove those attribute from the node
    filterAttributes.forEach((path: string) => {
      const NodeValuesAndPath = findValueUsingJsonPath(filteredNode, path);
      NodeValuesAndPath.forEach((element) => {
        const elementPath = element.path;
        const slicedPath = elementPath.slice(1, elementPath.length);
        let temp = filteredNode;
        for (const key of slicedPath) {
          const deleteKey = slicedPath[slicedPath.length - 1];
          if (key === deleteKey) {
            delete temp[deleteKey];
          } else {
            temp = temp[key];
          }
        }
      });
    });
  }
  logger.log('applyFilter:CDMFilter::Exclusion::Filtered Node', filteredNode);
  return filteredNode;
};

const createNewFilterObject = (newFilteredObject: any, keyPath: (string|number)[], value: any) => {
  const lastKeyIndex = keyPath.length - 1;
  for (let i = 0; i < lastKeyIndex; ++i) {
    const key = keyPath[i];
    if (!(typeof(keyPath[i+1]) === 'number')) {
      if (!(key in newFilteredObject)) {
        newFilteredObject[key] = {};
      }
    } else {
      if (!newFilteredObject[key]){
        newFilteredObject[key] = [];
      }
    }
    newFilteredObject = newFilteredObject[key];
  }
  newFilteredObject[keyPath[lastKeyIndex]] = value;
};

