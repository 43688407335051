import bindAllMethods from '../../../utils/bindAllMethods';
import { ILogoutClient } from '../../../clients/shell/logout';
import ILogoutService from './ILogoutService';
import { SetServiceDependencies } from '../../../infra/commonInitializer/types';
import { LogoutOptionsType } from '../ISessionService';
import { StateParamHandlerService } from '../../stateParamHandlerService';
import { StatesParamType } from '../../stateParamHandlerService/types';
import { INavigationService } from '../../../services/navigationService';

export type OnecloudLogoutServiceParameters = {
  logoutClient: ILogoutClient;
};

export default class OnecloudLogoutService implements ILogoutService {
  private _logoutClient: ILogoutClient;
  private _stateParamHandlerService: StateParamHandlerService;
  private _navigationService: INavigationService;

  public setDependencies({ clients, services }: SetServiceDependencies): void {
    const { logoutClient } = clients.onecloud;
    const { stateParamHandlerService, navigationService } = services;

    this._logoutClient = logoutClient;
    this._stateParamHandlerService = stateParamHandlerService;
    this._navigationService = navigationService;
    bindAllMethods(this);
  }

  public async logout(options?: LogoutOptionsType): Promise<void> {
    const { postLogoutRedirect } = options || {};

    const stateParamObject: StatesParamType =
      this._stateParamHandlerService.createLogoutStateObject(
        postLogoutRedirect
      );

    const redirectTo = this._logoutClient.configureLogoutUrl(
      location.origin,
      stateParamObject.state
    );

    return new Promise(() => {
      this._navigationService.redirect(redirectTo);
    });
  }
}
