import StageBuilder from '../../../stageBuilder/StageBuilder';
import { loginScene } from '../../LoginPathOperation/constants';
import { LoggedInHandleParamsType } from '../types';
import AbstractLoggedInHandler from './AbstractLoggedInHandler';

export default class ShellUserOnboardingHandler extends AbstractLoggedInHandler {
  protected processToShellOnboarding(stateBuilder: StageBuilder): void {
    // Currently, the onboarding is inside the login MFE.
    stateBuilder.setContent(loginScene);
    stateBuilder.setLayoutByKey(false);
    stateBuilder.setEndProcessChain(true);

    // For now, i'm not setting properties to the MFE, rooting
    // that wont be necessary.

    // const customProps = {
    //  properties: manifest.services.login.properties
    //  mosaicProps: manifest,
    //  session: services.session,
    //   stack: stack, =============== "maybe?"
    //   history: navigation,
    //   navigateFunction: navigation.push,
    //   getCurrentRoute,
    //   applicationStatusManager
    // };
  }

  public async handle(params: LoggedInHandleParamsType): Promise<void> {
    const {
      isUserOnboarded,
      manifest,
      services,
      proceedToShell,
      stateBuilder,
      decodedState
    } = params;

    const doesUserOnboardingRequiresOnboardingScreen =
      await services.userOnboardingService.shouldRenderOnboardingScreen();

    if (!isUserOnboarded && doesUserOnboardingRequiresOnboardingScreen) {
      this.processToShellOnboarding(stateBuilder);
      return;
    }

    if (!isUserOnboarded) {
      await services.userOnboardingService.onboardUser();
      return proceedToShell(manifest, decodedState);
    }

    return super.handle(params);
  }
}
