import jsonpath from 'jsonpath';
import { logger } from '../helpers/logger';
import { FilterObject, InstructionsResponse } from '../CDMFilter/filterTypes';
import { findValueUsingJsonPath } from '../helpers/findJsonPathValues';
import { FilterType } from '../CDMFilter/applyFilter';
import { TELEMETRY_FILTER_PATH, TELEMETRY_TREE_PATH } from '../dataCollectionService/dataCollectionServiceTypes';

export const sanitizeTCCS = (node: any, gun: string, bindings: InstructionsResponse) => {
  logger.log('Sanitize: applying TCSS filtration on:', node, 'with gun:', gun);

  const gunFilter = `${TELEMETRY_FILTER_PATH}[?(@.resourceId==='${gun}')]`;
  const notificationTccsFilter = applyTCCSFilter(node, gunFilter, bindings);

  if (!notificationTccsFilter) return;

  return subTreeTCCSFilter(node, gun, bindings);
};

const applyTCCSFilter = (node: any, filter: string, bindings: InstructionsResponse): boolean => {
  const appliedFilters = jsonpath.query(bindings, filter);
  if (!appliedFilters || appliedFilters.length === 0) return true;

  return appliedFilters.every((filterObj: FilterObject) => filterObj?.attributes ? checkTCCSFilter(filterObj.filterType, filterObj.attributes, node) : true);
};

const applyTCCSEventFilter = (node: any, filter: string, bindings: InstructionsResponse) => {
  const resultNodeValuesAndPathList: any[] = [];
  const appliedFilters = jsonpath.query(bindings, filter);

  if (!appliedFilters || appliedFilters.length === 0) return node;

  appliedFilters.forEach((filterObj: FilterObject) => {
    const checkTccsFilterResult = filterObj?.attributes ? checkTCCSEventFilter(filterObj.filterType, filterObj.attributes, node) : node;
    if (checkTccsFilterResult && checkTccsFilterResult.length > 0) {

      resultNodeValuesAndPathList.push(checkTccsFilterResult);
    }
  });

  return Array.from(new Set(resultNodeValuesAndPathList[0]));
};

const checkTCCSEventFilter = (type: string, filterAttributes: string[], filteredNode: any[]) => {
  let resultNodeValuesAndPathList: any[] = [];
  filterAttributes.forEach(attribute => {
    const nodeValuesAndPath = jsonpath.query(filteredNode, attribute);
    resultNodeValuesAndPathList.push(...nodeValuesAndPath);
  });
  resultNodeValuesAndPathList = Array.from(new Set(resultNodeValuesAndPathList));
  if (type !== FilterType.INCLUSION) {
    resultNodeValuesAndPathList = filteredNode.filter(value => !resultNodeValuesAndPathList.includes(value));
  }
  return resultNodeValuesAndPathList;
};

const checkTCCSFilter = (type: string, filterAttributes: string[], filteredNode: any) => {
  for (const attribute of filterAttributes) {
    const nodeValuesAndPath = findValueUsingJsonPath(filteredNode, attribute);
    if (type === FilterType.INCLUSION) {
      if (nodeValuesAndPath && nodeValuesAndPath.length > 0) return true;
    } else {
      if (nodeValuesAndPath && nodeValuesAndPath.length > 0) return false;
    }
  }

  logger.log('applyFilter: TCSSFilter::Filtered Node', filteredNode);
  return type !== FilterType.INCLUSION;
};

const subTreeTCCSFilter = (node: any, gun: string, bindings: InstructionsResponse) => {
  const bindingFilter = `${TELEMETRY_TREE_PATH}[?(@.resourceId==='${gun}')]`;
  const bindingFilterFilter = jsonpath.query(bindings, bindingFilter);

  for (const filter of bindingFilterFilter) {
    for (const filterSubTree of filter.subtrees) {
      const detailNodeFilter = filterSubTree.attribute;
      const ref = filterSubTree?.reference;
      if (!ref) continue;

      const detailTypeFilter = ref.identifierType === 'attribute'
        ? jsonpath.query(node, ref.identifier)[0]
        : ref.identifier;

      const detailFilter = `${TELEMETRY_FILTER_PATH}[?(@.resourceId==='${detailTypeFilter}')]`;
      const detailNode = jsonpath.query(node, detailNodeFilter);

      const checkEvents = findValueUsingJsonPath(node, detailNodeFilter);
      let filteredEvents: any;

      if (checkEvents && checkEvents.length > 0) {
        if (checkEvents[0].path[1] === 'events') {
          filteredEvents = applyTCCSEventFilter(detailNode, detailFilter, bindings);
          if (!filteredEvents || filteredEvents.length === 0) return;
          node.events = filteredEvents;
        }

        if (!filteredEvents && !applyTCCSFilter(detailNode, detailFilter, bindings)) return;
      }
    }
  }
  return node;
};
