import { GraphqlMockSchemaType } from '../types';

const user: GraphqlMockSchemaType['account']['user'] = {
  resourceId: '63cfa335c3bf0273eda134e4',
  email: 'john.doe@hp.com',
  emailVerified: true,
  fullName: 'John Doe',
  locale: 'en_US',
  phone: '+55912345678',
  phoneVerified: true,
  roleCategory: 'Admin'
};

const usersItems: GraphqlMockSchemaType['account']['users']['items'] = [user];

const devicesItems: GraphqlMockSchemaType['devices']['items'] = [
  {
    deviceId: '-ld2xP2d5Ymte0lXKkpdD-c4zw1TuxiIEaCmjG03Gkw',
    deviceOwnership: {
      resourceId: 'dd99d61b-9d24-4dd0-9b3d-b652b8280be1'
    },
    identity: {
      serialNumber: '5CD9471B6K',
      deviceUuid: null,
      location: null,
      friendlyName: null,
      bizModel: null,
      deviceSegment: null,
      makeAndModel: {
        name: 'HP Spectre x360 - 15t-df100 CTO',
        number: null,
        series: null,
        manufacturer: '',
        family: null
      },
      lastUpdatedAt: '2024-04-01T13:45:36.120Z',
      firmwareVersion: null
    },
    images: null,
    lastUpdatedAt: '2024-04-01T13:45:36.120Z',
    type: 'PC',
    support: null
  },
  {
    deviceId: '0WrHqqD-SFC9Woc0mSmat6-lnc3duuRLfTV879YPTmU',
    deviceOwnership: {
      resourceId: 'dd99d61b-9d24-4dd0-9b3d-b652b8280be1'
    },
    identity: {
      serialNumber: '5CM4260F7C',
      deviceUuid: null,
      location: null,
      friendlyName: null,
      bizModel: null,
      deviceSegment: null,
      makeAndModel: {
        name: 'HP Pavilion 23-p010in All-in-One Desktop PC',
        number: null,
        series: null,
        manufacturer: '',
        family: null
      },
      lastUpdatedAt: '2024-04-01T13:45:36.266Z',
      firmwareVersion: null
    },
    images: null,
    lastUpdatedAt: '2024-04-01T13:45:36.267Z',
    type: 'PC',
    support: null
  }
];

const defaultSchema: GraphqlMockSchemaType = {
  account: {
    resourceId: 'dd99d61b-9d24-4dd0-9b3d-b652b8280be1',
    users: {
      items: usersItems,
      totalCount: usersItems.length
    },
    user: user,
    subscriptions: [],
    paymentMethods: [],
    addresses: []
  },
  devices: {
    items: devicesItems,
    totalCount: devicesItems.length
  }
};

export default defaultSchema;
