import { createNoopMFeRouterInterface } from './NoopInterface';
import { getServices } from '../../../infra/commonInitializer';
import MFERouterInterface from './MFERouterInterface';
import { MFERouterInterfaceType } from './types';

let mfeRouterInterface: MFERouterInterface;

export function getMFERouterInterface(): MFERouterInterface {
  return mfeRouterInterface;
}

export default async function initializeMFERouterInterface({
  isEnabled
}: {
  isEnabled: boolean;
}): Promise<MFERouterInterfaceType> {
  if (!isEnabled) {
    return createNoopMFeRouterInterface({
      logCallback: () => console.warn('MFERouter is disabled.')
    });
  }
  if (mfeRouterInterface) return mfeRouterInterface.getInterface();

  const services = getServices();
  const { mfeRouterService } = services;

  mfeRouterInterface = new MFERouterInterface({ mfeRouterService });

  return mfeRouterInterface.getInterface();
}
