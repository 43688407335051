// It's the default web API for encrypt.
// Documentation: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest

import IEncryptService from './IEncryptService';

class WebCryptoApiEncryptService implements IEncryptService {
  public async encryptSHA256(text: string): Promise<string> {
    const msgUint8 = new TextEncoder().encode(text);
    const hashBuffer = await crypto?.subtle?.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      ?.map((b) => b.toString(16).padStart(2, '0'))
      ?.join('');
    return hashHex;
  }
}
// TODO: Refactor where it was used to consume from services
export default WebCryptoApiEncryptService;
