import { getUserIdentityProvider } from './getUserIdentityProvider';
import { StageBuilder } from '../TenantSelectorOperation/types';
import * as T from './types';
import { getServices } from '../../../../infra/commonInitializer';
import { ISessionService } from '../../../../services/session';
import { INavigationService } from '../../../../services/navigationService';
import { ILocalizationService } from '../../../../services/localizationService';
import { showEmailGatheringScreen } from './utils/showEmailGatheringScreen';
import { loginScene } from '../LoginPathOperation/constants';
import matchPath from '../../../../utils/matchPath';
import { LOGIN_AND_LOGGEDOUT_PATH_LIST } from '../../../session/constants';
import { isNative } from '../../../JWeb';
import { getPostLoginRedirect } from './utils/getPostLoginRedirect';

export default class NewLoginPathOperation
  implements T.RouterOperationInterface
{
  private sessionService: ISessionService;
  private navigationService: INavigationService;
  private localizationService: ILocalizationService;
  private loginData: T.NewLoginOperationDependenciesType['loginData'];
  private basePath: T.NewLoginOperationDependenciesType['basePath'];

  constructor(dependencies: T.NewLoginOperationDependenciesType) {
    this.loginData = dependencies.loginData;
    this.basePath = dependencies.basePath;

    const { sessionService, localizationService, navigationService } =
      getServices();

    this.sessionService = sessionService;
    this.localizationService = localizationService;
    this.navigationService = navigationService;
  }

  // TODO: This is a specific scenario of PathOperation
  // TODO: This could be in a RouteService or something similar
  isLoginPath(): boolean {
    let pathToCompare = this.navigationService.location.pathname || '';
    if (!pathToCompare.startsWith('/')) pathToCompare = '/' + pathToCompare;
    return matchPath(LOGIN_AND_LOGGEDOUT_PATH_LIST, {
      exact: true,
      pathToCompare
    });
  }

  // isLoginActive(): boolean {
  //   const loginAssetReference =
  //     this.loginData?.assetReference || '@jarvis/react-shell-login';
  //   const shouldInitializeLogin =
  //     this.loginData.enabled && !!loginAssetReference;
  //   const isLoggedIn = !!this.sessionService?.isLoggedIn();

  //   if (!shouldInitializeLogin) return false;
  //   const _isLoginPath = this.isLoginPath();
  //   if (_isLoginPath || !isLoggedIn) {
  //     idleLogoutClosure.stopIdleTimer();
  //   } else {
  //     idleLogoutClosure.startIdleTimer(manifest);

  //     return _isLoginPath;
  //   }
  // }

  // TODO: In the future, this could be in a AbstractOperation class
  protected processToLogin(
    stateBuilder: StageBuilder,
    assetReference = '@jarvis/react-shell-login'
  ): void {
    const loginSceneWithProperties = {
      ...loginScene,
      assetReference,
      properties: { ...loginScene.properties, ...this.loginData.properties }
    };

    stateBuilder.setContent(loginSceneWithProperties);
    stateBuilder.setLayoutByKey(false);
    stateBuilder.setEndProcessChain(true);
  }

  /**
   *
      case #1: First Login (i.e., the user doesn't have a session)
      case #2: Force Login (i.e., the user should login again)
      Assumption: The user is currently not in cooldown to exchange login.
  */
  async process(stateBuilder: StageBuilder): Promise<void> {
    if (!this.isLoginPath()) return;

    const services: T.NewLoginServiceType = {
      localizationService: this.localizationService,
      navigationService: this.navigationService,
      sessionService: this.sessionService
    };

    const postLoginRedirect = getPostLoginRedirect(
      this.loginData,
      this.basePath
    );

    if (await isNative()) {
      // Native Login Screen

      // Case #1: Native Login Screen for Signup and CreateAccount
      const resultAuth = await this.sessionService.redirectToIdentityProvider();

      // Currently, it using only one call for getToken.

      const loggedinUrl = await getUserIdentityProvider(
        postLoginRedirect,
        services,
        true,
        resultAuth
      );

      // Now we have to redirect to the loggedin page
      this.navigationService.redirect(loggedinUrl);
      // @BUG: the setRedirectTo is not working
      //stateBuilder.setRedirectTo(identityProviderUrl);
      stateBuilder.setEndProcessChain(true);
      return;
    }

    // Web Login Screen

    // 2° Case: Login Screen with Email Gathering
    // @TODO: Add this in login service with a better name
    if (showEmailGatheringScreen(this.loginData)) {
      this.processToLogin(stateBuilder, this.loginData?.assetReference);
      return;
    }

    // 3° Case: Redirect to Identity Provider
    await this.sessionService.redirectToIdentityProvider();

    // @BUG: the setRedirectTo is not working
    //stateBuilder.setRedirectTo(identityProviderUrl);
    stateBuilder.setEndProcessChain(true);
    return;
  }
}
