import { TenantVisualizationType } from '../strategy/types';
import RegexTenantFilter from '../tenantFilters/RegexTenantFilter/RegexTenantFilter';
import RoleFilter from '../tenantFilters/RoleFilter/RoleFilter';
import WhiteListTenantFilter from '../tenantFilters/WhiteListTenantFilter/WhiteListTenantFilter';
import { ITenantFilterBuilder } from './ITenantFilterBuilder';
import { TenantFilterBuilderPropsType } from './types';

export default class TenantFilterBuilder implements ITenantFilterBuilder {
  private _filter: TenantFilterBuilderPropsType['filter'];
  private _tenants: TenantVisualizationType[];

  constructor(props?: TenantFilterBuilderPropsType) {
    const { filter, tenants } = props || {};
    this._filter = filter || {};
    this._tenants = tenants || [];
  }

  filterByWhiteList(): ITenantFilterBuilder {
    this._tenants = new WhiteListTenantFilter(this._filter).filter(
      this._tenants
    );
    return this;
  }

  filterByRegexTenantType(): ITenantFilterBuilder {
    this._tenants = new RegexTenantFilter(this._filter).filter(this._tenants);
    return this;
  }

  filterByRole(): ITenantFilterBuilder {
    this._tenants = new RoleFilter(this._filter).filter(this._tenants);
    return this;
  }

  getTenantList(): TenantVisualizationType[] {
    return this._tenants;
  }
}
