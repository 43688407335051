import { LocaleType } from '../types';
import { ServiceLocalizationFallbackType } from './../types';

export type GenericHandleParam = {
  isLanguage: boolean;
  locale: LocaleType;
  currentList: string[];
  localeFallback?: LocaleType;
};

/**
 * The Handler interface declares a method for building the chain of handlers based
 * on chain of responsabilty pattern.
 * It also declares a method for executing a request.
 */
interface Handler {
  setNext(handler: Handler): Handler;

  handle(isLanguage: boolean, currentList: string[]): string;
}

/**
 * The default chaining behavior can be implemented inside a base handler class.
 */
export abstract class AbstractLocaleHandler implements Handler {
  private nextHandler: Handler;
  public _languagesFallback: ServiceLocalizationFallbackType;

  constructor(languagesFallback: ServiceLocalizationFallbackType) {
    this._languagesFallback = languagesFallback;
  }

  public setNext(handler: Handler): Handler {
    this.nextHandler = handler;
    return handler;
  }

  public getLanguage(listToIterate: string[]): string {
    return this.handle(true, listToIterate);
  }

  public getCountry(listToIterate: string[]): string {
    return this.handle(false, listToIterate);
  }

  /**
   *
   * @param isLanguage For checking if we are looking for language or country.
   * @param listToIterate A list could be lowerLanguageList or countries list.
   * @returns
   */
  public handle(isLanguage: boolean, listToIterate: string[]): string {
    return this.nextHandle(isLanguage, listToIterate);
  }

  public nextHandle(isLanguage: boolean, listToIterate: string[]): string {
    if (this.nextHandler) {
      return this.nextHandler.handle(isLanguage, listToIterate);
    }

    return undefined;
  }

  public genericHandle({
    isLanguage,
    locale,
    currentList,
    localeFallback
  }: GenericHandleParam): string {
    let fieldName;
    if (isLanguage) {
      fieldName = 'language';
    } else {
      fieldName = 'country';
    }

    // if unknown, just go to the next request.
    if (!locale[fieldName]) return this.nextHandle(isLanguage, currentList);

    // Check the current value on Language List or Country List
    const hasValue = currentList?.find(
      (value: string) => value === locale[fieldName].toLowerCase()
    );

    if (hasValue) {
      return locale[fieldName].toLowerCase();
    }

    // Checking if the value is in fallback list
    if (localeFallback?.[fieldName]) {
      const hasCallbackLocale = currentList.find(
        (locale: string) =>
          locale === localeFallback?.[fieldName]?.toLowerCase()
      );

      if (hasCallbackLocale) {
        return localeFallback?.[fieldName].toLowerCase();
      }
    }

    // Go to the next Handler
    return this.nextHandle(isLanguage, currentList);
  }
}
