import {
  BuildNotificationResult,
  DataCollectionEventAction,
  DataCollectionEventNames,
  DataCollectionEventStatus,
  DataCollectionHTTPResponse,
  FilterNotificationResult,
  FinishResult,
  jWebReady,
  Publisher,
  PublishNotificationResult
} from '@jarvis/jweb-core';

let publisher: Publisher;
export const publishResultEventData = async (
  action: DataCollectionEventAction,
  trackingIdentifiers?: string[],
  result?: BuildNotificationResult
  | FilterNotificationResult
  | PublishNotificationResult
  | FinishResult,
  httpServiceResult?: any,
  responseTime?: number) => {

  if (!trackingIdentifiers) return;

  const eventService = (await jWebReady).Plugins?.EventService;
  if (eventService && !publisher) {
    publisher = await eventService.createPublisher('com.hp.jarvis.data.collection.result.publisher') as Publisher;
  }

  const publishEventData = async (trackingIdentifier: string) => {
    const eventData: DataCollectionEventStatus = {
      trackingIdentifier,
      action,
      actionDetail: result
    };

    if (action === DataCollectionEventAction.publishNotification && httpServiceResult) {
      const telemetryServiceResponse: DataCollectionHTTPResponse = {
        responseCode: httpServiceResult.status,
        responseSizeInBytes: new Blob([JSON.stringify(httpServiceResult)]).size,
        responseTimeInMs: responseTime || 0,
        headers: httpServiceResult.headers,
        body: httpServiceResult.data ? httpServiceResult.data : httpServiceResult.statusText
      };
      eventData.actionDetail = { telemetryServiceResponse };
    }

    await (publisher as Publisher).publish(DataCollectionEventNames.cdmEventStatus, eventData);
  };

  trackingIdentifiers.forEach(async trackingIdentifier => {
    await publishEventData(trackingIdentifier);
  });
};
