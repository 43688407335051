import { JarvisWebHttpClient } from '@jarvis/web-http';
import { convertTenantStrategyToSessionAPITenantStrategy } from '../SessionAPIUtils';
import IExchangeClient, {
  ExchangeTokenDTOType,
  ExchangeTokenRequestType,
  ExchangeTokenResponseType
} from './IExchangeClient';
import { singleton } from 'tsyringe';

@singleton()
class ExchangeV3Client implements IExchangeClient {
  private jarvisWebHttpInstance: JarvisWebHttpClient;
  private apiName = '/api/session';
  private apiVersion = '/v3';
  private exchangeTokenTenantsEndpoint = '/exchangetoken';
  private _baseUrl: string;

  constructor() {
    this.jarvisWebHttpInstance = new JarvisWebHttpClient();
    this._baseUrl = `${window?.location?.origin}${this.apiName}${this.apiVersion}`;
  }

  public async exchangeToken(
    exchangeTenantTokenDTOType: ExchangeTokenDTOType
  ): Promise<ExchangeTokenResponseType> {
    const payload: ExchangeTokenRequestType = {
      tenantId: exchangeTenantTokenDTOType.tenantId,
      tokenType: convertTenantStrategyToSessionAPITenantStrategy(
        exchangeTenantTokenDTOType.tenantStrategy
      )
    };
    const { data } = await this.jarvisWebHttpInstance.post({
      url: `${this._baseUrl}${this.exchangeTokenTenantsEndpoint}`,
      headers: {
        Authorization: `Bearer ${exchangeTenantTokenDTOType.accessToken}`
      },
      data: payload
    });
    return data as ExchangeTokenResponseType;
  }
}

export default ExchangeV3Client;
