import bindAllMethods from '../../utils/bindAllMethods';
import IMonitoringService from './IMonitoringService';
// import { SplunkOtelWebType } from '@splunk/otel-web';
import {
  SpanType,
  SpanPropertiesType,
  TracerType,
  SplunkRumProps
} from './types';

export default class SplunkRumService implements IMonitoringService {
  private _enabled: boolean;
  private _globalTracer: TracerType;
  private _globalTracerName = 'shell-global-tracer-name';

  constructor(monitoringProps: SplunkRumProps) {
    this._enabled = monitoringProps.enabled;

    bindAllMethods(this);
  }

  //TODO: SplunkOtelWebType contains a typescript error so for now we wont use it.
  private _getInstance(): any {
    return (window as any)?.SplunkRum;
  }

  private _getTracer(): TracerType {
    if (!this._globalTracer) {
      this._globalTracer = this._getInstance?.()?.provider?.getTracer?.(
        this._globalTracerName
      );
    }
    return this._globalTracer;
  }

  public getSessionId(): string {
    return this._getInstance?.()?.getSessionId?.();
  }

  public startSpan(
    name: string,
    attributes: SpanPropertiesType['attributes']
  ): SpanType {
    const span = this._getTracer?.()?.startSpan?.(name, {
      attributes
    });
    return {
      end: () => {
        span?.end?.();
      }
    };
  }

  public isEnabled(): boolean {
    return this._enabled;
  }
}
