import { LoggedInHandleParamsType } from '../types';

/**
 * The Handler interface declares a method for building the chain of handlers.
 * It also declares a method for executing a request.
 */
export interface Handler {
  setNext(handler: Handler): Handler;
  handle(request: unknown): unknown;
}

export default abstract class AbstractLoggedInHandler implements Handler {
  private nextHandler: AbstractLoggedInHandler;

  public setNext(handler: AbstractLoggedInHandler): AbstractLoggedInHandler {
    this.nextHandler = handler;
    return handler;
  }

  public async handle(params: LoggedInHandleParamsType): Promise<void> {
    if (this.nextHandler) {
      return this.nextHandler.handle(params);
    }

    return null;
  }
}
