import bindAllMethods from '../../utils/bindAllMethods';
import { ThemeInputType, ScreenResponsivelyType, ThemeModeEnum } from './types';

export default class ThemeService {
  private _enabled: boolean;
  private _themeProvider: ThemeInputType['themeProvider'];
  private _screenResponsively: ScreenResponsivelyType;

  constructor(themeProps: ThemeInputType) {
    const { enable, themeProvider, screenResponsively } = themeProps || {
      enable: false,
      themeProvider: {},
      screenResponsively: {}
    };
    this._enabled = enable;
    this._themeProvider = { ...themeProvider };
    this._screenResponsively = { ...screenResponsively };
    bindAllMethods(this);
  }

  public getThemeProviderProperties(): ThemeInputType['themeProvider'] {
    if (this._enabled) {
      return this._themeProvider;
    }
    return {};
  }
  public getScreenResponsivelyProperties(): ScreenResponsivelyType {
    if (this._enabled) {
      return this._screenResponsively;
    }
    return {};
  }
  public getUserThemeMode(): ThemeModeEnum {
    const mmodeIsDefined =
      this._themeProvider?.mode in ThemeModeEnum &&
      !this._themeProvider?.userModeEnabled;

    const userContrastModeAvailable =
      window?.matchMedia?.('(forced-colors: active)')?.matches &&
      this._themeProvider?.userModeEnabled;

    const userDarkModeAvailable =
      window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches &&
      this._themeProvider?.userModeEnabled;

    if (!this._enabled) {
      return ThemeModeEnum.default;
    }
    if (userContrastModeAvailable) {
      return ThemeModeEnum.contrast;
    }
    if (userDarkModeAvailable) {
      return ThemeModeEnum.dark;
    }
    if (mmodeIsDefined) {
      return ThemeModeEnum[this._themeProvider?.mode];
    }
    return ThemeModeEnum.default;
  }
}
