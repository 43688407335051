import bindAllMethods from '../../../utils/bindAllMethods';
import * as T from './types';
import {
  ILocalizationService,
  ILocalizationTranslatorService
} from '../../../services/localizationService';

class LocalizationInterface {
  private _localizationService: ILocalizationService;
  private _localizationTranslatorService: ILocalizationTranslatorService;

  constructor({
    localizationService,
    localizationTranslatorService
  }: {
    localizationService: ILocalizationService;
    localizationTranslatorService: ILocalizationTranslatorService;
  }) {
    this._localizationService = localizationService;
    this._localizationTranslatorService = localizationTranslatorService;
    this._localizationService.injectLanguageIntoHTMLElementNode();

    bindAllMethods(this);
  }

  public getInterface(): T.LocalizationInterfaceType {
    return {
      enabled: this._localizationService.enabled,
      locale: this._localizationService.getStringifyLocale(),
      language: this._localizationService.language,
      country: this._localizationService.country,
      getGlobalTranslatorFunction:
        this._localizationTranslatorService.getGlobalTranslatorFunction,
      getLanguages: async () => this._localizationService.getLanguages(),
      setLocale: this._localizationService.setLocale,
      createTranslatorFunction:
        this._localizationTranslatorService.createTranslatorFunction,
      getReactTranslatorProvider:
        this._localizationTranslatorService.getReactTranslatorProvider,
      useReactTranslatorHook:
        this._localizationTranslatorService.useReactTranslatorHook,
      setLocalePreference: this._localizationService.setLocalePreference,
      getLocalePreference: this._localizationService.getLocalePreference,
      getLanguageDirection: async () =>
        this._localizationService.getLanguageDirection(),
      prefetchLocaleFile: this._localizationService.prefetchLocaleFile,
      useReactGetLanguageDirection:
        this._localizationService.useReactGetLanguageDirection
    };
  }
}

export default LocalizationInterface;
