import getValueByInsensitiveKey from '../../../utils/getValueByInsensitiveKey';
import { PathLocaleHandler } from '../handlers/PathLocaleHandler';
import { CookieLocaleHandler } from '../handlers/CookieLocaleHandler';
import { NavigatorLocaleHandler } from '../handlers/NavigatorLocaleHandler';
import { DefaultLocaleHandler } from '../handlers/DefaultLocaleHandler';
import { NativeLocaleHandler } from '../handlers/NativeLocaleHandler';

import { LocaleType } from '../types';
import {
  ServiceLocalizationFallbackType,
  ServiceLocalizationLanguageType
} from '../types';

/***
 * This method allow the portal tries to extract locale information from path,
 * native env, cookies, navigator and default value (in order).
 *
 * @param {ServiceLocalizationType} localization - Localization config
 * @returns {Promise<LocaleType>}
 */
export async function createLocaleBasedOnHandlers(
  localizationFallback: ServiceLocalizationFallbackType,
  languageMap: ServiceLocalizationLanguageType
): Promise<LocaleType> {
  const languageList = Object.keys(languageMap);
  const lowerLanguageList = languageList.map((language) =>
    language?.toLowerCase()
  );

  const pathLocale = new PathLocaleHandler(localizationFallback);
  const cookieLocale = new CookieLocaleHandler(localizationFallback);
  const nativeLocale = new NativeLocaleHandler(localizationFallback);
  const navigatorLocale = new NavigatorLocaleHandler(localizationFallback);
  const defaultLocale = new DefaultLocaleHandler(localizationFallback);

  await nativeLocale.init();

  const resultLocaleHandler = pathLocale;
  resultLocaleHandler
    .setNext(nativeLocale)
    .setNext(cookieLocale)
    .setNext(navigatorLocale)
    .setNext(defaultLocale);

  if (lowerLanguageList.length === 0)
    console.error('No language list was provided.');

  const language = resultLocaleHandler
    .getLanguage(lowerLanguageList)
    ?.toLocaleLowerCase?.();

  const countries = getValueByInsensitiveKey(languageMap, language);

  const country = resultLocaleHandler
    .getCountry(countries)
    ?.toLocaleLowerCase?.();
  return { language, country };
}

export function retrieveLocaleBasedOnPath(
  languageMap: ServiceLocalizationLanguageType
): LocaleType {
  const languageList = Object.keys(languageMap);
  const lowerLanguageList = languageList.map((language) =>
    language?.toLowerCase()
  );

  const pathLocale = new PathLocaleHandler({});

  const language = pathLocale
    .getLanguage(lowerLanguageList)
    ?.toLocaleLowerCase?.();

  const countries = getValueByInsensitiveKey(languageMap, language);

  const country = pathLocale.getCountry(countries)?.toLocaleLowerCase?.();
  return { language, country };
}
