import { JarvisWebHttpClient } from '@jarvis/web-http';
import { IAuthProviderService } from '../../../services/authProviderService';
import { getOneCloudBaseUrl } from '../../stackUtils';
import {
  CreateAccountOptionsType,
  CreateAccountResponseType
} from '../../../clients/stratus/accountmgtsvc/types';
import { Stack } from '../../../types/stratus';
import { OnecloudUserInfoResponseType } from '../types';

export default class OnecloudOauthClient {
  private urlPrefix = 'oauth-auth';
  private jarvisWebHttpInstance: JarvisWebHttpClient;
  private apiName = '/oauth2';
  private apiVersion = '/v1';
  private createStratusAccountEndpoint = '/stratus/create_account';
  private getUserDataEndpoint = '/userinfo';

  private _baseUrl: string;

  constructor({
    stack,
    authProvider
  }: {
    stack: Stack;
    authProvider: IAuthProviderService;
  }) {
    this.jarvisWebHttpInstance = new JarvisWebHttpClient({
      defaultAuthProvider: authProvider
    });
    this._baseUrl = `${getOneCloudBaseUrl(stack, this.urlPrefix)}${
      this.apiName
    }${this.apiVersion}`;
  }

  public async createStratusAccount(
    options: CreateAccountOptionsType
  ): Promise<CreateAccountResponseType> {
    const { data } = await this.jarvisWebHttpInstance.post({
      url: `${this._baseUrl}${this.createStratusAccountEndpoint}`,
      data: options
    });
    return data as CreateAccountResponseType;
  }

  public async getUserInfo(): Promise<OnecloudUserInfoResponseType> {
    const { data } = await this.jarvisWebHttpInstance.post({
      url: `${this._baseUrl}${this.getUserDataEndpoint}`
    });
    return data as OnecloudUserInfoResponseType;
  }
}
