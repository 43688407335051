
import { EventServiceErrorType, EventServicePluginError, Publisher as EventServicePublisher } from '@jarvis/jweb-core';
import { ErrorMessages } from '../utils/ErrorMessages';
import { eventServicePluginErrorMethod } from '../utils/exceptions';
import { validateIdentifier } from '../utils/ValidateIdentifier';
import { publisherInstances } from '../web';
import { eventHub } from './EventHub';


export const Publisher = (publisherId: string): EventServicePublisher | EventServicePluginError => {
  const publish = async (
    eventName: string,
    eventData: object,
  ): Promise<void | EventServicePluginError> => {
    if (!eventName || !validateIdentifier(eventName)) {
      return eventServicePluginErrorMethod(EventServiceErrorType.invalidIdentifier, ErrorMessages.invalidIdentifier);
    }
    eventHub.publish(eventName, publisherId, eventData);
  };
  const destroy = async (): Promise<void | EventServicePluginError> => {
    console.info ('destroyed the publisher');
    publisherInstances.delete(publisherId);
  };
  return {
    publish,
    destroy
  };
};
