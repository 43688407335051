import { CheckReturnObjectType } from '../../types';
import operationOR from '../operationOR';
import * as T from '../types';

const operationNOR: T.CriterionOperationFunctionMultipleCriterionType = async ({
  criterions,
  recursion
}) => {
  let operationResult: CheckReturnObjectType = undefined;

  operationResult = await operationOR({ criterions, recursion });

  return {
    result: !operationResult.result,
    fallbackKey: operationResult.fallbackKey
  };
};

export default operationNOR;
