import * as cookies from '../utils/cookies';
import getCurrentRoute from '../utils/toBeRemoved/getCurrentRoute';
import { getStratusAccessToken } from '../utils/getStratusAccessToken';
import { stackValidator } from '../utils/toBeRemoved/stack';

// TODO: Is this still used?
export default {
  cookies,
  getCurrentRoute,
  getStratusAccessToken,
  stackValidator
};
