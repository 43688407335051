import { UserOnboardingJoinInvitedOrganizationParams } from '../../../services/userOnboardingService/types';
import { IUserOnboardingService } from '../../../services/userOnboardingService';
import bindAllMethods from '../../../utils/bindAllMethods';
import { IUserService } from '../../../services/userService';
import { UserDataInterfaceV1Type } from './types';

// TODO: Make the own interface UserDataType
export type UserInterfaceV1Type = {
  _userData: {
    get(): Promise<UserDataInterfaceV1Type>;
  };
  onboarding: {
    isUserOnboarded(): Promise<boolean>;
    joinInvitedOrganization(
      options?: UserOnboardingJoinInvitedOrganizationParams
    ): Promise<void>;
    onboardUser(): Promise<void>;
    shouldRenderOnboardingScreen(): Promise<boolean>;
  };
};

type UserInterfaceV1Param = {
  userOnboardingService: IUserOnboardingService;
  userService: IUserService;
};

class UserInterfaceV1 {
  private _userOnboardingService: IUserOnboardingService;
  private _userService: IUserService;

  constructor({ userOnboardingService, userService }: UserInterfaceV1Param) {
    this._userOnboardingService = userOnboardingService;
    this._userService = userService;
    bindAllMethods(this);
  }

  public getInterface(): UserInterfaceV1Type {
    return {
      // For internal use only
      _userData: { get: this._userService.get },
      onboarding: {
        isUserOnboarded: this._userOnboardingService.isUserOnboarded,
        joinInvitedOrganization:
          this._userOnboardingService.joinInvitedOrganization,
        onboardUser: this._userOnboardingService.onboardUser,
        shouldRenderOnboardingScreen:
          this._userOnboardingService.shouldRenderOnboardingScreen
      }
    };
  }
}

export default UserInterfaceV1;
