import { NewLoginServiceType } from './types';
import { AccessToken, AuthPluginError } from '../../../../services/JWeb/types';
import { ISessionService } from '../../../../services/session';
import { internalLogger } from '../../../../interface/v1/logger';
import { _createOptions } from './_createOptions';

export async function getUserIdentityProvider(
  postLoginRedirect: string,
  services: NewLoginServiceType,
  isNative: boolean = false,
  authResult?: AuthPluginError | AccessToken
): Promise<string> {
  const { sessionService } = services;

  let nextUrl;
  if (isNative) {
    nextUrl = _getLoggedinUri(authResult);
  } else {
    nextUrl = await _getAuthUri(sessionService);
  }

  const options = await _createOptions(postLoginRedirect, nextUrl, services);

  const authenticationUrl = await sessionService.generateAuthenticationUrl(
    options
  );
  return authenticationUrl;
}

function _getLoggedinUri(authResult: AuthPluginError | AccessToken) {
  // Type guard to check if authResult is AuthPluginError
  const additionalParams = new URLSearchParams();
  if ('error' in authResult) {
    const { error } = authResult as AuthPluginError;
    additionalParams.append('error_type', error?.code);
    /* istanbul ignore next */
    internalLogger?.error?.('error_reason', error?.reason);
  }
  return `/loggedin?${additionalParams.toString()}`;
}

async function _getAuthUri(sessionService: ISessionService) {
  // Case #1 with Identity Provider
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email') || '';

  const providers = await sessionService.getProviderList({ email });
  return providers[0]?.authorizationEndpoint;
}
