import { AbstractLocaleHandler } from './AbstractLocaleHandler';
import { ServiceLocalizationFallbackType } from '../types';
import { isNative as isNativeJWeb, getDevicePlugin } from '../../JWeb';
import getValueByInsensitiveKey from '../../../utils/getValueByInsensitiveKey';
import { LocaleType } from '../types';

export class NativeLocaleHandler extends AbstractLocaleHandler {
  private isNative: boolean = undefined;
  private locale: LocaleType = {};

  constructor(languagesFallback: ServiceLocalizationFallbackType) {
    super(languagesFallback);
  }

  public async init(): Promise<void> {
    this.isNative = await isNativeJWeb();

    if (this.isNative) {
      const devicePluginJweb = await getDevicePlugin();
      this.locale = (await devicePluginJweb.getInfo()).locale;
    }
  }

  public handle(isLanguage: boolean, currentList: string[]): string {
    if (!this.isNative) {
      return super.nextHandle(isLanguage, currentList);
    }

    const nativeLocale = this.locale;

    // For fallback
    const nativeFallback = getValueByInsensitiveKey(
      this._languagesFallback,
      nativeLocale.language
    );

    return super.genericHandle({
      isLanguage: isLanguage,
      locale: nativeLocale,
      currentList: currentList,
      localeFallback: nativeFallback
    });
  }
}
