import { StateParamHandlerService } from '../../services/stateParamHandlerService';

export default class UsePostRedirect {
  private _stateParamHandlerService: StateParamHandlerService;

  constructor({ stateParamHandlerService }) {
    this._stateParamHandlerService = stateParamHandlerService;
  }

  public init(): void {
    if (this._stateParamHandlerService?.isPostRedirectable()) {
      this._stateParamHandlerService?.handleRedirect();
    }
  }
}
