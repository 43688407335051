import { writeStratusCookieDataToValueStore } from '../../services/JWeb';
import { decodeJWTPayload } from '../../utils/tokenUtils/JWTUtils';
import SessionObserver, {
  SessionEvents
} from '../../services/session/SessionObserver';

export default class WriteCookieDataToValueStore {
  public init = async (): Promise<void> => {
    SessionObserver.subscribe(
      SessionEvents.EXCHANGE_TENANT_TOKEN,
      this._writeStratusCookieToValueStore
    );

    SessionObserver.subscribe(
      SessionEvents.REFRESH_TOKEN,
      this._writeStratusCookieToValueStore
    );
  };

  public _writeStratusCookieToValueStore = async (
    authToken: string
  ): Promise<void> => {
    const userStratusToken = decodeJWTPayload(authToken);
    const tenantId = userStratusToken?.tenant_id || undefined;
    const stratusId = userStratusToken?.stratus_id || undefined;
    const wpIdUserId = userStratusToken?.wpid || undefined;
    const hpIdUserId = userStratusToken?.sub || undefined;
    writeStratusCookieDataToValueStore(
      stratusId,
      tenantId,
      wpIdUserId,
      hpIdUserId
    );
  };
}
