import bindAllMethods from '../../utils/bindAllMethods';
import ILocalizationTranslatorService, {
  TranslatorFunctionOptionsPropType,
  TranslatorFunctionResourcePropType
} from './ILocalizationTranslatorService';
import i18next, {
  InitOptions,
  Resource,
  TFunctionResult,
  ResourceLanguage,
  TFunction
} from 'i18next';
import { internalLogger } from '../../interface/v1/logger';
import ILocalizationService from './ILocalizationService';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { createGlobalResourcesUrls } from './utils/createGlobalResourcesUrls';
import {
  GetFirstSupportedFallbackLngResourceType,
  LocalizationServiceInputType,
  OptionsType
} from './types';

export type LocalizationTranslatorServiceParams = {
  localization: LocalizationServiceInputType;
  globalResources?: TranslatorFunctionResourcePropType;
};

const getDefaultValue = (key: string, options?: OptionsType): string => {
  if (typeof options === 'string') {
    return options;
  } else if (typeof options?.defaultValue === 'string') {
    return options?.defaultValue;
  } else if (key) {
    return key;
  } else {
    return '';
  }
};

export default class LocalizationTranslatorService
  implements ILocalizationTranslatorService
{
  private _localizationProperties: LocalizationServiceInputType;
  private _translatorContext: any;
  private _CachedReact: any;
  private _globalResources: TranslatorFunctionResourcePropType;
  private _localizationService: ILocalizationService;

  constructor({
    localization,
    globalResources
  }: LocalizationTranslatorServiceParams) {
    this._localizationProperties = localization;
    this._globalResources = globalResources;
    bindAllMethods(this);
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const { localizationService } = services;
    this._localizationService = localizationService;
    bindAllMethods(this);
  }

  private async _getTranslatorResourceFromUrl(resourceUrl: string) {
    let languageTranslationResource = {};
    try {
      languageTranslationResource =
        await this._localizationService.prefetchLocaleFile(resourceUrl);
    } catch (error) {
      internalLogger?.error?.(error);
      languageTranslationResource = {};
    }
    return languageTranslationResource;
  }

  public async createTranslatorFunction(
    resources: TranslatorFunctionResourcePropType,
    options?: TranslatorFunctionOptionsPropType
  ): Promise<TFunction> {
    const i18nInstanceOptions = await this._getI18nInstanceOptions(
      resources,
      options
    );

    return this._getTranslationFunction(i18nInstanceOptions);
  }

  private async _getI18nInstanceOptions(
    resources: TranslatorFunctionResourcePropType,
    options: TranslatorFunctionOptionsPropType
  ) {
    const defaultFallbackLngs = ['en_US'];
    const resourcesWithTranslationKey: Resource = {};

    const stringifyCurrentLocale =
      this._localizationService.getStringifyLocale();

    const currentLng =
      options?.lng?.replace('-', '_') ||
      stringifyCurrentLocale?.replace('-', '_');

    // the order of fallbackLng property is important for the i18next instance
    // it tries to find the translation resources in the order of the array
    const fallbackLngArray =
      options?.fallbackLng && options?.fallbackLng?.length > 0
        ? [...options.fallbackLng, ...defaultFallbackLngs]
        : defaultFallbackLngs;

    const { fallbackLng, fallbackResource } =
      await this._getFirstSupportedFallbackLngResource(
        fallbackLngArray,
        resources
      );

    if (!resources[currentLng]) {
      if (fallbackLng && fallbackResource)
        resourcesWithTranslationKey[fallbackLng] = {
          translation: fallbackResource
        };
    } else if (resources[currentLng]) {
      resourcesWithTranslationKey[currentLng] = {
        translation: await this._getResourceLanguage(currentLng, resources)
      };

      if (options?.ensureFallbackLng) {
        if (fallbackLng && fallbackResource)
          resourcesWithTranslationKey[fallbackLng] = {
            translation: fallbackResource
          };
      }
    }

    // Create i18next instance options
    const i18nInstanceOptions: InitOptions = {
      resources: resourcesWithTranslationKey,
      lng: currentLng,
      fallbackLng: fallbackLngArray,
      supportedLngs: Object.keys(resources)
    };

    return i18nInstanceOptions;
  }

  private async _getFirstSupportedFallbackLngResource(
    fallbackLngList: string[],
    resources: TranslatorFunctionResourcePropType
  ): Promise<GetFirstSupportedFallbackLngResourceType> {
    for (const l in fallbackLngList) {
      const fallbackLng = fallbackLngList[l];
      const isSupported = fallbackLng in resources;

      if (isSupported) {
        return {
          fallbackLng: fallbackLng,
          fallbackResource: await this._getResourceLanguage(
            fallbackLng,
            resources
          )
        };
      }
    }
    return {};
  }

  private async _getResourceLanguage(
    lng: string,
    resource: string | ResourceLanguage
  ): Promise<ResourceLanguage> {
    if (typeof resource[lng] === 'string') {
      // When string will be like: /assets/locale/en_US.json
      const languageTranslationResource =
        await this._getTranslatorResourceFromUrl(resource[lng] as string);
      return languageTranslationResource;
    } else {
      // When resource is a Map
      return resource[lng];
    }
  }

  private _getTranslationFunction(
    i18nInstanceOptions: InitOptions
  ): Promise<TFunction> {
    return new Promise<TFunction>((resolve) => {
      i18next.createInstance(i18nInstanceOptions, (error, TFunction) => {
        if (error) {
          throw error;
        }

        resolve((key?: string, options?: OptionsType) =>
          this._t(TFunction, key, options)
        );
      });
    });
  }

  private _t(
    tParam: TFunction,
    key?: string,
    options?: OptionsType
  ): TFunctionResult {
    const defaultValue = getDefaultValue(key, options);
    if (!tParam) {
      // Case #1: the i18n wasn't initialized
      return defaultValue;
    }

    if (!this._localizationProperties?.enable) {
      // Case #2: Translation is not enabled
      return defaultValue;
    }

    // Case #3: Found the translation
    // Case #4: in case of not found the key
    // the default value is returned by i18next
    const value = tParam(key, options);

    if (!value) {
      // Case #5: if Key is empty or undefined, value will be empty
      return defaultValue;
    }

    return value;
  }

  public getReactTranslatorProvider(React: any): any {
    if (!this._CachedReact) {
      this._CachedReact = React;
    }
    if (!this._translatorContext) {
      this._translatorContext = this._CachedReact.createContext({});
    }
    return this._TranslatorProvider;
  }

  private _TranslatorProvider(props: {
    children: unknown;
    resources: TranslatorFunctionResourcePropType;
    options?: TranslatorFunctionOptionsPropType;
  }) {
    const { children, resources, options } = props;
    const { lng } = options || {};
    const [value, setValue] = this._CachedReact.useState();

    this._CachedReact.useEffect(() => {
      if (resources) {
        this.createTranslatorFunction(resources, options)
          .then((t) => setValue({ t }))
          .catch((error) => {
            internalLogger?.error?.(
              'Error in TranslatorProvider method',
              error
            );
          });
      }
    }, [resources, lng]);

    if (!value) {
      return null;
    }

    return this._CachedReact.createElement(
      this._translatorContext.Provider,
      { value },
      children
    );
  }

  public useReactTranslatorHook(): any {
    return this._CachedReact?.useContext?.(this._translatorContext);
  }

  public async getGlobalTranslatorFunction(): Promise<TFunction> {
    const useGlobalTranslation =
      this._localizationProperties?.useGlobalTranslation;
    const globalResources = this._globalResources;
    const locales = this._localizationService.getLanguages();

    const resourceList = createGlobalResourcesUrls({
      locales,
      globalResources,
      useGlobalTranslation
    });

    return this.createTranslatorFunction(resourceList);
  }
}
