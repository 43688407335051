import { ServiceWorkerInputType } from './input.types';
import bindAllMethods from '../../utils/bindAllMethods';
import IServiceWorkerService from './IServiceWorkerService';
import { ServiceWorkerServiceTypeNames } from './constants';
import * as T from './types';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { INavigationService } from './../navigationService';
import { IAuthTokenService } from '../authTokenService';
import { IBackgroundTaskManagerService } from '../backgroundTaskService';
import { internalLogger } from '../../interface/v1/logger';

export const BackgroundTasksActionType = 'shell-background-tasks-action';

export default class ServiceWorkerService implements IServiceWorkerService {
  private _enabled: boolean;
  private _navigationService: INavigationService;
  private _authTokenService: IAuthTokenService;
  private _backgroundTaskManagerService: IBackgroundTaskManagerService;
  private _previousPathname: string;

  typeNames = ServiceWorkerServiceTypeNames;

  constructor(options?: ServiceWorkerInputType) {
    const { enable } = options || { enable: false };
    this._enabled = enable;

    bindAllMethods(this);
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const {
      navigationService,
      backgroundTaskManagerService,
      authTokenService
    } = services;

    this._navigationService = navigationService;
    this._authTokenService = authTokenService;
    this._backgroundTaskManagerService = backgroundTaskManagerService;
  }

  private async _callbackBackgroundTasksAction(event): Promise<void> {
    internalLogger.log(
      'BackgroundTasks received from Service Worker:',
      event?.data?.backgroundTasks
    );
    event?.data?.backgroundTasks?.forEach(async (bgData) => {
      await this._backgroundTaskManagerService
        .launchTask(bgData.taskId, {
          data: bgData.properties
        })
        ?.catch((error) => {
          internalLogger?.error(error);
        });
    });
  }

  private _addEventListenerToBackgroundTasksAction(): void {
    if (window?.navigator?.serviceWorker) {
      window.navigator.serviceWorker.onmessage = (event) => {
        if (event?.data?.type === BackgroundTasksActionType) {
          this._callbackBackgroundTasksAction(event);
        }
      };
    }
  }

  public init(): void {
    // The navigation must been finished at this point.
    this._previousPathname = undefined;
    this._startRouteTriggerListener();
    this._addEventListenerToBackgroundTasksAction();
  }

  public callbackRouterTrigger(): void {
    const currentPathname = this._navigationService.location?.pathname;

    if (this._previousPathname !== currentPathname) {
      this.postMessage({
        type: this.typeNames.routeChanged,
        data: {
          previousLocation: this._previousPathname,
          currentLocation: currentPathname,
          headers: {
            Authorization: this._authTokenService?.getToken()?.token
              ? 'Bearer ' + this._authTokenService?.getToken()?.token
              : undefined
          }
        }
      });
    }

    this._previousPathname = currentPathname;
  }

  private _startRouteTriggerListener(): void {
    this._navigationService.listen(this.callbackRouterTrigger);

    this.callbackRouterTrigger();
  }

  public async postMessage({
    data,
    type
  }: T.PostMessageArgumentType): Promise<void> {
    if (!this._enabled) return undefined;

    const message = {
      data,
      type
    };

    if (!window?.navigator?.serviceWorker?.controller?.postMessage)
      return undefined;

    window.navigator.serviceWorker.controller.postMessage(message);
  }

  public async clearCache(
    data: T.ServiceWorkerServiceClearCacheOptionsType
  ): Promise<void> {
    this.postMessage({ data, type: this.typeNames.clearCache });
  }
}
