import * as T from './types';
import { getMap } from './utils';

export default abstract class BaseEventService {
  protected _listenerHandlerMap: T.RemoveListenersMapType = new Map();

  abstract subscribe(
    eventName: string,
    action: T.EventServiceCallBackType,
    options?: T.EventServiceSubscribeOptionsType
  ): Promise<T.ListenerHandlerType>;

  abstract publish(
    eventName: string,
    eventData: T.EventServiceValueType['eventData']
  ): Promise<void>;

  addEventListener(name: string, action: T.EventCallBackType): void {
    const actionMap = getMap(name, this._listenerHandlerMap);
    const listenerHandler = this.subscribe(name, action);

    actionMap.set(action, listenerHandler);
  }

  removeEventListener(name: string, action: T.EventCallBackType): void {
    const actionMap = getMap(name, this._listenerHandlerMap);
    const listenerHandlerPromise = actionMap.get(action);

    actionMap.delete(action);

    listenerHandlerPromise.then((listenerHandler) =>
      listenerHandler.unsubscribe()
    );
  }
}
