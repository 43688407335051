import { isBase64 } from '../../utils/base64Encoder';
import IURLService, * as T from './IURLService';

export default class URLService implements IURLService {
  private _manifestBasePath: string;
  private _languageMap: Record<string, string[]>;
  private _removeLocaleUrl: boolean;

  constructor(options: T.URLServiceConstructorPropsType) {
    this._manifestBasePath = options.manifestBasePath;
    this._languageMap = options.languageMap || {};
    this._removeLocaleUrl = !!options.removeLocaleUrl;
  }

  createPath(
    location: T.CreatePathLocationPropertyType
  ): T.CreatePathReturnType {
    let result = '/';
    const pathProperties: T.GetPathDescriptionReturnType = (() => {
      const result: T.GetPathDescriptionReturnType = {
        basePath: this._manifestBasePath
      };

      if (typeof location === 'object') {
        result.country = location?.country;
        result.language = location?.language;

        const pathnameDescription = this.getPathDescription(location?.pathname);

        result.pathname = pathnameDescription?.pathname;

        if (location?.search || pathnameDescription?.search) {
          const locationSearchObject =
            typeof location?.search === 'object'
              ? location?.search
              : this.convertSearchStringToObject(location?.search);

          const searchObject = {
            ...this.convertSearchStringToObject(pathnameDescription?.search),
            ...locationSearchObject
          };

          result.search = this.convertSearchObjectToString(searchObject);
        }

        if (location?.hash || pathnameDescription?.hash) {
          const hashWithoutHashtag = location?.hash?.replace?.('#', '');

          if (hashWithoutHashtag?.length > 0) {
            result.hash = `#${hashWithoutHashtag}`;
          } else {
            result.hash = pathnameDescription?.hash;
          }
        }
      } else if (typeof location === 'string') {
        const { country, language, pathname, hash, search } =
          this.getPathDescription(location);

        result.pathname = pathname;
        result.country = country;
        result.language = language;
        result.hash = hash;
        result.search = search;
      }

      if (this._removeLocaleUrl) {
        result.country = undefined;
        result.language = undefined;
      }

      return result;
    })();

    if (pathProperties?.country && pathProperties?.language) {
      result += `/${pathProperties?.country}/${pathProperties?.language}`;
    }

    result += this._manifestBasePath || '';

    result += pathProperties?.pathname || '';

    result = result.replace(new RegExp('/+', 'g'), '/');

    result += pathProperties?.search || '';
    result += pathProperties?.hash || '';

    return result;
  }

  getPathDescription(path: string): T.GetPathDescriptionReturnType {
    const {
      country,
      language,
      pathname: pathNameWithBasePath
    } = this._getPathAfterLocaleWithDetails(path);

    const { basePath, pathname: pathNameWithSearchAndHash } =
      this._getPathAfterBasePath(pathNameWithBasePath);

    const { search, hash, pathname } =
      this._getURLSearchParamsAndHashFromPathname(pathNameWithSearchAndHash);

    return {
      basePath,
      country,
      language,
      pathname,
      search,
      hash
    };
  }

  getCurrentPathDescription(): T.GetPathDescriptionReturnType {
    return this.getPathDescription(
      window.location.pathname + window.location.search + window.location.hash
    );
  }

  convertSearchStringToObject<T = Record<string, any>>(search: string): T {
    const urlSearchParams = new URLSearchParams(search);

    const result = {} as T;

    urlSearchParams.forEach((value, key) => {
      try {
        if (isBase64(value)) {
          result[key] = JSON.parse(atob(value));
        } else {
          result[key] = value;
        }
      } catch {
        result[key] = value;
      }
    });

    return result;
  }

  /**
   * Return is a empty string or a search param that start with "?"
   */
  convertSearchObjectToString<T = Record<string, any>>(
    searchObject: T
  ): string {
    const urlSearchParams = new URLSearchParams();

    const checkIfValueIsValid = (value) => {
      const invalidQueryParamValues = [undefined, null, ''];

      return !invalidQueryParamValues.some(
        (invalidValue) => invalidValue === value
      );
    };

    if (typeof searchObject === 'object') {
      for (const key in searchObject) {
        if (checkIfValueIsValid(searchObject[key])) {
          const value =
            typeof searchObject[key] === 'object'
              ? btoa(JSON.stringify(searchObject?.[key]))
              : String(searchObject?.[key]);

          urlSearchParams.set(key, value);
        }
      }
    }

    const stringfiedSearchParam = urlSearchParams
      .toString()
      ?.replace?.('?', '');

    if (stringfiedSearchParam.length > 0) {
      return `?${stringfiedSearchParam}`;
    }

    return '';
  }

  private _getPathAfterLocaleWithDetails(pathname: string): {
    language?: string;
    country?: string;
    pathname?: string;
  } {
    const result: {
      language?: string;
      country?: string;
      pathname?: string;
    } = {};

    if (pathname) {
      const [possibleURLCountry, possibleURLLanguage] =
        pathname?.split?.('/')?.filter?.((value) => !!value) || [];

      const languageList = Object.keys(this._languageMap);

      const language = languageList
        .find(
          (language) =>
            language?.toLowerCase?.() === possibleURLLanguage?.toLowerCase?.()
        )
        ?.toLowerCase?.();

      const countries =
        this._languageMap?.[language?.toLowerCase()] ||
        this._languageMap?.[language?.toUpperCase()];

      const country = countries?.find?.((value) => {
        return value?.toLowerCase?.() === possibleURLCountry?.toLowerCase?.();
      });

      const pathnameWithoutCountry = pathname?.startsWith?.(`/${country}`)
        ? pathname?.replace?.(`/${country}`, '')
        : pathname;

      const pathnameWithoutCountryAndLanguage =
        pathnameWithoutCountry?.startsWith?.(`/${language}`)
          ? pathnameWithoutCountry?.replace?.(`/${language}`, '')
          : pathnameWithoutCountry;

      result.pathname = pathnameWithoutCountryAndLanguage;

      if (!this._removeLocaleUrl && language && country) {
        result.language = language;
        result.country = country;
      }
    }

    return result;
  }

  private _getPathAfterBasePath(pathname: string): {
    basePath?: string;
    pathname?: string;
  } {
    if (pathname?.startsWith?.(this._manifestBasePath)) {
      const pathnameWithoutLocale =
        this._manifestBasePath === '/'
          ? pathname
          : pathname?.replace?.(this._manifestBasePath, '');

      return {
        basePath: this._manifestBasePath,
        pathname: pathnameWithoutLocale
      };
    }

    return { pathname };
  }

  private _getURLSearchParamsAndHashFromPathname(path: string): {
    pathname?: string;
    search?: string;
    hash?: string;
  } {
    const [pathWithSearch, hash] = path?.split?.('#') || [];
    const [pathname, search] = pathWithSearch?.split?.('?') || [];

    return {
      pathname: pathname?.length > 0 ? pathname : '',
      search: search?.length > 0 ? `?${search}` : '',
      hash: hash?.length > 0 ? `#${hash}` : ''
    };
  }
}
