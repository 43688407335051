import { isNative } from '../../../../../services/JWeb';
import { Action, Location, UnregisterCallback } from 'history';
import EventNames from '../../../../../config/eventNames';
import {
  EventDataActionEnum,
  EventDataActivityEnum,
  EventDataControlNameEnum,
  EventDataScreenNameEnum,
  EventDataScreenPathEnum,
  EventSchemaVersionEnum
} from '../../../../../services/AnalyticsService/dependencies/AnalyticsEventEnums';
import getOSDetailsFromBrowser from '../../../../../utils/getOSDetailsFromBrowser';
import * as T from './types';
import { getServices } from '../../../../../infra/commonInitializer';
import { TFunction } from 'i18next';
import bindAllMethods from '../../../../../utils/bindAllMethods';
import IEventService from '../../../../../services/eventService/IEventService';
import { LOGIN_PATH_LIST } from '../../../../../services/session/constants';

export interface ListenerManagerConstructorProperties {
  storeInterface: T.OnboardingAgentConstructorDependencies['storeInterface'];
  localizationInterface: T.OnboardingAgentConstructorDependencies['localizationInterface'];
  navigationInterface: T.OnboardingAgentConstructorDependencies['navigationInterface'];
}

export default class ListenerManager {
  private _eventService: IEventService;
  private _storeInterface: T.OnboardingAgentConstructorDependencies['storeInterface'];
  private _localizationInterface: T.OnboardingAgentConstructorDependencies['localizationInterface'];
  private _navigationInterface: T.OnboardingAgentConstructorDependencies['navigationInterface'];
  private _removeConfirmBeforeLeaveListener?: () => void;
  private _globalTranslate: TFunction;
  private _removeNavigationBlocker: UnregisterCallback;
  private _confirmed: boolean;
  private lastAction: Action;
  private lastLocation: Location;

  constructor({
    storeInterface,
    localizationInterface,
    navigationInterface
  }: ListenerManagerConstructorProperties) {
    const { eventService } = getServices();
    this._eventService = eventService;
    this._storeInterface = storeInterface;
    this._localizationInterface = localizationInterface;
    this._navigationInterface = navigationInterface;
    bindAllMethods(this);
    //this.getNavigationPrompt.bind(this);
  }

  private async translate(key: string | undefined, defaultValue: string) {
    if (!this._globalTranslate) {
      this._globalTranslate =
        await this._localizationInterface?.getGlobalTranslatorFunction?.();
    }
    if (this._globalTranslate && key) {
      return this._globalTranslate?.(key, defaultValue);
    } else {
      return defaultValue;
    }
  }

  private getConfirmBeforeLeave = async () => {
    const manifest = this._storeInterface?.state?.manifest;

    const {
      message: manifestMessage,
      translationKey,
      enable
    } = manifest?.services?.onboarding?.confirmBeforeLeave || {};

    const hardCodedDefaultMessage =
      'Please stay on this screen. If you select OK, you may lose your progress. To continue, select Cancel.';

    const defaultMessage =
      typeof manifestMessage === 'string'
        ? manifestMessage
        : hardCodedDefaultMessage;

    return {
      enable: !!enable,
      message: await this.translate?.(translationKey, defaultMessage)
    };
  };

  private beforeunloadCallback = async (event: BeforeUnloadEvent) => {
    const { enable, message } = (await this.getConfirmBeforeLeave?.()) || {};
    const isLoginPath = LOGIN_PATH_LIST?.some?.((pathname) =>
      this._navigationInterface.location.pathname.startsWith(pathname)
    );

    if (!this._confirmed && enable && !isLoginPath) {
      event.returnValue = message;
      event.preventDefault();
      return message;
    }
  };

  private pagehideCallback = async (event: BeforeUnloadEvent) => {
    const { enable, message } = await this.getConfirmBeforeLeave();
    const isLoginPath = LOGIN_PATH_LIST?.some?.((pathname) =>
      this._navigationInterface.location.pathname.startsWith(pathname)
    );

    if (!this._confirmed && enable && !isLoginPath && confirm(message)) return;

    event.preventDefault();
  };

  private setListener = (eventName: 'pagehide' | 'beforeunload', callback) => {
    window.addEventListener(eventName, callback, {
      capture: true
    });

    this._removeConfirmBeforeLeaveListener = () => {
      this._removeNavigationBlocker();
      window.removeEventListener(eventName, callback, {
        capture: true
      });
    };
  };

  private createSimpleUiEventData({ action, controlName }) {
    return {
      action,
      activity: EventDataActivityEnum.allV01,
      screenPath: EventDataScreenPathEnum.portalOobe,
      screenName: EventDataScreenNameEnum.backButtonAlert,
      controlName,
      version: EventSchemaVersionEnum.simpleUi
    };
  }

  async getNavigationPrompt(location: Location, action: Action) {
    const { enable, message } = await this.getConfirmBeforeLeave();

    const allowedAction =
      action === 'POP' &&
      this.lastAction === 'POP' &&
      !!location.key &&
      location.pathname !== this.lastLocation?.pathname &&
      location.search === this.lastLocation?.search;

    this.lastAction = action;
    this.lastLocation = location;
    if (allowedAction && !!enable) {
      this._eventService.publish(
        EventNames.shellAnalyticsSimpleUiEvent,
        this.createSimpleUiEventData({
          action: EventDataActionEnum.modalWindowDisplayed,
          controlName: undefined
        })
      );

      if (!this._confirmed) {
        this._confirmed = confirm(message);
      }

      if (this._confirmed) {
        this._eventService.publish(
          EventNames.shellAnalyticsSimpleUiEvent,
          this.createSimpleUiEventData({
            action: EventDataActionEnum.controlHyperLinkClicked,
            controlName: EventDataControlNameEnum.okButton
          })
        );

        const triggerPath =
          this._storeInterface?.state?.onboarding?.sessionContext
            ?.onboardingContext?.entryUrl;

        if (this._removeConfirmBeforeLeaveListener)
          this._removeConfirmBeforeLeaveListener();

        window.location.href = triggerPath || '/';
      } else {
        this._eventService.publish(
          EventNames.shellAnalyticsSimpleUiEvent,
          this.createSimpleUiEventData({
            action: EventDataActionEnum.controlHyperLinkClicked,
            controlName: EventDataControlNameEnum.cancelButton
          })
        );
        this._navigationInterface.goForward();
        return false;
      }
    }
  }

  async startConfirmBeforeLeaveListener(): Promise<void> {
    if (await isNative()) return;
    this._confirmed = false;

    this._navigationInterface.push(
      `${this._navigationInterface.location.pathname}${
        this._navigationInterface.location.search || ''
      }`
    );

    this._removeNavigationBlocker = await this._navigationInterface.block(
      //TODO: Create a new signature for get NavigationPrompt
      //@ts-ignore
      this.getNavigationPrompt
    );

    if (!this._removeConfirmBeforeLeaveListener) {
      const OSDetails = getOSDetailsFromBrowser();
      const isIOS = OSDetails?.name?.toLocaleLowerCase()?.includes('ios');

      if (isIOS) {
        this.setListener('pagehide', this.pagehideCallback);
      } else {
        this.setListener('beforeunload', this.beforeunloadCallback);
      }
    }
  }

  stopConfirmBeforeLeaveListener() {
    if (typeof this._removeConfirmBeforeLeaveListener === 'function') {
      this._removeConfirmBeforeLeaveListener?.();
    }
  }
}
