import { Stack } from '../types/stratus';

export const getStackByStackNumber = (stackNumber: number): Stack => {
  let stack;
  switch (stackNumber) {
    case 3:
      stack = Stack.prod;
      break;
    case 2:
      stack = Stack.stage;
      break;
    case 1:
      stack = Stack.pie;
      break;
    default:
      stack = Stack.dev;
      break;
  }
  return stack;
};
export const getTroposStackStringByNumber = (stackNumber: number): string => {
  let stack;
  switch (stackNumber) {
    case 3:
      stack = 'pro';
      break;
    case 2:
      stack = 'stg';
      break;
    case 1:
      stack = 'pie';
      break;
    default:
      stack = 'dev';
      break;
  }
  return stack;
};

// Onecloud
export const getOneCloudBaseUrl = (stack: Stack, urlPrefix: string): string => {
  let url;
  switch (stack) {
    case Stack.prod:
      url = `https://${urlPrefix}.oc.hp.com`;
      break;
    default:
      url = `https://${urlPrefix}.stg.oc.hp.com`;
      break;
  }
  return url;
};

// Stratus
export const getStratusBaseUrl = (stack: Stack): string => {
  let url;
  switch (stack) {
    case Stack.prod:
      url = 'https://us1.api.ws-hp.com';
      break;
    case Stack.stage:
      url = `https://stage-us1.api.ws-hp.com`;
      break;
    default:
      url = `https://pie-us1.api.ws-hp.com`;
      break;
  }
  return url;
};

export const getAuthzBaseUrl = (stack: Stack): string => {
  let url;
  switch (stack) {
    case Stack.prod:
      url = 'https://authz.api.ws-hp.com';
      break;
    case Stack.stage:
      url = `https://authz.stage.api.ws-hp.com`;
      break;
    default:
      url = `https://pie.authz.wpp.api.hp.com`;
      break;
  }
  return url;
};
