import { LoggedInHandleParamsType } from '../types';
import AbstractLoggedInHandler from './AbstractLoggedInHandler';

export default class SkipUserOnboardingHandler extends AbstractLoggedInHandler {
  public async handle(params: LoggedInHandleParamsType): Promise<void> {
    const { decodedState, proceedToShell, manifest } = params;
    const skipUserOnboarding = decodedState?.skipUserOnboarding === 'true';
    if (skipUserOnboarding) {
      return proceedToShell(manifest, decodedState);
    }

    return super.handle(params);
  }
}
