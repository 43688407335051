import { UserInteractionEntryPoint } from '../../../../JWeb/JWebEnums';

const ENTRYPOINT_PARAM_NAME = 'target';

export const getUserInteractionEntryPointBasedOnParams =
  (): UserInteractionEntryPoint => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get(ENTRYPOINT_PARAM_NAME) === 'create') {
      return UserInteractionEntryPoint.createAccount;
    } else {
      return UserInteractionEntryPoint.signIn;
    }
  };
