export type importmapType = {
  imports: Record<string, string>;
};

export function getLocaleUrlFromAssetReference({
  importmap,
  assetReference = '',
  locale = ''
}: {
  importmap: importmapType;
  assetReference?: string;
  locale: string;
}): string {
  if (!assetReference) return '';

  let cleanedAssetReference = assetReference;

  if (assetReference.includes('react-shell-common-layout')) {
    cleanedAssetReference =
      assetReference.split('/').slice(0, -1).join('/') + '/';
  }

  const fullUrl = importmap?.imports[cleanedAssetReference];

  if (!fullUrl) return '';

  const baseUrl = fullUrl.split('/').slice(0, -1).join('/') + '/';

  const localePath = 'locale/';

  if (!locale) return '';

  const fileName = locale.replace('-', '_') + '.json';
  return baseUrl + localePath + fileName;
}
