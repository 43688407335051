import EventNames from '../../../config/eventNames';
import * as T from '../types';
import isObject from '../../../utils/isObject';
import IEventService from '../IEventService';
import { singleton } from 'tsyringe';
import bindAllMethods from '../../../utils/bindAllMethods';
import { publisherId, getMap } from '../utils';
import BaseEventService from '../BaseEventService';

@singleton()
class WebEventService extends BaseEventService implements IEventService {
  private _eventListeners: T.EventServiceListenersType = new Map();
  public eventNames = EventNames;

  constructor() {
    super();
    bindAllMethods(this);
  }

  async subscribe(
    eventName: string,
    action: T.EventServiceCallBackType
  ): Promise<T.ListenerHandlerType> {
    const actionMap = getMap(eventName, this._eventListeners);
    actionMap.set(action, ({ eventData }) => {
      action({
        eventData,
        eventName,
        publisherId
      });
    });

    return {
      unsubscribe: async () => {
        actionMap.delete(action);
      }
    };
  }

  async publish(
    eventName: string,
    eventData: T.EventServiceValueType['eventData']
  ): Promise<void> {
    const sanitizedEventData = isObject(eventData) ? eventData : {};
    const actionMap = getMap(eventName, this._eventListeners);

    actionMap.forEach((action) => {
      action({ eventData: sanitizedEventData, eventName, publisherId });
    });
  }
}

export default WebEventService;
