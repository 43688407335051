import criterionExternalSourceCache from '../criterionExternalSourceCache';
import { getServices } from '../../../../../../infra/commonInitializer';
import * as T from './types';

const eventSubscriptions = new Map<string, boolean>();

/**
 * Listen to events provided by the criterion to clear the cache when necessary and also send criterion event to update listeners.
 */
export default function createCriterionExternalSourceEvents(
  options: T.ExternalSourceEventOptions
): void {
  try {
    if (Array.isArray(options?.subscribeEvents)) {
      const { eventService } = getServices() || {};

      const eventName =
        eventService?.eventNames?.shellCriterionForceCheckUpdate;

      if (eventName) {
        options?.subscribeEvents?.forEach?.((thisEventName) => {
          if (!eventSubscriptions.get(thisEventName)) {
            eventSubscriptions.set(thisEventName, true);
            eventService?.subscribe?.(thisEventName, () => {
              criterionExternalSourceCache.removeExternalSourceCache(options);
              eventService?.publish?.(eventName, {});
            });
          }
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
}
