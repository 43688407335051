import EventNames from '../../../../config/eventNames';
import { PromiseHelper } from '../../../../utils/PromiseHelper';
import AppContext from '../../../../services/appContext/AppContext';

import { GrantHistoryOptionsType } from '../../../../services/grants/grantsHistory/types';
import { AuthContextEnum } from '../../../../services/authTokenService';
import { getServices } from '../../../../infra/commonInitializer';
import IEventService from '../../../../services/eventService/IEventService';
import bindAllMethods from '../../../../utils/bindAllMethods';
import { GrantHistoryType, GrantsHistoryInterfaceType } from './types';
import { IGrantHistoryService } from '../../../../services/grants/grantsHistory/IGrantHistoryService';

export class GrantsHistoryInterface {
  private defaultKey = 'default';
  private initializedAuthContext: Map<
    AuthContextEnum | typeof this.defaultKey,
    boolean
  > = new Map();
  private promiseHelper = new PromiseHelper();

  private eventsService: IEventService;
  private appContext: AppContext;
  private grantHistoryService: IGrantHistoryService;

  constructor() {
    const services = getServices();
    this.eventsService = services.eventService;
    this.appContext = services.appContext;
    this.grantHistoryService = services.grantHistoryService;
    bindAllMethods(this);

    this.appContext.addInitServices(this._initialize);
    this.appContext.addRefreshServices(this.refresh);
  }

  getInterface(): GrantsHistoryInterfaceType {
    return {
      refresh: this.refresh,
      checkGrantsHistory: this.checkGrantsHistory
    };
  }

  private async _initialize(options?: GrantHistoryOptionsType) {
    const contextKey = options?.authContext ?? this.defaultKey;
    let promise = this.promiseHelper.getPendingPromise(contextKey);
    const isInitialized = this.initializedAuthContext.get(contextKey);

    if (!isInitialized) {
      this.initializedAuthContext.set(contextKey, true);
      if (!promise) {
        promise = this.refresh(options);
        this.promiseHelper.setPendingPromise(contextKey, promise);
      }
    }

    await promise;
  }

  private async refreshAction(options?: GrantHistoryOptionsType) {
    if (await this.grantHistoryService.haveGrantsHistoryChanged(options)) {
      this.eventsService.publish(
        EventNames.shellGrantsHistoryChangedEventName,
        undefined
      );
    }
  }

  async refresh(options?: GrantHistoryOptionsType): Promise<void> {
    const promiseKey = options?.authContext ?? this.defaultKey;
    let promise = this.promiseHelper.getPendingPromise(promiseKey);
    if (this.grantHistoryService.isEnabled() && !promise) {
      promise = this.refreshAction(options);
      this.promiseHelper.setPendingPromise(promiseKey, promise);
    }
    await promise;
  }

  async checkGrantsHistory(
    grants: GrantHistoryType[],
    options?: GrantHistoryOptionsType
  ): Promise<boolean> {
    await this._initialize(options);
    return this.grantHistoryService.checkGrantsHistory(grants, options);
  }
}
