import bindAllMethods from '../../../../utils/bindAllMethods';
import * as T from '../INavigationHistory';
import { createBrowserHistory, History } from 'history';

export * from '../INavigationHistory';

export default class WebNavigationHistory implements T.INavigationHistory {
  // TODO: Change it to be private on future after we are able to not provide it as interface
  _history: History;
  get length(): T.INavigationHistory['length'] {
    return this?._history?.length;
  }
  get action(): T.INavigationHistory['action'] {
    return this?._history?.action;
  }
  get location(): T.INavigationHistory['location'] {
    return this?._history?.location;
  }

  constructor(options?: T.NavigationHistoryConstructorPropsType) {
    this._history = createBrowserHistory({
      basename: options?.basePath
    });

    bindAllMethods(this);
  }

  push(location: T.NavigationHistoryLocationDescriptor, state?: unknown): void {
    this._history?.push?.(location, state);
  }

  replace(
    location: T.NavigationHistoryLocationDescriptor,
    state?: unknown
  ): void {
    this._history?.replace?.(location, state);
  }

  go(n: number): void {
    this._history?.go?.(n);
  }

  goBack(): void {
    this._history?.goBack?.();
  }

  goForward(): void {
    this._history?.goForward?.();
  }

  block(
    prompt?: string | boolean | T.NavigationHistoryTransitionPromptHook
  ): T.NavigationHistoryUnregisterCallback {
    return this._history?.block?.(prompt);
  }

  listen(
    listener: T.NavigationHistoryLocationListener
  ): T.NavigationHistoryUnregisterCallback {
    return this._history?.listen?.(listener);
  }

  createHref(location: T.NavigationHistoryLocationDescriptorObject): string {
    return this._history?.createHref?.(location);
  }
}
