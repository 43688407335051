import { getOneCloudBaseUrl } from '../../../stackUtils';
import { Stack } from '../../../../types/stratus';
import {
  SESSION_API_BASE_URL,
  SESSION_API_LOGOUT_URL
} from '../../../shell/constants';
import { ILogoutClient } from '../../../shell/logout';

type OnecloudLogoutClientParams = {
  stack: Stack;
  clientId: string;
};

export default class OnecloudLogoutClient implements ILogoutClient {
  private _baseUrl: string;
  private _clientId: string;
  private urlPrefix = 'oauth-auth';
  private apiName = '/oauth2';
  private apiVersion = '/v1';
  private hpidLogoutEndpoint = '/extensions/hpid_logout';

  constructor({ stack, clientId }: OnecloudLogoutClientParams) {
    this._baseUrl = `${getOneCloudBaseUrl(stack, this.urlPrefix)}${
      this.apiName
    }${this.apiVersion}`;
    this._clientId = clientId;
  }

  configureLogoutUrl(shellBaseUrl: string, state: string): string {
    const postLoginRedirectUri = `${shellBaseUrl}${SESSION_API_BASE_URL}${SESSION_API_LOGOUT_URL}`;

    const searchParams = new URLSearchParams();
    searchParams.set('state', state);
    searchParams.set('redirect_uri', postLoginRedirectUri);
    searchParams.set('client_id', this._clientId);

    const endpointUrl = this._baseUrl + this.hpidLogoutEndpoint;

    return `${endpointUrl}?${searchParams.toString()}`;
  }
}
