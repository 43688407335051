import { jWebReady, jWebStates } from './initializer';
import { internalLogger } from '../../interface/v1/logger';

import {
  JWebType,
  PutKeyValueOption,
  ValueStorePlugin,
  DevicePlugin,
  DataCollectionPlugin,
  DataCollectionService
} from './types';
import * as JWebEnumsImport from './JWebEnums';
// eslint-disable-next-line no-restricted-imports
import { getDataCollectionService as getDataCollectionServiceImport } from '@jarvis/web-data-collection';
import { callJWebWithTimeout } from './callJWebWithTimeout';

export { getEventServicePlugin } from './eventService';

export const JWebEnums = JWebEnumsImport;

export function JWebErrorHandler<T>(object: Record<string, any>): T {
  if (object?.errorType) {
    throw new Error(`JWeb: ${object?.errorType} - ${object?.reason}`);
  }
  return object as T;
}

export async function getJWeb(): Promise<JWebType> {
  await jWebReady;
  return (window as any)?.JWeb;
}

const jWebContainerInstanceId = (async () => {
  try {
    const JWeb = await getJWeb();

    if (!JWeb.isNative) {
      return;
    } else if (!JWeb?.Plugins?.Container?.getInfo) {
      console.error('JWeb.Plugins.Container.getInfo() is not available');
      return;
    }

    const containerInfo = await callJWebWithTimeout(
      JWeb?.Plugins?.Container?.getInfo
    );

    if (containerInfo === false) {
      console.warn('JWeb.Plugins.Container.getInfo() timed out or failed');
      return;
    }

    return containerInfo?.instanceId;
  } catch (e) {
    console.error('Failed to get JWeb.Plugins.Container.getInfo. ', e);
  }
})();

export async function getJWebContainerInstanceId() {
  return jWebContainerInstanceId;
}

export async function isNative(): Promise<boolean> {
  return (await getJWeb())?.isNative;
}

export async function getPlatform(): Promise<string> {
  return (await getJWeb())?.platform;
}

export async function writeDataToValueStore(
  entriesValue: Array<PutKeyValueOption>
): Promise<void> {
  try {
    // Is Jweb ready?
    await jWebStates.JWebLibrary;

    // Is the plugins registered?
    await jWebStates.defaultPluginsRegistered;

    // Was valueStore installed?
    await jWebStates.plugins.ValueStore;

    // request current valueStore.
    const valueStore = await getValueStorePlugin();

    if (valueStore) {
      valueStore.put({
        entries: entriesValue
      });
    }
  } catch (e) {
    internalLogger?.error('Unable to write in Value Store. JWeb failed.');
  }
}

export async function getValueStorePlugin(): Promise<ValueStorePlugin> {
  const windowValueStore: ValueStorePlugin = (window as any)?.JWeb?.Plugins
    ?.ValueStore;
  if (!windowValueStore) {
    internalLogger?.error(
      'Unable to obtain ValueStore (missing some JWeb plugin)'
    );
  }
  return windowValueStore;
}

export async function getDevicePlugin(): Promise<DevicePlugin> {
  const windowDevicePlugin: DevicePlugin = (window as any)?.JWeb?.Plugins
    ?.Device;
  if (windowDevicePlugin === undefined) {
    internalLogger?.error(
      'Unable to obtain DevicePlugin (missing some JWeb plugin)'
    );
  }
  return windowDevicePlugin;
}

export async function getDataCollectionService(): Promise<DataCollectionService> {
  if (!(await isNative())) {
    // Was DataCollection installed?
    await jWebStates.plugins.DataCollection;
    return await getDataCollectionServiceImport();
  }
  return undefined;
}

export async function getDataCollectionPlugin(): Promise<DataCollectionPlugin> {
  const windowDataCollection: DataCollectionPlugin = (window as any)?.JWeb
    ?.Plugins?.DataCollection;
  if (!windowDataCollection) {
    internalLogger?.error(
      'Unable to obtain DataCollection (missing some JWeb plugin)'
    );
  }
  return windowDataCollection;
}

async function _writeStratusUserIdToValueStore(entryValue: string) {
  const valueStore = await getValueStorePlugin();
  valueStore.put({
    entries: [
      {
        key: JWebEnumsImport.ReservedValueStoreKeys.stratusUserId,
        value: entryValue
      }
    ]
  });
}

async function _writeTenantIdToValueStore(entryValue: string) {
  const valueStore = await getValueStorePlugin();
  valueStore.put({
    entries: [
      {
        key: JWebEnumsImport.ReservedValueStoreKeys.tenantId,
        value: entryValue
      }
    ]
  });
}

async function _writeWpidToValueStore(entryValue: string) {
  const valueStore = await getValueStorePlugin();
  valueStore.put({
    entries: [
      {
        key: JWebEnumsImport.ReservedValueStoreKeys.wpIdUserId,
        value: entryValue
      }
    ]
  });
}

async function _writeHpIdUserIdValueStore(entryValue: string) {
  const valueStore = await getValueStorePlugin();
  valueStore.put({
    entries: [
      {
        key: JWebEnumsImport.ReservedValueStoreKeys.hpIdUserId,
        value: entryValue
      }
    ]
  });
}

export async function writeStratusCookieDataToValueStore(
  stratusId: any,
  tenantId: any,
  wpIdUserId: any,
  hpIdUserId: any
): Promise<void> {
  if (!(await isNative())) {
    await _writeStratusUserIdToValueStore(stratusId);
    await _writeTenantIdToValueStore(tenantId);
    await _writeWpidToValueStore(wpIdUserId);
    await _writeHpIdUserIdValueStore(hpIdUserId);
  }
}
