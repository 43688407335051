import { MicrofrontendRouterLayoutType } from '../../types';
import { MicrofrontendRouterAssetType } from './types';
import { getServices } from '../../../../infra/commonInitializer';

import { internalLogger } from '../../../../interface/v1/logger';
import EventNames from '../../../../config/eventNames';

export const TENANT_SELECTOR_ASSET_REFERENCE =
  '@jarvis/react-shell-tenant-selector';

/**
 * Based on project *react-tenant-selector-mfe*, the TenantSelector can receive the following properties:
 *
 * finishTenantSelector: Callback function that is called when tenantSelector flow is finished
 * errorCallback: Callback function that you can treat any error that can happens on tenantSelector flow.
 * fullscreen: Boolean -  operation that makes you select if you want
 * Tenant Selector in full screen mode centered in your screen or not.
 * preventReload: Boolean -  operation that you can choose to prevent reload.
 *
 * https://github.azc.ext.hp.com/ClientOS/react-tenant-selector-mfe
 */

export const finishTenantSelector = (): void => {
  /* istanbul ignore next */
  internalLogger?.debug?.(' calling finishTenantSelector method.');
  const { eventService } = getServices();
  eventService.publish(EventNames.globalMicrofrontendRouterURL, undefined);
};

// This is the entity that represents the TenantSelector page.
export const tenantSelectorScene: MicrofrontendRouterAssetType &
  MicrofrontendRouterLayoutType = {
  assetReference: TENANT_SELECTOR_ASSET_REFERENCE,
  criterionKey: undefined,
  enable: true,
  key: 'clientos-tenant-selector',
  label: 'Tenant Selector Page',
  path: undefined,
  isPathRoute: false, // (putting here to skip the custom TenantSelector code inside the react-nav  (In Renderer loader))   // TODO: remove this properties when possible
  preloadAssetReferenceLocale: true,
  properties: {
    finishTenantSelector: finishTenantSelector,
    errorCallback: console.error,
    fullscreen: false,
    preventReload: false
  },
  public: true, // TODO: SHOULD be false, but as true I skip the legacy loader in the react-nav (DefaultRoutes loader) // Check this assumption.
  layoutKey: false, // We don't want a layout for this page
  skeletonReference: undefined,
  tenantHandlerOverride: undefined
};
