import { JarvisAuthProvider } from '@jarvis/web-http';
import { AuthContextEnum } from '../../../services/authTokenService';
import * as TntCommonResourceRegistryTypes from '../../../clients/stratus/tntCommonResourceRegistry/types';
import { ISessionService } from '../../../services/session';
import BaseStratusTenantHandler from './BaseTenantHandler';
import { TenantStrategyEnum } from './strategy';
import TenantStrategy from './TenantStrategy';
import * as T from './types';

type CustomerTenantHandlerProperties = {
  fallback?: string;
};

export default class CustomerTenantHandler
  extends BaseStratusTenantHandler
  implements TenantStrategy
{
  private _strategy = TenantStrategyEnum.stratusCustomer;
  private _options: CustomerTenantHandlerProperties;
  private _assetReference: string;
  private _sessionService: ISessionService;

  constructor({
    stack,
    options,
    authProvider,
    parentAuthProvider,
    assetReference,
    sessionService
  }: T.TenantHandlerStrategyType) {
    super({ stack, authProvider, parentAuthProvider });
    this._options = options;
    this._assetReference = assetReference;
    this._sessionService = sessionService;
  }

  getAssetReference(): string {
    return this._assetReference;
  }

  getStrategy = (): TenantStrategyEnum => {
    return this._strategy;
  };

  //TODO: why we have a ID as a parameter since the exchange is based on the values present on the repository?
  setTenant = async (
    id: string,
    authContext: AuthContextEnum
  ): Promise<void> => {
    await this._sessionService.exchangeToken(
      id,
      authContext,
      TenantStrategyEnum.stratusCustomer
    );
  };

  getAuthProvider(): JarvisAuthProvider {
    return this._authProvider;
  }

  private async _getOrganizationTenantId(): Promise<string> {
    return (window as any).Shell.v1.tenantHandler.getTenantByLevel(1)?.id;
  }

  public async _getPaginatedTenantListData(
    options: T.GetPaginatedTenantListOptionsType
  ): Promise<T.PaginatedTenantVisualizationType> {
    options.tenantId = await this._getOrganizationTenantId();
    const nodeResponse: TntCommonResourceRegistryTypes.GetNodeResponseType =
      await this._nodeClient.getNodeAdministersCrossUses(
        options as TntCommonResourceRegistryTypes.GetNodeAdministersCrossUsesOptionsType
      );

    const tenants: T.TenantVisualizationType[] = nodeResponse.content.map(
      (content) => {
        return {
          id: content.nodeId,
          name: content.accountName
        };
      }
    );
    const result: T.PaginatedTenantVisualizationType = {
      totalPages: nodeResponse.totalPages,
      currentPage: nodeResponse.pageable.pageNumber,
      tenants
    };
    return result;
  }

  isRequiredToNavigate = (): boolean => {
    return false;
  };

  getFallback = (): string => {
    return this?._options?.fallback;
  };

  isTenantValid(
    filter: T.TenantHandlerFilterType,
    tenant: T.TenantVisualizationType
  ): boolean {
    console.info(
      `Method isTenantValid is not implemented - tenantId: ${tenant?.id} - filter: ${filter}`
    );
    return true;
  }
}
