// TODO: This session should be removed from the application ASAP

import { ISessionService } from '../../services/session';
import { initDeprecatedSessionSvc } from '../../clients/shell/session';
import { InterfacesType } from '../../interface';

export type InitializeDeprecatedServicesType = {
  sessionService: ISessionService;
  orgSelector: InterfacesType['v1']['orgSelector'];
  isNative: boolean;
};

export default class InitializeDeprecatedServices {
  public static async init({
    sessionService,
    orgSelector,
    isNative
  }: InitializeDeprecatedServicesType): Promise<void> {
    await initDeprecatedSessionSvc({ sessionService });
    if (sessionService?.isLoggedIn() && !isNative)
      await orgSelector?.updateDomainList();
  }
}
