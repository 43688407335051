import { Stack } from '../../types/stratus';

export const stackValidator = (stackSring: string) => {
  switch (stackSring) {
    case 'dev':
      return Stack.pie;
    case 'pie':
      return Stack.pie;
    case 'stage':
      return Stack.stage;
    case 'prod':
      return Stack.prod;
    default:
      return Stack.pie;
  }
};
