import AuthenticationProviderEnum from '../../config/authenticationProviderEnum';
import { decodeJWTPayload } from './JWTUtils';

const ISSUER_SUBSTRING = {
  STRATUS: 'authz.wpp.api.hp.com',
  ONECLOUD: 'oc.hp.com'
};

const getAuthenticationProviderByToken = (
  token: string
): AuthenticationProviderEnum => {
  if (!token) return null;
  const payload = decodeJWTPayload(token);
  const issuer: string = payload?.iss;

  if (!issuer) return null;

  if (issuer.includes(ISSUER_SUBSTRING.STRATUS)) {
    return AuthenticationProviderEnum.authz;
  }
  if (issuer.includes(ISSUER_SUBSTRING.ONECLOUD)) {
    return AuthenticationProviderEnum.coptor;
  }
  return null;
};

export default getAuthenticationProviderByToken;
