import { ControlledData } from './interfaces/controlledData';
import { Event } from './event/Event';
import { Notifications } from './notification';
import { Originator } from './Originator/Originator';
import { OriginatorDetail } from './Originator/OriginatorDetail';
import { OriginatorContext } from './OriginatorContext/OriginatorContext';
import { OriginatorContextDetail } from './OriginatorContext/OriginatorContextDetail';

export const buildNotification = (controlledData: ControlledData, events: Event[]): Notifications => {
  const originatorDetail = new OriginatorDetail(controlledData, new Date().toISOString());
  const originatorContextDetail: OriginatorContextDetail = new OriginatorContextDetail(controlledData);
  const notification = new Notifications(
    events,
    new Originator(originatorDetail),
    new OriginatorContext(originatorContextDetail)
  );
  return notification;
};
