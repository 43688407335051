import { getServices } from '../../../infra/commonInitializer';
import TenantLevelEnum from '../../../services/tenantHandler/TenantLevelEnum';

const TabHelpers = {
  /**
   * @description Updates the URL with tenant-specific query parameters.
   * This method modifies the current URL's query parameters to include tenant information,
   * based on the tenants handled by the Tenant Handler Service. The 'getHandledTenantList()'
   * returns a list of valid tenants after having the TenantSelectorKey set inside the
   * Tenant Handle Service. If isn't set or something wrong happens, the HandledTenantList
   * will be an empty array list. We only perform the query parameters updating if there's
   * at least one valid tenant to be filled in If the HandledTenantList is not empty, it
   * first retrieves a list of tenants that have been processed ('proccessed' flag is true)
   * and updates the URL's query parameters to reflect these tenants.
   * @returns {void}
   */
  old_persistTenantQueryParam: (): void => {
    const { navigationService, tenantHandlerService } = getServices();

    // Adding a Callback for setPersistQueryParam
    navigationService.setPersistQueryParam(
      'tenantQueryParams',
      (queryParamsObject) => {
        const newQueryParams = { ...queryParamsObject };
        let lastTenantLevel = 0;
        const HandledTenantList = tenantHandlerService?.getHandledTenantList();

        if (HandledTenantList.length) {
          HandledTenantList.forEach((t) => {
            if (t.proccessed) {
              lastTenantLevel =
                t.level > lastTenantLevel ? t.level : lastTenantLevel;
              newQueryParams[`t${t.level}`] = t.id;
            }
          });

          for (const key in newQueryParams) {
            const isTenantKey = new RegExp('^t[0-9]+$').test(key);

            if (isTenantKey) {
              const tenantNumber = Number(
                key?.split?.('t')?.filter?.(Boolean)?.[0]
              );

              if (tenantNumber > lastTenantLevel) {
                newQueryParams[key] = undefined;
              }
            }
          }
        }

        return newQueryParams;
      }
    );
  },
  /**
   * @description Remove the tenant-specific query parameters from the URL.
   */
  removeTenantsFromQueryParams: (): void => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const { navigationService } = getServices();

    // Cleaning the QueryParams
    Object.values(TenantLevelEnum).forEach((level) => {
      urlSearchParams.delete(`t${level}`);
    });

    navigationService.replace({
      ...navigationService.location,
      search: urlSearchParams.toString()
    });
  }
};

export default TabHelpers;
