export const subscription = {
  '$schema': 'http://json-schema.org/draft-04/schema#',
  'version': '1.4.0',
  'gun': 'com.hp.cdm.service.eventing.version.1.resource.subscription',
  'jsonSchemaGun': 'com.hp.cdm.service.eventing.version.1.resource.subscription.schema.json',
  'id': 'https://schemaregistry.analysis.ext.hp.com/cdm/gun/com.hp.cdm.service.eventing.version.1.resource.subscription.schema.json',

  'definitions': {
    'authenticationMethod': {
      'description': 'the HTTP Authentication scheme',
      'enum': [
        'none',
        'basicAuth',
        'deviceServiceBasicAuth'
      ],
      'title': 'authenticationMethod',
      'type': 'string'
    },
    'serviceJwtConfig': {
      'additionalProperties': false,
      'properties': {
        'keyLifecycle': {
          'description': 'number od seconds before rotating the keys. Value of 1 means one-time only. Value of 0 means no key rotation.',
          'type': 'integer'
        },
        'serviceName': {
          'type': 'string'
        },
        'serviceNameType': {
          'description': 'FQDN prefix for the serviceName. GUN format.',
          'type': 'string'
        }
      },
      'required': [
        'serviceName'
      ],
      'title': 'serviceJwtConfig',
      'type': 'object'
    },
    'subscription': {
      'additionalProperties': false,
      'description': 'This schema represents the eventing subscription',
      'properties': {
        'authentication': {
          'description': 'The authentication data in the form of key/value pairs used to authenticate with the event sink (not returned as part of a get).',
          'type': 'object'
        },
        'authenticationMethod': {
          '$ref': '#/definitions/authenticationMethod'
        },
        'callbackUri': {
          'description': 'The callback uri that asynchronous events will be sent to as they occur.',
          'type': 'string'
        },
        'connectionTimeout': {
          'description': 'The maximum time (in seconds) to allow for making a connection to a server',
          'type': 'integer'
        },
        'eventCategory': {
          '$ref': 'com.hp.cdm.service.eventing.version.1.sharedTypes.eventing.schema.json#/definitions/eventCategory'
        },
        'filterId': {
          'description': 'identifier of the filter to be applied for this telemetry category event data',
          'type': 'integer',
          'minimum': 1,
          'maximum': 2147483647
        },
        'links': {
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/links'
        },
        'maximumConsecutiveRetries': {
          'description': 'The maximum number of times to retry an attempt to access the URI. A value of 0 indicates no retry if the first attempt fails.',
          'type': 'integer'
        },
        'payloadSecurity': {
          'additionalProperties': false,
          'description': 'identifies the service keys used to sign and encrypt the payload',
          'properties': {
            'serviceJwtConfig': {
              '$ref': '#/definitions/serviceJwtConfig'
            }
          },
          'type': 'object'
        },
        'pushEventDetail': {
          'default': 'true',
          'enum': [
            'true',
            'false'
          ],
          'type': 'string'
        },
        'requestTimeout': {
          'description': 'The maximum time (in seconds) to allow for sending a request to a server and receiving a response.',
          'type': 'integer'
        },
        'retryInterval': {
          'description': 'The minimum number of seconds to wait before attempting a retry. ',
          'type': 'integer'
        },
        'subscriptionId': {
          '$ref': '#/definitions/subscriptionId'
        },
        'token': {
          '$ref': '#/definitions/token'
        },
        'version': {
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/version'
        },
        'clientId': {
          'description': 'Unique identifier for a client',
          'type': 'string'
        },
        'clientType': {
          'description': 'possible connection types that client can specify',
          'default': 'network',
          'enum': [
            'network',
            'usb'
          ],
          'type': 'string'
        }
      },
      'required': [
        'callbackUri',
        'eventCategory'
      ],
      'title': 'subscription',
      'type': 'object'
    },
    'subscriptionId': {
      'description': 'Unique identifier for a subscription',
      'type': 'string'
    },
    'token': {
      'description': 'The security token provided by the client (not returned as part of a get).',
      'type': 'string'
    }
  }
};
