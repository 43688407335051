import { QueueItem } from '../Queue/QueueItem';
import { QueueWorker } from '../Queue/QueueWorker';
import { logger } from '../helpers/logger';
import { getWindowValues } from '../client/utils/enum';
import { dataCollectionService } from '../dataCollectionService/dataCollectionService';
import { QueueItemStatus } from '../Queue/queueHelpers';

const DB_NAME = 'eventDataBase';
const TABLE_NAME = 'eventData';
const DB_VERSION = 1;
const KEY_PATH = 'id';

const openDB = (name: string, version: number, upgradeCallback: (db: IDBDatabase) => void): Promise<IDBDatabase> => new Promise((resolve, reject) => {
  if (!indexedDB) {
    const errorMessage = 'IndexDB::setUpDB::Your browser doesn\'t support a stable version of IndexedDB.';
    logger.log(errorMessage);
    return reject(new Error(errorMessage));
  }
  const request = indexedDB.open(name, version);

  request.onerror = (event) => {
    logger.error('IndexDB::SetUpDB::error:', event);
    reject(request.error);
  };

  request.onsuccess = (event: any) => resolve(event.target.result);

  request.onupgradeneeded = (event: any) => upgradeCallback(event.target.result);
});

export class EventDB {
  static db: IDBDatabase | null = null;
  static getDatabase = async () =>{
    try {
      const db = await openDB(DB_NAME, DB_VERSION, (db: IDBDatabase) => {
        if (!db.objectStoreNames.contains(TABLE_NAME)) {
          db.createObjectStore(TABLE_NAME, { keyPath: KEY_PATH });
        }
      });
      EventDB.db = db;
      return db;
    } catch (error) {
      logger.error('IndexDB::EventDB::getDatabase::Database initialization failed', error);
      return null;
    }
  };
  static getElement = async () => {
    try {
      EventDB.db = await EventDB.getDatabase();
      if (EventDB.db instanceof IDBDatabase) {
        const transaction = EventDB.db.transaction(TABLE_NAME, 'readonly');
        const objectStore = transaction.objectStore(TABLE_NAME);

        const cursor = await new Promise<IDBCursorWithValue | null>((resolve, reject) => {
          const request = objectStore.openCursor();

          request.onsuccess = () => {
            resolve(request.result);
          };

          request.onerror = () => {
            reject(request.error);
          };
        });

        if (cursor) {
          logger.log('IndexDB::EventDB::getElement:cursorkey:', cursor.key);
          return cursor;
        }
        await new Promise(resolve => transaction.oncomplete = resolve);
      }

    } catch (e){
      logger.error('IndexDB::EventDB::getElement:Error:', e);
      return null;
    }
  };

  static removeById = async (id: string) => {
    try {
      EventDB.db = await EventDB.getDatabase();
      if (EventDB.db instanceof IDBDatabase) {
        const request = EventDB.db.transaction([TABLE_NAME], 'readwrite').objectStore(TABLE_NAME).delete(id);
        request.onsuccess = () => {
          logger.log(`IndexDB::EventDB::removeById:event ${id} is deleted successfully`);
        };
        request.onerror = () => {
          logger.error(`IndexDB::EventDB::removeById:error:Unable to delete event with id ${id}`);
        };
      }
    } catch (e) {
      logger.error('IndexDB::EventDB::removeById::error:', e);
    }
  };

  static add = async (item: QueueItem) => {
    try {
      EventDB.db = await EventDB.getDatabase();
      if (EventDB.db instanceof IDBDatabase) {
        const transaction = EventDB.db.transaction([TABLE_NAME], 'readwrite');
        const objectStore = transaction.objectStore(TABLE_NAME);
        return new Promise((resolve, reject) => {
          const request = objectStore.put(item);
          request.onsuccess = async () => {
            logger.log('IndexDB::EventDB::add::Item Created with id:' + item.id,'&','Queue Worker Running?', QueueWorker.running);
            const webAppConsent = getWindowValues().sessionStorage.getItem('webAppConsent');
            const preConsentEventAccumulation = dataCollectionService.getConfiguration()?.preConsentEventAccumulation;
            const isBatchingEnabled = dataCollectionService.getConfiguration()?.isBatchingEnabled;
            const preBuilt = item.preBuilt;
            if (!QueueWorker.running && (!preConsentEventAccumulation || (webAppConsent !== 'undefined' && webAppConsent)) && (isBatchingEnabled === false || preBuilt === true)) {
              logger.log('IndexDB::EventDB::add:starting the Queue Worker');
              await QueueWorker.startSendData();
            }
          };
          request.onerror = () => {
            logger.error('IndexDB::EventDB::add::error:Unable to add to database!', request.error);
            reject(request.error);
          };

          transaction.oncomplete = () => resolve(true);
          transaction.onerror = () => reject(transaction.error);
        });
      }
    } catch (e) {
      logger.error('IndexDB::EventDB::add::error:',e);
    }
  };

  static count = async () => {
    try {
      EventDB.db = await EventDB.getDatabase();
      if (EventDB.db instanceof IDBDatabase) {
        const transaction = EventDB.db.transaction(TABLE_NAME, 'readonly');
        const objectStore = transaction.objectStore(TABLE_NAME);
        const count = await new Promise<number>((resolve, reject) => {
          const request = objectStore.count();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });
        logger.log('IndexDB::EventDB::count:', count);
        return count;
      }
    } catch (e) {
      logger.error('IndexDB::EventDB::count::error:', e);
    }
    return 0;
  };

  static update = async (item: object, key: number) => {
    if (! await EventDB.isKeyPresent(key)) {
      logger.log('IndexDB::EventDB::update:item with this key is already deleted!');
      return;
    }
    try {
      EventDB.db = await EventDB.getDatabase();
      if (EventDB.db instanceof IDBDatabase) {
        const transaction = EventDB.db.transaction([TABLE_NAME], 'readwrite');
        const objectStore = transaction.objectStore(TABLE_NAME);
        logger.log('IndexDB::EventDB::update:updating queue Item');
        const updateRequest = objectStore.put(item);
        await new Promise((resolve, reject) => {
          updateRequest.onsuccess = () => {
            logger.log('IndexDB::EventDB::update:Item updated');
            resolve(true);
          };
          updateRequest.onerror = () => {
            logger.error('IndexDB::EventDB::update:Error updating item');
            reject(updateRequest.error);
          };
        });
        await new Promise((resolve, reject) => {
          transaction.oncomplete = () => {
            window.dispatchEvent(new CustomEvent('dbLoaded'));
            resolve(true);
          };
          transaction.onerror = () => {
            logger.error('IndexDB::EventDB::update:Transaction error');
            reject(transaction.error);
          };
        });

        return true;
      }
    } catch (e) {
      logger.error(`IndexDB::EventDB::update:error:${e}`);
      return false;
    }
  };

  static isKeyPresent = async (key: number) => {
    try {
      EventDB.db = await EventDB.getDatabase();
      if (EventDB.db instanceof IDBDatabase) {
        const transaction = EventDB.db.transaction(TABLE_NAME, 'readonly');
        const objectStore = transaction.objectStore(TABLE_NAME);
        const getKey = await new Promise<boolean>((resolve, reject) => {
          const request = objectStore.get(key);
          request.onsuccess = () => resolve(!!request.result);
          request.onerror = () => reject(request.error);
        });
        return getKey;
      }
    } catch (e) {
      logger.error('IndexDB::EventDB::isKeyPresent::error:', e);
    }
    return false;
  };

  static getProcessElementFromDB = async () => {
    try {
      EventDB.db = await EventDB.getDatabase();
      if (EventDB.db instanceof IDBDatabase) {
        const transaction = EventDB.db.transaction(TABLE_NAME);
        const objectStore = transaction.objectStore(TABLE_NAME);
        const cursor = await new Promise<IDBCursorWithValue | null>((resolve, reject) => {
          const request = objectStore.openCursor();

          request.onsuccess = () => {
            resolve(request.result);
          };

          request.onerror = () => {
            reject(request.error);
          };
        });

        while (cursor) {
          if (cursor.value.status !== QueueItemStatus.pending) {
            return cursor.value;
          }
          cursor.continue();
        }
        await new Promise<void>((resolve, reject) => {
          transaction.oncomplete = () => resolve();
          transaction.onerror = () => reject(transaction.error);
          transaction.onabort = () => reject(transaction.error);
        });
      }
    } catch (e) {
      logger.error('IndexDB::EventDB::getProcessElementFromDB::error:', e);
      return null;
    }
  };

  static async getQueueItems(status: QueueItemStatus): Promise<QueueItem[]> {
    const result: QueueItem[] = [];
    try {
      EventDB.db = await EventDB.getDatabase();
      if (EventDB.db instanceof IDBDatabase) {
        const transaction = EventDB.db.transaction(TABLE_NAME, 'readonly');
        const store = transaction.objectStore(TABLE_NAME);
        const request = store.openCursor();
        request.onsuccess = (event: Event) => {
          const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
          if (cursor) {
            if (cursor.value.status === status) {
              result.push(cursor.value);
            }
            cursor.continue();
          }
        };

        await new Promise<void>((resolve, reject) => {
          transaction.oncomplete = () => resolve();
          transaction.onerror = () => reject(transaction.error);
          transaction.onabort = () => reject(transaction.error);
        });
      }
    } catch (e) {
      logger.error('IndexDB::EventDB::getQueueItems::error:', e);
    }

    return result;
  }
}
