import defaultMockConfig from './defaultMockConfig';
import getGraphqlMockNorthboundAPI from './northboundAPIs/getGraphqlMockNorthboundAPI';
import { GraphqlMockSchemaType } from './northboundAPIs/getGraphqlMockNorthboundAPI/types';
import getMockedFeatureFlagsNorthboundAPI from './northboundAPIs/getMockedFeatureFlagsNorthboundAPI';
import getMockedSessionInterfaceNorthboundAPI from './northboundAPIs/getMockedSessionInterfaceNorthboundAPI';
import type * as T from './types';

export default async function getMockedNorthboundAPIs(
  options: T.MockedCommonsNorthboundApiDependenciesType
): Promise<T.MockedCommonsNorthboundApiType> {
  const result: T.MockedCommonsNorthboundApiType = {};

  if (!options?.enable) return result;

  const sanitizedOptions = options?.useDefaultMocks
    ? defaultMockConfig
    : options;

  const addMock = <
    NbApiName extends keyof T.MockedCommonsNorthboundApiType['v1']
  >(
    name: NbApiName,
    nbApi: {
      v1?: T.MockedCommonsNorthboundApiType['v1'][NbApiName];
      v2?: NbApiName extends keyof T.MockedCommonsNorthboundApiType['v2']
        ? T.MockedCommonsNorthboundApiType['v2'][NbApiName]
        : undefined;
    }
  ) => {
    const { v1, v2 } = nbApi || {};
    if (v1) {
      result.v1 = {
        ...result.v1,
        [name]: v1
      };
    }
    if (v2) {
      result.v2 = {
        ...result.v2,
        [name]: v2
      };
    }
  };

  addMock(
    'graphql',
    await getGraphqlMockNorthboundAPI(sanitizedOptions?.northboundAPIs?.graphql)
  );

  const isLoggedInValue = await (async () => {
    // Return the first boolean value found in order of priority
    const graphQlIsLoggedIn = await result?.v1?.graphql
      ?.query?.('')
      ?.then?.(
        (result) =>
          (result?.data as GraphqlMockSchemaType)?.account?.user?.email
      )
      ?.catch?.(() => undefined);

    const isLoggedInCheckOrder = [
      sanitizedOptions?.northboundAPIs?.sessionInterface?.isLoggedIn,
      !!graphQlIsLoggedIn
    ];

    return !!isLoggedInCheckOrder.find(Boolean);
  })();

  addMock(
    'sessionInterface',
    await getMockedSessionInterfaceNorthboundAPI({
      ...sanitizedOptions?.northboundAPIs?.sessionInterface,
      isLoggedIn: isLoggedInValue
    })
  );

  addMock(
    'featureFlags',
    await getMockedFeatureFlagsNorthboundAPI(
      sanitizedOptions?.northboundAPIs?.featureFlags
    )
  );

  return result;
}
