import { AbstractLocaleHandler } from './AbstractLocaleHandler';
import getValueByInsensitiveKey from '../../../utils/getValueByInsensitiveKey';
import { ServiceLocalizationFallbackType } from './../types';
import getNavigatorLocale from '../utils/getNavigatorLocale';

export class NavigatorLocaleHandler extends AbstractLocaleHandler {
  constructor(languagesFallback: ServiceLocalizationFallbackType) {
    super(languagesFallback);
  }

  public handle(isLanguage: boolean, currentList: string[]): string {
    const navigatorLocale = getNavigatorLocale();

    // For fallback
    const navigatorFallback = getValueByInsensitiveKey(
      this._languagesFallback,
      navigatorLocale?.language
    );

    return super.genericHandle({
      isLanguage: isLanguage,
      locale: navigatorLocale,
      currentList: currentList,
      localeFallback: navigatorFallback
    });
  }
}
