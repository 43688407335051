import { internalLogger } from '../interface/v1/logger';
import { RepositoriesType } from './commonInitializer/RepositoriesType';
import { getRepositories } from './commonInitializer';

let repositories: RepositoriesType;

export const clearRepositories = (skipList = []): void => {
  try {
    repositories = getRepositories();
  } catch (error) {
    internalLogger?.error?.(error);
  }

  // Filter repositories based on the skipList.
  const repoNames = Object.keys(repositories);

  const allowedList = repoNames.filter((repo) => !skipList.includes(repo));

  const repositoriesToClear = allowedList.map((r) => repositories[r]);

  internalLogger?.debug?.(
    'Clear the following repositories from JSHELL.',
    repositoriesToClear
  );

  repositoriesToClear?.forEach((r) => r?.clear?.());
};
