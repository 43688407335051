export type ThemeInputType = {
  enable?: boolean;
  themeProvider?: ThemeProviderType;
  screenResponsively?: ScreenResponsivelyType;
};

export type ScreenResponsivelyType = {
  responsiveBreakpoint?: string;
  size?: 'small' | 'medium' | 'large';
  [key: string]: unknown;
};

export type ThemeProviderType = {
  mode?: '' | 'light' | 'dark' | 'contrast';
  userModeEnabled?: boolean;
  [key: string]: unknown;
};

export enum ThemeModeEnum {
  light = 'light',
  dark = 'dark',
  contrast = 'contrast',
  default = 'light'
}
