import { UuidV4 } from '../../../utils/crypto';
import bindAllMethods from '../../../utils/bindAllMethods';
import IBackgroundTaskService, {
  BackgroundTasksOptionsType,
  Task,
  TaskData,
  TaskWrapper
} from './IBackgroundTaskService';
import { BackgroundTasksTaskStateEnum } from './enums';
import { BackgroundTaskError } from './backgroundTaskError';
import { BackgroundTaskErrorEnum } from './backgroundTaskError/enum';
import { internalLogger } from '../../../interface/v1/logger';
class BackgroundTaskService implements IBackgroundTaskService {
  private _taskPool: Record<string, TaskWrapper>;

  constructor() {
    this._taskPool = {};
    bindAllMethods(this);
  }

  async register(id: string, task: Task): Promise<void> {
    console.log(`JSHELL BackgroundTask register: ${id} - START`);
    if (this._taskPool[`${id}`]) {
      throw new Error('Id already registered.');
    }
    const taskWrapper: TaskWrapper = {
      task,
      taskId: id,
      launchHistory: []
    };
    this._taskPool[`${id}`] = taskWrapper;
    console.log(`JSHELL BackgroundTask register: ${id} - FINISH`);
  }

  async get(id: string): Promise<TaskData> {
    const task = this._taskPool[`${id}`];
    if (!task) {
      throw new Error(`Task with id '${id}' does not exist.`);
    }
    const taskData: TaskData = {
      taskId: task.taskId,
      launchHistory: task.launchHistory
    };
    return taskData;
  }

  getBackgroundTaskList(): TaskData[] {
    return Object.values(this._taskPool);
  }

  async launch(
    taskId: string,
    options: BackgroundTasksOptionsType,
    attempt: number = 0
  ): Promise<any> {
    internalLogger?.log(
      `JSHELL BackgroundTask launch: ${taskId}-${options?.launchId} - LAUNCH`
    );
    const taskWrapper = this._taskPool[`${taskId}`];
    const maxAttemptsValue = options?.maxAttempts || 1;

    if (!taskWrapper) {
      throw new BackgroundTaskError(
        BackgroundTaskErrorEnum.TASKID_NOT_EXISTS,
        `Task with id '${taskId}' does not exist.`
      );
    }

    if (
      taskWrapper.launchHistory.find(
        (task) => task.launchId === options?.launchId
      )
    ) {
      throw new BackgroundTaskError(
        BackgroundTaskErrorEnum.LAUNCHID_ALREADY_EXISTS,
        `LaunchId: ${options?.launchId} already exists in BackgroundTask: ${taskId}.`
      );
    }

    const launchId = options?.launchId || UuidV4.getRandomUUID();

    const taskLaunchHistoryLength = taskWrapper.launchHistory.push({
      launchId,
      promise: undefined,
      result: undefined,
      state: BackgroundTasksTaskStateEnum.LAUNCHING
    });

    const launchedTaskItem =
      taskWrapper.launchHistory[taskLaunchHistoryLength - 1];

    return (launchedTaskItem.promise = taskWrapper
      .task({ ...options, launchId })
      .then((result) => {
        launchedTaskItem.state = BackgroundTasksTaskStateEnum.FINISHED;
        launchedTaskItem.result = result;

        return result;
      })
      .catch((error) => {
        launchedTaskItem.state = BackgroundTasksTaskStateEnum.ERROR;
        launchedTaskItem.result = error;
        if (attempt < maxAttemptsValue) {
          internalLogger?.log(
            `Trying to launch BackgroundTask - attempt (${attempt}/${maxAttemptsValue})...`
          );
          return this.launch(
            taskId,
            { ...options, launchId: UuidV4.getRandomUUID() },
            attempt + 1
          );
        } else {
          throw new BackgroundTaskError(
            BackgroundTaskErrorEnum.MAX_ATTEMPTS_REACHED,
            `LaunchId: ${options?.launchId} of BackgroundTask: ${taskId} failed after ${maxAttemptsValue} attempts.`,
            {
              launchId,
              taskId,
              error
            }
          );
        }
      }));
  }
}

export default BackgroundTaskService;
