import { BackgroundTaskErrorEnum } from './enum';
import { BackgroundTaskErrorDataType } from './types';

export class BackgroundTaskError extends Error {
  errorType: BackgroundTaskErrorEnum;
  message: string;
  data?: BackgroundTaskErrorDataType;

  constructor(
    errorType: BackgroundTaskErrorEnum,
    message: string,
    data?: BackgroundTaskErrorDataType
  ) {
    super();
    this.errorType = errorType;
    this.message = message;
    this.data = data;
  }
}
