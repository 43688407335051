import {
  MicrofrontendRouterAssetType,
  MicrofrontendRouterLayoutType
} from '../../types';

export const SHELL_LOGIN_ASSET_REFERENCE = '@jarvis/react-shell-login';

// This is the entity that represents the Login page.
export const loginScene: MicrofrontendRouterAssetType &
  MicrofrontendRouterLayoutType = {
  assetReference: SHELL_LOGIN_ASSET_REFERENCE,
  criterionKey: undefined,
  enable: true,
  key: undefined,
  path: undefined,
  label: 'Login Page',
  isPathRoute: false,
  preloadAssetReferenceLocale: true,
  properties: {},
  public: true,
  layoutKey: false,
  skeletonReference: undefined,
  tenantHandlerOverride: undefined
};
