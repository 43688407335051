import bindAllMethods from '../../utils/bindAllMethods';
import { IAppContext } from './IAppContext';

type ServiceMethodType = (...args: any) => any;

export default class AppContext implements IAppContext {
  private _initContextServices: ServiceMethodType[];
  private _refreshContextServices: ServiceMethodType[];

  constructor() {
    this._initContextServices = [];
    this._refreshContextServices = [];
    bindAllMethods(this);
  }

  public addInitServices(serviceInitMethod): void {
    this._initContextServices.push(serviceInitMethod);
  }

  public addRefreshServices(serviceRefreshMethod): void {
    this._refreshContextServices.push(serviceRefreshMethod);
  }

  public async initializeContextServices(): Promise<void> {
    await Promise.all(this._initContextServices);
  }

  public async refreshContext(): Promise<void> {
    await Promise.all(this._refreshContextServices.map((f) => f()));
  }
}
