import { Stack } from '../../../../../../../types/stratus';
import { JarvisAuthProvider, JarvisWebHttpClient } from '@jarvis/web-http';
import getStratusApiDomain from '../../../../../../../utils/getStratusApiDomain';
import * as T from './types';
import { internalLogger } from '../../../../../../../interface/v1/logger';

const LOCALSTORAGE_OD_URLS = 'onboarding-director-urls';
const APPLICATION_CONTEXT = '/onboarding-director';
const RESOURCE_URL = '/v1/sessions';
class OnboardingDirector {
  baseUrl: string;
  authProvider: JarvisAuthProvider;
  httpClient?: JarvisWebHttpClient;
  sessionResourceUrl?: string;

  constructor(stack: Stack, authProvider: JarvisAuthProvider) {
    this.authProvider = authProvider;
    const urls = this.getUrlsFromLocalStorage();
    const defaultUrls = this.setDefaultUrls(stack, urls);

    this.baseUrl =
      urls?.applicationContextUrl?.local || defaultUrls.applicationContextUrl;

    this.sessionResourceUrl =
      urls?.sessionResourceUrl?.local || defaultUrls.sessionResourceUrl;

    (async () => {
      this.httpClient = new JarvisWebHttpClient(
        (await this.authProvider.getAccessToken()) && {
          defaultAuthProvider: this.authProvider
        }
      );
    })();
  }

  private getUrlsFromLocalStorage(): T.LocalStorageEndpointUrlsType {
    return JSON.parse(window.localStorage.getItem(LOCALSTORAGE_OD_URLS));
  }

  private setDefaultUrls(
    stack: Stack,
    urls: T.LocalStorageEndpointUrlsType
  ): {
    sessionResourceUrl: string;
    applicationContextUrl: string;
  } {
    const defaultUrl = getStratusApiDomain(stack) + APPLICATION_CONTEXT;

    const newLocalStorageUrlsObject = {
      sessionResourceUrl: {
        ...urls?.sessionResourceUrl,
        default: RESOURCE_URL
      },
      applicationContextUrl: {
        ...urls?.applicationContextUrl,
        default: defaultUrl
      }
    };

    window.localStorage.setItem(
      LOCALSTORAGE_OD_URLS,
      JSON.stringify(newLocalStorageUrlsObject)
    );

    return {
      sessionResourceUrl: RESOURCE_URL,
      applicationContextUrl: defaultUrl
    };
  }

  async post({
    data
  }: T.PostRequestOptionsType): Promise<T.OnboardingDirectorSessionResponseType> {
    const baseURL = this.baseUrl + this.sessionResourceUrl;

    internalLogger?.log?.('OD POST request', { baseURL, data });

    return this.httpClient
      .post({ baseURL, data })
      .then((e) => {
        const result = e.data as T.OnboardingDirectorSessionResponseType;
        internalLogger?.log?.('OD POST response', result);

        return result;
      })
      .catch((error) => {
        internalLogger?.error?.('OD POST error response', error);
        throw error;
      });
  }

  async put({
    data,
    baseURL
  }:
    | T.PutRequestOptionsType
    | T.PutRequestBackgroundRequestsType): Promise<T.OnboardingDirectorSessionResponseType> {
    internalLogger?.log?.('OD PUT request', { baseURL, data });

    return this.httpClient
      .put({ baseURL, data })
      .then((e) => {
        const result = e.data as T.OnboardingDirectorSessionResponseType;

        internalLogger?.log?.('OD PUT response', result);

        return result;
      })
      .catch((error) => {
        internalLogger?.error?.('OD PUT error response', error);
        throw error;
      });
  }

  async get({
    data
  }: T.GetRequestOptionsType): Promise<
    T.OnboardingDirectorSessionResponseType[]
  > {
    const { app, onboardingContext, limit } = data;
    let limitString = '',
      onboardingContextString = '',
      appString = '';

    if (limit) {
      limitString = `&limit=${limit}`;
    }
    if (onboardingContext) {
      onboardingContextString = `&onboardingContext=${encodeURI(
        JSON.stringify(onboardingContext)
      )}`;
    }
    if (app) {
      appString = `&app=${encodeURI(JSON.stringify(app))}`;
    }

    const endPoint = '/resumable';
    const queryParams = `?sortBy=${T.SortByEnum.updatedAtDesc}${limitString}${onboardingContextString}${appString}`;
    const baseURL =
      this.baseUrl + this.sessionResourceUrl + endPoint + queryParams;

    internalLogger?.log?.('OD GET resumable sessions request', { baseURL });

    return this.httpClient
      .get({ baseURL })
      .then((e) => {
        const result = e.data as T.OnboardingDirectorSessionResponseType[];

        internalLogger?.log?.('OD GET resumable sessions response', result);

        return result;
      })
      .catch((error) => {
        internalLogger?.error?.(
          'OD GET resumable sessions error response',
          error
        );
        throw error;
      });
  }
}

export default OnboardingDirector;
