import { isValidRegex } from '../../../../../utils/regex';
import { getServices } from '../../../../../infra/commonInitializer';
import { internalLogger } from '../../../../../interface/v1/logger';

export default function valueFunctionQueryParam(
  value: Record<string, any>
): boolean {
  const { URLService } = getServices() || {};

  const currentPathDescription = URLService.getCurrentPathDescription();
  const queryParamObject = URLService.convertSearchStringToObject(
    currentPathDescription?.search
  );

  function compareValue(criterionValue: any, currentQueryParamValue: any) {
    const valueTypes = typeof void 0;
    const checkType = (value: any, types: (typeof valueTypes)[]) =>
      types?.some?.((type) => typeof value === type);

    if (isValidRegex(criterionValue)) {
      try {
        if (
          checkType(currentQueryParamValue, ['boolean', 'number', 'string'])
        ) {
          return new RegExp(criterionValue).test(currentQueryParamValue);
        }
        return false;
      } catch {
        internalLogger.error(
          "Invalid query param regex. Can't compare values."
        );
        return false;
      }

      // Compare values as string;
    } else if (checkType(criterionValue, ['boolean', 'number', 'string'])) {
      if (checkType(currentQueryParamValue, ['boolean', 'number', 'string'])) {
        return String(criterionValue) === String(currentQueryParamValue);
      }
      return false;
    } else if (criterionValue === null || criterionValue === undefined) {
      return (
        currentQueryParamValue === null ||
        currentQueryParamValue === undefined ||
        currentQueryParamValue === 'null' ||
        currentQueryParamValue === 'undefined'
      );
    } else if (Array.isArray(criterionValue)) {
      // Check if every criterionValue matches with some currentQueryParamValue
      return criterionValue?.every?.((criterionIndexValue) =>
        currentQueryParamValue?.some?.((currentQueryParamIndexValue) =>
          compareValue(criterionIndexValue, currentQueryParamIndexValue)
        )
      );
    } else if (typeof criterionValue === 'object') {
      const criterionValueEntries = Object.entries(criterionValue);

      // Check if every criterionValue property matches with some currentQueryParamValue property
      return criterionValueEntries?.every?.(
        ([criterionPropertyKey, criterionPropertyValue]) => {
          const currentQueryParamPropertyValue =
            currentQueryParamValue?.[criterionPropertyKey];

          return compareValue(
            criterionPropertyValue,
            currentQueryParamPropertyValue
          );
        }
      );
    }

    return false;
  }

  return compareValue(value, queryParamObject);
}
