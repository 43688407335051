const regexHelpers = {
  start: '^',
  end: '$',
  emptyEnd: '$/',
  wildcard: '(.*)',
  relativePath: '(?:[^/]+)',
  mayHaveQueryParams: '(\\?.*|)',
  mayEndWithQueryParams: '(\\?.*|)$'
};

const isValidRegex = (s: string) => {
  const haveRegexStart = s?.startsWith?.(regexHelpers.start);
  const haveRegexEnd = s?.endsWith?.(regexHelpers.end);

  return haveRegexStart && haveRegexEnd;
};

export { isValidRegex, regexHelpers };
