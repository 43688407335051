import ABTestingInterface from './ABTestingInterfaceV1';
import { ABTestingInterfaceType } from './types';

let abTestingInterface: ABTestingInterface;

export default async function initializeABTestingInterface(): Promise<ABTestingInterfaceType> {
  if (abTestingInterface) return abTestingInterface.getInterface();
  abTestingInterface = new ABTestingInterface();

  return abTestingInterface.getInterface();
}
