import * as T from './types';
import RoutesInterface from './RoutesInterface';
import { getServices } from '../../../infra/commonInitializer';

let routesInterface: RoutesInterface;

export function getRoutesInterface(): RoutesInterface {
  return routesInterface;
}

export default async function initializeRoutesInterface(): Promise<T.RoutesInterfaceType> {
  if (routesInterface) return routesInterface.getInterface();

  const services = getServices();
  const { routesService } = services;

  routesInterface = new RoutesInterface({ routesService });

  return routesInterface.getInterface();
}

export function createNoopRoutesInterface(): T.RoutesInterfaceType {
  return {
    getRoutes: function (): T.RouteType[] {
      console.debug('Function not implemented.');
      return [];
    },
    findRouteByKey: function (): T.RouteType {
      console.debug('Function not implemented.');
      return {};
    },
    findRouteByPath: function (): T.RouteType {
      console.debug('Function not implemented.');
      return {};
    }
  };
}
