import { Logger } from '../helpers/logger';

export type Configuration = AuthProviderConfiguration | APIKeyConfiguration;
export interface BaseConfiguration {
  stack: Stack;
  dataIngressCustomEndpoint?: string;
  dataValveCustomEndpoint?: string;
  verboseLogging?: boolean;
  queueConfiguration?: QueueConfiguration;
  useValveControllerFiltering: boolean;
  useOneTrustFiltering: boolean;
  preConsentEventAccumulation?: boolean;
  /*
  * A boolean flag indicating whether the batching of events must be used or not, by default it is true
  */
  isCustomBatchConfigEnabled?: boolean;
  isBatchingEnabled?: boolean;
  batchConfiguration?: BatchConfiguration;
}
export interface QueueConfiguration {
  publishRetries?: number;
  publishRetryDelay?: number;
  queueItemTTLInHours?: number;
  queueSizeLimit?: number;
}
/*
*  configuration info for the batching of events
*/
export interface BatchConfiguration {
  maxEventCount?: number;
  minEventCount?: number;
  maxEventAge?: number;
  evaluationFrequency?: number;
}
export interface AuthProviderConfiguration extends BaseConfiguration {
  authProvider: AuthProvider;
}
export interface APIKeyConfiguration extends BaseConfiguration {
  telemetryAPIkey: string;
  valveControllerAPIkey: string;
  cacheControl: string;
}

export interface ConfigurationProvider {
  /**
   *
   * @return the configuration object
   */
  configuration: Configuration;
}

export enum Stack {
  local,
  dev,
  pie,
  staging,
  production,
  custom
}

export interface AuthProvider {
  getAccessToken: (forceRefresh?: boolean) => Promise<string>;
}

export enum QueueDefaults {
  PUBLISH_RETRIES = 3,
  PUBLISH_RETRY_DELAY = 30,
  QUEUE_ITEM_TTL_IN_HOURS = 24,
  QUEUE_SIZE_LIMIT = 1000
}

export enum BatchConfigDefaults {
  MAX_EVENT_COUNT = 250, // Default value for the maximum number of events that can be contained in a single Notification object
  MIN_EVENT_COUNT = 10, // Default value for the minimum number of Events to accumulate in a Notification prior publishing
  MAX_EVENT_AGE_IN_SECONDS = 300, // Default value for the maximum age allowed for an Event object is 5 minutes
  EVALUATION_FREQUENCY_IN_SECONDS = 60, // Default value for scheduled frequency with which to evaluate the batching logic is 1 minute
}

export const defaultIsBatchEnabled = false;
export const TELEMETRY_FILTER_PATH = '$.telemetryClientConfiguration.filters.cdm.json';
export const TELEMETRY_TREE_PATH = '$.telemetryClientConfiguration.trees.cdm.json';
export const ERROR_UNEXPECTED = 'Unexpected error in FilteringMethods';
export const ERROR_INVALID_BINDINGS = 'Bindings are undefined';
export const ERROR_INVALID_BINDINGS_DETAIL = 'Invalid bindings';

const defaultBatchConfiguration: BatchConfiguration = {
  maxEventCount: BatchConfigDefaults.MAX_EVENT_COUNT,
  minEventCount: BatchConfigDefaults.MIN_EVENT_COUNT,
  maxEventAge: BatchConfigDefaults.MAX_EVENT_AGE_IN_SECONDS,
  evaluationFrequency: BatchConfigDefaults.EVALUATION_FREQUENCY_IN_SECONDS
};

export const validateQueueConfigValue = (value: number | undefined, defaultValue: number): number => value && value >= 0 ? value : defaultValue;

const validateEventCountValues = (defaultValue: number, value?: number) => value && value > 0 && value <= defaultValue ? value : defaultValue;
const validateUncappedEventValues = (defaultValue: number, value?: number) => value && value > 0 ? value : defaultValue;

export const validateBatchConfig = (logger: Logger, batchConfig?: BatchConfiguration) => {
  if (batchConfig === undefined) return defaultBatchConfiguration;

  const maxEventCount = validateEventCountValues(BatchConfigDefaults.MAX_EVENT_COUNT, batchConfig.maxEventCount);
  let minEventCount = validateUncappedEventValues(BatchConfigDefaults.MIN_EVENT_COUNT, batchConfig.minEventCount);
  if (minEventCount > maxEventCount) {
    logger.warn('BatchConfig::validateBatchConfig::log:minEventCount cannot be greater than maxEventCount, setting minEventCount to maxEventCount');
    minEventCount = maxEventCount;
  }

  const maxEventAge = validateUncappedEventValues(BatchConfigDefaults.MAX_EVENT_AGE_IN_SECONDS, batchConfig.maxEventAge);
  let evaluationFrequency = validateUncappedEventValues(BatchConfigDefaults.EVALUATION_FREQUENCY_IN_SECONDS, batchConfig.evaluationFrequency);
  if (evaluationFrequency > maxEventAge) {
    logger.warn('BatchConfig::validateBatchConfig::log:evaluationFrequency cannot be greater than maxEventAge, setting evaluationFrequency to maxEventAge');
    evaluationFrequency = maxEventAge;
  }

  return {
    minEventCount,
    maxEventCount,
    maxEventAge,
    evaluationFrequency
  };
};
