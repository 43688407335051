import operationAND from './operationAND';
import operationNAND from './operationNAND';
import operationNOR from './operationNOR';
import operationNOT from './operationNOT';
import operationOR from './operationOR';
import { CriterionOperationFunctionType } from './types';

const operationFunctions = {
  AND: operationAND,
  OR: operationOR,
  NOT: operationNOT,
  NAND: operationNAND,
  NOR: operationNOR
};

export type CriterionOperationFunctionsObjectType = {
  [key in keyof typeof operationFunctions]: CriterionOperationFunctionType;
};

export default operationFunctions as CriterionOperationFunctionsObjectType;
