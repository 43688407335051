import {
  optanonAlertBoxClosedCookieName,
  optanonConsentCookieName
} from '../../../config/constants';
import { getCookie } from '../../../utils/cookies';
import createScript from '../../../utils/createScript';
import { OptimizelyInputType } from './types';

export default function optimizely(optimizely: OptimizelyInputType) {
  const { enable, snippetId } = optimizely || {};
  const scriptId = 'optimizely-script';
  const optimizelyScript = document.getElementById(scriptId);

  if (enable && !optimizelyScript) {
    const optanonBoxClosed = getCookie(optanonAlertBoxClosedCookieName);
    const optanonConsent = getCookie(optanonConsentCookieName);

    const consentInteractionRegex = /interactionCount=1/;
    const consentGroupsRegex = /groups=1:1,2:1/;
    const consentBoxClosed = !!optanonBoxClosed.length;
    const consentInteracted = consentInteractionRegex.test(optanonConsent);
    const consentFulfilled = consentGroupsRegex.test(optanonConsent);

    if (consentBoxClosed && consentInteracted && consentFulfilled) {
      const scriptUrl = `https://cdn.optimizely.com/js/${snippetId}.js`;
      const script = createScript(scriptId, scriptUrl);

      if (document.head.prepend) {
        document.head.prepend(script);
      } else {
        document.head.appendChild(script);
      }
    }
  }
}
