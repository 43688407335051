import { CheckReturnObjectType } from '../../types';
import operationAND from '../operationAND';
import * as T from '../types';

const operationNAND: T.CriterionOperationFunctionMultipleCriterionType =
  async ({ criterions, recursion }) => {
    let operationResult: CheckReturnObjectType = undefined;

    operationResult = await operationAND({ criterions, recursion });

    return {
      result: !operationResult.result,
      fallbackKey: operationResult.fallbackKey
    };
  };

export default operationNAND;
