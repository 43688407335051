import { ControlledData } from '../interfaces/controlledData';
import { Schema } from '../interfaces/Schema';

export class OriginatorDetail implements Schema {
  /**
   * Current DateTime
   */
  currentDateTime: string;
  /**
   * OS platform
   */
  osPlatform: string;
  /**
   * Application Identifier Name
   */
  webAppName?: string;
  /**
   * Application version
   */
  webAppVersion?: string;
  /**
   * OneTrust OptanonConsent cookie with a urn prefix
   */
  webAppConsent?: string;
  /**
   * consentId uuid value from webAppConsent
   */
  visitorUuid?: string;
  /**
   * Application deployed UUID
   */
  visitUuid?: string;

  constructor(controlledData: ControlledData, currentDateTime: string) {
    this.currentDateTime = currentDateTime;

    this.osPlatform = controlledData.osPlatform;

    this.webAppName = controlledData.webAppName;

    this.webAppVersion = controlledData.webAppVersion;

    this.webAppConsent = controlledData.webAppConsent;

    this.visitorUuid = controlledData.visitorUuid;

    this.visitUuid = controlledData.visitUuid;
  }

  version = '1.1.0';
  get gun() {
    return 'com.hp.cdm.domain.telemetry.type.originatorDetail.originator.ucdeCloudWebApp.version.1';
  }
}
