import { UuidV4 } from '../../utils/crypto';
import eventNames from '../../config/eventNames';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { internalLogger } from '../../interface/v1/logger';
import bindAllMethods from '../../utils/bindAllMethods';
import { JWebErrorHandler, isNative } from '../JWeb';
import {
  ServiceInstanceEvent,
  ServiceInstanceState,
  ServiceRoutingErrorType
} from '../JWeb/JWebEnums';
import {
  closeNativeOnboardingInstance,
  getAvailableServices,
  getNativeServiceLaunchOptions,
  launchNativeService
} from '../JWeb/JWebServiceRouting';
import { IBackgroundTaskManagerService } from '../backgroundTaskService';
import * as T from './types';
import { IEventService } from '../eventService';

export enum WebServiceRoutingEvents {
  ServiceInstanceLaunching = 'service-instance-launching',
  ServiceInstanceClosed = 'service-instance-closed',
  BackgroundTaskServiceInstanceClosed = 'background-task-service-instance-closed',
  BackgroundTaskServiceInstanceLaunching = 'background-task-service-instance-launching'
}

class WebServiceRouting implements T.WebServiceRoutingType {
  private _backgroundTaskManagerService: IBackgroundTaskManagerService;
  private _eventService: IEventService;
  private _serviceList: T.ServiceList = { services: [] };
  private _lastId = 0;
  private _serviceInstance?: T.ServiceInstance;
  private _serviceLaunchOptions?: T.LaunchServiceOptions;
  private _loadNativeServicesPromise: Promise<void>;
  private _launchServiceBlockingPromise: Promise<
    T.ServiceRoutingErrorType | T.ServiceInstance
  >;
  serviceRoutingErrorType = ServiceRoutingErrorType;
  ServiceInstanceState = ServiceInstanceState;
  Events = WebServiceRoutingEvents;

  constructor(dependencies?: T.WebServiceRoutingDependenciesType) {
    bindAllMethods(this);
    this.addServices(dependencies?.services);
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const { eventService, backgroundTaskManagerService } = services;
    this._eventService = eventService;
    this._backgroundTaskManagerService = backgroundTaskManagerService;
  }

  public initialize(): void {
    this._startListenServiceInstanceCancelled();

    this._loadNativeServicesPromise = (async () => {
      if (await isNative()) {
        const nativeServices = (await getAvailableServices()) || [];
        const services = nativeServices.map<T.NativeServiceType>((service) => ({
          ...service,
          serviceType: 'native',
          public: true
        }));

        this.addServices(services);
      }
    })();
  }

  private _startedListenServiceInstanceCancelled = false;
  private async _startListenServiceInstanceCancelled() {
    if (this._startedListenServiceInstanceCancelled) return;
    this._startedListenServiceInstanceCancelled = true;
    const eventName = 'ServiceInstanceCancelled';

    internalLogger?.log?.(
      'onboarding-web-_startListenServiceInstanceCancelled: ',
      {
        eventName
      }
    );

    await this._eventService.subscribe(eventName, () => {
      internalLogger?.log?.(
        'onboarding-web-_startListenServiceInstanceCancelled-received-event: ',
        {
          eventName
        }
      );

      this.closeServiceInstance({
        resultData: {
          result: 'cancelled'
        }
      });
    });
  }

  private _startedListenNativeStageFinishedSubscribersMap: Map<
    string,
    () => void
  > = new Map();
  private async _startListenNativeStageFinished(
    serviceInstance: T.ServiceInstance
  ) {
    const publisherId = serviceInstance?.eventPublisherId;
    if (!(await isNative()) || !publisherId) {
      internalLogger?.log?.(
        'webServiceRouter-_startListenNativeStageFinished: publisherId is undefined'
      );

      return;
    }
    const removeSubscriber = () => {
      const unsubscribe =
        this._startedListenNativeStageFinishedSubscribersMap.get(publisherId);

      try {
        if (typeof unsubscribe === 'function') unsubscribe();
      } catch (error) {
        console.error(
          'webServiceRouter-_startListenNativeStageFinished-failed-to-unsubscribe: ',
          error
        );
      }
      this._startedListenNativeStageFinishedSubscribersMap.delete(publisherId);
    };

    const eventName = ServiceInstanceEvent.jarvisEventServiceInstanceClosed;

    internalLogger?.log?.(
      'webServiceRouter-_startListenNativeStageFinished: ',
      {
        eventName,
        serviceInstance
      }
    );

    removeSubscriber();

    const subscribeResponse = await this._eventService.subscribe(
      eventName,
      (eventInfo) => {
        const resultData: T.CloseServiceInstanceOptions['resultData'] =
          eventInfo?.eventData?.extraData;

        internalLogger?.log?.(
          'webServiceRouter-_startListenNativeStageFinished-received-event: ',
          {
            eventName,
            eventInfo,
            serviceInstance
          }
        );
        this.closeServiceInstance({
          resultData
        });

        removeSubscriber();
      },
      { publisherId: publisherId }
    );

    this._startedListenNativeStageFinishedSubscribersMap.set(
      publisherId,
      subscribeResponse.unsubscribe
    );
  }

  separateErrorObject<U>(data: U | T.ServiceRoutingErrorType) {
    const serviceRoutingError: T.ServiceRoutingErrorType = (
      data as T.ServiceRoutingErrorType
    )?.errorType
      ? (data as T.ServiceRoutingErrorType)
      : undefined;

    const nonErrorValue = serviceRoutingError?.errorType
      ? undefined
      : (data as Exclude<U, T.ServiceRoutingErrorType>);

    return {
      serviceRoutingError,
      data: nonErrorValue
    };
  }

  private _createId() {
    return String(this._lastId++);
  }

  async getNativeServiceLaunchOptions() {
    if (await isNative()) {
      return await getNativeServiceLaunchOptions();
    }
  }

  addServices(services: T.Service[]): void {
    internalLogger?.log?.('onboarding-web-addServices: ', { services });

    if (Array.isArray(services)) {
      services.forEach((service) => {
        if (service.serviceType === 'native') {
          this._serviceList.services.unshift(service);
        } else {
          this._serviceList.services.push(service);
        }
      });
    }
  }

  getTaskListService(): T.WebTaskServiceType[] {
    const taskValues = this._backgroundTaskManagerService
      .getTaskList()
      .map((task) => {
        const obj: T.WebTaskServiceType = {
          id: task.taskId,
          serviceType: 'web-task',
          ...task
        };
        delete obj.taskId;
        return obj;
      });

    return taskValues;
  }

  async getServices(): Promise<T.ServiceList | T.ServiceRoutingErrorType> {
    await this._loadNativeServicesPromise;
    const tasks = this.getTaskListService();
    const services = [...this._serviceList.services, ...tasks];

    internalLogger?.log?.('onboarding-web-getServices: ', { services });

    return { services };
  }

  async getServiceAvailability(
    options: T.GetServiceAvailabilityOptions
  ): Promise<T.ServiceRoutingErrorType | T.Service> {
    const services = await this.getServices();
    const { data, serviceRoutingError } = this.separateErrorObject(services);

    if (serviceRoutingError) {
      internalLogger?.log?.('onboarding-web-getServiceAvailability-error: ', {
        serviceRoutingError
      });
      return serviceRoutingError;
    }

    const service = data?.services?.find(
      (service) => service?.id === options?.serviceId
    );

    internalLogger?.log?.('onboarding-web-getServiceAvailability: ', {
      service
    });

    if (service) {
      return service;
    } else {
      const errorResult = {
        errorType: this.serviceRoutingErrorType.serviceNotFound
      };

      internalLogger?.log?.('onboarding-web-getServiceAvailability-error: ', {
        errorResult
      });

      return errorResult;
    }
  }

  async launchService(
    serviceLaunchOptions: T.LaunchServiceOptions
  ): Promise<T.ServiceRoutingErrorType | T.ServiceInstance> {
    if (this._launchServiceBlockingPromise) {
      internalLogger?.log?.('onboarding-web-launchService-pending-promise');

      await this._launchServiceBlockingPromise;
    }
    let resolveBlockingPromise: () => void;
    this._launchServiceBlockingPromise = new Promise((resolve) => {
      resolveBlockingPromise = () => resolve(undefined);
    });

    try {
      const { data: service, serviceRoutingError } = this.separateErrorObject(
        await this.getServiceAvailability({
          serviceId: serviceLaunchOptions?.serviceId
        })
      );

      if (serviceRoutingError) {
        internalLogger?.log?.('onboarding-web-launchService-error: ', {
          serviceRoutingError
        });
        return serviceRoutingError;
      } else if (!service?.id) {
        const errorResult = {
          errorType: this.serviceRoutingErrorType.serviceNotSupported,
          reason: 'Service id is missing'
        };

        internalLogger?.log?.('onboarding-web-launchService-error: ', {
          errorResult
        });

        return errorResult;
      } else if (service?.serviceType === 'web' && !service?.assetReference) {
        const errorResult = {
          errorType: this.serviceRoutingErrorType.serviceNotSupported,
          reason: 'Web Service require assetReference'
        };

        internalLogger?.log?.('onboarding-web-launchService-error: ', {
          errorResult
        });

        return errorResult;
      }

      const newServiceInstance = {
        eventPublisherId: null,
        instanceId: this._createId(),
        serviceId: service?.id,
        // TODO: How we should start from launching and move it to running only when it is loaded?
        state: this.ServiceInstanceState.running
      };

      if (!newServiceInstance?.serviceId || !newServiceInstance?.instanceId) {
        const errorResult = {
          errorType: this.serviceRoutingErrorType.serviceNotSupported
        };

        internalLogger?.log?.('onboarding-web-launchService-error: ', {
          errorResult
        });

        return errorResult;
      } else {
        internalLogger?.log?.('onboarding-web-launchService-service: ', {
          service
        });

        if (service?.serviceType === 'native') {
          const serviceRouting = JWebErrorHandler<T.ServiceInstance>(
            await launchNativeService(serviceLaunchOptions)
          );

          this._startListenNativeStageFinished(serviceRouting);
        }

        this._serviceInstance = newServiceInstance;
        this._serviceLaunchOptions = serviceLaunchOptions;

        this._eventService.publish(this.Events.ServiceInstanceLaunching, {
          serviceInstance: this._serviceInstance,
          serviceLaunchOptions: this._serviceLaunchOptions
        });

        if (service?.serviceType === 'web') {
          const servicePath = Array.isArray(service?.path)
            ? service?.path?.[0]
            : service?.path;

          if (servicePath?.startsWith?.('/')) {
            this._eventService.publish(
              eventNames?.shellCallInterfaceNavigationPush,
              {
                properties: {
                  servicePath,
                  keepQueryParamsInSamePath:
                    serviceLaunchOptions?.serviceOptions
                      ?.keepQueryParamsInSamePath
                }
              }
            );
          }
        }

        internalLogger?.log?.(
          `service-router-launch-service-${this._serviceInstance?.serviceId}`
        );

        return this._serviceInstance;
      }
    } catch (error) {
      console.error(error);
      const errorResult = {
        errorType: this.serviceRoutingErrorType.unknownError
      };

      internalLogger?.log?.('onboarding-web-launchService-error: ', {
        errorResult
      });

      return errorResult;
    } finally {
      resolveBlockingPromise?.();
    }
  }

  async closeNativeServiceInstance(options: {
    result: 'success' | 'failed' | 'cancelled';
    errorCode?: string;
  }) {
    const serviceInstanceLaunchOptions = this.separateErrorObject(
      await this.getServiceInstanceLaunchOptions()
    )?.data;

    await closeNativeOnboardingInstance({
      resultData: {
        appSessionId:
          serviceInstanceLaunchOptions?.serviceOptions?.appSessionId,
        serviceId: serviceInstanceLaunchOptions?.serviceId,
        result: {
          result: options?.result,
          xCorrelationId:
            serviceInstanceLaunchOptions?.serviceOptions?.onboardingContext
              ?.sessionContext?.xCorrelationId
        },
        errorInfo: {
          errorCode: options?.errorCode
        }
      }
    });
  }

  closeServiceInstance(
    closeServiceInstanceOptions?: T.CloseServiceInstanceOptions
  ): void {
    if (this._serviceInstance) {
      this._serviceInstance.state = this.ServiceInstanceState.closed;

      this._eventService.publish(
        this.Events.ServiceInstanceClosed,
        closeServiceInstanceOptions
      );

      internalLogger?.log?.(
        `service-router-close-service-${this._serviceInstance?.serviceId}`
      );
    }
  }

  async launchBackgroundTasksService(
    tasks: T.LaunchBackgroundTaskServiceInstanceOptions[]
  ): Promise<void> {
    for await (const backgroundTask of tasks) {
      const { options, taskId } = backgroundTask || {};
      if (!options?.launchId) options.launchId = UuidV4.getRandomUUID();
      await this._backgroundTaskManagerService
        ?.launchTask(taskId, {
          ...options,
          closeBackgroundTaskCallback: (result?: Record<string, any>) => {
            this.closeBackgroundTaskServiceInstance({
              resultData: {
                taskId,
                launchId: options.launchId,
                result: result || undefined
              }
            });
          }
        })
        ?.catch((error) => {
          internalLogger?.error?.(
            `service-router-launch-background-task-service-${taskId} error:`,
            error
          );
        });
      internalLogger?.log?.('service-router-launch-background-task-service');
    }
  }

  closeBackgroundTaskServiceInstance(
    options: T.CloseBackgroundTaskServiceInstanceOptions
  ): void {
    const { launchId } = options?.resultData || {};
    if (!launchId) {
      internalLogger?.error?.(
        `service-router-close-background-task-service - id is missing`
      );
      return;
    }

    this._eventService.publish(
      `${this.Events.BackgroundTaskServiceInstanceClosed}-${launchId}`,
      options
    );

    internalLogger?.log?.(
      `service-router-close-background-task-service-${launchId}`
    );
  }

  async getServiceInstance(): Promise<
    T.ServiceRoutingErrorType | T.ServiceInstance
  > {
    const serviceInstance = this._serviceInstance;

    if (serviceInstance) {
      return serviceInstance;
    } else {
      return {
        errorType: this.serviceRoutingErrorType.serviceInstanceNotFound
      };
    }
  }

  async getServiceInstanceLaunchOptions(): Promise<
    T.ServiceRoutingErrorType | T.LaunchServiceOptions
  > {
    if (this._serviceLaunchOptions) {
      return this._serviceLaunchOptions;
    } else {
      return {
        errorType: this.serviceRoutingErrorType.serviceInstanceNotFound
      };
    }
  }
}

export default WebServiceRouting;
