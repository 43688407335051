import { UuidV4 } from '../../utils/crypto';
import * as T from './types';

export const publisherId =
  'shell-commons-event-service-' + UuidV4.getRandomUUID();

export function getMap(
  name: string,
  mapType: Map<string, Map<any, any>>
): Map<any, any> {
  let map = mapType.get(name);
  if (!map) {
    map = new Map();
    mapType.set(name, map);
  }

  return map;
}
