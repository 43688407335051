import bindAllMethods from '../../../utils/bindAllMethods';
import * as T from './types';
import { INavigationService } from '../../../services/navigationService';
import { getServices } from '../../../infra/commonInitializer';

class NavigationInterface {
  private _navigationService: INavigationService;

  constructor() {
    const services = getServices();
    this._navigationService = services.navigationService;
    bindAllMethods(this);
  }
  getNavigationInterface(): T.NavigationInterfaceType {
    const navigationInterface =
      this._navigationService.getHistory() as T.NavigationInterfaceType;
    // To make retrocompatibility with the new navigation interface
    navigationInterface.redirect = (url) => {
      window.location.href = url;
    };

    return navigationInterface;
  }
}

export default NavigationInterface;
