import { OneCloudRoleOrgDataType } from '../../clients/oneCloud/relationshipMgt/types';
import { TenantNodeType, TenantVisualizationType } from './strategy/types';

export default class TenantUtils {
  public static _convertStratusTenantNodeToTenantVisualizationType(
    tenantNode: TenantNodeType
  ): TenantVisualizationType {
    return {
      id: tenantNode.nodeId,
      name: tenantNode.accountName || tenantNode.description,
      type: tenantNode.type,
      roleCategory: tenantNode.roleCategory
    };
  }

  public static _convertOnecloudOrgToTenantVisualizationType({
    org: organization,
    role
  }: OneCloudRoleOrgDataType): TenantVisualizationType {
    return {
      id: organization.id,
      name: organization.name,
      type: organization?.category?.type || organization.type,
      roleCategory: role?.[0]?.type,
      roleName: role?.[0]?.name
    };
  }
}
