export type EventServiceValueType = {
  eventData: any;
  eventName: string;
  publisherId: string;
};

export type EventServiceCallBackType = (event: EventServiceValueType) => void;

export type EventServiceListenersType = Map<
  string,
  Map<EventServiceCallBackType, EventServiceCallBackType>
>;

export type ListenerHandlerType = {
  unsubscribe: () => Promise<void>;
};

export enum OnboardingAgentEvents {
  onboardingAgentFailedToLaunch = 'onboardingAgentFailedToLaunch'
}

export type EventServiceSubscribeType = (
  name: string,
  action: EventServiceCallBackType,
  options?: EventServiceSubscribeOptionsType
) => Promise<ListenerHandlerType>;

export type EventServicePublishType = (
  name: string,
  eventData: EventServiceValueType['eventData']
) => Promise<void>;

export type EventServiceSubscribeOptionsType =
  EventServiceNativeSubscribeOptionsType;

export type EventServiceNativeSubscribeOptionsType = {
  /** Native only */
  publisherId: string;
};

export type EventValueType = any;

export type EventCallBackType = (event: EventValueType) => void;

export type RemoveListenersMapType = Map<
  string,
  Map<EventServiceCallBackType, Promise<ListenerHandlerType>>
>;
