import { IShellDAO } from '../../dao/IShellDAO';
import { GrantType } from './types';
import { AbstractRepository } from '../AbstractRepository';

export class GrantRepository extends AbstractRepository<GrantType[]> {
  constructor({
    shellDAO,
    enableSanitizer = false
  }: {
    shellDAO: IShellDAO;
    enableSanitizer?: boolean;
  }) {
    super({
      shellDAO: shellDAO,
      prefixKey: 'jshellGrants',
      enableSanitizer
    });
  }

  saveAll(): void {
    console.debug('Not supported in GrantRepository.');
    throw new Error('Method not implemented.');
  }

  findAll(id?: string): GrantType[][] {
    console.debug('Not supported in GrantRepository.');
    throw new Error('Method not implemented.');
  }

  delete(): boolean {
    console.debug('Not supported in GrantRepository.');
    throw new Error('Method not implemented.');
  }
}
