import { inject, singleton } from 'tsyringe';
import { TokenType } from '../../interface/types';
import { getCookie } from '../../utils/cookies';
import ISupportSessionService from './ISupportSessionService';

type SupportSessionServiceParametersType = {
  initialEnablementStatus?: boolean;
};

export const SUPPORT_SESSION_ENABLED = 'enabled';

@singleton()
class SupportSessionService implements ISupportSessionService {
  private _initialEnablementStatus: boolean;

  constructor(
    @inject('SupportSessionProps')
    supportSessionProps: SupportSessionServiceParametersType
  ) {
    this._initialEnablementStatus =
      supportSessionProps?.initialEnablementStatus;
  }

  public isSupportSession(): boolean {
    return (
      this._initialEnablementStatus &&
      this.isSupportSessionStartedBySessionAPI()
    );
  }

  public isSupportSessionStartedBySessionAPIAndInitialStatusNotEnabled(): boolean {
    return (
      !this._initialEnablementStatus &&
      this.isSupportSessionStartedBySessionAPI()
    );
  }

  public isSupportSessionStartedBySessionAPIOrInitialStatusEnabled(): boolean {
    return (
      this._initialEnablementStatus ||
      this.isSupportSessionStartedBySessionAPI()
    );
  }

  public isSupportSessionStartedBySessionAPI(): boolean {
    return getCookie(TokenType.supportSession, false) === 'true';
  }

  public getSupportSessionToken(): string {
    return getCookie(TokenType.stratusAccessToken, false);
  }
}

export default SupportSessionService;
