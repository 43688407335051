import { LaunchBackgroundTaskServiceInstanceOptions } from '../../../services/webServiceRouting/types';
import { getServices as getApplicationServices } from '../../../infra/commonInitializer';
import { Task } from '../../../services/backgroundTaskService/backgroundTask/IBackgroundTaskService';
import * as T from './types';
import { OnboardingInputType } from '../../../services/userOnboardingService/types';

export default ({
  onboardingInput
}: {
  onboardingInput: OnboardingInputType;
}): T.ServiceRoutingInterfaceType => {
  const services = getApplicationServices();
  const { webServiceRouting, backgroundTaskService } = services;

  function isEnabled() {
    return onboardingInput?.enable;
  }

  async function getServiceInstanceLaunchOptions(): Promise<
    T.LaunchServiceOptions | T.ServiceRoutingError
  > {
    // const state = _getState();

    // const launchOptions = {
    //   serviceId: state?.context?.nextService?.serviceId,
    //   serviceOptions: {
    //     onboardingContext: state?.context,
    //     appSessionId: state?.appSessionId
    //   }
    // };

    const serviceInstanceLaunchOptions =
      await webServiceRouting.getServiceInstanceLaunchOptions();

    return webServiceRouting.separateErrorObject(serviceInstanceLaunchOptions)
      ?.data;
  }

  async function launchService(launchOptions: {
    /**
     * The identifier of the service that should be launched
     */
    serviceId: string;
    /**
     * Options specific to the service being launched, if any are required
     */
    serviceOptions?: Record<string, any>;
  }) {
    if (isEnabled()) {
      // payload.resultData.
      await webServiceRouting.launchService({
        serviceId: launchOptions?.serviceId,
        serviceOptions: launchOptions?.serviceOptions
      });
      // const state = _getState();

      // await state?.nextStage?.(payload?.resultData, {
      //   authentication: payload?.authentication
      // });
    }
  }

  async function closeServiceInstance(
    payload: T.ServiceRoutingCloseServiceInstancePropType
  ): Promise<void> {
    if (isEnabled()) {
      // payload.resultData.
      webServiceRouting.closeServiceInstance(payload as any);
      // const state = _getState();

      // await state?.nextStage?.(payload?.resultData, {
      //   authentication: payload?.authentication
      // });
    }
  }

  async function getServices(): Promise<{
    services: T.InternalServiceObjectType[];
  }> {
    const response = webServiceRouting.separateErrorObject(
      await webServiceRouting.getServices()
    )?.data;

    return response;
  }

  async function launchBackgroundTasks(
    launchOptions: LaunchBackgroundTaskServiceInstanceOptions[]
  ): Promise<void> {
    return webServiceRouting.launchBackgroundTasksService(launchOptions);
  }

  async function registerBackgroundTask(id: string, task: Task): Promise<void> {
    await backgroundTaskService.register(id, task);
  }
  return {
    launchService,
    getServiceInstanceLaunchOptions,
    closeServiceInstance,
    getServices,
    registerBackgroundTask,
    launchBackgroundTasks
  };
};

export function createNoopServiceRoutingInterface(): T.ServiceRoutingInterfaceType {
  return {
    launchService: function (): Promise<void> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    getServiceInstanceLaunchOptions: function (): Promise<any> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    closeServiceInstance: function (): Promise<void> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    getServices: function (): Promise<any> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    registerBackgroundTask: function (): Promise<void> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    launchBackgroundTasks: function (): Promise<void> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    }
  };
}
