import {
  ThemeModeEnum,
  ScreenResponsivelyType,
  ThemeProviderType
} from '../../../services/themeService/types';
import { getServices } from '../../../infra/commonInitializer';
import ThemeInterface from './ThemeInterface';
import { ThemeInterfaceType } from './types';

let themeInterface: ThemeInterface;

export function getThemeInterface(): ThemeInterface {
  return themeInterface;
}

export default async function initializeThemeInterface(): Promise<ThemeInterfaceType> {
  if (themeInterface) return themeInterface.getInterface();

  const services = getServices();
  const { themeService } = services;

  themeInterface = new ThemeInterface({ themeService });

  return themeInterface.getInterface();
}

export function createNoopThemeInterface(): ThemeInterfaceType {
  return {
    getThemeProviderProperties: function (): ThemeProviderType {
      console.debug('Function not implemented.');
      return {};
    },
    getScreenResponsivelyProperties: function (): ScreenResponsivelyType {
      console.debug('Function not implemented.');
      return {};
    },
    getUserThemeMode: function (): ThemeModeEnum {
      console.debug('Function not implemented.');
      return ThemeModeEnum.light;
    }
  };
}
