import * as LDClient from 'launchdarkly-js-client-sdk';
import bindAllMethods from '../../utils/bindAllMethods';
import IApplicationService from '../applicationService/IApplicationService';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { IUserService } from '../userService';
import { LAUNCH_DARKLY_ANONYMOUS_HASH } from './constants';
import IFeatureFlagClient from './IFeatureFlagClient';
import IFeatureFlagService from './IFeatureFlagService';
import LaunchDarklyFeatureFlagClient from './LaunchDarklyFeatureFlagClient';
import {
  LaunchDarklyFFServiceParams,
  LaunchDarklyProjectType,
  LaunchDarklyContextProps,
  GetFeatureFlagParams
} from './types';
import { IAuthTokenService } from '../authTokenService';
import { ISessionService } from '../session';
import FeatureFlagKeysEnum from './FeatureFlagKeysEnum';
import IEventService from '../eventService/IEventService';

export default class LaunchDarklyFeatureFlagService
  implements IFeatureFlagService
{
  private _eventService: IEventService;
  private _projectSettingsList: LaunchDarklyProjectType[];
  private _launchDarklyClients: {
    key: string;
    client: IFeatureFlagClient;
    promise: Promise<IFeatureFlagClient>;
  }[];
  private _userService: IUserService;
  private _applicationService: IApplicationService;
  private _authTokenService: IAuthTokenService;
  private _sessionService: ISessionService;

  constructor({ projectSettingsList }: LaunchDarklyFFServiceParams) {
    this._projectSettingsList = projectSettingsList;
    this._launchDarklyClients = [];
    bindAllMethods(this);
  }

  // This is syncronous to avoid performance issues.
  public init(): void {
    this._proccessStartupSettings();
  }

  private _proccessStartupSettings(): void {
    this._projectSettingsList?.forEach?.((project) => {
      if (project?.initializeOnStartup) this.getClient(project.key);
    });
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const {
      eventService,
      userService,
      applicationService,
      authTokenService,
      sessionService
    } = services;
    this._applicationService = applicationService;
    this._eventService = eventService;
    this._userService = userService;
    this._authTokenService = authTokenService;
    this._sessionService = sessionService;
  }

  public async getClient(key: string): Promise<IFeatureFlagClient> {
    let clientWrapper = this._launchDarklyClients?.find((c) => c.key === key);
    let client = clientWrapper?.client;

    if (!clientWrapper) {
      clientWrapper = {
        key,
        client: null,
        promise: this._initializeClient({ key })
      };
      this._launchDarklyClients?.push(clientWrapper);
    }

    if (!client) {
      client = await clientWrapper?.promise;
      clientWrapper.client = client;
    }

    return client;
  }

  public async createCustomClient(
    options: LaunchDarklyProjectType
  ): Promise<IFeatureFlagClient> {
    const context = await this._generateContext(options?.contextList);
    const projectSettings = this._projectSettingsList.find(
      (p) => p.key === options?.key
    );
    if (!projectSettings?.clientId) {
      console.warn(
        `This key ${options.key} is not match any information in manifest file`
      );
      return;
    }

    const client = new LaunchDarklyFeatureFlagClient();
    await client.initializeClient({
      key: projectSettings?.key,
      clientId: projectSettings?.clientId,
      context: context,
      eventService: this._eventService
    });
    return client;
  }

  public useReactFeatureFlag(
    React: any,
    clientName: string,
    options: GetFeatureFlagParams
  ): unknown {
    const { defaultValue, key } = options;
    const [result, setResult] = React?.useState(defaultValue) || [];

    React.useEffect(() => {
      const flagKey = key;
      const setNewValue = (value, _) => setResult(value);
      const ldClientPromise = (async () => {
        const client = (await this.getClient(
          clientName
        )) as LaunchDarklyFeatureFlagClient;
        const ldClient = client.getClient();

        const value = await client?.getFeatureFlag({ defaultValue, key });
        setNewValue(value, null);
        ldClient.on(`change:${flagKey}`, setNewValue);
        return ldClient;
      })();

      return () =>
        ldClientPromise.then((result) =>
          result.off(`change:${flagKey}`, setNewValue)
        );
    }, [defaultValue, key]);

    return result;
  }

  private async _generateSingleContext(
    props: LaunchDarklyContextProps
  ): Promise<LDClient.LDSingleKindContext> {
    const context = {
      kind: props.kind
    } as LDClient.LDSingleKindContext;

    const { key: contextKey } = props || {};

    const { email, fqTenantId, tenantId } = props?.attributes || {};

    // Manage Key
    switch (contextKey) {
      case FeatureFlagKeysEnum.email:
        context.key = await this._userService?.getEmail?.();
        break;
      case FeatureFlagKeysEnum.tenantId:
        context.key = this._authTokenService.getTenantIdFromToken();
        break;
      case FeatureFlagKeysEnum.fqTenantId:
        context.key = this._authTokenService.getFqTenantIdFromToken();
        break;
      case FeatureFlagKeysEnum.portalName:
        context.key = this._applicationService.getAppName();
        break;
      default:
        context.key = LAUNCH_DARKLY_ANONYMOUS_HASH;
        break;
    }

    // Manage Attributes
    if (email) context.email = await this._userService?.getEmail?.();
    if (fqTenantId)
      context.fqTenantId = this._authTokenService.getFqTenantIdFromToken();
    if (tenantId)
      context.tenantId = this._authTokenService.getTenantIdFromToken();

    return context;
  }

  private async _generateMultiContext(
    contextPropsList: LaunchDarklyContextProps[]
  ): Promise<LDClient.LDMultiKindContext> {
    const multiContext = { kind: 'multi' } as LDClient.LDMultiKindContext;

    await Promise.all(
      contextPropsList.map(async (contextProps) => {
        const context = await this._generateSingleContext(contextProps);
        multiContext[context.kind] = context;
      })
    );

    return multiContext;
  }

  private async _generateDefaultContext(): Promise<LDClient.LDSingleKindContext> {
    const context = await this._generateSingleContext({
      kind: 'user',
      key: FeatureFlagKeysEnum['anonymous']
    });
    return context;
  }

  private async _generateContext(
    contextPropsList: LaunchDarklyContextProps[]
  ): Promise<LDClient.LDContext> {
    let context: LDClient.LDContext;
    const isLoggedIn = this._sessionService.isLoggedIn();

    // Default LD context
    if (!contextPropsList || !isLoggedIn) {
      context = await this._generateDefaultContext();

      // Single context
    } else if (contextPropsList.length === 1) {
      context = await this._generateSingleContext(contextPropsList[0]);

      // Multi context
    } else {
      context = await this._generateMultiContext(contextPropsList);
    }

    return context;
  }

  private async _initializeClient({
    key
  }: {
    key: string;
  }): Promise<IFeatureFlagClient> {
    const {
      clientId,
      contextList,
      liveUpdate = true
    } = this._projectSettingsList.find((p) => p.key === key) || {};

    if (!clientId) {
      console.warn(
        `This key ${key} 'is not match any information in manifest file`
      );
      return;
    }
    const context = await this._generateContext(contextList);

    const client = new LaunchDarklyFeatureFlagClient();
    await client.initializeClient({
      key,
      clientId,
      context,
      eventService: this._eventService,
      liveUpdate
    });
    return client;
  }
}
