import getNode from '../../../clients/stratus/tntCommonResourceRegistry/getNode';
import createNode from '../../../clients/stratus/tntCommonResourceRegistry/createNode';

import { deleteCookie, getCookie, setCookie } from '../../../utils/cookies';

import {
  tenantIdName,
  customerIdName,
  organizationIdName
} from '../../../config/constants';

import { CreateNodeRequestBodyType } from '../../../clients/stratus/tntCommonResourceRegistry/createNode/types';
import * as T from './types';
import { UserContextEnum } from '../../types';
import { stackValidator } from '../../../utils/toBeRemoved/stack';
import { isAstSession } from '../../../clients/shell/session';

import {
  removeItemLocalStorage,
  getItemLocalStorage,
  setItemLocalStorage
} from '../../../utils/toBeRemoved/localStorage';
import TenantLevelEnum from '../../../services/tenantHandler/TenantLevelEnum';
import {
  GetTenantListOptionsType,
  PaginatedTenantVisualizationType,
  TenantVisualizationType
} from '../../../services/tenantHandler/strategy/types';
import { getServices } from '../../../infra/commonInitializer';
import { decodeJWTPayload } from '../../../utils/tokenUtils/JWTUtils';

type StateType = 'success' | 'error' | 'loading';

export default async ({
  onboarding,
  authProvider,
  orglessAuthProvider,
  navigation,
  localization,
  tenantHandlerService
}: T.OrgSelectorConstructorOptionsType): Promise<T.OrgSelectorInterfaceType> => {
  const _isEnabledAtConfig = tenantHandlerService.isEnabled();

  // TODO: Conferir com Everton o comportamento do AST
  const isDomainSelectorActive = _isEnabledAtConfig && !isAstSession();

  const _stack = stackValidator(onboarding?.stack);
  const _authProvider = authProvider;
  const _orglessAuthProvider = orglessAuthProvider;
  const _userOnboardingPath = onboarding?.userOnboardingPath;

  // let _idList: string[] = [];
  let _currentTenant: T.DeprecatedTenantDataType;
  let _tenantList: T.DeprecatedTenantDataType[] = [];

  let _totalCount: number;
  let _initializeState: StateType;
  let _initPromise: Promise<void>;

  /**
   * React Function that should return a JSX.Element
   * */
  let ReactDomainSelectorPage: (props: Record<string, unknown>) => unknown;

  function getCurrentCustomer() {
    return tenantHandlerService.getTenantId(TenantLevelEnum.subTenant);
  }

  async function getCustomers(
    orgTenantId: string,
    options?: T.GetCustomersType
  ): Promise<PaginatedTenantVisualizationType> {
    const customerPaginationOptions: GetTenantListOptionsType = {
      tenantId: orgTenantId,
      page: options?.pagination?.currentPage,
      paginationSize: options?.pagination?.size
    };

    const customerTenantStrategy = tenantHandlerService?.getTenantStrategy(
      TenantLevelEnum.subTenant
    );

    return customerTenantStrategy.getPaginatedTenantList(
      customerPaginationOptions
    );
  }

  async function setCustomer(customerTenantId: string, shouldReload = true) {
    await tenantHandlerService.setTenant(
      customerTenantId,
      TenantLevelEnum.subTenant,
      {}
    );
    if (shouldReload) window.location.reload();
    // TODO enable refresh without reload the page JSHELL - 3142
    // refreshWithoutReloadPage();
  }

  async function setOrgAsDefault(shouldReload = true) {
    const orgTenantId = getOrgTenantId();

    setCookie(tenantIdName, orgTenantId, undefined, false);

    await _authProvider.onTokenExchangeRequired(UserContextEnum.organization);
    cleanTenantCookie();
    if (shouldReload) window.location.reload();
  }
  // TODO enable refresh without reload the page JSHELL - 3142
  // function refreshWithoutReloadPage() {
  //   accessControl.refresh();
  //   entitlements.refresh();
  // }

  /**
   * @description
   * Function that should set into cookie and local storage tenant id.
   *
   * @param {string} tenantId - Tenant id to update in the cookie.
   * @param {Object} options - Options to change the behavior of setTenantCookie function.
   * @param {Object} options.refreshToken
   *
   * @returns {void}
   * */
  async function setTenantCookie(
    tenantId?: string,
    options?: T.SetTenantCookieOptionsType
  ): Promise<void> {
    const previousTenantId = getTenantCookie();
    setCookie(tenantIdName, tenantId, undefined, false);
    setItemLocalStorage(organizationIdName, tenantId);

    if (options?.refreshToken) {
      // TODO: Check if this behavior ir really needed on frontend, or it is responsibility of backend / authz.
      await _orglessAuthProvider.getAccessToken(true);
      await _orglessAuthProvider.onTokenExchangeRequired();
    }
    if (options?.reloadPageOnCookieChange) {
      if (previousTenantId !== tenantId) {
        // TODO why need this promise?
        await new Promise(() => window.location.reload());
      }
    }
    // TODO enable refresh without reload the page JSHELL - 3142
    // refreshWithoutReloadPage();
  }

  function cleanTenantCookie(): void {
    deleteCookie(tenantIdName, undefined, false);
    removeItemLocalStorage(organizationIdName);
    removeItemLocalStorage(customerIdName);
  }

  async function getTenantById(
    tenantId: string,
    authProvider?: T.AuthProviderV2Type
  ): Promise<T.DeprecatedTenantDataType> {
    const tenantRawData = await getNode({
      authProvider: authProvider || _orglessAuthProvider,
      stack: _stack,
      tenantId
    });

    return {
      id: tenantRawData.nodeId,
      nodeId: tenantRawData.nodeId,
      name: tenantRawData.accountName,
      accountName: tenantRawData.accountName,
      type: tenantRawData.type
    };
  }

  function isEnabled() {
    return !!isDomainSelectorActive;
  }

  function getTenantCookie() {
    return getCookie(tenantIdName);
  }

  function getOrgTenantId() {
    const services = getServices();
    const { supportSessionService, authTokenService } = services;
    if (supportSessionService?.isSupportSession()) {
      const jwt = authTokenService.getToken()?.token;
      const tenantId = decodeJWTPayload(jwt)?.tenant_id;
      return tenantId;
    }
    return (
      tenantHandlerService.getTenantId(TenantLevelEnum.tenant) ||
      getItemLocalStorage(organizationIdName) ||
      getCookie(organizationIdName) ||
      getCookie(tenantIdName)
    );
  }

  async function createTenant(data: CreateNodeRequestBodyType) {
    const language = localization?.language?.toLowerCase?.() || 'en';
    const country = localization?.country?.toUpperCase?.() || 'US';
    const idToken = await authProvider.getIDToken();

    const newTenantId = await createNode({
      authProvider: _orglessAuthProvider,
      stack: _stack,
      data: {
        type: _currentTenant?.type,
        accountId: null,
        parentAccountResourceId: null,
        language,
        countrySet: [country],
        idToken: idToken,
        ...data
      }
    });

    const newTenant = await getTenantById(newTenantId.nodeId);

    if (newTenant) {
      const organizationTenantStrategy =
        tenantHandlerService?.getTenantStrategy(TenantLevelEnum.tenant);

      organizationTenantStrategy.getTenantList(true);
      _tenantList.push(newTenant);
    }

    return newTenant;
  }

  /*
    At first, retrieves the current tenant from the Stratus JWT and checks if
    already contains the Tenant data cached.
    If not, updates the local data and returns the current tenant info.
  */
  function getCurrentTenant(): TenantVisualizationType & {
    nodeId?: string;
    accountName?: string;
  } {
    const currentContext = tenantHandlerService.getCurrentContext();
    const customerTenant =
      tenantHandlerService.getTenantByContext(currentContext);

    const tenantData = {
      ...customerTenant?.data,
      id: customerTenant?.id,
      nodeId: customerTenant?.id,
      accountName: customerTenant?.data?.name
    };

    _currentTenant = tenantData;

    return tenantData;
  }

  function getTenantList() {
    let list = [..._tenantList];

    if (_currentTenant) {
      list = list?.filter((v) => v && v !== _currentTenant);
      list.unshift(_currentTenant);
    }

    return list;
  }

  function getTotalCount() {
    return _totalCount;
  }

  //https://hp-jira.external.hp.com/browse/JSHELL-2813
  const tryRedirectToOrgSelectorFallback = async () => {
    const organizationTenantStrategy = tenantHandlerService?.getTenantStrategy(
      TenantLevelEnum.tenant
    );
    const _orgSelectorFallbackPath = organizationTenantStrategy?.getFallback();

    if (!_orgSelectorFallbackPath && !_userOnboardingPath) {
      return null;
    }
    const path = _orgSelectorFallbackPath || _userOnboardingPath;

    const href = navigation.createHref({
      pathname: addSlashToPathIfNeeded(path)
    });
    const hrefWithoutSearch = removeQueryString(href);

    if (href && window.location.pathname !== hrefWithoutSearch) {
      await new Promise(() => {
        window.location.href = href;
      });
    }

    function removeQueryString(path) {
      return path.split('?')?.[0];
    }

    //adds '/' if not in manifest.json to prevent infinte redirect loop.
    function addSlashToPathIfNeeded(path): string {
      return path.startsWith('/') ? path : path.replace(/^/, '/');
    }
  };

  const updateDomainList = async () => {
    if (!isDomainSelectorActive) return;
    const organizationTenantStrategy = tenantHandlerService?.getTenantStrategy(
      TenantLevelEnum.tenant
    );

    const tenantList = await organizationTenantStrategy.getTenantList(true);

    const tenantIdFromStorage = getOrgTenantId();
    let currentTenant: T.DeprecatedTenantDataType;

    const filteredTenantList = tenantList.map((tenant) => {
      const filledTenant: T.DeprecatedTenantDataType = {
        ...tenant,
        accountName: tenant.name,
        nodeId: tenant.id
      };
      if (filledTenant?.id === tenantIdFromStorage) {
        currentTenant = filledTenant;
      }
      return filledTenant;
    });

    _totalCount = filteredTenantList.length;
    _currentTenant = currentTenant;
    _tenantList = filteredTenantList || [];
  };

  async function init(usesFallback = true) {
    if (!_initializeState || _initializeState === 'error') {
      _initializeState = 'loading';
      _initPromise = updateDomainList();
    }
    try {
      await _initPromise;
      _initializeState = 'success';

      if (
        !(_tenantList?.length > 0) &&
        usesFallback &&
        !_currentTenant?.nodeId
      ) {
        await tryRedirectToOrgSelectorFallback();
      }
    } catch (error) {
      _initializeState = 'error';
      await tryRedirectToOrgSelectorFallback();
      throw error;
    }
  }

  return {
    ReactDomainSelectorPage,
    setTenantCookie,
    getOrgTenantCookie: getTenantCookie,
    getOrgTenantId,
    getTenantCookie,
    setCustomer,
    setOrgAsDefault,
    isEnabled,
    createTenant,
    getCurrentCustomer,
    getCurrentTenant,
    getTenantList,
    getTotalCount,
    updateDomainList,
    init,
    getCustomers,
    getTenantById
  };
};
