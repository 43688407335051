import { JarvisWebHttpClient } from '@jarvis/web-http';
import { GetTenantRequestDataType } from '../../../clients/stratus/tntCommonResourceRegistry/types';
import { getOneCloudBaseUrl } from '../../../clients/stackUtils';
import { Stack } from '../../../types/stratus';
import { GetOnecloudPersonalDomainResponseType, NodeType } from './types';
import { IAuthProviderService } from '../../../services/authProviderService';

export default class OnecloudRegistryRelationshipClient {
  private urlPrefix = 'registry-relationship';
  private jarvisWebHttpInstance: JarvisWebHttpClient;
  private apiName = '/ocrel-registry';
  private apiVersion = '/v2';
  private personDomainsEndpoint = '/person/domains';
  private getNodeEndpoint = '/node';

  private _baseUrl: string;

  constructor({
    stack,
    authProvider
  }: {
    stack: Stack;
    authProvider: IAuthProviderService;
  }) {
    this.jarvisWebHttpInstance = new JarvisWebHttpClient({
      defaultAuthProvider: authProvider
    });
    this._baseUrl = `${getOneCloudBaseUrl(stack, this.urlPrefix)}${
      this.apiName
    }${this.apiVersion}`;
  }

  public async getPersonDomains(options?: {
    params?: GetTenantRequestDataType;
  }): Promise<GetOnecloudPersonalDomainResponseType> {
    const { data } = await this.jarvisWebHttpInstance.get({
      url: `${this._baseUrl}${this.personDomainsEndpoint}`,
      params: { ...options?.params }
    });
    return data as GetOnecloudPersonalDomainResponseType;
  }

  public async getNode(options: { nodeId: string }): Promise<NodeType> {
    const { data } = await this.jarvisWebHttpInstance.get({
      url: `${this._baseUrl}${this.getNodeEndpoint}/${options.nodeId}`
    });
    return data as NodeType;
  }
}
