import { OnboardingAgentEvents } from '../../../services/eventService/types';
import { WebServiceRoutingEvents } from '../../webServiceRouting';
import { internalLogger } from '../../../interface/v1/logger';
import { getServices } from '../../../infra/commonInitializer';
import * as T from './../types';
import Criterion from '../../../interface/v1/Criterion';
import { IEventService } from '../../../services/eventService';
import EventNames from '../../../config/eventNames';

type syncDataFunctionType = () => Promise<void>;

type SceneReporterType = {
  fallbackInterface: T.ChainOfOperationsInputType['fallbackInterface'];
  criterionInterface: Criterion<any>;
  storeInterface: T.ChainOfOperationsInputType['storeInterface'];
  eventService: IEventService;
  navigationInterface: T.ChainOfOperationsInputType['navigationInterface'];
};

export default class SceneReporter {
  private _criterionInterface: Criterion<any>;
  private _fallbackInterface: T.ChainOfOperationsInputType['fallbackInterface'];
  private _navigationInterface: T.ChainOfOperationsInputType['navigationInterface'];
  private _storeInterface: T.ChainOfOperationsInputType['storeInterface'];
  private _eventService: IEventService;
  private _eventList: string[];

  constructor({
    fallbackInterface,
    criterionInterface,
    storeInterface,
    eventService,
    navigationInterface
  }: SceneReporterType) {
    this._fallbackInterface = fallbackInterface;
    this._criterionInterface = criterionInterface;
    this._storeInterface = storeInterface;
    this._eventService = eventService;
    this._navigationInterface = navigationInterface;

    this._eventList = this.createRelatedEvents();
  }

  public createRelatedEvents = (): string[] => {
    const fallbackEvent = this._fallbackInterface.eventName;
    const criterionEvent = this._criterionInterface.eventName;

    const onboardingEvent = this.setOnboardingConnector();
    const urlEvent = this.setNavigationConnector();

    return [
      fallbackEvent,
      criterionEvent,
      onboardingEvent,
      urlEvent,
      OnboardingAgentEvents.onboardingAgentFailedToLaunch,
      WebServiceRoutingEvents.ServiceInstanceClosed,
      WebServiceRoutingEvents.ServiceInstanceLaunching
    ];
  };

  // This needs storeInterface already working
  public setOnboardingConnector = (): string => {
    // TODO: create this event inside onboarding code
    const onboardingEvent = EventNames.globalMicrofrontendRouterOnboarding;

    this._storeInterface.addObserver(
      this._storeInterface.generateId(),
      (state, previousStage) => {
        if (
          JSON.stringify(state?.onboarding) !==
          JSON.stringify(previousStage?.onboarding)
        ) {
          this._eventService.publish(onboardingEvent, undefined);
        }
      }
    );
    return onboardingEvent;
  };

  // This needs navigationInterface already working
  public setNavigationConnector = (): string => {
    // TODO: create this event inside navigation code
    const urlEvent = EventNames.globalMicrofrontendRouterURL;
    this._navigationInterface.listen(() => {
      this._eventService.publish(urlEvent, undefined);
    });
    return urlEvent;
  };

  public setEventListeners = (
    syncDataCallback: syncDataFunctionType,
    customEventList = this._eventList
  ): void => {
    const { eventService } = getServices();
    customEventList.forEach((key) =>
      eventService.addEventListener(key, syncDataCallback)
    );
    internalLogger?.log?.(`MFE Router: Setting event listeners. `);
  };
}
