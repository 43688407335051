import * as T from './types';
import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client';
import { Stack } from '../../../types/stratus';
import { getOneCloudBaseUrl } from '../../stackUtils';

export default class OneCloudAggregationSvcApiClient extends StratusClient {
  public static getBaseUrl(stack: Stack): string {
    const urlPrefix = 'aggregation-relationship';
    const apiName = '/aggregation';
    const apiVersion = '/v1';
    const listPersonOrgsEndpoint = '/AggregationAPI/ListPersonOrgs';

    return `${getOneCloudBaseUrl(
      stack,
      urlPrefix
    )}${apiName}${apiVersion}${listPersonOrgsEndpoint}`;
  }

  public async listPersonOrgs(
    options?: T.OneCloudAggregationAPIListPersonOrgsParams
  ): Promise<T.OneCloudAggregationAPIRoleOrganizationListResponseDataType> {
    const response = await this.jarvisWebHttpInstance.get({
      params: {
        ...options
      }
    });
    return response.data as T.OneCloudAggregationAPIRoleOrganizationListResponseDataType;
  }

  public async listPersonDelegatedOrgs(
    options?: T.OneCloudAggregationAPIListPersonDelegatedOrgsParams
  ): Promise<T.OneCloudAggregationAPIRoleOrganizationListResponseDataType> {
    const response = await this.jarvisWebHttpInstance.get({
      params: {
        ...options
      }
    });
    return response.data as T.OneCloudAggregationAPIRoleOrganizationListResponseDataType;
  }
}
