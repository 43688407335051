import * as T from './types';
import { RoutesService } from '../../../services/RoutesService';
import bindAllMethods from '../../../utils/bindAllMethods';

export type RoutesServiceParam = {
  routesService: RoutesService;
};
export default class Routes {
  private _routesService: RoutesService;

  constructor({ routesService }: RoutesServiceParam) {
    this._routesService = routesService;
    bindAllMethods(this);
  }

  public getInterface(): T.RoutesInterfaceType {
    return {
      getRoutes: this._routesService.getRoutes,
      findRouteByKey: this._routesService.findRouteByKey,
      findRouteByPath: this._routesService.findRouteByPath
    };
  }
}
