export const originatorDetail = {

  '$schema': 'http://json-schema.org/draft-04/schema#',
  'gun': 'com.hp.cdm.domain.telemetry.type.originatorDetail.originator.ucdeCloudWebApp.version.1',
  'type': 'object',
  'version': '1.1.0',
  'description': 'Schema for CDM originator detail used by Cloud WebApps',
  'additionalProperties': false,
  'definitions': {
    'originatorDetail': {
      'type': 'object',
      'required': [
        'version',
        'currentDateTime',
        'osPlatform',
        'webAppName',
        'webAppConsent',
        'webAppVersion',
        'visitorUuid',
        'visitUuid'
      ],
      'properties': {
        'version': {
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/version'
        },
        'currentDateTime': {
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/dateTime',
          'description': 'Cloud time of when the CDM payload was created'
        },
        'osPlatform': {
          'description': 'OS Platform Name',
          'type': 'string',
          'enum': [
            'Android',
            'iOS',
            'iPadOS',
            'Windows',
            'MacOS',
            'Linux',
            'ChromeOS'
          ]
        },
        'webAppName': {
          '_comment': '// TODO: Need more real world examples',
          'description': 'Application Identifier Name',
          'type': 'string',
          'pattern': '^[a-zA-Z][a-zA-Z0-9._-]+[a-zA-Z0-9]$',
          'maxLength': 128,
          '_examples': [
            'com.hpi.cdm.testapp'
          ]
        },
        'webAppVersion': {
          'description': 'Application version, 3 dot notation. Semantic Versioning is HIGHLY recommended, with 4 segment for a monotonically increasing buildnumber or yyyymmddhh',
          'pattern': '^[0-9]+[.][0-9]+[.][0-9]+[.][0-9]+$',
          'type': 'string',
          '_examples': [
            '2.3.5.2',
            '8.4.1.2',
            '21.02.29.129',
            '1.5.0.20201128'
          ]
        },
        'webAppConsent': {
          '_comment': '// urn model used to allow for migration away from OneTrust in the future',
          'description': 'Contents of the OneTrust OptanonConsent cookie with a urn prefix (URL decoded!!)',
          'type': 'string',
          'pattern': '^urn:onetrust:',
          '_examples': [
            'urn:onetrust:isGpcEnabled=1&datestamp=Tue+Feb+22+2022+13:54:21+GMT-0800+(Pacific+Standard+Time)&version=6.22.0&isIABGlobal=false&hosts=&consentId=ed35144b-2ad8-4be7-88fc-ce1d6e5eaa2a&interactionCount=0&landingPath=https://platinum.hpinstantink.com/&groups=1:1,2:1,3:1,4:0'
          ],
          'maxLength': 4096
        },
        'visitorUuid': {
          '_comment': '// set to the consentId uuid value from webAppConsent field',
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/uuid',
          'description': 'Unique UUID for the visitOR, intended as a long term cookie tracking (tbd onetrust?)'
        },
        'visitUuid': {
          '_comment': '// set to a session cookie with an idle timeout of 1hr',
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/uuid',
          'description': 'Unique UUID for the visit, intended as a short term cooking tracking (tbd app specific)'
        }
      },
      'additionalProperties': false
    }
  },
  'id': 'https://schemaregistry.analysis.ext.hp.com/cdm/gun/com.hp.cdm.domain.telemetry.type.originatorDetail.originator.ucdeCloudWebApp.version.1.schema.json',
  'jsonSchemaGun': 'com.hp.cdm.domain.telemetry.type.originatorDetail.originator.ucdeCloudWebApp.version.1.schema.json'
};
