import { NavigationInterfaceType } from '../../interface/v1/navigation/types';
import { IEventService } from '../../services/eventService';
import { EventServiceValueType } from '../../services/eventService/types';

export type InitClientosNavigationPushEventHandlerType = {
  navigation: NavigationInterfaceType;
  eventService: IEventService;
};

export default class InitClientosNavigationPushEventHandler {
  private _navigation: NavigationInterfaceType;
  private _eventService: IEventService;

  constructor({
    navigation,
    eventService
  }: InitClientosNavigationPushEventHandlerType) {
    this._navigation = navigation;
    this._eventService = eventService;
  }

  private clientosNavigationPushEventHandler = (
    event: EventServiceValueType
  ) => {
    const { location, state } = event.eventData || {};
    if (location) {
      this._navigation.push(location, state);
    } else {
      console.error(
        'error at event clientos-northbound-api-v1-navigation-push. Location is missing.'
      );
    }
  };

  public async init(): Promise<void> {
    await this._eventService.subscribe(
      this._eventService.eventNames?.clientosCallInterfaceNavigationPush,
      this.clientosNavigationPushEventHandler
    );
  }
}
