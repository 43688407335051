import { CheckReturnObjectType } from '../../types';
import * as T from '../types';

const operationAND: T.CriterionOperationFunctionMultipleCriterionType = async ({
  criterions,
  recursion
}) => {
  let operationResult: CheckReturnObjectType = undefined;

  for (const criterion of criterions) {
    if (operationResult === undefined || operationResult?.result === true) {
      operationResult = await recursion(criterion);
    }
  }

  return operationResult;
};

export default operationAND;
