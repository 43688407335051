import { inject, injectable } from 'tsyringe';

import type AccessTokenContext from './types';
import type IGetAccessTokenService from './IGetAccessTokenService';
import AuthContextEnum from '../AuthContextEnum';
import AuthTokenRepository from '../AuthTokenRepository';
import TenantLevelEnum from '../../../services/tenantHandler/TenantLevelEnum';
import bindAllMethods from '../../../utils/bindAllMethods';
import type ITenantHandlerService from '../../../services/tenantHandler/ITenantHandlerService';
import { TenantHandlerService } from '../../../services/tenantHandler';

@injectable()
class GetAccessTokenService implements IGetAccessTokenService {
  private _tenantHandlerService: ITenantHandlerService;
  private _authTokenRepository: AuthTokenRepository;

  constructor(
    @inject(TenantHandlerService)
    tenantHandlerService: ITenantHandlerService,
    @inject(AuthTokenRepository) authTokenRepository: AuthTokenRepository
  ) {
    this._authTokenRepository = authTokenRepository;
    this._tenantHandlerService = tenantHandlerService;
    bindAllMethods(this);
  }

  getAccessToken(accessTokenAuthContext?: string): AccessTokenContext {
    let _accessTokenContext: string = accessTokenAuthContext;
    if (!_accessTokenContext) {
      _accessTokenContext = this._tenantHandlerService.getCurrentContext();
    }
    const accessTokenContext = this.findByAuthContext(
      _accessTokenContext as AuthContextEnum
    );
    return accessTokenContext as AccessTokenContext;
  }

  public findByAuthContext(authContex: AuthContextEnum): AccessTokenContext {
    const sufix = this.getSuffix(authContex);
    const authTokenStorageType = this._authTokenRepository.findOne(sufix);
    return {
      token: authTokenStorageType?.token,
      authContext: authTokenStorageType?.context
    } as AccessTokenContext;
  }

  public getSuffix(authContext: AuthContextEnum): string {
    if (!this._tenantHandlerService.isEnabled()) return '';
    let sufix: string;
    switch (authContext) {
      case AuthContextEnum.tenantless:
        sufix = '';
        break;
      case AuthContextEnum.tenant:
        sufix = this._tenantHandlerService.getTenantSuffix(
          TenantLevelEnum.tenant
        );
        break;
      case AuthContextEnum.subtenant:
        sufix = this._tenantHandlerService.getTenantSuffix(
          TenantLevelEnum.subTenant
        );
        break;
      default:
        sufix = this._tenantHandlerService.getTenantSuffix(null);
        break;
    }
    return sufix;
  }
}

export default GetAccessTokenService;
