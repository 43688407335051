import auth from './auth';
import { LoginInputType } from '../../infra/commonInitializer/types';

export type ShellInterfaceV2Type = {
  authProvider: any;
};

export default async function initializeShellInterfaceV2(
  loginInput: LoginInputType
): Promise<ShellInterfaceV2Type> {
  const authProvider = auth(loginInput);

  return {
    authProvider
  };
}
