import { getServices } from '../../../infra/commonInitializer';
import { RouterOperationInterface } from './RouterOperationInterface';
import { RouteServiceInputType } from '../../RoutesService/types';
import StageBuilder from '../stageBuilder/StageBuilder';
import {
  MicrofrontendRouterAssetType,
  MicrofrontendRouterLayoutType
} from '../types';

export default abstract class AbstractOperation
  implements RouterOperationInterface
{
  /***
   *
   * These methods could be useful for the extended classes.
   * FUTURE: In the future this could be a pre-analysed data to be insered in the CoR.
   */
  protected getRouteByCurrentPath(
    applyCriterionsMatch?: boolean
  ): Promise<RouteServiceInputType> {
    const { routesService, navigationService } = getServices();

    const currentPath = navigationService.getHistory().location.pathname;
    return routesService.findRouteWithPriority(
      currentPath,
      applyCriterionsMatch
    );
  }

  protected processToLogin(stateBuilder: StageBuilder): void {
    const { loginService } = getServices();
    const loginPath = loginService.getLoginPath();
    // I don't know why the overwrite is missing here. I think
    // it's necessary. But,anyways, I will keep without it for
    // now.  { overwrite: true }
    stateBuilder.setRedirectTo(loginPath);
    stateBuilder.setEndProcessChain(true);
  }

  protected processContent(
    stateBuilder: StageBuilder,
    route: MicrofrontendRouterAssetType
  ): void {
    stateBuilder.setContent(route);
  }

  protected processLayout(
    stateBuilder: StageBuilder,
    route: MicrofrontendRouterLayoutType
  ): void {
    // case #1: layoutKey is defined
    if (typeof route?.layoutKey === 'string' || route?.layoutKey === false) {
      stateBuilder.setLayoutByKey(route?.layoutKey);
      return;
    }

    // case #2: layoutKey is undefined
    // will be used the default deprecated layout from react-nav
    if (route?.public || route?.hidden) {
      stateBuilder.setLayoutByKey(false);
      return;
    }

    // case #3: layoutKey is undefined and not public nor hidden
    // will be used the default deprecated layout from react-nav
    stateBuilder.setLayout({
      enable: true,
      useDefaultRouteLayout: true
    });
  }

  /**
   *  The main method to process the operation
   */
  public abstract process(stateBuilder: StageBuilder): Promise<void>;
}
