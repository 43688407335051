import bindAllMethods from '../../../utils/bindAllMethods';
import {
  GetProviderListParam,
  GetProviderListResponseType,
  IProviderClient
} from '../../../clients/shell/provider';
import ILoginService, { RedirectToLoginParams } from './ILoginService';
import { GenerateAuthenticationUrlParams } from './types';
import { SetServiceDependencies } from '../../../infra/commonInitializer/types';
import { StateParamHandlerService } from '../../../services/stateParamHandlerService';
import { _getLoginPath } from '../utils/getLoginPath';
import {
  AuthorizationUrlBuilder,
  IAuthorizationUrlBuilder
} from './AuthorizationUrlBuilder';

class LoginServiceNative implements ILoginService {
  private _providerClient: IProviderClient;
  private _stateParamHandlerService: StateParamHandlerService;

  public setDependencies({ clients, services }: SetServiceDependencies): void {
    const { shell } = clients;
    this._providerClient = shell?.providerClient;
    this._stateParamHandlerService = services.stateParamHandlerService;
    bindAllMethods(this);
  }

  public getLoginPath(params?: RedirectToLoginParams): string {
    return _getLoginPath(params);
  }

  /**
   *  @deprecated This method uses the old version of redirect.
   * */
  public redirectToLogin(params?: RedirectToLoginParams): void {
    const loginPath = _getLoginPath(params);
    window.location.href = loginPath;
  }

  public async getProviderList(
    _?: GetProviderListParam
  ): Promise<GetProviderListResponseType> {
    throw new Error('Method not implemented.');
  }

  public async generateAuthenticationUrl(
    options: GenerateAuthenticationUrlParams
  ): Promise<string> {
    const authorizationUrlBuilder: IAuthorizationUrlBuilder =
      new AuthorizationUrlBuilder(
        options.authorizationUrl,
        this._stateParamHandlerService
      );

    authorizationUrlBuilder
      .withMaxAgeZeroOnLogin()
      .withAuthorizationExtraParams()
      .withState(options.postLoginRedirect, options.language, options.country)
      .withLocale(options.language, options.country);

    return authorizationUrlBuilder.build();
  }
}

export default LoginServiceNative;
