import { INavigationService } from '../../../services/navigationService';
import { ILocalizationService } from '../../../services/localizationService';
import LocaleSessionRepository, {
  LocaleSessionStorageType
} from './LocaleSessionRepository';

type StorageLocaleInitParams = {
  navigationService: INavigationService;
  localizationService: ILocalizationService;
  localeSessionRepository: LocaleSessionRepository;
};

export default class StorageLocale {
  public static async init({
    navigationService,
    localizationService,
    localeSessionRepository
  }: StorageLocaleInitParams): Promise<void> {
    if (!localizationService.enabled) return;

    const navigation = navigationService;

    const loggedInPath = navigation?.createHref?.({
      pathname: '/loggedin'
    });

    const currentPath = window.location.pathname;
    const locale = localeSessionRepository.find();

    if (currentPath === loggedInPath && locale) {
      try {
        const localizationSvcLocale: LocaleSessionStorageType = {
          country: localizationService.country,
          language: localizationService.language
        };
        const shouldUpdateLocale =
          JSON.stringify(locale) !== JSON.stringify(localizationSvcLocale);

        localeSessionRepository.clear();

        shouldUpdateLocale && (await localizationService.setLocale(locale));
      } catch (error) {
        console.error(error);
      }
    } else {
      const { country, language } = localizationService;
      localeSessionRepository.save({ country, language });
    }
  }
}
