import { isValidRegex } from './regex';
import { TenantVisualizationType } from '../../strategy/types';
import { ITenantFilter } from '../ITenantFilter';
import { FilterByTenantTypeType, RegexTenantFilterPropsType } from './types';

export default class RegexTenantFilter implements ITenantFilter {
  private _filterProps: RegexTenantFilterPropsType;

  constructor(filter?: RegexTenantFilterPropsType) {
    this._filterProps = filter;
  }

  private _getTenantTypeRegex(tenantType: string): string {
    return isValidRegex(tenantType) ? tenantType : `^${tenantType}$`;
  }

  private _getRegexList(filter: FilterByTenantTypeType['filter']) {
    return filter?.tenantType?.map((tenantType) => {
      const regexTenantType = this._getTenantTypeRegex(tenantType);
      return new RegExp(regexTenantType);
    });
  }

  private _filterByTenantType(
    filter: RegexTenantFilterPropsType,
    tenant: TenantVisualizationType
  ) {
    const _tenantTypeFilters: RegExp[] = this._getRegexList(filter);

    return (
      !_tenantTypeFilters?.length ||
      _tenantTypeFilters?.find((regex) => regex.test(tenant.type))
    );
  }

  filter(tenantList: TenantVisualizationType[]): TenantVisualizationType[] {
    return tenantList.filter((tenant: TenantVisualizationType) => {
      return this._filterByTenantType(this._filterProps, tenant);
    });
  }
}
