import { IShellDAO } from '../../../dao/IShellDAO';

export type LocaleSessionStorageType = {
  country: string;
  language: string;
};

type LocaleSessionRepositoryParams = {
  shellDAO: IShellDAO;
};

export default class LocaleSessionRepository {
  private shellDAO: IShellDAO;
  private prefixKey = 'jshellLocale';

  constructor({ shellDAO }: LocaleSessionRepositoryParams) {
    this.shellDAO = shellDAO;
  }

  save(locale: LocaleSessionStorageType): void {
    this.shellDAO.save(this.prefixKey, locale);
  }

  find(): LocaleSessionStorageType {
    return this.shellDAO.findOne(this.prefixKey) || undefined;
  }

  clear(): boolean {
    return this.shellDAO.delete(this.prefixKey);
  }
}
