async function callJWebWithTimeout<T>(
  externalMethod: () => Promise<T>,
  timeoutValue: number = 2000
): Promise<T | false> {
  let timeoutId: NodeJS.Timeout;
  const safeguardPromise = new Promise<false>((resolve) => {
    timeoutId = setTimeout(() => {
      console.error(
        'JWeb Method is taking too long to resolve and will be canceled'
      );
      resolve(false);
    }, timeoutValue);
  });

  try {
    const result = await Promise.race([externalMethod(), safeguardPromise]);
    clearTimeout(timeoutId);
    return result;
  } catch (error) {
    console.error('Error calling JWeb method', error);
    return false;
  }
}

export { callJWebWithTimeout };
