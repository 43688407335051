export const notificationSchema = {
  '$ref': '#/definitions/eventNotification',
  '$schema': 'http://json-schema.org/draft-04/schema#',
  'description': 'Telemetry event notification schema promoted from 1.3.0-beta.2',
  'definitions': {
    'ackToken': {
      'description': 'Token that the server must return to acknowledge the notification',
      'type': 'string'
    },
    'eventNotification': {
      'description': 'List of events in order of sequence number',
      'properties': {
        'events': {
          'description': 'A list of events in order of sequence number starting at sequenceNumber with a maximum length of limit.',
          'items': {
            '$ref': 'com.hp.cdm.service.eventing.version.1.resource.eventingEvent.schema.json#/definitions/event'
          },
          'type': 'array',
          'maxItems': 250
        },
        'links': {
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/links'
        },
        'metadata': {
          'additionalProperties': false,
          'properties': {
            'ackToken': {
              '$ref': '#/definitions/ackToken'
            },
            'notificationGlobalCount': {
              'description': 'Number of notifications sent (not necessarily acknowledged). Persistent over power-cycle.',
              'maximum': 2147483647,
              'minimum': 0,
              'type': 'integer'
            }
          },
          'type': 'object'
        },
        'originator': {
          'additionalProperties': false,
          'properties': {
            'originatorDetail': {
              '$ref': 'com.hp.cdm.domain.telemetry.type.originatorDetail.originator.ucdeCloudWebApp.version.1.schema.json#/definitions/originatorDetail'
            },
            'originatorDetailType': {
              'type': 'string'
            }
          },
          'required': [
            'originatorDetailType'
          ],
          'type': 'object',
          'description': 'Core information uniquely specifying the collecting client (detailed in a separate schema)'
        },
        'originatorContext': {
          'description': 'Additional information describing the collecting client (specified in a separate schema)',
          'additionalProperties': false,
          'properties': {
            'originatorContextDetail': {
              '$ref': 'com.hp.cdm.domain.telemetry.type.originatorContextDetail.originator.ucdeCloudWebApp.version.1.schema.json#/definitions/originatorContextDetail'
            },
            'originatorContextDetailType': {
              'type': 'string'
            }
          },
          'required': [
            'originatorContextDetail',
            'originatorContextDetailType'
          ],
          'type': 'object'
        },
        'gateway': {
          'description': 'Information regarding the ip address of the connecting client (populated by ingress gateway)',
          'additionalProperties': false,
          'properties': {
            'ipv4Address': {
              'description': 'ipv4 address of the client (printer, software, etc) connecting to the gateway',
              'type': 'string',
              'format': 'ipv4'
            },
            'ipv6Address': {
              'description': 'ipv6 address of the client (printer, software, etc) connecting to the gateway',
              'type': 'string',
              'format': 'ipv6'
            },
            'gatewayDateTime': {
              '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/dateTime',
              'description': 'Gateway time at arrival of payload'
            }
          },
          'required': [
            'gatewayDateTime'
          ],
          'oneOf': [
            {
              'required': [
                'ipv4Address'
              ]
            },
            {
              'required': [
                'ipv6Address'
              ]
            }
          ],
          'type': 'object'
        },
        'payloadManifestJec': {
          'description': 'JWE-encrypted payload manifest (compact format)',
          'type': 'string'
        },
        'subscriptionId': {
          '$ref': 'com.hp.cdm.service.eventing.version.1.resource.subscription.schema.json#/definitions/subscriptionId'
        },
        'token': {
          '$ref': 'com.hp.cdm.service.eventing.version.1.resource.subscription.schema.json#/definitions/token'
        },
        'version': {
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/version'
        },
        'privacy': {
          'description': 'Privacy information describing data policies/consents (specified in a separate schema)',
          'additionalProperties': false,
          'properties': {
            'privacyDetail': {
              'type': 'object'
            },
            'privacyDetailType': {
              'type': 'string'
            }
          },
          'required': [
            'privacyDetailType',
            'privacyDetail'
          ],
          'type': 'object'
        }
      },
      'required': [
        'version'
      ],
      'title': 'Event Notification',
      'type': 'object',
      'additionalProperties': false
    },
    'eventNotificationAck': {
      'additionalProperties': false,
      'properties': {
        'ackToken': {
          '$ref': '#/definitions/ackToken'
        },
        'version': {
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/version'
        }
      },
      'required': [
        'version'
      ],
      'type': 'object'
    }
  },
  'gun': 'com.hp.cdm.service.eventing.version.1.resource.notification',
  'version': '1.3.0',
  'jsonSchemaGun': 'com.hp.cdm.service.eventing.version.1.resource.notification.schema.json',
  'id': 'https://schemaregistry.analysis.ext.hp.com/cdm/gun/com.hp.cdm.service.eventing.version.1.resource.notification.schema.json'
};
