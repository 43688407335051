import * as T from './types';

/**
 * Get non cached property value from external source
 */
export default async function criterionExternalSourceGetPropertyValue(
  options: T.CriterionExternalSourceGetPropertyValueOptionsType
): Promise<unknown> {
  const module = await System.import(options.assetReference);
  const sourceObject = module[options.importKey];
  const propertyArray = options.property.split('.');

  const propertyRef = propertyArray.reduce((prev, current) => {
    if (prev) {
      if (prev[current] instanceof Function && current !== 'constructor') {
        return prev[current].bind(prev);
      } else {
        return prev[current];
      }
    }
    return undefined;
  }, sourceObject);

  if (typeof propertyRef === 'function') {
    if (options?.arguments && !Array.isArray(options?.arguments)) {
      throw new Error(
        'Criterion error: ExternalSource arguments must be an array'
      );
    }

    const propertyArguments = options?.arguments || [];
    return propertyRef(...propertyArguments);
  } else {
    return propertyRef;
  }
}
