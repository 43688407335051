import { OriginatorContextDetail } from './OriginatorContextDetail';

/**
 * CDM Originator Context object.
 *
 * @constructor builds a new OriginatorContext object
 * @param originatorContextDetail originator context detail
 * @property originatorContextDetail originator context detail
 */
export class OriginatorContext {
  constructor(originatorContextDetail: OriginatorContextDetail) {
    this.originatorContextDetail = originatorContextDetail;
    this.originatorContextDetailType = originatorContextDetail.gun;
  }
  originatorContextDetail: OriginatorContextDetail;
  /**
   * Originator context GUN
   */
  originatorContextDetailType: string;
}
