import bindAllMethods from '../../../utils/bindAllMethods';
import type IRefreshClient from '../../../clients/shell/refresh/IRefreshClient';
import { clearUserData } from '../utils/clearUserData';
import AuthContextEnum from '../../authTokenService/AuthContextEnum';
import type ISupportSessionService from '../../supportSession/ISupportSessionService';
import type IRefreshTokenService from './IRefreshTokenService';
import type IAuthTokenService from '../../authTokenService/IAuthTokenService';
import { RefreshParameterType } from '../ISessionService';
import SessionObserver, { SessionEvents } from '../SessionObserver';
import IdTokenRepository from '../IdTokenRepository';
import { Lifecycle, inject, injectable, scoped } from 'tsyringe';
import { ShellSessionRefreshResponseType } from '../../../clients/shell/refresh/IRefreshClient';
import { SupportSessionService } from '../../supportSession';
import { AuthTokenService } from '../../authTokenService';
import { RefreshV3Client } from '../../../clients/shell/refresh';
import {
  ITenantHandlerService,
  TenantHandlerService
} from '../../tenantHandler';
import { INavigationService } from '../../../services/navigationService';
import { NavigationService } from '../../../services/navigationService';

@injectable()
@scoped(Lifecycle.ContainerScoped)
class RefreshTokenServiceWeb implements IRefreshTokenService {
  private _refreshClient: IRefreshClient;
  private _supportSessionService: ISupportSessionService;
  private _refreshPromise: Promise<void>;
  private _authTokenService: IAuthTokenService;
  private _idTokenRepository: IdTokenRepository;
  private _tenantHandler: ITenantHandlerService;
  private _navigationService: INavigationService;

  constructor(
    @inject(SupportSessionService)
    supportSessionService: ISupportSessionService,
    @inject(AuthTokenService) authTokenService: IAuthTokenService,
    @inject(IdTokenRepository) idTokenRepository: IdTokenRepository,
    @inject(RefreshV3Client) refreshClient: IRefreshClient,
    @inject(TenantHandlerService) tenantHandler: ITenantHandlerService,
    @inject(NavigationService) navigationService: INavigationService
  ) {
    this._supportSessionService = supportSessionService;
    this._authTokenService = authTokenService;
    this._idTokenRepository = idTokenRepository;
    this._refreshClient = refreshClient;
    this._tenantHandler = tenantHandler;
    this._navigationService = navigationService;

    bindAllMethods(this);
  }

  public async refresh(
    refreshParameterType?: RefreshParameterType
  ): Promise<void> {
    if (this._refreshPromise) return this._refreshPromise;

    const refreshAction = async () => {
      if (this._supportSessionService.isSupportSession()) {
        return;
      }

      if (
        this._supportSessionService.isSupportSessionStartedBySessionAPIAndInitialStatusNotEnabled()
      ) {
        clearUserData();
        //TODO: move this kind of behavior to a callback that will be injected by a class at the constructor
        this._navigationService.redirect('/');
      }

      const authContext = this._authTokenService.getCurrentContext();
      const tenantsIdMap =
        refreshParameterType?.tenantsIdMap ||
        this._tenantHandler.getTenantIdsMap();

      try {
        const shellSessionRefreshResponse: ShellSessionRefreshResponseType =
          await this._refreshClient.refresh({
            authContext,
            tenantsIdMap
          });

        this._saveRefreshData(shellSessionRefreshResponse);
        await SessionObserver.notify(
          SessionEvents.REFRESH_TOKEN,
          this._authTokenService.getToken()?.token
        );
      } catch (error) {
        console.error(error);
        // Check if it's an HTTP error to prevent request aborts
        if (error?.response?.data) {
          throw error;
        }
      }
    };

    this._refreshPromise = refreshAction().finally(() => {
      this._refreshPromise = undefined;
    });

    return this._refreshPromise;
  }

  private _saveRefreshData(
    shellSessionRefreshResponse: ShellSessionRefreshResponseType
  ): void {
    if (shellSessionRefreshResponse?.shellTenantlessData?.token) {
      this._authTokenService.setToken(
        shellSessionRefreshResponse.shellTenantlessData.token,
        AuthContextEnum.tenantless
      );
    }
    if (shellSessionRefreshResponse?.shellTenantData?.token) {
      this._authTokenService.setToken(
        shellSessionRefreshResponse.shellTenantData.token,
        AuthContextEnum.tenant
      );
    }
    if (shellSessionRefreshResponse?.shellSubtenantData?.token) {
      this._authTokenService.setToken(
        shellSessionRefreshResponse.shellSubtenantData.token,
        AuthContextEnum.subtenant
      );
    }
    if (shellSessionRefreshResponse?.shellStratusIdToken) {
      this._idTokenRepository.save({
        token: shellSessionRefreshResponse?.shellStratusIdToken
      });
    }
  }
}

export default RefreshTokenServiceWeb;
