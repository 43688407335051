import bindAllMethods from '../../utils/bindAllMethods';
import { decodeJWTPayload } from '../../utils/tokenUtils/JWTUtils';
import { ISessionService } from '../session';
import IUserService from './IUserService';
import { UserDataType } from './types';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { IAuthProviderService } from '../authProviderService';

export type OnecloudUserServiceParams = {
  sessionService: ISessionService;
  authProviderService: IAuthProviderService;
};

export default class OnecloudUserService implements IUserService {
  private _sessionService: ISessionService;
  private _authProviderService: IAuthProviderService;
  private _userData: UserDataType;

  public setDependencies({ services }: SetServiceDependencies): void {
    const { sessionService, authProviderService } = services;
    this._sessionService = sessionService;
    this._authProviderService = authProviderService;

    bindAllMethods(this);
  }

  public async getEmail(): Promise<string> {
    return decodeJWTPayload(this._sessionService?.getIdToken?.())?.email;
  }

  public async getCountry(): Promise<string> {
    return (await this.get()).locale?.split('_')[1]?.toLocaleLowerCase();
  }

  public async get(): Promise<UserDataType> {
    if (!this._userData) {
      await this.refresh();
    }
    const idTokenData = decodeJWTPayload(this._sessionService?.getIdToken?.());

    this._userData = {
      idpType: idTokenData.idp_type,
      email: {
        value: idTokenData.email,
        verified: idTokenData.email_verified
      },
      phoneNumber: {
        value: idTokenData.phone_number,
        verified: idTokenData.phone_number_verified
      },
      familyName: idTokenData.family_name,
      givenName: idTokenData.given_name,
      userName: idTokenData.preferred_username,
      locale: idTokenData.locale
    } as UserDataType;

    return this._userData;
  }

  public async refresh(): Promise<void> {
    if (!this._sessionService.isLoggedIn()) return;
    await this._authProviderService.getAccessToken(true);
  }
}
