import { ApplicationContext, ValveControllerMetadata } from '@jarvis/jweb-core';
import { Notifications } from '../client/notification';
import getEventIdSuffix from '../helpers/getEventIdSuffix';
import { Queue } from './Queue';
import { QueueItemStatus } from './queueHelpers';
export class QueueItem {
  // Queue will contain the Envelope
  constructor(
    metadata: ValveControllerMetadata,
    notification: Notifications,
    applicationContext: ApplicationContext,
    trackingIdentifiers?: string[],
    state?: string,
    attemptCount?: number,
    error?: string,
    preBuilt?: boolean,
    status?: QueueItemStatus
  ) {
    this.state = state || 'default';
    this.attemptCount = attemptCount || 0;
    this.metadata = metadata;
    this.notification = notification;
    this.applicationContext = applicationContext;
    this.error = error;
    this.trackingIdentifiers = trackingIdentifiers;
    this.preBuilt = preBuilt;
    this.status = status;
  }
  id: number = parseInt(Date.now()+''+ getEventIdSuffix(),10);
  creationDate: string = new Date().toISOString();
  expirationDate: string = new Date(new Date().setHours(new Date().getHours() + Queue.queueItemTTLInHours)).toISOString();
  state = 'defaultState';
  attemptCount: number;
  metadata: ValveControllerMetadata;
  notification: Notifications;
  applicationContext: ApplicationContext;
  error?: string;
  trackingIdentifiers?: string[];
  preBuilt?: boolean;
  status?: QueueItemStatus;
}
