import { getServices } from '../../../infra/commonInitializer';
import bindAllMethods from '../../../utils/bindAllMethods';
import * as T from './types';

export default class Layout implements T.ILayoutInterface {
  constructor() {
    bindAllMethods(this);
  }

  getLayoutByKey(key: string): T.AssetPropertyType {
    const { layoutsService } = getServices();

    return layoutsService.getLayoutByKey(key);
  }
}
