import { LoginInputType } from '../../../../session/types';

export function showEmailGatheringScreen(login: LoginInputType): boolean {
  const emailGathering = login?.emailGathering || false;

  const urlParams = new URLSearchParams(window.location.search);
  const emailAlreadySelected = !!urlParams.get('email');

  return emailGathering && !emailAlreadySelected;
}
