export const rtlLanguages = [
  'ar',
  'dv',
  'fa',
  'ha',
  'he',
  'ks',
  'ku',
  'ps',
  'ur',
  'yi'
];

export enum Direction {
  rtl = 'rtl',
  ltr = 'ltr',
  auto = 'auto'
}
