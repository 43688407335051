import { SetServiceDependencies } from 'src/infra/commonInitializer/types';
import bindAllMethods from '../../utils/bindAllMethods';
import { ITenantHandlerService } from '../tenantHandler';
import { IPortalOnboardingService } from './IPortalOnboardingService';
import { INavigationService } from '../navigationService';

export default class PortalOnboardingService
  implements IPortalOnboardingService
{
  private _tenantHandlerService: ITenantHandlerService;
  private _navigationService: INavigationService;

  constructor() {
    bindAllMethods(this);
  }

  setDependencies(options: SetServiceDependencies): void {
    const { tenantHandlerService, navigationService } = options?.services || {};
    this._tenantHandlerService = tenantHandlerService;
    this._navigationService = navigationService;
  }

  public isUserOnPortalOnboardingPath(): boolean {
    const currentPortalOnboardingList =
      this._tenantHandlerService?.getPortalOnboardingList();

    return currentPortalOnboardingList?.some((portalOnboarding) => {
      return (
        portalOnboarding.route === this._navigationService.location.pathname
      );
    });
  }
}
