import { DataCollectionEventNames } from '../../../JWeb/JWebEnums';
import {
  DataValveCDMEvent,
  PublishCdmEventsOptions,
  AnalyticsServiceInputType
} from '../../types';
import IAnalyticsService from '../../IAnalyticsService';
import EventNames from '../../../../config/eventNames';
import { createCDMEvent } from '../../dependencies/AnalyticsEventBuilder';
import { EventCategoryEnum } from '../../dependencies/AnalyticsEventEnums';
import { SetServiceDependencies } from '../../../../infra/commonInitializer/types';
import bindAllMethods from '../../../../utils/bindAllMethods';
import { EventServiceValueType } from '../../../eventService/types';
import { internalLogger } from '../../../../interface/v1/logger';
import { jWebStates } from '../../../JWeb/initializer';
import { IMonitoringService } from '../../../monitoringService';
import getFlatMap from '../../../../utils/getFlatMap';
import * as JwebEventService from '../../../JWeb/eventService';
import IEventService from '../../../eventService/IEventService';

export default class CustomAnalyticsService implements IAnalyticsService {
  private _isEnabled = false;
  private _debugEnabled = false;
  private _events: IEventService;
  private _analyticsEventName: string;
  private _monitoringService: IMonitoringService;

  constructor(analyticsProps: AnalyticsServiceInputType) {
    this._isEnabled = !!analyticsProps?.enabled;
    this._debugEnabled = analyticsProps.publishErrors;
    this._analyticsEventName =
      analyticsProps?.customAnalyticsSubsystem?.eventName;

    bindAllMethods(this);
  }

  setInterfaceDependencies(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars
    _dependencies: Parameters<IAnalyticsService['setInterfaceDependencies']>[0]
  ): void {
    // Do nothing.
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    this._events = services.eventService;
    this._monitoringService = services.monitoringService;
  }

  public isEnabled(): boolean {
    return this._isEnabled;
  }

  public async init(): Promise<void> {
    // Checking if all JWEB dependences are ready.
    // TODO: I can't lock this call, because the commons uses await in this init().
    this.isEnabledWithAllPlugins();

    // Subscribe Events
    this._subscribeEvents();
  }

  public async isEnabledWithAllPlugins(): Promise<boolean> {
    if (!this.isEnabled()) {
      return false;
    }

    try {
      const JwebStatePromiseList = [
        jWebStates.plugins.EventService.then(() => true).catch(
          () => 'EventService'
        )
      ];

      const results = await Promise.all(JwebStatePromiseList);
      const failedPlugins = results.filter(
        (result) => typeof result === 'string'
      );

      if (failedPlugins.length > 0) {
        console.error(
          'Unable to run Analytics. Some JWeb Plugin is missing: ',
          failedPlugins
        );
        this._isEnabled = false;
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.error('Unable to run Analytics. Something went wrong', e);
      this._isEnabled = false;
      return false;
    }
  }

  public async publishCdmEvents(
    events: DataValveCDMEvent[],
    options?: PublishCdmEventsOptions
  ): Promise<void> {
    try {
      if (!this.isEnabled()) return;

      this._events.publish(this._analyticsEventName, {
        events,
        options
      });
    } catch (e) {
      internalLogger?.error?.('Analytics: publishCdmEvents Internal Error', e);
    }
  }

  public callbackPublishNotificationError(event: EventServiceValueType): void {
    const { eventData } = event || {};
    if (!eventData) return;

    const isPublished = eventData.action === 'publishNotification';
    const is200Code =
      eventData.actionDetail?.telemetryServiceResponse?.responseCode == 200;

    if (isPublished && !is200Code) {
      // We found an error, so will write it on our monitoring service.
      const dataToBeSaved = Object.fromEntries(
        getFlatMap(eventData.actionDetail)
      );

      this._monitoringService
        .startSpan('jshell-analytics', dataToBeSaved)
        .end();
    }
  }

  /**
   *
   * TODO: In the following, everything could be considered as a behavior of Analytics.
   * */
  public callbackShellSimpleUiEvent(event: EventServiceValueType): void {
    this.publishCdmEvents([
      createCDMEvent(EventCategoryEnum.simpleUi, event?.eventData)
    ]);
  }

  private async _subscribeForCmdEventsStratus(): Promise<void> {
    const subscriber = await JwebEventService.getSubscribe();
    subscriber.subscribe(
      { eventName: DataCollectionEventNames.cdmEventStatus },
      this.callbackPublishNotificationError
    );
  }

  private _subscribeEvents() {
    // Use case #1: When should send the Simple UI event.
    this._events.subscribe(
      EventNames.shellAnalyticsSimpleUiEvent,
      this.callbackShellSimpleUiEvent
    );

    // Use case #2: When should send the errors from Data Collection.
    if (this._debugEnabled) {
      this._subscribeForCmdEventsStratus();
    }
  }
}
