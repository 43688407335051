import bindAllMethods from '../../../utils/bindAllMethods';
import { ThemeService } from '../../../services/themeService';
import { ThemeInterfaceType } from './types';

export type ThemeServiceParam = {
  themeService: ThemeService;
};

class ThemeInterface {
  private _themeService: ThemeService;

  constructor({ themeService }: ThemeServiceParam) {
    this._themeService = themeService;
    bindAllMethods(this);
  }

  public getInterface(): ThemeInterfaceType {
    return {
      getScreenResponsivelyProperties:
        this._themeService.getScreenResponsivelyProperties,
      getThemeProviderProperties: this._themeService.getThemeProviderProperties,
      getUserThemeMode: this._themeService.getUserThemeMode
    };
  }
}

export default ThemeInterface;
