function isInLoggedinPath(): boolean {
  const pathname = '/loggedin';
  const currentPath = window.location.pathname;
  return currentPath?.includes(pathname);
}

function isInLoginPath(): boolean {
  const pathname = '/login';
  const currentPath = window.location.pathname;
  return currentPath?.includes(pathname);
}

export { isInLoggedinPath, isInLoginPath };
