export const base64Encode = (data: any) => {
  const value = JSON.stringify(data);

  return window.btoa(value);
};

export const base64Decode = (data: string) => {
  const result = window.atob(data);

  try {
    return JSON.parse(result);
  } catch {
    return result;
  }
};

export const isBase64 = (data: string): boolean => {
  try {
    return btoa(atob(data)) === data;
  } catch {
    return false;
  }
};
