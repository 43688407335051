export const originatorContextDetail = {

  '$schema': 'http://json-schema.org/draft-04/schema#',
  'gun': 'com.hp.cdm.domain.telemetry.type.originatorContextDetail.originator.ucdeCloudWebApp.version.1',
  'description': 'Schema for CDM originatorContext detail used by Cloud WebApps',
  'type': 'object',
  'version': '1.0.0',
  'definitions': {
    'originatorContextDetail': {
      'type': 'object',
      'required': [
        'version'
      ],
      'properties': {
        'version': {
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/version'
        },
        'associatedDeviceUuid': {
          '_producer': 'cloud-webapp',
          'description': 'Device Uuid of the imagingDevice associated with this event if available',
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/uuid'
        },
        'associatedDeviceProductNumber': {
          '_comment': '// TODO:  This definition should probably be moved to commonGlossary',
          '_producer': 'cloud-webapp',
          'description': 'HP Device Product Number associated with this event if available',
          'type': 'string',
          'pattern': '[A-Z0-9]+',
          'minLength': 6,
          'maxLength': 8,
          '_examples': [
            'A7F66A'
          ]
        },
        'associatedStratusUserId': {
          '_comment': '// TODO:  Need to lock down more with regex or enum and examples.  Perhaps make this commonGlossary.',
          '_producer': 'cloud-webapp',
          'type': 'string',
          'maxLength': 256,
          'description': 'Stratus UserID of the user associated with this event if available',
          'examples': [
            ''
          ]
        },
        'ucdeCorrelationId': {
          '_producer': 'cloud-webapp',
          'description': 'Cloud service correlation linking key, utilize x-reference-id header unless otherwise specified',
          'type': 'string',
          'maxLength': 256
        }
      },
      'additionalProperties': false
    }
  },
  'id': 'https://schemaregistry.analysis.ext.hp.com/cdm/gun/com.hp.cdm.domain.telemetry.type.originatorContextDetail.originator.ucdeCloudWebApp.version.1.schema.json',
  'jsonSchemaGun': 'com.hp.cdm.domain.telemetry.type.originatorContextDetail.originator.ucdeCloudWebApp.version.1.schema.json',
  'additionalProperties': false
};
