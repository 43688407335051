import { DataCollectionHTTPResponse, InvocationDetail, ValveFilterError, ValveFilterErrorType } from '@jarvis/jweb-core';

export class DataCollectionError implements ValveFilterError {
  invocationDetails: InvocationDetail;
  errorType: ValveFilterErrorType;
  reason?: string;
  httpResponse?: DataCollectionHTTPResponse;
  exception?: string;

  constructor(invocationDetails: InvocationDetail, errorType: ValveFilterErrorType, reason?: string, exception?: string ,httpResponse?: DataCollectionHTTPResponse) {
    this.invocationDetails = invocationDetails;
    this.errorType = errorType;
    this.reason = reason;
    this.httpResponse = httpResponse;
    this.exception = exception;
  }
}
