import LocalizationInterface from './LocalizationInterface';
import { LocalizationInterfaceType } from './types';
import { getServices } from '../../../infra/commonInitializer';
import { Direction } from '../../../services/localizationService/constants';
import { ServiceLocalizationLanguageType } from '../../../services/localizationService/types';

let localizationInterface: LocalizationInterface;

export function getLocalizationInterface(): LocalizationInterface {
  return localizationInterface;
}

export default async function initializeLocalizationInterface(): Promise<LocalizationInterfaceType> {
  if (localizationInterface) return localizationInterface.getInterface();

  const { localizationService, localizationTranslatorService } = getServices();

  localizationInterface = new LocalizationInterface({
    localizationService,
    localizationTranslatorService
  });

  return localizationInterface.getInterface();
}

export function createNoopLocalizationInterface(): LocalizationInterfaceType {
  return {
    enabled: false,
    locale: '',
    language: '',
    country: '',
    getLanguages: function (): Promise<ServiceLocalizationLanguageType> {
      console.debug('Function not implemented.');
      return Promise.resolve({});
    },
    getGlobalTranslatorFunction: function (): Promise<any> {
      console.debug('Function not implemented.');
      return Promise.resolve({});
    },
    setLocale: function (): Promise<void> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    createTranslatorFunction: function (): Promise<any> {
      console.debug('Function not implemented.');
      return Promise.resolve('');
    },
    getReactTranslatorProvider: function (): () => any {
      console.debug('Function not implemented.');
      return () => {};
    },
    useReactTranslatorHook: function () {
      console.debug('Function not implemented.');
    },
    setLocalePreference: function (): void {
      console.debug('Function not implemented.');
    },
    getLocalePreference: function (): string {
      console.debug('Function not implemented.');
      return '';
    },
    getLanguageDirection: function (): Promise<Direction> {
      console.debug('Function not implemented.');
      return Promise.resolve(Direction.auto);
    },
    prefetchLocaleFile: function (): Promise<any> {
      console.debug('Function not implemented.');
      return Promise.resolve({});
    },
    useReactGetLanguageDirection: function (): Direction {
      console.debug('Function not implemented.');
      return Direction.rtl;
    }
  };
}
