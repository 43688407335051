export type SanitizeItemType = {
  key: string;
  expirationDate: number;
};

export type SortedArrayType = {
  nextCurrent: () => void;
  resetCurrent: () => void;
  push: (obj: SanitizeItemType) => void;
  top: () => SanitizeItemType;
  removeFirstItem: () => void;
  sort: () => void;
  clear: () => void;
  deleteItem: (key: string) => void;
  getLength: () => number;
};

/**
 * A sorted array implementation that maintains the order of its elements.
 */
export const SortedArray = (): SortedArrayType => {
  const _list: SanitizeItemType[] = [];

  const nextCurrent = () => {
    sort();
  };

  const resetCurrent = () => {
    sort();
  };

  const sort = () => {
    _list.sort((a, b) => {
      return a.expirationDate - b.expirationDate;
    });
  };

  const removeFirstItem = () => {
    _list.shift();
  };

  const deleteItem = (key: string) => {
    _list.forEach((t, index) => {
      if (t.key === key) {
        _list.splice(index, 1);
      }
    });
    sort();
  };

  const getLength = () => {
    return _list.length;
  };

  /**
   * Removes all elements from the sorted array.
   */
  const clear = () => {
    _list.splice(0, _list.length);
  };

  /**
   * Adds one element to the sorted array.
   * @param obj The elements to add to the sorted array.
   */
  const push = (obj: SanitizeItemType) => {
    // Removing item with the same key if exists before adding the new one.
    _list.forEach((t, index) => {
      if (t.key === obj.key) {
        _list.splice(index, 1);
      }
    });

    _list.push(obj);
    sort();
  };

  const top = () => {
    if (_list.length === 0) {
      return undefined;
    }
    return _list[0];
  };

  return {
    nextCurrent,
    resetCurrent,
    push,
    top,
    removeFirstItem,
    sort,
    getLength,
    deleteItem,
    clear
  };
};
