import { IEventService } from '../../../services/eventService';

export const handleOnActive = (events: IEventService): void => {
  events.publish('jshell-useractivity-onactive', null);
};

export const handleOnIdle = (events: IEventService): void => {
  events.publish('jshell-useractivity-onidle', null);
};

export const handleOnHide = (events: IEventService): void => {
  events.publish('jshell-useractivity-onhide', null);
};

export const handleOnShow = (events: IEventService): void => {
  events.publish('jshell-useractivity-onshow', null);
};
