import { SortedArray, SanitizeItemType, SortedArrayType } from './SortedArray';
import { ReceivedObjectType } from '../../dao/decorators/types';
import { IShellDAO } from '../../dao/IShellDAO';
import { IShellDAOWithSanitize } from '../../dao/decorators/WithTTL';
import { SetRepositoryWithTTLParams } from './types';

/* Sanitize is JSHELL's service for cleaning up repository entries.
  -> TTL should be used in seconds.
*/
export class SanitizeService {
  private ShellDAOWithTTL: IShellDAOWithSanitize;
  private _currentList = SortedArray();
  private _lastTimeout: ReturnType<typeof setTimeout>;
  private _defaultTimerInSecs = 60;

  constructor(defaultTTL: number) {
    this._defaultTimerInSecs = defaultTTL;
  }

  /** Setting the current storage service and asks to it to retrieve
   * the already storage data */
  setRepositoryWithTTL({
    shellDaoWithTTL,
    prefixKey
  }: SetRepositoryWithTTLParams): void {
    this.ShellDAOWithTTL = shellDaoWithTTL;
    this.ShellDAOWithTTL.setCallbacks({
      callbackToDeleteItem: this.removeItemFromList,
      callbackToAddItem: this.addItemToList
    });
    this.ShellDAOWithTTL.retrieveDataFromStorage({ prefixKey });

    this._lastTimeout = setTimeout(() => {
      this.checkNextElement();
    }, this._defaultTimerInSecs * 1000);
  }

  removeItemFromList = (key: string): void => {
    this._currentList.deleteItem(key);
  };

  addItemToList = (key: string, data: ReceivedObjectType['data']): void => {
    const { options, createdAt } = data;
    const { ttl } = options;

    if (ttl === undefined || createdAt === undefined) return;
    // The value will not expire.
    if (ttl === 0) return;

    const ed = new Date(createdAt);
    ed.setSeconds(ed.getSeconds() + ttl);
    const expirationDate = ed.getTime();

    this._currentList.push({ key, expirationDate });
  };

  clearLoop = (): void => {
    clearTimeout(this._lastTimeout);
  };

  removeTopIfExpired = (data: SanitizeItemType): boolean => {
    const currentTs = new Date().getTime();

    if (data.expirationDate <= currentTs) {
      this.ShellDAOWithTTL.delete(data.key);
      return true;
    }
    return false;
  };

  checkNextElement = (): void => {
    const data = this._currentList.top();
    if (data === undefined) {
      this.nextLoop(this._defaultTimerInSecs * 1000);
      return;
    }
    const wasRemoved = this.removeTopIfExpired(data);
    if (wasRemoved) {
      this.checkNextElement();
    } else {
      const currentTs = new Date().getTime();
      const nextInMs = data.expirationDate - currentTs;
      this.nextLoop(nextInMs);
    }
  };

  nextLoop = (timer: number): void => {
    this.clearLoop();

    this._lastTimeout = setTimeout(() => {
      this.checkNextElement();
    }, timer);
  };

  getStorage = (): IShellDAO => {
    return this.ShellDAOWithTTL.getEnhancedMethods();
  };

  getCurrentList = (): SortedArrayType => this._currentList;

  getLastTimeout = (): ReturnType<typeof setTimeout> => this._lastTimeout;
}
