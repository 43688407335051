import { getServices } from '../../../infra/commonInitializer';
import {
  FallbackOperation,
  LoginPathOperation,
  OnboardingAgentOperation,
  PathRouteOperation,
  ServiceRoutingOperation,
  LoggedInPathOperation,
  TenantSelectorOperation,
  NewLoginPathOperation
} from '../operations';
import { ChainOfOperationsInputType, RouterOperationInterface } from '../types';

export const CreateStandardOperationFlow = (
  options: ChainOfOperationsInputType
): RouterOperationInterface[] => {
  // All theses services must be ready here.
  const {
    tenantHandlerService,
    sessionService,
    monitoringService,
    navigationService
  } = getServices();

  //Another wall of shame of interfaces
  const {
    storeInterface,
    navigationInterface,
    fallbackInterface,
    authProviderInterface,
    localizationInterface,
    criterionInterface
  } = options;

  // The order matters
  return [
    new FallbackOperation({
      fallbackInterface
    }),
    // new LoginPathOperation({
    //  navigationInterface
    // }),
    new NewLoginPathOperation({
      loginData: options.data.login,
      basePath: options.data.basePath
    }),
    new LoggedInPathOperation({
      criterionInterface,
      onboardingData: options.data.onboarding,
      userOnboardingData: options.data.userOnboarding,
      loginData: options.data.login,
      basePath: options.data.basePath
    }),
    new ServiceRoutingOperation(),
    new OnboardingAgentOperation({
      globalPreloadAssetReferenceLocale:
        options.data.localization?.preloadAssetReferenceLocale,
      resumeOnTriggers: !!options.data.onboarding?.resumeOnTriggers,
      authProviderInterface,
      navigationInterface,
      stack: options.data.stack,
      storeInterface,
      onboardingTriggerList: options.data.onboarding?.triggers,
      onboardingDirectorClientId: options.data.onboarding?.clientId,
      localizationInterface: localizationInterface,
      fallbackInterface,
      sessionService,
      tenantHandlerService,
      monitoringService,
      navigationService
    }),
    new TenantSelectorOperation({
      userOnboardingPath: options.data.onboarding?.userOnboardingPath
    }),
    new PathRouteOperation({
      // TODO: preloadAssetReferenceLocale should came from LocalizationService
      globalPreloadAssetReferenceLocale:
        options.data.localization?.preloadAssetReferenceLocale,
      fallbackInterface
    })
  ];
};
