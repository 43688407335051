export const eventing ={
  '$schema': 'http://json-schema.org/draft-04/schema#',
  'gun': 'com.hp.cdm.service.eventing.version.1.sharedTypes.eventing',
  'version': '1.16.0',
  'id': 'https://schemaregistry.analysis.ext.hp.com/cdm/gun/com.hp.cdm.service.eventing.version.1.sharedTypes.eventing.schema.json',
  'type': 'object',
  'additionalProperties': false,
  'definitions': {
    'eventCategory': {
      'description': 'Possible values of Event Categories for a Telemetry Event',
      'type': 'string',
      'enum': [
        'supply',
        'job',
        'systemError',
        'power',
        'fwUpdate',
        'jobStatus',
        'jobError',
        'wifiNetwork',
        'wifiSetup',
        'errorRecovery',
        'printHead',
        'calibration',
        'userConsentManagement',
        'uuidInfo',
        'lifetimeCounterSnapshot',
        'simpleUi',
        'systemInformation',
        'rtp',
        'deviceSecurityAssessment',
        'deviceInformation',
        'diagnoseInformation',
        'driverConfigInformation',
        'driverUCDEIRInfo',
        'psdrSystemInformation',
        'bizModelUpdate',
        'deviceRegistration',
        'manufacturing',
        'fingerprintValidation',
        'engineStats',
        'healthTracker',
        'calibrationData',
        'misrData',
        'misrMetrics',
        'deviceClaim',
        'supplyManufacturing',
        'sysInfoBase',
        'jobInfo',
        'rtpCloud',
        'rtpCloudManufacturing',
        'suppliesAssessment',
        'cloudJobStatus',
        'iccResult',
        'helpUsage',
        'errorSystemEvents',
        'warningSystemEvents',
        'infoSystemEvents',
        'supplyEncoding',
        'deviceFwUpdateReport',
        'deviceEnvProfile',
        'ucdeDeviceEntitlement',
        'ucdeOrgUpdate',
        'ucdeAddress',
        'ucdeAddressAssociation',
        'iotOnboarding',
        'frontPanelFlow',
        'visitContextRaw',
        'browserInformation',
        'vcMvcSyncTransaction',
        'vcAuditTransaction',
        'vcMetadataDbSyncTransaction',
        'vcAuditFilterGeneration',
        'mvcManifest',
        'subscriptionEnrollment',
        'subscriptionBilling',
        'paasUsageInfo',
        'owsFlow',
        'pcFirmwareInfo',
        'pcIdentity',
        'pcOperatingSystem',
        'pcHwComponentsInfo',
        'pcDeviceInfo',
        'pcComponentsInfo',
        'messagehubUpdate',
        'transitionComCent',
        'pcDetails',
        'purchaseOrder',
        'thirdPartyOffer',
        'ucdeGrant',
        'ucdeOrderFulfillment',
        'ucdeOrgInvite',
        'userAccount',
        'qauth',
        'deviceSetupCheck',
        'pcFwComponentsInfo',
        'pcUsageSummary',
        'dcrSupplyData',
        'subscriptionUsage',
        'hpAppAudienceInstance',
        'customerSatisfaction',
        'userPreProvision',
        'networkReport',
        'scanAcquireTask'
      ]
    }
  },
  'jsonSchemaGun': 'com.hp.cdm.service.eventing.version.1.sharedTypes.eventing.schema.json'
};
