import { LoggedInHandleParamsType } from '../types';
import AbstractLoggedInHandler from './AbstractLoggedInHandler';

export default class InvitationFlowHandler extends AbstractLoggedInHandler {
  public async handle(params: LoggedInHandleParamsType): Promise<void> {
    const { decodedState, proceedToShell, services, manifest } = params;
    const isInvitationFlow = decodedState?.invite === 'true';

    if (isInvitationFlow) {
      await services.userOnboardingService.joinInvitedOrganization({
        tenantId: decodedState?.orgId
      });
      return proceedToShell(manifest, decodedState);
    }

    return super.handle(params);
  }
}
