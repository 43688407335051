import * as T from './types';
import BreadcrumbService from '../../../services/breadcrumbService';
import { getServices } from '../../../infra/commonInitializer';
import bindAllMethods from '../../../utils/bindAllMethods';

export default class Breadcrumb implements T.BreadcrumbInterfaceType {
  private _breadcrumbService: BreadcrumbService;
  constructor() {
    const services = getServices();
    this._breadcrumbService = services?.breadcrumbService;
    bindAllMethods(this);
  }

  listen(callback: T.BreadcrumbListenerCallbackType): () => void {
    return this._breadcrumbService.listen(callback);
  }

  useReactListener(React: any): T.BreadcrumbItemType[] {
    return this._breadcrumbService.useReactListener(React);
  }

  getBreadcrumbs(): T.BreadcrumbItemType[] {
    return this._breadcrumbService.getBreadcrumbs();
  }

  add(breadcrumbItem: T.BreadcrumbItemType): void {
    this._breadcrumbService.add(breadcrumbItem);
  }

  isEnabled(): boolean {
    return this._breadcrumbService.isEnabled();
  }

  remove(breadcrumbKey: string): void {
    this._breadcrumbService.remove(breadcrumbKey);
  }
}

export function createNoopBreadcrumbInteface(): T.BreadcrumbInterfaceType {
  return {
    listen: function (): () => void {
      console.debug('Function not implemented.');
      return () => {};
    },
    useReactListener: function (React: any): T.BreadcrumbItemType[] {
      console.debug('Function not implemented.');
      return [];
    },
    getBreadcrumbs: function (): T.BreadcrumbItemType[] {
      console.debug('Function not implemented.');
      return [];
    },
    add: function (breadcrumbItem: T.BreadcrumbItemType): void {
      console.debug('Function not implemented.');
    },
    isEnabled: function (): boolean {
      console.debug('Function not implemented.');
      return false;
    },
    remove: function (breadcrumbKey: string): void {
      console.debug('Function not implemented.');
    }
  };
}
