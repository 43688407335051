import * as T from './types';
import NavigationInterface from './NavigationInterface';
import LegacyNavigationInterface from './LegacyNavigationInterface';

let navigationInterface: T.NavigationInterfaceType;

export default async function initializeNavigationInterface(options?: {
  useLegacyNavigation: boolean;
}): Promise<T.NavigationInterfaceType> {
  if (!navigationInterface) {
    if (options?.useLegacyNavigation) {
      navigationInterface =
        new LegacyNavigationInterface().getNavigationInterface();
    } else {
      navigationInterface = new NavigationInterface().getInterface();
    }
  }

  return navigationInterface;
}
