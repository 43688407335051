import { AbstractLocaleHandler } from './AbstractLocaleHandler';
import getValueByInsensitiveKey from '../../../utils/getValueByInsensitiveKey';
import { ServiceLocalizationFallbackType } from './../types';
import getPathLocale from '../utils/getPathLocale';

export class PathLocaleHandler extends AbstractLocaleHandler {
  constructor(languagesFallback: ServiceLocalizationFallbackType) {
    super(languagesFallback);
  }

  public handle(isLanguage: boolean, currentList: string[]): string {
    const pathLocale = getPathLocale();

    // For fallback
    const pathFallback = getValueByInsensitiveKey(
      this._languagesFallback,
      pathLocale?.language
    );

    return this.genericHandle({
      isLanguage: isLanguage,
      locale: pathLocale,
      currentList: currentList,
      localeFallback: pathFallback
    });
  }
}
