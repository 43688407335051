import IEventService from '../../../services/eventService/IEventService';
import EventNames from '../../../config/eventNames';
import { getServices } from '../../../infra/commonInitializer';
import * as T from './types';

export default class EventInterface implements T.EventsInterfaceType {
  private _eventService: IEventService;
  public shellEventNames: typeof EventNames;

  constructor() {
    const services = getServices();
    this._eventService = services.eventService;
    this.shellEventNames = this._eventService.eventNames;
  }

  addEventListener(name: string, action: T.EventCallBackType): void {
    this._eventService.addEventListener(name, action);
  }

  removeEventListener(name: string, action: T.EventCallBackType): void {
    this._eventService.removeEventListener(name, action);
  }

  triggerEvent(name: string, event: T.EventValueType): void {
    this._eventService.publish(name, event);
  }
}

export function createNoopEventInterface(): T.EventsInterfaceType {
  return {
    shellEventNames: EventNames,
    addEventListener: function (): void {
      console.debug('Function not implemented.');
    },
    removeEventListener: function (): void {
      console.debug('Function not implemented.');
    },
    triggerEvent: function (): void {
      console.debug('Function not implemented.');
    }
  };
}
