import * as T from '../../../types/stratus';
import { JarvisWebHttpClient } from '@jarvis/web-http';

export default async function encryptVisitorUUID(options: {
  stack: T.Stack;
  visitorUuid: string;
}): Promise<string> {
  const { stack, visitorUuid } = options || {};
  const urlEnv = (() => {
    switch (stack) {
      case T.Stack.prod:
        return '';
      case T.Stack.stage:
        return 'stage';
      default:
        return 'pie';
    }
  })();

  const baseUrl = `https://api.hpsmart${urlEnv}.com/api/2/encrypt`;

  const httpClient = new JarvisWebHttpClient();

  const encryptedVisitorUUID: string = await httpClient
    .post({ url: baseUrl, params: { purpose: 'ga' }, data: { visitorUuid } })
    .then((r) => r.data?.visitorUuid);
  return encryptedVisitorUUID;
}
