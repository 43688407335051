import { Schema } from '../interfaces/Schema';
import { getDeviceType, getWindowValues, validateBrowserInformation, getBrowserInfoResult, isValidOsVersion } from '../utils/enum';

const MAX_LENGTH_256 = 256;
const MAX_LENGTH_64 = 64;
const MAX_LENGTH_32 = 32;
const MAX_LENGTH_10 = 10;

export class BrowserInfoEventDetail implements Schema {
  /**
   * country and region codes as per ISO 3166-1 alpha-2
   */
  osCountryRegion: string;
  /**
   * language code as per ISO 639-1
   */
  osLanguage: string;
  /**
   * OS Name
   */
  osName: string;
  /**
   * OS - Version number
   */
  osVersion: string;
  /**
   * Browser Name
   */
  browserName: string;
  /**
   * Browser User Agent
   */
  browserUserAgent?: string;
  /**
   * Browser - Major Version number
   */
  browserVersion: string;
  /**
   * Browser - Version number
   */
  browserVersionFull: string;
  /**
   * Browser - Screen resolution
   */
  browserScreenResolution: string;
  /**
   * Engine Name
   */
  engineName: string;
  /**
   * Engine - Version number
   */
  engineVersion: string;
  /**
   * System Device Category
   */
  sysCategory: string;
  /**
   * System Device Manufacturer
   */
  sysManufacturer: string;
  /**
   * System Device Model Name
   */
  sysModelName: string;

  constructor() {
    const windowValues = getWindowValues();
    const browserInfo = getBrowserInfoResult();
    this.osCountryRegion = windowValues.osCountryRegion;
    this.osLanguage = windowValues.osLanguage;
    this.osName = validateBrowserInformation(browserInfo.os.name , MAX_LENGTH_64);
    this.osVersion = isValidOsVersion(browserInfo.os.version) ? validateBrowserInformation(browserInfo.os.version , MAX_LENGTH_32): 'unknown';
    this.browserName = validateBrowserInformation(browserInfo.browser.name, MAX_LENGTH_64);
    this.browserUserAgent = validateBrowserInformation(windowValues.browserUserAgent, MAX_LENGTH_256);
    this.browserVersion = validateBrowserInformation(browserInfo.browser.major, MAX_LENGTH_32);
    this.browserVersionFull = validateBrowserInformation(browserInfo.browser.version, MAX_LENGTH_32);
    this.browserScreenResolution = validateBrowserInformation(windowValues.browserScreenResolution, MAX_LENGTH_10);
    this.engineName = validateBrowserInformation(browserInfo.engine.name, MAX_LENGTH_64);
    this.engineVersion = validateBrowserInformation(browserInfo.engine.version, MAX_LENGTH_32);
    this.sysCategory = validateBrowserInformation(getDeviceType(), MAX_LENGTH_32);
    this.sysManufacturer = validateBrowserInformation(browserInfo.device.type, MAX_LENGTH_64);
    this.sysModelName = validateBrowserInformation(browserInfo.device.model, MAX_LENGTH_64);
  }

  version = '1.0.0';
  get gun() {
    return 'com.hp.cdm.domain.telemetry.type.eventDetail.category.browserInformation.version.1';
  }
}
