import EventNames from '../../../config/eventNames';
import { getServices } from '../../../infra/commonInitializer';
import AppContext from '../../../services/appContext/AppContext';
import { AuthContextEnum } from '../../../services/authTokenService';
import IEventService from '../../../services/eventService/IEventService';
import { IGrantService } from '../../../services/grants/IGrantService';
import { GrantOptionsType, GrantType } from '../../../services/grants/types';
import bindAllMethods from '../../../utils/bindAllMethods';
import { PromiseHelper } from '../../../utils/PromiseHelper';
import { GrantsInterfaceType } from './types';

export class GrantsInterface {
  private defaultKey = 'default';
  private initializedAuthContext: Map<
    AuthContextEnum | typeof this.defaultKey,
    boolean
  > = new Map();
  private promiseHelper = new PromiseHelper();

  private eventsService: IEventService;
  private appContext: AppContext;
  private grantService: IGrantService;

  constructor() {
    const services = getServices();
    this.eventsService = services.eventService;
    this.appContext = services.appContext;
    this.grantService = services.grantService;
    bindAllMethods(this);

    this.appContext.addInitServices(this._initialize);
    this.appContext.addRefreshServices(this.refresh);
  }

  getInterface(): GrantsInterfaceType {
    return {
      refresh: this.refresh,
      checkGrants: this.checkGrants,
      checkPendingGrants: this.checkPendingGrants
    };
  }

  private async _initialize(options?: GrantOptionsType) {
    const contextKey = options?.authContext ?? this.defaultKey;
    let promise = this.promiseHelper.getPendingPromise(contextKey);
    const isInitialized = this.initializedAuthContext.get(contextKey);

    if (!isInitialized) {
      this.initializedAuthContext.set(contextKey, true);
      if (!promise) {
        promise = this.refresh(options);
        this.promiseHelper.setPendingPromise(contextKey, promise);
      }
    }

    await promise;
  }

  private async refreshAction(options?: GrantOptionsType) {
    if (await this.grantService.haveGrantsChanged(options)) {
      this.eventsService.publish(
        EventNames.shellGrantsChangedEventName,
        undefined
      );
    }
    if (await this.grantService.havePendingGrantsChanged(options)) {
      this.eventsService.publish(
        EventNames.shellPendingGrantsChangedEventName,
        undefined
      );
    }
  }

  async refresh(options?: GrantOptionsType): Promise<void> {
    const promiseKey = options?.authContext ?? this.defaultKey;
    let promise = this.promiseHelper.getPendingPromise(promiseKey);
    if (this.grantService.isEnabled() && !promise) {
      promise = this.refreshAction(options);
      this.promiseHelper.setPendingPromise(promiseKey, promise);
    }
    await promise;
  }

  async checkGrants(
    grants: GrantType[],
    options?: GrantOptionsType
  ): Promise<boolean> {
    await this._initialize(options);
    return this.grantService.checkGrants(grants, options);
  }

  async checkPendingGrants(
    grants: GrantType[],
    options?: GrantOptionsType
  ): Promise<boolean> {
    await this._initialize(options);
    return this.grantService.checkPendingGrants(grants, options);
  }

  async getGrant(
    grant: GrantType,
    options?: GrantOptionsType
  ): Promise<GrantType> {
    await this._initialize(options);
    return this.grantService.getGrant(grant, options);
  }

  async getPendingGrant(
    pendingGrant: GrantType,
    options?: GrantOptionsType
  ): Promise<GrantType> {
    await this._initialize(options);
    return this.grantService.getPendingGrant(pendingGrant, options);
  }
}
