import IAuthzClient from '../../../clients/stratus/authz/IAuthzClient';
import bindAllMethods from '../../../utils/bindAllMethods';
import { ILogoutClient } from '../../../clients/shell/logout';
import { Stack } from '../../../types/stratus';
import ILogoutService from './ILogoutService';
import { ISessionService } from '..';
import { SetServiceDependencies } from '../../../infra/commonInitializer/types';
import { StateParamHandlerService } from '../../../services/stateParamHandlerService';
import { StatesParamType } from '../../../services/stateParamHandlerService/types';
import { LogoutOptionsType } from '../ISessionService';
import { INavigationService } from '../../../services/navigationService';

export type StratusLogoutServiceParameters = {
  stack: Stack;
  logoutClient: ILogoutClient;
  authzClient: IAuthzClient;
  sessionService: ISessionService;
};

export default class StratusLogoutService implements ILogoutService {
  private _stack: Stack;
  private _logoutClient: ILogoutClient;
  private _authzClient: IAuthzClient;
  private _sessionService: ISessionService;
  private _stateParamHandlerService: StateParamHandlerService;
  private _navigationService: INavigationService;

  public setDependencies({ clients, services }: SetServiceDependencies): void {
    const { stratus, shell } = clients;
    const {
      applicationService,
      sessionService,
      stateParamHandlerService,
      navigationService
    } = services;
    this._stack = applicationService.getAuthStack();
    this._logoutClient = shell.logoutClient;
    this._authzClient = stratus.authzClient;
    this._sessionService = sessionService;
    this._stateParamHandlerService = stateParamHandlerService;
    this._navigationService = navigationService;

    bindAllMethods(this);
  }

  public async logout(options?: LogoutOptionsType): Promise<void> {
    const { postLogoutRedirect } = options || {};

    const stateParamObject: StatesParamType =
      this._stateParamHandlerService.createLogoutStateObject(
        postLogoutRedirect
      );

    const postLogoutRedirectUrl = this._logoutClient.configureLogoutUrl(
      location.origin
    );

    const redirectTo = this._authzClient.configureLogoutUrl(
      this._stack,
      this._sessionService.getIdToken(),
      postLogoutRedirectUrl,
      stateParamObject.state
    );

    return new Promise(() => {
      this._navigationService.redirect(redirectTo);
    });
  }
}
