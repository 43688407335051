import { GetKeyValueOptions, jWebReady, Publisher, PutKeyValueOptions, RemoveKeyValueOptions, ValueRemovedEventData, ValueStoreError, ValueStoreErrorType, ValueStoreEventPublisherId, ValueStoreGetResult, ValueStoreGetSuccessResult, ValueStorePutResult, ValueStorePutResultData, ValueStoreRemoveResult, ValueStoreRemoveResultData, ValueStoreValueRemovedEventName, ValueStoreValueUpdatedEventName, ValueUpdatedEventData } from '@jarvis/jweb-core';
import { ErrorMessages } from './utils/constants';
import { valueStorePluginErrorMethod } from './utils/exception';
import { setNullForUndefinedValue } from './utils/helper';
import { encryptStorage } from './utils/storage';
import { isValueStorePluginWeb } from './web';

class ValueStoreServiceSingleton {
  valueStoreSecretKey = '';
  publisher: Publisher | undefined;

  setValueStoreSecretKey(key: string) {
    this.valueStoreSecretKey = key;
  }

  async get(options: GetKeyValueOptions): Promise<ValueStoreGetResult | ValueStoreError> {
    console.log(`Getting Value Store Key/Value pair with Key: ${options.keys}`);
    if (!options.keys || !options.keys.length) {
      return valueStorePluginErrorMethod(ValueStoreErrorType.invalidOptions, ErrorMessages.optionsNotPresent);
    }
    const result: (ValueStoreGetSuccessResult | ValueStoreError)[] = [];
    let value: string | null | undefined;
    try {
      options.keys.forEach(key => {
        if (!key.trim()) {
          result.push(valueStorePluginErrorMethod(ValueStoreErrorType.invalidOptions, ErrorMessages.providedKeyEmpty));
        } else {
          value = encryptStorage().decrypt(key);
          result.push({ key,value:setNullForUndefinedValue(value) });
        }
      });
      return { result };
    } catch (e) {
      console.error(e);
      return valueStorePluginErrorMethod(ValueStoreErrorType.unknownError, ErrorMessages.unknownError);
    }
  }

  async put(options: PutKeyValueOptions): Promise<ValueStoreError | ValueStorePutResult> {
    console.log(`Put Value Store Key/Value pair with Key: ${options.entries}`);
    if (!options.entries || !options.entries.length) {
      return valueStorePluginErrorMethod(ValueStoreErrorType.invalidOptions, ErrorMessages.optionsNotPresent);
    }
    const result: (ValueStorePutResultData | ValueStoreError)[] = [];
    const updatedEntries: ValueStorePutResultData[] = [];
    let oldValue: string | null | undefined;
    try {
      options.entries.forEach(entry => {
        if (!entry.key.trim()) {
          result.push(valueStorePluginErrorMethod(ValueStoreErrorType.invalidOptions, ErrorMessages.providedKeyEmpty));
        } else {
          oldValue = encryptStorage().decrypt(entry.key);
          oldValue = setNullForUndefinedValue(oldValue);
          const newValue = entry.value === undefined ? '' : entry.value;
          encryptStorage().encrypt(entry.key, newValue);
          result.push({ key: entry.key, newValue, oldValue });
          updatedEntries.push({ key: entry.key, newValue, oldValue });
        }
      });
      this.publishKeyValueUpdatedEvent(updatedEntries);
      return { result };
    } catch (e) {
      console.error(e);
      return valueStorePluginErrorMethod(ValueStoreErrorType.unknownError, ErrorMessages.unknownError);
    }
  }

  async remove(options: RemoveKeyValueOptions): Promise<ValueStoreError | ValueStoreRemoveResult> {
    console.log(`Removing Value Store Key/Value pair with Key: ${options.keys}`);
    if (!options.keys || !options.keys.length) {
      return valueStorePluginErrorMethod(ValueStoreErrorType.invalidOptions, ErrorMessages.optionsNotPresent);
    }
    const result: (ValueStoreGetSuccessResult | ValueStoreError)[] = [];
    let value: string | null | undefined;
    const removedEntries: ValueStoreRemoveResultData[] = [];
    try {
      options.keys.forEach(key => {
        if (key.trim()) {
          value = encryptStorage().decrypt(key);
          value = setNullForUndefinedValue(value);
          result.push({ key, value }) ;
          removedEntries.push({ key, value });
          encryptStorage().remove(key);
        } else {
          result.push(valueStorePluginErrorMethod(ValueStoreErrorType.invalidOptions, ErrorMessages.providedKeyEmpty));
        }
      });
      this.publishKeyValueRemovedEvent(removedEntries);
      return { result };
    } catch (e) {
      console.error(e);
      return valueStorePluginErrorMethod(ValueStoreErrorType.unknownError, ErrorMessages.unknownError);
    }
  }

  private async publishKeyValueUpdatedEvent(updatedEntries: ValueStorePutResultData[]){
    const eventData: ValueUpdatedEventData = {
      updatedEntries
    };
    const updatedEventName: ValueStoreValueUpdatedEventName = 'ValueUpdated';
    if (this.publisher) {
      await this.publisher.publish(updatedEventName, eventData);
    } else {
      console.warn('ValueStore::publishKeyValueUpdatedEvent: EventService plugin is not available');
    }
  }

  private async publishKeyValueRemovedEvent(removedEntries: ValueStoreRemoveResultData[]){
    const eventData: ValueRemovedEventData = {
      removedEntries
    };
    const removedEventname: ValueStoreValueRemovedEventName = 'ValueRemoved';
    if (this.publisher) {
      await this.publisher.publish(removedEventname, eventData);
    } else {
      console.warn('ValueStore::publishKeyValueRemovedEvent: EventService plugin is not available');
    }
  }
}

const initializeService = async (service: ValueStoreServiceSingleton) => {
  try {
    jWebReady.then((jwebResult) => {
      const eventServicePlugin = jwebResult.Plugins?.EventService;
      const valueStorePlugin = jwebResult.Plugins?.ValueStore;
      if (valueStorePlugin === undefined) {
        console.warn('ValueStoreService::initialize: Missing ValueStore and/or EventService plugins');
        return;
      }
      if (!isValueStorePluginWeb(valueStorePlugin) || valueStorePlugin.getValueStoreService() !== service) {
        console.warn('ValueStoreService::initialize: ValueStoreServiceSingleton instance mismatch');
        return;
      }
      if (eventServicePlugin !== undefined) {
        const publisherId: ValueStoreEventPublisherId = 'com.hp.jarvis.value.store.publisher';
        eventServicePlugin
          .createPublisher(publisherId)
          .then((publisherResult) => {
            if ('publish' in publisherResult) {
              service.publisher = publisherResult;
            } else {
              console.error(`ValueStore::initialize::createPublisher: ${publisherResult}`);
            }
          })
          .catch((error) => console.error(`ValueStore::initialize::createPublisher: ${error}`));
      } else {
        console.warn('ValueStore::initialize: Missing EventService plugins');
      }
    }).catch((error) => console.error(`ValueStore::initialize::JWebReady: ${error}`));
  } catch (e) {
    console.log(e);
  }
};

const ValueStoreService = new ValueStoreServiceSingleton();

initializeService(ValueStoreService);

export { ValueStoreService };
