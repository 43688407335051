import { TenantVisualizationType } from '../../strategy/types';
import { ITenantFilter } from '../ITenantFilter';
import { WhiteListTenantFilterPropsType } from './types';

export default class WhiteListTenantFilter implements ITenantFilter {
  private _tenantIdWhiteList: string[];

  constructor(filter?: WhiteListTenantFilterPropsType) {
    const { tenantIdWhiteList = [] } = filter || {};
    this._tenantIdWhiteList = tenantIdWhiteList;
  }

  filter(tenantList: TenantVisualizationType[]): TenantVisualizationType[] {
    if (this._tenantIdWhiteList.length == 0) return tenantList;

    return tenantList.filter((tenant) => {
      return this._tenantIdWhiteList.includes(tenant.id);
    });
  }
}
