import IAssetLoaderService from './IAssetLoaderService';

class SystemJSAssetLoaderService implements IAssetLoaderService {
  public async batchLoad(keyList: string[]): Promise<any> {
    return Promise.allSettled(
      keyList.map(
        async (key) =>
          await System.import(key)
            .then((res) => res.default())
            .then(() =>
              console.log(`JSHELL BackgroundTask loadAssets: ${key} - LOADED`)
            )
            .catch((error) => console.error(error))
      )
    );
  }
}

export default SystemJSAssetLoaderService;
