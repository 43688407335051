import { ValveControllerMetadata } from '@jarvis/jweb-core';
import { bindingsMetadataKey, getWindowValues } from '../client/utils/enum';
import { APIKeyConfiguration, BatchConfiguration } from '../dataCollectionService/dataCollectionServiceTypes';
import { BindingsClient } from '../client/BindingsClient/BindingsClient';
import { dataCollectionService } from '../dataCollectionService/dataCollectionService';
import { Batching } from '../CDMFilter/filterTypes';
import { logger } from './logger';
import { checkInvalidateMetaData, removeInvalidateValveControllerMetaData } from './checkInvalidateMetaData';

const pendingBindingRequests: any = {};

export const getCachedBindings = async (
  valveControllerMetadata: ValveControllerMetadata
) => {
  const windowValue = getWindowValues();
  const previousBindingData = JSON.parse(
    windowValue.localStorage.getItem(bindingsMetadataKey)
  );
  const valveMetaData = JSON.stringify(valveControllerMetadata);
  if (previousBindingData) {
    const previousValveMetaData = Object.prototype.hasOwnProperty.call(previousBindingData, valveMetaData);
    if (previousValveMetaData) {
      const currentTime = new Date().getTime();
      const difference = currentTime - previousBindingData[valveMetaData].timestamp;
      const maxAge = getMaxAge(previousBindingData[valveMetaData].cacheControl) || 10;
      const expirationTime = Math.floor(difference / 1000 / 60); // 10min duration
      if (expirationTime < maxAge) {
        return previousBindingData[valveMetaData].getBindings;
      } else {
        const getBindings = await getBindingsAPI(valveControllerMetadata);
        if (getBindings?.data){
          const bindingsData = getBindings?.data;
          const cacheControl = getBindings?.headers['cache-control'];
          previousBindingData[valveMetaData] = { getBindings: bindingsData, timestamp: new Date().getTime(), cacheControl };
          getBindings && windowValue.localStorage.setItem(
            bindingsMetadataKey,
            JSON.stringify({ ...previousBindingData })
          );
          return bindingsData;
        }
        return '';
      }
    } else {
      const getBindings = await getBindingsAPI(valveControllerMetadata);
      if (getBindings?.data) {
        const bindingsData = getBindings?.data;
        const cacheControl = getBindings?.headers['cache-control'];
        previousBindingData[valveMetaData] = { getBindings: bindingsData, timestamp: new Date().getTime(), cacheControl };
        getBindings && windowValue.localStorage.setItem(
          bindingsMetadataKey,
          JSON.stringify({ ...previousBindingData })
        );
        return bindingsData;
      }
      return '';
    }
  } else {
    const getBindings = await getBindingsAPI(valveControllerMetadata);
    if (getBindings?.data) {
      const bindingsData = getBindings?.data;
      const cacheControl = getBindings?.headers['cache-control'];
      getBindings && windowValue.localStorage.setItem(
        bindingsMetadataKey,
        JSON.stringify({ ...JSON.parse(windowValue.localStorage.getItem(bindingsMetadataKey)), [valveMetaData]: { getBindings: bindingsData, timestamp: new Date().getTime(), cacheControl } })
      );
      return bindingsData;
    }
    return '';
  }
};


const getBindingsAPI = async (
  valveControllerMetadata: ValveControllerMetadata
) => {
  const configuration = dataCollectionService.getConfiguration() as APIKeyConfiguration;
  let bindingMetaData;
  if (configuration) {
    if (pendingBindingRequests[JSON.stringify(valveControllerMetadata)]) {
      bindingMetaData = await pendingBindingRequests[JSON.stringify(valveControllerMetadata)];
      logger.log('CacheStorage::getBindingsAPI:taking the previous promise');
    } else {
      const cacheControl = checkInvalidateMetaData(valveControllerMetadata) ? 'no-cache': configuration.cacheControl;
      const bindingClient = new BindingsClient(configuration.stack, configuration.valveControllerAPIkey, cacheControl, configuration.dataValveCustomEndpoint);
      try {
        const bindingrequest = bindingClient.getBindings(valveControllerMetadata);
        pendingBindingRequests[JSON.stringify(valveControllerMetadata)] = bindingrequest;
        bindingMetaData = await bindingrequest;

        // set the batch configuration for binging data
        if (bindingMetaData.status === 200) {
          if (!configuration.isCustomBatchConfigEnabled) {
            const bindingsData = bindingMetaData.data;
            if ('telemetryClientConfiguration' in bindingsData && 'batching' in bindingsData.telemetryClientConfiguration) {
              const batchConfigData = bindingsData.telemetryClientConfiguration.batching as Batching;
              const batchConfiguration: BatchConfiguration = {
                maxEventCount: batchConfigData.maxEventsPerNotification,
                minEventCount: batchConfigData.minEventsPerNotification,
                maxEventAge: batchConfigData.eventAgeInSeconds,
                evaluationFrequency: batchConfigData.evaluationFrequencyInSeconds
              };

              // set the batch configuration
              dataCollectionService.setBatchConfiguration(batchConfigData?.enabled, batchConfiguration);

            } else {
              logger.warn('CacheStorage::getBindingsAPI::Warn:Batch Configuration is not available in bindings data');
            }
          } else {
            logger.warn('CacheStorage::getBindingsAPI::Warn:Custom Batch Configuration is enabled');
          }
        } else {
          logger.error('CacheStorage::getBindingsAPI:getBindings error:', 'Error in fetching the bindings data');
        }
        removeInvalidateValveControllerMetaData(valveControllerMetadata);
      } catch (e) {
        logger.error('CacheStorage::getBindingsAPI:getBindings error:', e);
        delete pendingBindingRequests[JSON.stringify(valveControllerMetadata)];
        return e;
      }
      delete pendingBindingRequests[JSON.stringify(valveControllerMetadata)];
    }
    return bindingMetaData;
  } else {
    logger.warn('CacheStorage::getBindingsAPI::Warn:Set the APIkey Configuration before Calling the GetBindings');
  }
};

const getMaxAge = (cacheControl: string) => {
  const cacheControlDirectives = cacheControl.split(',');
  const maxAge = cacheControlDirectives.find(element => element.includes('max-age'));
  return maxAge ? Number(maxAge.split('=')[1]) / 60 : 10;
};
