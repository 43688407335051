export const bulkAddEventListener = (
  object: any,
  events: string[],
  callback: () => void
): void => {
  events.forEach((event) => {
    object.addEventListener(event, callback);
  });
};

export const bulkRemoveEventListener = (
  object: any,
  events: string[],
  callback: () => void
) => {
  events.forEach((event) => {
    object.removeEventListener(event, callback);
  });
};
