/* eslint-disable no-restricted-imports */

/**
 * JWEB is initializing here when it imports jWebReady as a non-blocking promise.
 *
 */
import { jWebReady } from '@jarvis/jweb-core';
import { registerValueStorePlugin } from '@jarvis/web-value-store';
import { registerEventServicePlugin } from '@jarvis/web-event-service';
import { registerDataCollectionPlugin } from '@jarvis/web-data-collection';

const jWebResultStates = {
  JWebLibrary: { resolve: undefined, reject: undefined },
  defaultPluginsRegistered: { resolve: undefined, reject: undefined },
  plugins: {
    ValueStore: { resolve: undefined, reject: undefined },
    EventService: { resolve: undefined, reject: undefined },
    DataCollection: { resolve: undefined, reject: undefined },
    Auth: { resolve: undefined, reject: undefined }
  }
};
const jWebStates = {
  JWebLibrary: new Promise((resolve, reject) => {
    jWebResultStates.JWebLibrary = { resolve, reject };
  }),
  defaultPluginsRegistered: new Promise((resolve, reject) => {
    jWebResultStates.defaultPluginsRegistered = { resolve, reject };
  }),
  plugins: {
    ValueStore: new Promise((resolve, reject) => {
      jWebResultStates.plugins.ValueStore = { resolve, reject };
    }),
    EventService: new Promise((resolve, reject) => {
      jWebResultStates.plugins.EventService = { resolve, reject };
    }),
    DataCollection: new Promise((resolve, reject) => {
      jWebResultStates.plugins.DataCollection = { resolve, reject };
    }),
    Auth: new Promise((resolve, reject) => {
      jWebResultStates.plugins.Auth = { resolve, reject };
    })
  }
};

const initializingJWebAndStates = async (): Promise<void> => {
  registerOnceJwebPlugins();
  return jWebReady
    .then((jweb) => {
      // library
      console?.debug?.(`JShell: jWebReady fully loaded. `);

      if (jweb) {
        jWebResultStates.JWebLibrary.resolve(true);
        if (jweb.Plugins) {
          jWebResultStates.defaultPluginsRegistered.resolve(true);
        } else {
          jWebResultStates.defaultPluginsRegistered.reject(false);
        }
      } else {
        jWebResultStates.JWebLibrary.reject(false);
        jWebResultStates.defaultPluginsRegistered.reject(false);
      }

      unlockingPlugins();
    })
    .catch((error) => {
      console?.error?.(`jWebReady: unable to load JWEB (error: ${error})`);
      jWebResultStates.JWebLibrary.reject(false);
      jWebResultStates.defaultPluginsRegistered.reject(false);
      jWebResultStates.plugins.ValueStore.reject(false);
    });
};

/** Jshell Registers
 * */
const _registerValueStore = () => {
  registerValueStorePlugin();
};
const _registerDataCollection = () => {
  registerDataCollectionPlugin();
};

const _registerEventService = () => {
  registerEventServicePlugin();
};

const registerOnceJwebPlugins = (): void => {
  _registerEventService();
  _registerDataCollection();
  _registerValueStore();

  console?.debug?.(`JShell: registered the plugins for JWeb. `);
};

const unlockingPlugins = () => {
  if ((window as any)?.JWeb?.Plugins?.ValueStore) {
    jWebResultStates.plugins.ValueStore.resolve(true);
  } else {
    jWebResultStates.plugins.ValueStore.reject(false);
  }

  if ((window as any)?.JWeb?.Plugins?.DataCollection) {
    jWebResultStates.plugins.DataCollection.resolve(true);
  } else {
    jWebResultStates.plugins.DataCollection.reject(false);
  }

  if ((window as any)?.JWeb?.Plugins?.EventService) {
    jWebResultStates.plugins.EventService.resolve(true);
  } else {
    jWebResultStates.plugins.EventService.reject(false);
  }

  if ((window as any)?.JWeb?.Plugins?.Auth) {
    jWebResultStates.plugins.Auth.resolve(true);
  } else {
    jWebResultStates.plugins.Auth.reject(false);
  }
};

initializingJWebAndStates();

export {
  jWebReady,
  jWebStates,
  registerOnceJwebPlugins,
  initializingJWebAndStates
};
