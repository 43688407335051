import bindAllMethods from '../../utils/bindAllMethods';
import ILocalizationService from './ILocalizationService';
import {
  ServiceLocalizationLanguageType,
  LocalizationServiceInputType
} from './types';
import { rtlLanguages, Direction } from './constants';
import { setCookie } from '../../utils/cookies';
import { getLocalePreference as _getCoookieLocalePreference } from './utils/getCookieLocale';
import {
  localePreferenceCookieName,
  errorInvalidLocaleFormat
} from '../../config/constants';
import { createLocaleBasedOnHandlers } from './utils/createLocaleBasedOnHandlers';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { INavigationService } from './../navigationService';
import { LocaleType } from './types';
import LanguageListProvider from './LanguageListProvider';
import { internalLogger } from '../../interface/v1/logger';

export type LocalizationServicesParams = {
  localization: LocalizationServiceInputType;
};

export default class LocalizationService implements ILocalizationService {
  private _isEnabled: boolean;
  private _language = '';
  private _country = '';
  private _navigationService: INavigationService;
  private _localizationProperties?: LocalizationServiceInputType;
  private _cachedResourcesPromise: Map<string, Promise<Response>> = new Map();
  private _languageListProvider: LanguageListProvider;
  public _currentLanguageKey: string = undefined;

  constructor({ localization }: LocalizationServicesParams) {
    this._localizationProperties = localization;
    this._isEnabled = localization?.enable || false;
    this._languageListProvider = new LanguageListProvider({ localization });
    bindAllMethods(this);
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const { navigationService } = services;
    this._navigationService = navigationService;
    bindAllMethods(this);
  }

  public async init(): Promise<void> {
    if (!this._isEnabled) return;

    // TODO this should be a JSHELL's use case
    // Is vital that it runs as soon as possible.
    const currentLanguageMap =
      this._languageListProvider.getExtendedLanguageMap();
    const newLocale = await this._retrieveLanguageAndCountry(
      currentLanguageMap
    );
    this._language = newLocale.language;
    this._country = newLocale.country;
  }

  private async _retrieveLanguageAndCountry(
    currentLanguageMap: ServiceLocalizationLanguageType
  ): Promise<LocaleType> {
    return await createLocaleBasedOnHandlers(
      this._localizationProperties?.fallbacks || {},
      currentLanguageMap
    );
  }

  //protected members
  get language(): string {
    return this._language;
  }

  get country(): string {
    return this._country;
  }

  get enabled(): boolean {
    return this._isEnabled;
  }

  /**
   * If the contextKey is different from the current one, this method sets
   * the new contextKey and requests it to update the Localization locale.
   */
  public async setCurrentContext(contextKey?: string): Promise<void> {
    if (!this._isEnabled) return;

    if (this._currentLanguageKey != contextKey) {
      this._currentLanguageKey = contextKey;

      const currentLanguageMap = this._languageListProvider.getLanguageMapByKey(
        this._currentLanguageKey
      );

      const newLocale = await this._retrieveLanguageAndCountry(
        currentLanguageMap
      );

      if (!newLocale.language || !newLocale.country) {
        // Were undefined.
        return;
      }

      if (
        newLocale.language != this.language ||
        this.country != newLocale.country
      ) {
        internalLogger?.log?.('Locale will be changed to: ', {
          language: newLocale.language,
          country: newLocale.country
        });

        this.setLocale({
          language: newLocale.language,
          country: newLocale.country
        });
      }
    }
  }

  public getExtendedLanguageMap(): ServiceLocalizationLanguageType {
    return this._languageListProvider.getExtendedLanguageMap();
  }

  /** PrefetchLocaleFile returns the JSON response. */
  public async prefetchLocaleFile(url: string): Promise<Response> {
    if (!url) return Promise.reject();

    if (this._cachedResourcesPromise.has(url)) {
      return this._cachedResourcesPromise.get(url);
    }
    const resourcePromise = fetch(url).then((response) => response.json());
    this._cachedResourcesPromise.set(url, resourcePromise);
    return resourcePromise;
  }

  public isPreloadAssetReferenceLocale(): boolean {
    return this._localizationProperties?.preloadAssetReferenceLocale;
  }

  public getStringifyLocale(): string {
    const lcLanguage = this._language?.toLowerCase();
    const ucCountry = this._country?.toUpperCase();
    if (lcLanguage && ucCountry) {
      return lcLanguage + '-' + ucCountry;
    } else if (lcLanguage) {
      return lcLanguage;
    } else {
      return '';
    }
  }

  public async setLocale(options: {
    language: string;
    country: string;
  }): Promise<void> {
    if (this._isEnabled) {
      this._language = options.language;
      this._country = options.country;

      await this._navigationService.refreshPath();
    }
  }

  public getLanguages(): ServiceLocalizationLanguageType {
    const defaultValue = {};
    return (
      this._languageListProvider.getLanguageMapByKey(
        this._currentLanguageKey
      ) || defaultValue
    );
  }

  public async checkAllowedLanguages(
    languages: ServiceLocalizationLanguageType
  ): Promise<boolean> {
    // Fow now, we assume the entries are lowercase
    return languages[this._language.toLocaleLowerCase()]?.includes(
      this._country.toLocaleLowerCase()
    );
  }

  public getLanguageDirection(): Direction {
    const isRtlSupported = this?._localizationProperties?.isRtlSupported;
    if (isRtlSupported) {
      if (
        rtlLanguages?.some?.(
          (rtlLanguage) =>
            rtlLanguage.toLowerCase() === this._language.toLowerCase()
        )
      ) {
        return Direction.rtl;
      }
    }
    return Direction.ltr;
  }

  public injectLanguageIntoHTMLElementNode(): void {
    const html = document.getElementsByTagName('html')?.[0];

    if (html) {
      html.lang = this._language?.toLowerCase?.();
      html.dir = this.getLanguageDirection()?.toLowerCase?.();
    }
  }

  public setLocalePreference(locale: string): void {
    const localeRegex = /^[a-z]{2}-[A-Z]{2}$/gim;
    if (localeRegex.test(locale)) {
      setCookie(localePreferenceCookieName, locale, undefined, false);
    } else {
      throw new Error(errorInvalidLocaleFormat);
    }
  }

  public getLocalePreference(): string {
    return _getCoookieLocalePreference();
  }

  public useReactGetLanguageDirection(React: any): Direction {
    const [value] = React.useState(this.getLanguageDirection());
    return value;
  }
}
