import { CheckReturnObjectType } from '../../types';
import * as T from '../types';

const operationNOT: T.CriterionOperationFunctionSingleCriterionType = async ({
  criterion,
  recursion
}) => {
  let operationResult: CheckReturnObjectType = undefined;

  operationResult = await recursion(criterion);

  return {
    result: !operationResult.result,
    fallbackKey: operationResult.fallbackKey
  };
};

export default operationNOT;
