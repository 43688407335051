import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import bindAllMethods from '../../utils/bindAllMethods';
import IAssetLoaderService from '../backgroundTaskService/assetLoader/IAssetLoaderService';
import { BackgroundTaskRegistrar } from './IBackgroundTaskManagerService';
import IBackgroundTaskService, {
  BackgroundTasksOptionsType,
  TaskData
} from './backgroundTask/IBackgroundTaskService';

export type BackgroundTaskHandlerServiceProps = {
  enabled: boolean;
  backgroundTaskRegistrarList: BackgroundTaskRegistrar[];
};

class BackgroundTaskManagerService {
  private _enabled: boolean;
  private _isLoading: boolean;
  private _backgroundTaskRegistrarList: BackgroundTaskRegistrar[];
  private _backgroundTaskService: IBackgroundTaskService;
  private _assetLoaderService: IAssetLoaderService;

  private _pendingTaskList: {
    taskId: string;
    options: {
      data: Record<string, any>;
      launchId?: string;
      closeBackgroundTaskCallback?: (result?: Record<string, any>) => void;
    };
  }[];

  private _assetLoadState: { promise: Promise<any>; loading: boolean } = {
    promise: null,
    loading: false
  };

  constructor({
    backgroundTaskRegistrarList,
    enabled
  }: BackgroundTaskHandlerServiceProps) {
    this._enabled = enabled;
    this._backgroundTaskRegistrarList = backgroundTaskRegistrarList;
    this._pendingTaskList = [];
    this._isLoading = true;
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const { backgroundTaskService, assetLoaderService } = services;
    this._backgroundTaskService = backgroundTaskService;
    this._assetLoaderService = assetLoaderService;
    bindAllMethods(this);
  }

  public async init(): Promise<void> {
    if (!this._enabled) return;
    const keys = this._backgroundTaskRegistrarList.map((item) => item.key);
    this.loadAssets(keys);
  }

  public async loadAssets(keys: string[]): Promise<void> {
    if (!this._enabled) {
      console.warn(
        `JSHELL BackgroundTask loadAssets: You can't load assets, you need to enable BackgroundTask feature`
      );
      return;
    }
    this._assetLoadState.loading = true;
    const batchLoadKeys = keys.map((key) => {
      console.log(`JSHELL BackgroundTask loadAssets: ${key}`);
      const item = this._backgroundTaskRegistrarList.find(
        (item) => item.key === key
      );
      return item.assetReference;
    });

    this._assetLoadState.promise = await this._assetLoaderService.batchLoad(
      batchLoadKeys
    );
    this._assetLoadState.loading = false;
    this._isLoading = false;
    this.launchTaskQueue();
  }

  public getTaskList(): TaskData[] {
    return this._backgroundTaskService.getBackgroundTaskList();
  }

  public async launchTask(
    taskId: string,
    options: BackgroundTasksOptionsType
  ): Promise<void> {
    if (!this._enabled) return;
    if (this._assetLoadState.loading || this._isLoading) {
      console.log(
        `JSHELL BackgroundTask launch: ${taskId}-${options?.launchId} - ON QUEUE`
      );
      this._pendingTaskList.push({ taskId, options });
    } else {
      await this._backgroundTaskService.launch(taskId, options);
    }
  }

  private launchTaskQueue() {
    this._pendingTaskList?.forEach((task) => {
      this._backgroundTaskService.launch(task.taskId, task.options);
    });
  }
}

export default BackgroundTaskManagerService;
