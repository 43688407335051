import { OriginatorDetail } from './OriginatorDetail';

export class Originator {
  constructor(originatorDetail: OriginatorDetail) {
    this.originatorDetail = originatorDetail;
    this.originatorDetailType = originatorDetail.gun;
  }

  originatorDetail: OriginatorDetail;
  originatorDetailType: string;
}
