import { match } from 'path-to-regexp';

// TODO: Maybe this could be a utils in URLService.
// TODO: Explain that the method always needs receiving path with /.
export function getPathnameFromUrl(url: string): string {
  if (typeof url !== 'string') return;
  const isPathname = url.startsWith('/');

  const pathnameWithSearch = isPathname ? url : url?.split('/')[1];

  return pathnameWithSearch?.split('?')[0];
}

export default function matchPath(
  path: string | string[],
  options?: { exact?: boolean; pathToCompare?: string | string[] }
): boolean {
  const pathArray = Array.isArray(path) ? path : [path];

  const pathToCompare = options?.pathToCompare || window.location.pathname;
  const unsanitizedPathsToCompare = Array.isArray(pathToCompare)
    ? pathToCompare
    : [pathToCompare];

  const arrayOfPathsToCompare = unsanitizedPathsToCompare.map((thisPath) =>
    getPathnameFromUrl(thisPath)
  );

  (window as any).checkPath = (path1, path2) =>
    match(path1, {
      start: true,
      end: !!options?.exact
    })(path2);

  return !!pathArray?.some((thisPath) => {
    const pathname = getPathnameFromUrl(thisPath);

    if (!pathname) return false;

    const checkPath = match(pathname, {
      start: true,
      end:
        typeof options?.exact === 'boolean' ? options?.exact : pathname === '/'
    });

    return arrayOfPathsToCompare
      ? arrayOfPathsToCompare?.some(
          (pathToCompare) => !!checkPath(pathToCompare)
        )
      : false;
  });
}
