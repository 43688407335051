import { getServices } from '../../../../infra/commonInitializer';

type LoginRequiredInputType = {
  assetReference?: string;
  public?: boolean;
};

/***
 * Check whether the user will be redirected to the login page, in case of (1) the desired page to be private, (2) he isn't logged and (3) the page contains an assetReference.
 * */
const shouldGoToLoginPage = async (
  object: LoginRequiredInputType
): Promise<boolean> => {
  if (object?.assetReference && !object?.public) {
    const { sessionService } = getServices();
    return !sessionService?.isLoggedIn?.();
  }
  return false;
};

export default shouldGoToLoginPage;
