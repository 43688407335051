/**
 * A helper class for managing pending promises.
 */
export class PromiseHelper {
  /**
   * A map of pending promises, keyed by a string identifier.
   */
  private pendingPromises: Map<string, Promise<any>> = new Map();

  /**
   * Gets the pending promise with the specified key.
   * @param key The key of the pending promise to get.
   * @returns The pending promise, or undefined if it does not exist.
   */
  public getPendingPromise(key: string): Promise<any> | undefined {
    return this.pendingPromises.get(key);
  }

  /**
   * Sets the pending promise with the specified key, it will be deleted from
   * the map when it resolves.
   * If the key already exists, it replaces the previous value.
   * @param key The key of the pending promise to set.
   * @param value The pending promise to set.
   */
  public setPendingPromise(key: string, value: Promise<any>): void {
    // Autodelete the promise after resolve
    if (this.getPendingPromise(key) !== value) {
      value.finally(() => {
        if (this.getPendingPromise(key) === value) {
          this.deletePendingPromise(key);
        }
      });
      this.pendingPromises.set(key, value);
    }
  }

  /**
   * Deletes the pending promise with the specified key.
   * @param key The key of the pending promise to delete.
   * @returns True if the pending promise was deleted, false if it did not exist.
   */
  public deletePendingPromise(key: string): boolean {
    return this.pendingPromises.delete(key);
  }
}
