import IAnalyticsService from '../../../services/AnalyticsService/IAnalyticsService';
import { AnalyticsInterfaceType } from './types';
import bindAllMethods from '../../../utils/bindAllMethods';

type AnalyticsInterfaceParam = {
  analyticsService: IAnalyticsService;
};

class AnalyticsInterface {
  private _analyticsService: IAnalyticsService;

  constructor({ analyticsService }: AnalyticsInterfaceParam) {
    this._analyticsService = analyticsService;

    bindAllMethods(this);
  }

  // keep compatibility.
  private async _isEnabled(): Promise<boolean> {
    return this._analyticsService.isEnabled();
  }

  public getInterface(): AnalyticsInterfaceType {
    return {
      publishCdmEvents: this._analyticsService.publishCdmEvents,
      isEnabled: this._isEnabled
    };
  }
}

export default AnalyticsInterface;
