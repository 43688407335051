import {
  optanonAlertBoxClosedCookieName,
  optanonConsentCookieName
} from '../../../../config/constants';
import { getCookie } from '../../../../utils/cookies';
import createScript from '../../../../utils/createScript';
import IABTestingClient from '../../IABTestingClient';
import { DynamicYieldInputType } from './types';

export interface ConsentManager {
  hasUserConsented(): boolean;
}

export class DefaultConsentManager implements ConsentManager {
  private optanonBoxClosed: string;
  private optanonConsent: string;

  constructor() {
    this.optanonBoxClosed = getCookie(optanonAlertBoxClosedCookieName);
    this.optanonConsent = getCookie(optanonConsentCookieName);
  }

  hasUserConsented(): boolean {
    const consentInteractionRegex = /interactionCount=1/;
    const consentGroupsRegex = /groups=1:1,2:1/;
    const consentBoxClosed = !!this.optanonBoxClosed.length;
    const consentInteracted = consentInteractionRegex.test(this.optanonConsent);
    const consentFulfilled = consentGroupsRegex.test(this.optanonConsent);

    return consentBoxClosed && consentInteracted && consentFulfilled;
  }
}

class DynamicYieldClient implements IABTestingClient {
  private snippetId: string;
  private scriptId: string = 'dynamicYield-script';
  private consentManager: ConsentManager;

  constructor(
    dynamicYield: DynamicYieldInputType,
    consentManager: ConsentManager = new DefaultConsentManager()
  ) {
    this.snippetId = dynamicYield.snippetId;
    this.consentManager = consentManager;
  }

  private addPreconnectLinks(urls: string[]): void {
    urls.forEach((url) => {
      const link = document.createElement('link');
      link.rel = 'preconnect';
      link.href = url;
      document.head.appendChild(link);
    });
  }

  private insertDynamicYieldScripts(): void {
    const dynamicScriptUrl = `https://cdn.dynamicyield.com/api/${this.snippetId}/api_dynamic.js`;
    const staticScriptUrl = `https://cdn.dynamicyield.com/api/${this.snippetId}/api_static.js`;

    const dynamicScript = createScript(
      `${this.scriptId}-dynamic`,
      dynamicScriptUrl
    );
    const staticScript = createScript(this.scriptId, staticScriptUrl);

    if (document.head.prepend) {
      document.head.prepend(staticScript);
      document.head.prepend(dynamicScript);
    } else {
      document.head.appendChild(staticScript);
      document.head.appendChild(dynamicScript);
    }
  }

  public init(): void {
    const dynamicYieldScript = document.getElementById(this.scriptId);

    if (!dynamicYieldScript && this.consentManager.hasUserConsented()) {
      const preconnectUrls = [
        '//cdn.dynamicyield.com',
        '//st.dynamicyield.com',
        '//rcom.dynamicyield.com'
      ];
      this.addPreconnectLinks(preconnectUrls);
      this.insertDynamicYieldScripts();
    }
  }
}

export default DynamicYieldClient;
