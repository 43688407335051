export const dataValve = {
  '$schema': 'http://json-schema.org/draft-04/schema#',
  'version': '1.2.0',
  'description': 'Adding transactionId to filterInfo',
  'gun': 'com.hp.cdm.service.dataValve.version.1.sharedTypes.dataValve',
  'jsonSchemaGun': 'com.hp.cdm.service.dataValve.version.1.sharedTypes.dataValve.schema.json',
  'oneOf': [
    {
      '$ref': '#/definitions/filterInfo'
    },
    {
      '$ref': '#/definitions/filterError'
    }
  ],
  'definitions': {
    'filterInfo': {
      'type': 'object',
      'additionalProperties': false,
      'properties': {
        'transactionId': {
          'type': 'string',
          'description': 'Each syncrhronization of filters with the end devices or local valve controllers are identified by a unique ID. This ID should be passed to the actual data telemetry for correlation purposes.'
        },
        'filterId': {
          'description': 'Filter identifier',
          'type': 'integer',
          'minimum': 1,
          'maximum': 2147483647
        },
        'filterType': {
          'type': 'string',
          'description': 'filterType none is set when unknown value is received, it is reported in filter error response while applying the filter',
          'enum': [
            'exclusion',
            'inclusion',
            'none'
          ],
          'default': 'none'
        },
        'resourceId': {
          'description': 'Resource identifier, gun/resource url/ ledm namespace',
          'type': 'string',
          'minLength': 1,
          'maxLength': 512
        },
        'attributes': {
          'description': 'array of xpaths in case of ledm resourcetype, jsonpaths in case of cdm resourceType of the attributes',
          'type': 'array',
          'maxItems': 128,
          'items': {
            'type': 'string',
            'maxLength': 1024
          }
        }
      }
    },
    'filterError': {
      'description': 'Filter errors',
      'type': 'string',
      'enum': [
        'notFound',
        'badFilter'
      ]
    }
  },
  'id': 'https://schemaregistry.analysis.ext.hp.com/cdm/gun/com.hp.cdm.service.dataValve.version.1.sharedTypes.dataValve.schema.json'
};
