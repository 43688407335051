import { LocaleType } from '../types';

export default function getPathLocale(): LocaleType {
  const currentPath = window.location.pathname;

  const splitedCurrentPath = currentPath
    .split('/')
    ?.filter((v) => typeof v === 'string' && v.length > 0);

  const pathValue = splitedCurrentPath?.[1];
  const countryValue = splitedCurrentPath?.[0];

  const validLanguageLength = 2;
  const isValidLanguage = pathValue && pathValue.length == validLanguageLength;

  const isValidCountry =
    countryValue && countryValue.length == validLanguageLength;

  const languagePath =
    isValidLanguage && isValidCountry ? pathValue : undefined;

  const countryPath = languagePath ? countryValue : undefined;

  return {
    language: languagePath,
    country: countryPath
  };
}
