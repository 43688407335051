import MonitoringInterfaceV1 from './MonitoringInterfaceV1';
import { getServices } from '../../../infra/commonInitializer';
import { SpanType } from '../../../services/monitoringService/types';
import { MonitoringInterfaceV1Type } from './types';

let monitoringInterfaceV1: MonitoringInterfaceV1;

function _createMonitoringInterfaceV1() {
  const services = getServices();
  const { monitoringService } = services;

  return new MonitoringInterfaceV1({
    monitoringService
  });
}

export function getMonitoringInterfaceV1(): MonitoringInterfaceV1 {
  return monitoringInterfaceV1;
}

export default async function initializeMonitoringInterfaceV1(): Promise<MonitoringInterfaceV1Type> {
  if (monitoringInterfaceV1) return monitoringInterfaceV1.getInterface();
  monitoringInterfaceV1 = _createMonitoringInterfaceV1();

  return monitoringInterfaceV1.getInterface();
}

export function createNoopMonitoringInterfaceV1(): MonitoringInterfaceV1Type {
  return {
    getSessionId: function (): string {
      console.debug('Function not implemented.');
      return '';
    },
    startSpan: function (): SpanType {
      console.debug('Function not implemented.');
      return { end: () => {} };
    }
  };
}
