import { SetServiceDependencies } from '../../../infra/commonInitializer/types';
import { IAuthProviderService } from '../../authProviderService';
import IAuthTokenService from '../../authTokenService/IAuthTokenService';
import { IGrantHistoryService } from './IGrantHistoryService';
import IApplicationService from '../../applicationService/IApplicationService';
import { GrantHistoryOptionsType, GrantHistoryType } from './types';
import { GrantHistoryRepository } from './GrantHistoryRepository';
import { AuthContextEnum } from '../../authTokenService';
import { GrantHistoryInputType } from './types';
import IGrantHistoryClient from '../../../clients/stratus/grants/grantsHistory/IGrantHistoryClient';
import { ITenantHandlerService } from '../../tenantHandler';
import { getTroposStackStringByNumber } from '../../../clients/stackUtils';

export class GrantHistoryService implements IGrantHistoryService {
  private _authTokenService: IAuthTokenService;
  private _authProviderService: IAuthProviderService;
  private _applicationService: IApplicationService;
  private _grantHistoryRepository: GrantHistoryRepository;
  private _grantHistoryClient: IGrantHistoryClient;
  private _enabled: boolean;
  private _tenantHandlerService: ITenantHandlerService;

  constructor(props?: GrantHistoryInputType) {
    this._enabled = props?.enabled || false;
  }

  public setDependencies({
    services,
    repositories,
    clients
  }: SetServiceDependencies): void {
    const {
      authProviderService,
      authTokenService,
      applicationService,
      tenantHandlerService
    } = services;
    const { grantHistoryRepository } = repositories;
    const { stratus } = clients;

    this._authProviderService = authProviderService;
    this._authTokenService = authTokenService;
    this._applicationService = applicationService;
    this._grantHistoryRepository = grantHistoryRepository;
    this._grantHistoryClient = stratus.grantHistoryClient;
    this._tenantHandlerService = tenantHandlerService;
  }

  private shouldUpdateCache(
    srcGrants: GrantHistoryType[],
    targetGrants: GrantHistoryType[]
  ): boolean {
    return JSON.stringify(srcGrants) !== JSON.stringify(targetGrants);
  }

  private getGrantsFromCache(authContext: AuthContextEnum): GrantHistoryType[] {
    return this._grantHistoryRepository.findOne(
      this._authTokenService.getSuffix(authContext)
    );
  }

  private saveGrantsToCache(
    grants: GrantHistoryType[],
    authContext: AuthContextEnum
  ): void {
    this._grantHistoryRepository.save(
      this._authTokenService.getSuffix(authContext),
      grants
    );
  }

  private async getGrantsFromClient(
    authContext: AuthContextEnum,
    offset: number = 0
  ): Promise<GrantHistoryType[]> {
    const limit = 50;

    const tenantId = this._tenantHandlerService?.getTenantId();
    const stackNumber = this._applicationService.getAuthStack();
    const stackName = getTroposStackStringByNumber(stackNumber);

    const grants = await this._grantHistoryClient?.getGrantHistory({
      stack: stackName,
      authProvider:
        this._authProviderService.createAuthProviderByAuthContextEnum(
          authContext
        ),
      offset,
      limit,
      tenantId
    });

    let allGrants: GrantHistoryType[] =
      grants?.results?.map((c) => ({
        grant: c.grant,
        level: c.level
      })) || [];

    const totalResultsLength = grants?.totalRecords || 0;
    const pageResultsLength = grants?.results?.length || 0;
    const hasMoreGrantsHistoryPages =
      totalResultsLength > limit && pageResultsLength === limit;

    if (hasMoreGrantsHistoryPages) {
      const newOffset = offset + limit;
      const nextGrants = await this.getGrantsFromClient(authContext, newOffset);
      allGrants = allGrants.concat(...nextGrants);
    }

    return allGrants;
  }

  public async checkGrantsHistory(
    grants: GrantHistoryType[],
    options?: GrantHistoryOptionsType
  ): Promise<boolean> {
    if (!this.isEnabled()) return true;

    const authContext =
      options?.authContext || this._authTokenService.getCurrentContext();

    const contents = this.getGrantsFromCache(authContext);
    return grants?.every?.((grantToCheck) =>
      contents?.some?.((cachedGrant: GrantHistoryType) => {
        const hasLevel = !!grantToCheck?.level;
        const levelTest = hasLevel
          ? grantToCheck.level === cachedGrant.level
          : true;
        return grantToCheck.grant === cachedGrant.grant && levelTest;
      })
    );
  }

  public async haveGrantsHistoryChanged(
    options?: GrantHistoryOptionsType
  ): Promise<boolean> {
    if (!this.isEnabled()) return false;

    const authContext =
      options?.authContext || this._authTokenService.getCurrentContext();

    const grants = await this.getGrantsFromClient(authContext);
    const cachedGrants = this.getGrantsFromCache(authContext);

    if (this.shouldUpdateCache(grants, cachedGrants)) {
      this.saveGrantsToCache(grants, authContext);
      return true;
    }

    return false;
  }

  public isEnabled(): boolean {
    if (!this._enabled) {
      console.warn('Grants History: disabled');
      return false;
    }
    return true;
  }
}
