import { Event } from './event/Event';
import { Schema } from './interfaces/Schema';
import { Originator } from './Originator/Originator';
import { OriginatorContext } from './OriginatorContext/OriginatorContext';

const EVENT_MAJOR_VERSION = '1';
const EVENT_MINOR_VERSION = '3';
const EVENT_PATCH_VERSION = '0';
const EVENT_VERSION = `${EVENT_MAJOR_VERSION}.${EVENT_MINOR_VERSION}.${EVENT_PATCH_VERSION}`;
const EVENT_GUN = `com.hp.cdm.service.eventing.version.${EVENT_MAJOR_VERSION}.resource.notification`;
export class Notifications implements Schema {
  constructor(events: Event[], originator: Originator, originatorContext: OriginatorContext) {
    this.events = events;
    this.originator = originator;
    this.originatorContext = originatorContext;
  }

  events: Event[];
  originator: Originator;
  originatorContext: OriginatorContext;
  version = EVENT_VERSION;

  get gun() {
    return EVENT_GUN;
  }
}
