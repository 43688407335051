// TODO: Check if this is not duplicated in another file
export function getURLSearchParams(
  params?: string | Record<string, any>
): URLSearchParams {
  // URLSearchParams already encode all query params, if provide encoded value it will encode twice
  const decodedParams = {};
  if (typeof params === 'string') {
    let stringParams = params;
    if (stringParams?.startsWith?.('?'))
      stringParams = stringParams?.substring(1);

    const paramsList = stringParams.split('&');

    paramsList.forEach((param) => {
      // Split only the first '='
      const [key, value] = param.split(/=(.*)/s);
      if (key && value) {
        decodedParams[key] = decodeURIComponent(value);
      }
    });
  } else if (typeof params === 'object') {
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (key && value) {
        decodedParams[key] = decodeURIComponent(value);
      }
    });
  }

  return new URLSearchParams(decodedParams);
}
