import { IShellDAO } from '../../../dao/IShellDAO';
import { GrantHistoryType } from './types';
import { AbstractRepository } from '../../AbstractRepository';

export class GrantHistoryRepository extends AbstractRepository<
  GrantHistoryType[]
> {
  constructor({
    shellDAO,
    enableSanitizer = false
  }: {
    shellDAO: IShellDAO;
    enableSanitizer?: boolean;
  }) {
    super({
      shellDAO: shellDAO,
      prefixKey: 'jshellGrantHistory',
      enableSanitizer
    });
  }

  saveAll(): void {
    console.debug('Not supported in GrantRepository.');
    throw new Error('Method not implemented.');
  }

  findAll(id?: string): GrantHistoryType[][] {
    console.debug('Not supported in GrantRepository.');
    throw new Error('Method not implemented.');
  }

  delete(): boolean {
    console.debug('Not supported in GrantRepository.');
    throw new Error('Method not implemented.');
  }
}
