import { INavigationService } from '../../services/navigationService';
import { deleteCookie } from '../../utils/cookies';
import { getServices } from '../../infra/commonInitializer';

export default class RemoveMaxAgeCookieInLoggedIn {
  private _navigationService: INavigationService;

  constructor() {
    const { navigationService } = getServices();
    this._navigationService = navigationService;
  }

  public init(): void {
    if (this._isInLoggedinPath()) {
      deleteCookie('jarvisreactshelllogin__setMaxAge0OnLogin');
    }
  }

  private _isInLoggedinPath(): boolean {
    const pathname = '/loggedin';
    const currentPath = this._navigationService.location.pathname;

    return currentPath?.includes(pathname);
  }
}
