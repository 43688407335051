import { OnboardingInstanceSession } from './newTypes';
import * as T from './types';
import { getServices } from '../../../../../infra/commonInitializer';
import WebServiceRouting from '../../../../../services/webServiceRouting';

interface LegacyStoreManagerConstructorProps {
  storeInterface: T.OnboardingAgentConstructorDependencies['storeInterface'];
}

export default class LegacyStoreManager {
  private _storeInterface: T.OnboardingAgentConstructorDependencies['storeInterface'];
  private _webServiceRouting: WebServiceRouting;

  constructor({ storeInterface }: LegacyStoreManagerConstructorProps) {
    const { webServiceRouting } = getServices();
    this._webServiceRouting = webServiceRouting;
    this._storeInterface = storeInterface;
  }

  updateStore(session: OnboardingInstanceSession): void {
    this._storeInterface.setState((previousState) => ({
      onboarding: {
        ...previousState?.onboarding,
        sessionContext: session?.context?.sessionContext,
        setSidebarVisibility: (enable: boolean) => {
          this._storeInterface.setState((prev) => ({
            ...prev,
            onboarding: {
              ...prev?.onboarding,
              sideBar: {
                ...prev?.onboarding?.sideBar,
                enabled: !!enable
              }
            }
          }));
        },
        updateStageContext: (context: any) => {
          this._storeInterface.setState((prev) => ({
            ...prev,
            onboarding: { ...prev?.onboarding, stageContext: context }
          }));
        },
        nextStage: (resultData, options) => {
          this._webServiceRouting.closeServiceInstance({
            ...options,
            resultData
          });
        }
      }
    }));
  }
}
