import { AuthContextEnum } from '../../services/authTokenService';
import { TenantStrategyEnum } from '../../services/tenantHandler/strategy/strategy';
import { TenantSessionAPIEnum } from './exchange';

export const convertTenantStrategyToSessionAPITenantStrategy = (
  tenantStrategy: TenantStrategyEnum
): TenantSessionAPIEnum => {
  let sessionTenantType;
  switch (tenantStrategy) {
    case TenantStrategyEnum.stratusNative:
      sessionTenantType = TenantSessionAPIEnum.native;
      break;
    case TenantStrategyEnum.stratusCustomer:
      sessionTenantType = TenantSessionAPIEnum.customer;
      break;
    case TenantStrategyEnum.stratusOrganization:
      sessionTenantType = TenantSessionAPIEnum.organization;
      break;
    case TenantStrategyEnum.onecloudOrganization:
      sessionTenantType = TenantSessionAPIEnum.organization;
      break;
    default:
      sessionTenantType = TenantSessionAPIEnum.orgless;
      break;
  }
  return sessionTenantType;
};

export const converAuthContextToSessionAPITenantStrategy = (
  authContextEnum: AuthContextEnum
): TenantSessionAPIEnum => {
  let sessionTenantType;
  switch (authContextEnum) {
    case AuthContextEnum.subtenant:
      sessionTenantType = TenantSessionAPIEnum.customer;
      break;
    case AuthContextEnum.tenant:
      sessionTenantType = TenantSessionAPIEnum.organization;
      break;
    default:
      sessionTenantType = TenantSessionAPIEnum.orgless;
      break;
  }
  return sessionTenantType;
};
