import bindAllMethods from '../../../utils/bindAllMethods';
import { IMonitoringService } from '../../../services/monitoringService';
import { MonitoringInterfaceV1Type } from './types';

type MonitoringInterfaceV1Param = {
  monitoringService: IMonitoringService;
};

class MonitoringInterfaceV1 {
  private _monitoringService: IMonitoringService;

  constructor({ monitoringService }: MonitoringInterfaceV1Param) {
    this._monitoringService = monitoringService;
    bindAllMethods(this);
  }

  public getInterface(): MonitoringInterfaceV1Type {
    return {
      getSessionId: () => this._monitoringService.getSessionId(),
      startSpan: (name, props) => this._monitoringService.startSpan(name, props)
    };
  }
}

export default MonitoringInterfaceV1;
