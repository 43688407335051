import deepMerge from '../../utils/deepMergeObjects';
import { ServiceLocalizationLanguageType } from './types';
import { LocalizationServicesParams } from './LocalizationService';

export const GLOBAL_CONTEXT_KEY = 'JSHELL_GLOBAL_LOCALE_CONTEXT';

export default class LanguageListProvider {
  private _contextLanguageListMap: Record<
    string,
    ServiceLocalizationLanguageType
  > = {};
  private _globalLanguageList: ServiceLocalizationLanguageType;
  private _extendedLanguageList: ServiceLocalizationLanguageType;

  constructor({ localization }: LocalizationServicesParams) {
    // Merge langauges
    let appendContextLanguages = {};
    localization?.contextList?.forEach((value) => {
      const { key, languages } = value;
      this._contextLanguageListMap[key] = languages;
      appendContextLanguages = deepMerge(appendContextLanguages, languages);
    });
    const globalLang = localization?.languages || {};
    this._globalLanguageList = {
      ...globalLang
    };

    this._extendedLanguageList = deepMerge(globalLang, appendContextLanguages);
  }

  /**
   * This method allows to find the current valid language list     .
   * @returns ServiceLocalizationLanguageType Current Language List
   */
  private _getContextLanguageMap(
    languageKey?: string
  ): ServiceLocalizationLanguageType {
    if (this._contextLanguageListMap[languageKey]) {
      return this._contextLanguageListMap[languageKey];
    }

    return {};
  }

  /**
   * To retrieve global language, languageKey must be GLOBAL_CONTEXT_KEY.
   * To retrieve isolated context languages, languageKey must be valid.
   */
  public getLanguageMapByKey(
    languageKey?: string
  ): ServiceLocalizationLanguageType {
    if (languageKey === GLOBAL_CONTEXT_KEY) return this._globalLanguageList;

    if (languageKey) return this._getContextLanguageMap(languageKey);

    return this._globalLanguageList;
  }

  public getExtendedLanguageMap(): ServiceLocalizationLanguageType {
    return this._extendedLanguageList;
  }
}
