import IApplicationService from '../../services/applicationService/IApplicationService';
import { IAuthTokenService } from '../../services/authTokenService';
import { ITenantHandlerService } from '../../services/tenantHandler';
import TenantObserver, {
  TenantEvents
} from '../../services/tenantHandler/TenantObserver';
import { internalLogger } from '../../interface/v1/logger';

export type SetFirstTenantForNativeType = {
  authTokenService: IAuthTokenService;
  tenantHandlerService: ITenantHandlerService;
  applicationService: IApplicationService;
};

export default class SetFirstTenantForNative {
  private _authTokenService: IAuthTokenService;
  private _tenantHandlerService: ITenantHandlerService;
  private _applicationService: IApplicationService;

  constructor({
    authTokenService,
    tenantHandlerService,
    applicationService
  }: SetFirstTenantForNativeType) {
    this._authTokenService = authTokenService;
    this._tenantHandlerService = tenantHandlerService;
    this._applicationService = applicationService;
  }

  public async init(): Promise<void> {
    TenantObserver.subscribe(
      TenantEvents.SET_TENANT_HANDLER_KEY,
      this.setFirstTenant
    );
  }
  public setFirstTenant = async (): Promise<void> => {
    const isJShell = !!this._applicationService?.getClientId();
    const tokenTenantId = this._authTokenService?.getTenantIdFromToken();
    const shellTenantId = this._tenantHandlerService?.getTenantId();
    if (isJShell && tokenTenantId && !shellTenantId) {
      internalLogger?.debug?.(
        'Setting in ClientOS the following tenant:',
        tokenTenantId
      );
      return this._tenantHandlerService?.setInitialTenants([
        {
          id: tokenTenantId,
          level: this._tenantHandlerService?.START_TENANT_LEVEL
        }
      ]);
    }
  };
}
