import { CDMObject, ValveFilterError, ValveFilterErrorType } from '@jarvis/jweb-core';
import { sanitize } from '../CDMFilter/sanitize';
import getTelemetryConsent from '../client/utils/consents';
import { ERROR_INVALID_BINDINGS, ERROR_INVALID_BINDINGS_DETAIL, ERROR_UNEXPECTED } from '../dataCollectionService/dataCollectionServiceTypes';
import { sanitizeTCCS } from '../TCCSFilter/sanitize';
import { DataValveFilterError } from './dataValveError';
import { logger } from './logger';

export const oneTrustFiltering = (cdmObjects: (ValveFilterError | CDMObject)[], consent: string) => {
  let isTelemetryAllowed = false;
  try {
    isTelemetryAllowed = getTelemetryConsent(consent);
  } catch (error: any) {
    if (error instanceof DataValveFilterError){
      throw error;
    } else {
      throw new DataValveFilterError(ValveFilterErrorType.treeNotAllowed,error.message,'Invalid webAppConsent', error);
    }
  }
  const filteredResults = cdmObjects.map(cdmObject => {
    if ((cdmObject as ValveFilterError).errorType) {
      return cdmObject;
    }
    if ((cdmObject as CDMObject).treeGun !== 'com.hp.cdm.service.eventing.version.1.resource.notification' || !isTelemetryAllowed) {
      return new DataValveFilterError(ValveFilterErrorType.treeNotAllowed,'ResourceId is invalid or Telemetry consent provided is not allowed','');
    }
    return cdmObject;
  });
  return filteredResults;
};

export const valveControllerFiltering = (cdmObjects: (ValveFilterError | CDMObject)[], bindings: object) => {
  const filteredResults = cdmObjects.map(cdmObject => {
    if (Object.keys(bindings).length !== 0) {
      try {
        if ((cdmObject as ValveFilterError).errorType) {
          return cdmObject;
        }
        const notification = JSON.parse((cdmObject as CDMObject).tree);
        const notificationObject = JSON.stringify(notification);
        const sanitizedObject = sanitize(notification, (cdmObject as CDMObject).treeGun, bindings, notificationObject, false);
        logger.log('FilteringMethods::ValveControllerFiltering::Sanitized object:', sanitizedObject.sanitizedNode);
        (cdmObject as CDMObject).tree = JSON.stringify(sanitizedObject.sanitizedNode);
        return cdmObject;
      } catch (error: any) {
        throw new DataValveFilterError(ValveFilterErrorType.treeNotAllowed,ERROR_UNEXPECTED, error);
      }
    } else {
      throw new DataValveFilterError(ValveFilterErrorType.valveControllerAPIError,ERROR_INVALID_BINDINGS,ERROR_INVALID_BINDINGS_DETAIL);
    }
  });
  return filteredResults;
};

const tccsFilterCDMObject = (cdmObject: ValveFilterError | CDMObject, bindings: object): ValveFilterError | CDMObject => {
  if ((cdmObject as ValveFilterError).errorType) {
    return cdmObject;
  }

  try {
    const notification = JSON.parse((cdmObject as CDMObject).tree);
    const tcssFilteredObject = sanitizeTCCS(notification, (cdmObject as CDMObject).treeGun, bindings);
    logger.log('FilteringMethods::tccsFiltering::Sanitized object:', tcssFilteredObject);
    (cdmObject as CDMObject).tree = JSON.stringify(tcssFilteredObject);
    return cdmObject;
  } catch (error: any) {
    throw new DataValveFilterError(ValveFilterErrorType.treeNotAllowed, ERROR_UNEXPECTED, error);
  }
};

export const tccsFiltering = (cdmObjects: (ValveFilterError | CDMObject)[], bindings: object) => {
  if (Object.keys(bindings).length === 0) {
    throw new DataValveFilterError(ValveFilterErrorType.valveControllerAPIError, ERROR_INVALID_BINDINGS, ERROR_INVALID_BINDINGS_DETAIL);
  }

  return cdmObjects.map(cdmObject => tccsFilterCDMObject(cdmObject, bindings));
};
