import { IShellDAO } from '../../dao/IShellDAO';
import { TokenContextUtils } from '../../utils/tokenUtils/TokenContextUtils';
import {
  PlanInfoDetailsType,
  ProgramInfoMapPropertyType
} from '../../clients/stratus/entitlements/getProgramInfo/types';

export type EntitlementsDAOItemType = {
  key: string;
  value: EntitlementStorageType;
};

export type EntitlementStorageType = {
  planInfo: PlanInfoDetailsType;
  programInfoMap: {
    [key: string]: ProgramInfoMapPropertyType;
  };
};

export class EntitlementRepository {
  private shellDAO: IShellDAO;
  private prefixKey = 'jshellEntitlements';

  constructor({ shellDAO }) {
    this.shellDAO = shellDAO;
  }

  save(sufix: string, entitlementStorage: EntitlementStorageType): void {
    const _id = TokenContextUtils.getKey(this.prefixKey, sufix);
    this.shellDAO.save(_id, entitlementStorage);
  }

  findOne(sufix: string): EntitlementStorageType {
    const _id = TokenContextUtils.getKey(this.prefixKey, sufix);
    return this.shellDAO.findOne(_id) || undefined;
  }

  delete(sufix: string): boolean {
    const _id = TokenContextUtils.getKey(this.prefixKey, sufix);
    return this.shellDAO.delete(_id);
  }

  clear(): void {
    const items = this.shellDAO.findAll({
      prefixKey: this.prefixKey
    }) as EntitlementsDAOItemType[];
    const filteredItemKeys = [];
    items.forEach((t) => {
      if (t.key.startsWith(this.prefixKey)) filteredItemKeys.push(t.key);
    });
    this.shellDAO.deleteAll(filteredItemKeys);
  }
}
