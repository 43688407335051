import { IServiceWorkerService } from '../../../services/serviceWorkerService';
import bindAllMethods from '../../../utils/bindAllMethods';
import { ServiceWorkerServiceTypeNames } from '../../../services/serviceWorkerService/constants';
import * as T from './types';

type ServiceWorkerParams = {
  serviceWorkerService: IServiceWorkerService;
};

class ServiceWorkerInterface implements T.ServiceWorkerInterfaceV1Type {
  private _serviceWorkerService: IServiceWorkerService;
  typeNames = ServiceWorkerServiceTypeNames;

  constructor({ serviceWorkerService }: ServiceWorkerParams) {
    this._serviceWorkerService = serviceWorkerService;

    bindAllMethods(this);
  }

  public async clearCache(options: T.clearCacheOptionsParams): Promise<void> {
    this._serviceWorkerService.clearCache({
      url: options.url
    });
  }

  public getInterface(): T.ServiceWorkerInterfaceV1Type {
    return {
      clearCache: this.clearCache
    };
  }
}

export default ServiceWorkerInterface;
