import TenantObserver, {
  TenantEvents
} from '../../../services/tenantHandler/TenantObserver';
import { ITenantHandlerService } from '../../../services/tenantHandler';
import { LastTenantIdsRepository } from './LastTenantIdsRepository';
import TenantRetriever from './TenantRetriever';
import TabHelpers from './Helpers';
import TenantSetter from './TenantSetter';
import { ISessionService } from '../../../services/session';
import IApplicationService from '../../../services/applicationService/IApplicationService';
import AuthenticationProviderEnum from '../../../config/authenticationProviderEnum';
import { getServices } from '../../../infra/commonInitializer';
import { isInLoginPath } from '../../../utils/pathUtils/isInPath';
import { internalLogger } from '../../../interface/v1/logger';

type MultipleTabsDeamonOptions = {
  repository: LastTenantIdsRepository;
  isNative: boolean;
};

export default class MultipleTabsDeamon {
  private _tenantHandlerService: ITenantHandlerService;
  private _repository: LastTenantIdsRepository;
  private _tenantRetriever: TenantRetriever;
  private _tenantSetter: TenantSetter;
  private _sessionService: ISessionService;
  private _applicationService: IApplicationService;
  private _isNative: boolean;
  private _queryParamsEnabled: boolean;

  constructor({ repository, isNative }: MultipleTabsDeamonOptions) {
    const {
      tenantHandlerService,
      sessionService,
      authTokenService,
      applicationService,
      navigationService
    } = getServices();
    this._tenantHandlerService = tenantHandlerService;
    this._sessionService = sessionService;
    this._applicationService = applicationService;
    this._repository = repository;
    this._isNative = isNative;

    // For Coptor, it is being disabled by default.
    this._queryParamsEnabled =
      this._applicationService.getAuthenticationProvider() !=
      AuthenticationProviderEnum.coptor;

    this._tenantSetter = new TenantSetter({
      navigationService,
      repository,
      queryParamsEnabled: this._queryParamsEnabled
    });
    this._tenantRetriever = new TenantRetriever({
      navigationService,
      tenantHandlerService,
      authTokenService,
      applicationService,
      repository,
      queryParamsEnabled: this._queryParamsEnabled
    });
  }

  private _isInLoginPath(): boolean {
    return isInLoginPath();
  }

  public async init(): Promise<void> {
    if (!this._tenantHandlerService?.isEnabled()) {
      return;
    }

    if (this._isNative) {
      internalLogger?.debug?.('Multitab is disabled in Native Environment.');
      return;
    }

    /*
     * 1. When the user is not logged yet is expected the repository would be empty, based on cleanUpEnvironment behavior.
     **/

    const isLoggedIn = this._sessionService.isLoggedIn();

    /*
     * 2. Nevertheless, in /login path, if there are tenant IDs in the query parameters, we set these tenants IDs in the last tenant IDs repository and updates the URL removing the tenant IDs.

     * When queryParamsEnabled is false,
     * this._tenantRetriever.hasTenantOnQueryParams() will always return false.
    * */
    if (
      this._isInLoginPath() &&
      this._tenantRetriever.hasTenantOnQueryParams()
    ) {
      this.overrideReposityTenantsFromQueryParams();
      return;
    }

    /*
     * 2. When the user is logged in:
     *  a. Setting the Candidate Tenant
     *  b. Subscribe to the TenantEvents.SET_TENANT event to update the last tenant
     */
    if (isLoggedIn) {
      // Update the tenant params in the URL in ever navigation push.
      if (this._queryParamsEnabled) TabHelpers.old_persistTenantQueryParam();

      this.setInitialTenantBasedOnLastTenant();

      this._eventSubscriptions();
    }
  }

  /**
   * This is a feature to retrieve the tenant from QueryParams and setting it as priority over Repository. */
  private overrideReposityTenantsFromQueryParams = () => {
    const fromQueryparams =
      this._tenantRetriever.retrieveTenantListFromQueryParams();
    const fromRepo =
      this._tenantRetriever.retrieveLastTenantsIdFromRepository();
    const tenantIdList = this._tenantRetriever.mergeTenants(
      fromQueryparams,
      fromRepo
    );
    this._repository.save(tenantIdList);

    // Cleaning the QueryParams avoids the tenant IDs to be duplicated during the redirects to identity provider.
    // Disabling to force retry if the exchangeToken gets 400
    //TabHelpers.removeTenantsFromQueryParams();
  };

  private _eventSubscriptions = () => {
    // 1. When the SET_TENANT_HANDLER_KEY is called, the ClientOS is ready to set the tenant.
    TenantObserver.subscribe(
      TenantEvents.SET_TENANT_HANDLER_KEY,
      this.setTenantsFromInitialTenants
    );

    // 2. Subscribe to the TenantEvents.SET_TENANT event to receive updates when the tenant was set.
    TenantObserver.subscribe(
      TenantEvents.SET_TENANT,
      this._tenantSetter.cb_updateLastTenantData
    );
  };

  // Here we set the behavior for effectively set the tenant,
  // but ONLY when the jshell-commons finishes its routering.
  /*
   * Every time a page refresh ocourrs:
   * Case #1: If contains a tenant in URL (candidate tenant)
   *   It will be set as the initial tenant. In this case, the
   *   LastTenent Repository contains the last valid tenant.
   *
   * Case #2: If doesn't contain in URL and contains in Repository.
   *    Get from the Repository and set as the initial tenant.
   *
   * Case #3: if doesn't contain any tenant in URL and Repostory.
   *    The initial Tenant will be empty.
   *    For Web: The Tenant Selector Operation will handle it.
   *    For Native: We need to set the TenantId to be exchange (since there is no tenant selector)
   */
  private setInitialTenantBasedOnLastTenant() {
    let tenantIdList =
      this._tenantRetriever.retrieveTenantListFromQueryParams();

    if (!tenantIdList.length)
      tenantIdList =
        this._tenantRetriever.retrieveLastTenantsIdFromRepository();

    this._tenantHandlerService?.setInitialTenants(tenantIdList);
  }

  /*
   *   This method triggers the setTenant in the Tenant Handler Service if applicable.
  TODO: This method should be placed in a better service or behavior.
   *  */
  public setTenantsFromInitialTenants = async (): Promise<boolean> => {
    // 1. Get the Candidates from the Initial Tenants;
    const candidateTenantList = this._tenantRetriever.retrieveInitialTenants();

    // 2. There is no tenant to set. Probably the Tenant Selector Operation will handle from now on.
    if (!candidateTenantList?.length) return false;

    // 3. Now, let's set the candidate tenant.
    const options = { reload: false };

    internalLogger?.debug?.(
      'Entering in tenant:',
      JSON.stringify(candidateTenantList)
    );

    const tenantIdList = [] as string[];

    TabHelpers.removeTenantsFromQueryParams();

    for (const tenant of candidateTenantList) {
      tenantIdList.push(tenant.id);
      const tenantSuffix = tenantIdList.join(
        this._tenantHandlerService.TENANT_SUFFIX_CONCAT_STRING
      );
      const alreadyHasTokenForTenant =
        this._tenantHandlerService?.checkIfTenantIsStoredBySuffix(tenantSuffix);

      if (!alreadyHasTokenForTenant) {
        // The tenantHandlerInterface.setTenant is only called when the user doesn't always contains its token.
        internalLogger?.debug?.(
          'The user doesnt contains the tenant token, lets call setTenant to tenant:',
          tenant.id
        );

        await this._tenantHandlerService?.setTenant(
          tenant.id,
          tenant.level,
          options,
          undefined
        );

        return true;
      }
      if (alreadyHasTokenForTenant) {
        internalLogger?.debug?.(
          'The user already contains the tenant token for tenant: ',
          tenant.id
        );
        // It's a valid tenant, probably we already enter in there.
        // So let's sync our URL and last reposity with that.
        this._tenantSetter.updateLastTenantOnURL([tenant]);
        this._tenantSetter.updateLastTenantOnRepository([tenant]);

        return true;
      }
    }
  };
}
