import { JarvisWebHttpClient } from '@jarvis/web-http';
import {
  customerIdName,
  enableAST,
  organizationIdName,
  tenantIdName
} from '../../config/constants';
import { TokenType, UserContextEnum } from '../../interface/types';
import { internalLogger } from '../../interface/v1/logger';
import {
  getJWeb,
  isNative,
  JWebErrorHandler,
  writeStratusCookieDataToValueStore,
  JWebEnums
} from '../../services/JWeb';
import * as JWebTypes from '../../services/JWeb/types';
import { deleteCookie, getCookie, setCookie } from '../../utils/cookies';
import { getStratusAccessToken } from '../../utils/getStratusAccessToken';

import { decodeJWTPayload } from '../../utils/tokenUtils/JWTUtils';
import {
  getItemLocalStorage,
  removeItemLocalStorage,
  setItemLocalStorage
} from '../../utils/toBeRemoved/localStorage';

import { ISessionService } from '../../services/session';

let _sessionService: ISessionService;

export type InitDeprecatedSessionSvc = {
  sessionService: ISessionService;
};

const jarvisWebHttpNoProvider = new JarvisWebHttpClient();

export const initDeprecatedSessionSvc = async ({
  sessionService
}: InitDeprecatedSessionSvc): Promise<void> => {
  _sessionService = sessionService;
};

/**
 * @deprecated Use supportSessionService instead. Soon,it will be deprecated.
 */
export const isAstSession = (): boolean => {
  if (!getItemLocalStorage(enableAST)) return false;
  const astToken = getCookie(TokenType.astToken, false);
  return astToken === 'true';
};

/**
 * @deprecated Use v1.sessionService instead. Soon, getSessionData() will be deprecated.
 */
export const getSessionData = async () => {
  const result = {
    isLoggedIn: false
  };

  if (await isNative()) {
    await setStratusAccessTokenFromNative();
    const accessTokenCookie = getStratusAccessToken(
      TokenType.stratusAccessToken
    );
    result.isLoggedIn = !!accessTokenCookie;
  } else {
    result.isLoggedIn = !!_sessionService?.isLoggedIn?.();
    if (!result?.isLoggedIn) clearUserData();
  }

  internalLogger.log('getSessionData', result);
  return result;
};

/**
 * @deprecated Use v1.authTokenService instead. Soon, storeUserTokens() will be deprecated.
 */
export const storeUserTokens = (data: any) => {
  internalLogger.log('storeUserTokens');

  if (!data) return; // allow compatibility with old version of API

  if (data.stratusTokenType === UserContextEnum.organization) {
    setItemLocalStorage(TokenType.orgedToken, data.stratusToken);
  }

  //for now there's no handle for stratusTokenType as 'customer'
};

/**
 * @deprecated Use the SessionClient instead. Soon, getTenantTokenWithoutStore() will be deprecated.
 */
export const getTenantTokenWithoutStore = async (
  tokenType: UserContextEnum,
  tenantId: string,
  token: string
) => {
  const { data } = await jarvisWebHttpNoProvider.post({
    url: `/api/session/v2/exchangetoken/tenants/${tenantId}`,
    params: { tokenType },
    headers: {
      Authorization: `Bearer ${token}`
    },
    retries: 0
  });
  return data;
};

/**
 * @deprecated Use the v1.sessionService instead. Soon, exchangeTenancyToken() will be deprecated.
 */
export const exchangeTenancyToken = async (tokenType: UserContextEnum) => {
  internalLogger.log('exchangeTenancyToken');

  if (isAstSession()) return;

  if (await isNative()) {
    await setStratusAccessTokenFromNative();
  } else {
    try {
      const { data } = await jarvisWebHttpNoProvider.post({
        url: '/api/session/v2/exchangetenanttoken',
        params: { tokenType }
      });
      storeUserTokens(data);
    } catch (error) {
      internalLogger?.error(error);
      // retrying in API v1
      await jarvisWebHttpNoProvider.post({
        url: '/api/session/v1/exchangetenanttoken',
        params: { tokenType }
      });
    }
  }
};

/**
 * @deprecated Use the v1.sessionService instead. Soon, exchangeToken() will be deprecated.
 */
export const exchangeToken = async (tokenType: UserContextEnum) => {
  internalLogger.log('exchangeToken');
  if (await isNative()) {
    await setStratusAccessTokenFromNative();
  } else {
    try {
      const { data } = await jarvisWebHttpNoProvider.post({
        url: '/api/session/v2/exchangetoken',
        params: { tokenType }
      });
      storeUserTokens(data);
    } catch (error) {
      internalLogger?.error(error);
      // retrying in API v1
      await jarvisWebHttpNoProvider.post({
        url: '/api/session/v1/exchangetoken',
        params: { tokenType }
      });
    }
  }
};

let refreshTokenPromise: Promise<void>;

/**
 * @deprecated Use the v1.sessionService instead. Soon, refreshToken() will be deprecated.
 */
export const refreshToken = async () => {
  function getDecodedUserStratusJWTPayload() {
    const userToken = getStratusAccessToken(TokenType.stratusAccessToken);
    if (!userToken) return undefined;

    return decodeJWTPayload(userToken);
  }

  const userStratusToken = getDecodedUserStratusJWTPayload();
  const tenantId = userStratusToken?.tenant_id || undefined;
  const stratusId = userStratusToken?.stratus_id || undefined;
  const wpIdUserId = userStratusToken?.wpid || undefined;
  const hpIdUserId = userStratusToken?.sub || undefined;

  if (refreshTokenPromise) return refreshTokenPromise;
  internalLogger.log('refreshToken');

  const action = async () => {
    const sessionStartedAsAST = getItemLocalStorage(enableAST);

    if (isAstSession()) return;

    if (sessionStartedAsAST && !isAstSession()) {
      clearUserData();
      window.location.href = '/';
    }
    if (await isNative()) {
      await setStratusAccessTokenFromNative();
    } else {
      try {
        const { data } = await jarvisWebHttpNoProvider.post({
          url: '/api/session/v2/token'
        });
        if (!data) return;
        setItemLocalStorage(
          TokenType.orglessToken,
          data[TokenType.orglessToken]
        );
        await writeStratusCookieDataToValueStore(
          stratusId,
          tenantId,
          wpIdUserId,
          hpIdUserId
        );
        setItemLocalStorage(TokenType.orgedToken, data[TokenType.orgedToken]);
      } catch (error) {
        internalLogger?.error(error);
        // retrying in API v1
        await jarvisWebHttpNoProvider.post({
          url: '/api/session/v1/token'
        });
        await writeStratusCookieDataToValueStore(
          stratusId,
          tenantId,
          wpIdUserId,
          hpIdUserId
        );
      }
    }
  };

  refreshTokenPromise = action().finally(() => {
    refreshTokenPromise = undefined;
  });

  return refreshTokenPromise;
};

/**
 * @deprecated Use the v1.sessionService instead. Soon, clearUserData() will be deprecated.
 */
const clearUserData = () => {
  internalLogger.log('clearUserData');

  deleteCookie(tenantIdName);
  removeItemLocalStorage(organizationIdName);
  removeItemLocalStorage(customerIdName);
  removeItemLocalStorage(TokenType.orglessToken);
  removeItemLocalStorage(TokenType.orgedToken);
  removeItemLocalStorage(enableAST);

  // Removing deprecrated cookies
  deleteCookie(TokenType.deprecatedOrglessToken, '/', false);
  deleteCookie(TokenType.deprecatedOrgedToken, '/', false);
};

/**
 * @deprecated Use the v1.sessionService instead. Soon, clearSession() will be deprecated.
 */
export const clearSession = async (stack: any) => {
  internalLogger.log('clearSession');
  if (await isNative()) {
    const Auth = await getJWeb()?.then?.((res) => res?.Plugins?.Auth);
    await Auth.logout().then((v) => JWebErrorHandler(v));
    await setStratusAccessTokenFromNative();
  } else {
    let redirectTo = '/loggedout';
    const astCookie = getCookie(TokenType.astToken, false) === 'true';
    const astStorageEnable = localStorage.getItem(enableAST) === 'true';
    if (astStorageEnable || astCookie) {
      redirectTo = `${location.origin}/api/session/v1/logout?state=ast`;
    } else {
      const isEqualTo = (...args: any[]) =>
        args.some((v) => {
          return typeof stack === 'string' ? stack.startsWith(v) : stack === v;
        });
      const baseUrlSessionApi = `${location.origin}/api/session/v1/logout`;
      // TODO move to utils
      const baseUrlAuthz = (() => {
        if (isEqualTo('stage', 2)) {
          return 'https://authz.stage.api.ws-hp.com/openid/v1/logout';
        } else if (isEqualTo('prod', 3)) {
          return 'https://authz.api.ws-hp.com//openid/v1/logout';
        } else {
          return 'https://pie.authz.wpp.api.hp.com/openid/v1/logout';
        }
      })();
      const stratusId =
        getCookie(TokenType.stratusIdToken) ||
        getCookie(TokenType.deprecatedstratusIdToken);
      redirectTo = `${baseUrlAuthz}?id_token_hint=${stratusId}&post_logout_redirect_uri=${baseUrlSessionApi}&state=shell`;
    }
    clearUserData();
    return new Promise(function (resolve, reject) {
      window.location.href = redirectTo;
    });
  }
};

export async function setStratusAccessTokenFromNative() {
  internalLogger.log('setStratusAccessTokenFromNative');
  if (await isNative()) {
    const Auth = await getJWeb()?.then?.((res) => res?.Plugins?.Auth);

    const { tokenValue, expiresAt } =
      (await Auth?.getToken?.({
        tokenProviderOptions: {
          requireFreshToken: true,
          allowUserInteraction: false,
          tokenType: JWebEnums.TokenType.user
        }
      }).then((v) => JWebErrorHandler<JWebTypes.AccessToken>(v))) || {};

    const haveAccessToken =
      typeof tokenValue === 'string' && tokenValue.length > 1;
    if (haveAccessToken) {
      // TODO: take a look on expiresAt format
      setCookie(TokenType.stratusAccessToken, tokenValue, expiresAt);
    } else {
      // TODO: check why we should remove the stratus cookie
      deleteCookie(TokenType.stratusAccessToken);
    }
  }
}
