import bindAllMethods from '../../utils/bindAllMethods';
import AuthContextEnum from './AuthContextEnum';
import AuthTokenRepository from './AuthTokenRepository';
import IAuthTokenService from './IAuthTokenService';
import { AuthTokenStorageType } from './types';
import { decodeJWTPayload } from '../../utils/tokenUtils/JWTUtils';
import type ISupportSessionService from '../supportSession/ISupportSessionService';
import type ITenantHandlerService from '../tenantHandler/ITenantHandlerService';
import type IGetAccessTokenService from './getAcessTokenService/IGetAccessTokenService';
import { inject, singleton } from 'tsyringe';
import { TenantHandlerService } from '../tenantHandler';
import { SupportSessionService } from '../supportSession';

@singleton()
class AuthTokenService implements IAuthTokenService {
  private _getAccessTokenService: IGetAccessTokenService;
  private _authTokenRepository: AuthTokenRepository;
  private _tenantHandlerService: ITenantHandlerService;
  private _supportSessionService: ISupportSessionService;

  constructor(
    @inject(TenantHandlerService)
    tenantHandlerService: ITenantHandlerService,
    @inject(AuthTokenRepository) authTokenRepository: AuthTokenRepository,
    @inject(SupportSessionService)
    supportSessionService: ISupportSessionService,
    @inject('IGetAccessTokenService')
    getAccessTokenService: IGetAccessTokenService
  ) {
    this._tenantHandlerService = tenantHandlerService;
    this._authTokenRepository = authTokenRepository;
    this._supportSessionService = supportSessionService;
    this._getAccessTokenService = getAccessTokenService;
    bindAllMethods(this);
  }

  public getTenantIdFromGivenToken(token: string): string {
    const decodedToken = decodeJWTPayload(token);
    const tenantId =
      decodedToken?.tenant_id || decodedToken?.tenants?.[0]?.tenant_id;
    return tenantId?.trim();
  }

  public getTenantIdFromToken(authContext?: AuthContextEnum): string {
    const jwt = this.getToken(authContext)?.token;
    const decodedToken = decodeJWTPayload(jwt);
    const tenantId =
      decodedToken?.tenant_id || decodedToken?.tenants?.[0]?.tenant_id;
    return tenantId?.trim();
  }

  public getFqTenantIdFromToken(authContext?: AuthContextEnum): string {
    const jwt = this.getToken(authContext)?.token;
    const decodedToken = decodeJWTPayload(jwt);
    const fqTenantId =
      decodedToken?.fq_tenant_id || decodedToken?.tenants?.[0]?.fq_tenant_id;
    return fqTenantId?.trim();
  }

  public getCurrentContext(): AuthContextEnum {
    return this._tenantHandlerService.getCurrentContext();
  }

  public getToken(authContext?: AuthContextEnum): AuthTokenStorageType {
    if (this._supportSessionService.isSupportSession()) {
      return {
        context: AuthContextEnum.tenant,
        token: this._supportSessionService.getSupportSessionToken()
      } as AuthTokenStorageType;
    }
    const accessTokenOutput =
      this._getAccessTokenService.getAccessToken(authContext);
    return {
      token: accessTokenOutput.token,
      context: accessTokenOutput.authContext
    } as AuthTokenStorageType;
  }

  public decodeJWT(token: string): object {
    return decodeJWTPayload(token);
  }

  public setToken(token: string, authContext: AuthContextEnum): void {
    if (!token) {
      console.error('You cannot set an empty token');
      throw new Error('You cannot set an empty token');
    }
    if (this._supportSessionService.isSupportSession()) {
      return;
    }
    const authToken = { token, context: authContext } as AuthTokenStorageType;
    this._clearTokensFromUpperLevels(authContext);
    const sufix = this.getSuffix(authContext);
    this._authTokenRepository.save(sufix, authToken);
  }

  public deleteToken(authContext: AuthContextEnum): void {
    const sufix = this.getSuffix(authContext);
    this._authTokenRepository.delete(sufix);
  }

  public clear(): void {
    this._authTokenRepository.clear();
  }

  public getSuffix(authContext: AuthContextEnum): string {
    return this._getAccessTokenService.getSuffix(authContext);
  }

  // TODO: Need to be here until tenantHandler be the only way to deal with tenants
  private _clearTokensFromUpperLevels(authContext: AuthContextEnum) {
    if (this._tenantHandlerService?.isEnabled()) return;
    if (authContext === AuthContextEnum.subtenant) {
      return;
    }
    if (authContext === AuthContextEnum.tenant) {
      this.deleteToken(AuthContextEnum.subtenant);
    } else if (authContext === AuthContextEnum.tenantless) {
      this.deleteToken(AuthContextEnum.tenant);
      this.deleteToken(AuthContextEnum.subtenant);
    }
  }
}

export default AuthTokenService;
