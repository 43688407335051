import { LocaleType } from '../types';

export default function getNavigatorLocale(): LocaleType {
  const fullLangueName = window.navigator.languages?.find((e) => {
    const includeLangue = e.includes(window.navigator.language);

    return includeLangue;
  });
  const [language, country] = fullLangueName
    ? fullLangueName.split('-', 2)
    : [];

  return {
    language,
    country
  };
}
