import { GenerateAuthenticationUrlParams } from 'src/services/session/loginService';
import { NewLoginServiceType } from './types';

export async function _createOptions(
  postLoginRedirect: string,
  nextUrl: string,
  services: NewLoginServiceType
): Promise<GenerateAuthenticationUrlParams> {
  const { localizationService } = services;
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email') || '';

  const options: GenerateAuthenticationUrlParams = {
    postLoginRedirect,
    authorizationUrl: nextUrl
  };

  if (localizationService?.enabled) {
    options.country = localizationService.country || undefined;
    options.language = localizationService.language || undefined;
  }

  if (email) {
    options.target = 'password';
  }

  return options;
}
