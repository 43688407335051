import optimizely from '../../interface/v1/optimizely';
import bindAllMethods from '../../utils/bindAllMethods';
import DynamicYieldClient from './client/dynamicYieldClient';
import IABTestingClient from './IABTestingClient';
import IABTestingService from './IABTestingService';
import { ABTestingServiceParams } from './types';

export class ABTestingService implements IABTestingService {
  private readonly _snippetId: string;
  private readonly _clientName: string;
  private readonly _enabled: boolean;
  private _currentClient?: IABTestingClient;

  constructor({ enabled, clientName, snippetId }: ABTestingServiceParams) {
    this._enabled = enabled;
    this._snippetId = snippetId;
    this._clientName = clientName;
    this.init();

    bindAllMethods(this);
  }

  private isDynamicYieldEnabled(): boolean {
    return (
      this._enabled && this._clientName === 'dynamicYield' && !!this._snippetId
    );
  }

  private isOptimizelyEnabled(): boolean {
    return (
      this._enabled && this._clientName === 'optimizely' && !!this._snippetId
    );
  }

  public init(): void {
    if (this.isDynamicYieldEnabled()) {
      this._currentClient = new DynamicYieldClient({
        snippetId: this._snippetId
      });
      this._currentClient.init();
    } else if (this.isOptimizelyEnabled()) {
      optimizely({
        snippetId: this._snippetId,
        enable: this._enabled
      });
    }
  }

  public isEnabled(): boolean {
    return this._enabled;
  }
}
