import { EventServiceValueType } from '../../../services/eventService/types';
import { internalLogger } from '../../../interface/v1/logger';
import IEventService from '../../../services/eventService/IEventService';
import { INavigationService } from '../../../services/navigationService';
import matchPath from '../../../utils/matchPath';

export default class EventsBehaviors {
  private _eventService: IEventService;
  private _navigationService: INavigationService;

  constructor(options: {
    eventService: IEventService;
    navigationService: INavigationService;
  }) {
    this._eventService = options.eventService;
    this._navigationService = options.navigationService;
  }

  public init(): void {
    this._navigationEvents();
  }

  private _navigationEvents() {
    // Triggerers
    const shellUrlChanged = () => {
      // TODO: I have no idea how this works before Navigation init() call.
      let previousLocation = this._navigationService.location;

      this._navigationService.listen((location, action) => {
        const eventData = {
          currentLocation: location,
          previousLocation,
          action
        };

        this._eventService.publish(
          this._eventService.eventNames.shellUrlChanged,
          eventData
        );

        internalLogger?.log?.('url-changed: ', eventData);

        previousLocation = location;
      });
    };

    const shellCallInterfaceNavigationPush = () => {
      const navigationPushEventHandler = (event: EventServiceValueType) => {
        const navigation = this._navigationService;

        let path = event?.eventData?.properties?.servicePath;

        const isSamePath = matchPath(navigation.location.pathname, {
          exact: true,
          pathToCompare: path
        });

        if (
          isSamePath &&
          event?.eventData?.properties?.keepQueryParamsInSamePath
        ) {
          const newUrl = new URL(
            (path + navigation.location.search)
              .replace(/[?]/g, '&') // replace all ? with &
              .replace('&', '?'), // replace first & with ?
            window.location.origin
          );

          path = newUrl.pathname + `?${newUrl.searchParams.toString()}`;
        }

        internalLogger?.log?.(
          `pushing-to-path: ${path} from: ${navigation.location.pathname}`
        );

        navigation?.push?.(path);
      };

      this._eventService.subscribe(
        this._eventService.eventNames?.shellCallInterfaceNavigationPush,
        navigationPushEventHandler
      );
    };

    const clientosCallInterfaceNavigationGoBack = () => {
      const navigationGoBackEventHandler = () => {
        const navigation = this._navigationService;
        navigation.goBack();
      };

      this._eventService.subscribe(
        this._eventService.eventNames?.clientosCallInterfaceNavigationGoBack,
        navigationGoBackEventHandler
      );
    };

    shellUrlChanged();
    shellCallInterfaceNavigationPush();
    clientosCallInterfaceNavigationGoBack();
  }
}
