import { ServiceLocalizationLanguageType } from '../types';
import { TranslatorFunctionResourcePropType } from '../ILocalizationTranslatorService';

type GlobalResourcesUrlsProps = {
  locales: ServiceLocalizationLanguageType;
  globalResources?: TranslatorFunctionResourcePropType;
  useGlobalTranslation?: boolean;
};

export const createGlobalResourcesUrls = ({
  locales,
  globalResources,
  useGlobalTranslation
}: GlobalResourcesUrlsProps): TranslatorFunctionResourcePropType => {
  if (!useGlobalTranslation) {
    return {};
  }

  if (globalResources) {
    return globalResources;
  }

  const resourceList = {};

  if (typeof locales !== 'object') {
    return {};
  }

  for (const thisLanguage in locales) {
    const countryList = locales?.[thisLanguage];
    if (Array.isArray(countryList)) {
      countryList.forEach((thisCountry) => {
        if (typeof thisCountry === 'string') {
          const thisLocale = `${thisLanguage.toLocaleLowerCase()}_${thisCountry.toLocaleUpperCase()}`;
          resourceList[thisLocale] = `/assets/locale/${thisLocale}.json`;
        }
      });
    }
  }

  return resourceList;
};
