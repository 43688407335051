import {
  AuthContextEnum,
  IAuthTokenService
} from '../../services/authTokenService';
import { getCookie } from '../../utils/cookies';
import { ISupportSessionService } from '../../services/supportSession';
import { TokenType } from '../../interface/types';

type SetSupportSessionTokenParams = {
  supportSessionService: ISupportSessionService;
  authTokenService: IAuthTokenService;
};

export default class SetSupportSessionToken {
  private _supportSessionService: ISupportSessionService;
  private _authTokenService: IAuthTokenService;

  constructor({
    supportSessionService,
    authTokenService
  }: SetSupportSessionTokenParams) {
    this._supportSessionService = supportSessionService;
    this._authTokenService = authTokenService;
  }

  public async init(): Promise<void> {
    if (this._supportSessionService.isSupportSession()) {
      const accessToken =
        getCookie(TokenType.deprecatedstratusAccessToken, false) ||
        getCookie(TokenType.stratusAccessToken, false);
      this._authTokenService.setToken(accessToken, AuthContextEnum.tenant);
    }
  }
}
