import { EncryptStorage } from 'storage-encryption';
import { ValueStoreService } from '../valueStoreService';

export const encryptStorage = () => {
  if (ValueStoreService.valueStoreSecretKey) {
    console.log('Using value store secret key!');
    const secretKey = ValueStoreService.valueStoreSecretKey;
    return new EncryptStorage(JSON.stringify({ secretKey }));
  }
  console.warn('Using default value store key!');
  return new EncryptStorage(JSON.stringify('1234567890'));
};


