import AuthContextEnum from '../services/authTokenService/AuthContextEnum';
import { TokenType } from '../interface/types';
import { getServices } from '../infra/commonInitializer';

export function getStratusAccessToken(tokenTypeName: TokenType): string {
  const services = getServices();
  const authTokenService = services?.authTokenService;
  let authContext: AuthContextEnum;
  if (
    [TokenType.deprecatedOrglessToken, TokenType.orglessToken].includes(
      tokenTypeName
    )
  ) {
    authContext = AuthContextEnum.tenantless;
  } else if (
    [TokenType.orgedToken, TokenType.deprecatedOrgedToken].includes(
      tokenTypeName
    )
  ) {
    authContext = AuthContextEnum.tenant;
  } else {
    authContext = authTokenService.getCurrentContext();
  }
  return authTokenService.getToken(authContext)?.token || '';
}
