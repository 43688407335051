import { GrantHistorySvcClient } from '@jarvis/web-stratus-client/dist/src/clients/grants/grant-history-svc';
import { GrantsHistoryResponseType } from '../../../../services/grants/grantsHistory/types';
import { GetGrantHistoryPropsType } from './types';
import IGrantHistoryClient from './IGrantHistoryClient';
import { internalLogger } from '../../../../interface/v1/logger';

export type BaseURLProvider = (
  apiName: string,
  apiVersion: string
) => Promise<string>;

export default class GrantHistoryClient implements IGrantHistoryClient {
  async getGrantHistory({
    stack,
    authProvider,
    offset = 0,
    limit,
    tenantId = ''
  }: GetGrantHistoryPropsType): Promise<GrantsHistoryResponseType> {
    const baseUrlProvider: BaseURLProvider = async (apiName, apiVersion) => {
      return `https://stratus-${stack}.${apiName}`;
    };

    const grantHistoryClient = new GrantHistorySvcClient(
      baseUrlProvider,
      authProvider
    );
    try {
      return (
        await grantHistoryClient.getGrantsHistory({ limit, offset, tenantId })
      ).data as GrantsHistoryResponseType;
    } catch (error) {
      if (error.response?.status === 404) {
        internalLogger.log('record not found');
        return {
          results: [],
          totalRecords: 0
        } as GrantsHistoryResponseType;
      } else {
        internalLogger.error(error);
      }
    }
  }
}
