import { inject, singleton } from 'tsyringe';
import { AbstractRepository } from './../AbstractRepository';
import { AuthTokenStorageType } from './types';
import ShellLocalStorageDAOImpl from '../../dao/ShellLocalStorageDAOImpl';

@singleton()
class AuthTokenRepository extends AbstractRepository<AuthTokenStorageType> {
  public static _prefixKey = 'jshellAuthToken';

  constructor(
    @inject(ShellLocalStorageDAOImpl)
    shellLocalStorageDAOImpl: ShellLocalStorageDAOImpl
  ) {
    super({
      shellDAO: shellLocalStorageDAOImpl,
      prefixKey: AuthTokenRepository._prefixKey
    });
  }

  saveAll(): void {
    console.debug('Not supported in AuthTokenRepository.');
    throw new Error('Method not implemented.');
  }

  findAll(): AuthTokenStorageType[] {
    console.debug('Not supported in AuthTokenRepository.');
    throw new Error('Method not implemented.');
  }
}

export default AuthTokenRepository;
