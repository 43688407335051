import { GrantsInterface } from './GrantsInterface';
import { GrantsInterfaceType } from './types';

let grantsInterface: GrantsInterface;

export function getSessionInterfaceV1(): GrantsInterfaceType {
  return grantsInterface.getInterface();
}

export default async function initializeGrantsInterfaceV1(): Promise<GrantsInterfaceType> {
  if (grantsInterface) return grantsInterface.getInterface();
  grantsInterface = new GrantsInterface();

  return grantsInterface.getInterface();
}

export function createNoopGrantsInterface(): GrantsInterfaceType {
  return {
    refresh: function (): Promise<void> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    checkGrants: function (): Promise<boolean> {
      console.debug('Function not implemented.');
      return Promise.resolve(false);
    },

    checkPendingGrants: function (): Promise<boolean> {
      console.debug('Function not implemented.');
      return Promise.resolve(false);
    }
  };
}
