import { ISupportSessionService } from '../../services/supportSession';
import { TokenType } from '../../interface/types';
import {
  AuthContextEnum,
  IAuthTokenService
} from '../../services/authTokenService';
import { ISessionService } from '../../services/session';
import { getCookie } from '../../utils/cookies';

export type SetFirstApplicationTokenParametersType = {
  sessionService: ISessionService;
  authTokenService: IAuthTokenService;
  supportSessionService: ISupportSessionService;
};

export default class SetFirstApplicationToken {
  private _sessionService: ISessionService;
  private _authTokenService: IAuthTokenService;
  private _supportSessionService: ISupportSessionService;

  constructor({
    sessionService,
    authTokenService,
    supportSessionService
  }: SetFirstApplicationTokenParametersType) {
    this._sessionService = sessionService;
    this._authTokenService = authTokenService;
    this._supportSessionService = supportSessionService;
  }

  public async init(): Promise<void> {
    return this._setFirstWebToken();
  }

  private async _setFirstWebToken(): Promise<void> {
    if (
      !this._sessionService.getIdToken() &&
      !this._supportSessionService.isSupportSession()
    )
      return;

    if (!this._authTokenService.getToken()?.token) {
      const initialToken =
        getCookie(TokenType.deprecatedstratusAccessToken, false) ||
        getCookie(TokenType.stratusAccessToken, false);
      !!initialToken &&
        this._authTokenService.setToken(
          initialToken,
          AuthContextEnum.tenantless
        );
    }
  }
}
