abstract class UuidV4 {
  private static regex =
    /(?:^[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[a-f0-9]{4}-[a-f0-9]{12}$)|(?:^0{8}-0{4}-0{4}-0{4}-0{12}$)/u;
  private static uuidTemplate = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  private static uuidDefault = '00000000-0000-0000-0000-000000000000';

  private static uuidCreator(c): string {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  }

  private static uuid(): string {
    return this.uuidTemplate.replace(/[xy]/g, this.uuidCreator);
  }

  static getRandomUUID(): string {
    const uuid = this.uuid();
    return this.regex.test(uuid) ? uuid : this.uuidDefault;
  }
}

export { UuidV4 };
