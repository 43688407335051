import { TenantStrategyEnum } from '../../services/tenantHandler/strategy/TenantStrategyEnum';
import AuthenticationProviderEnum from '../../config/authenticationProviderEnum';

/**
 * Mapping of authentication providers to their respective tenant strategy overrides.
 *
 * @type {Object.<AuthenticationProviderEnum, Object.<TenantStrategyEnum, TenantStrategyEnum>>}
 */
let strategyMapping = {
  [AuthenticationProviderEnum.authz]: {},
  [AuthenticationProviderEnum.coptor]: {}
};

const _addRule = ({ from, to }) => {
  const auxMapping = {};
  auxMapping[from] = to;
  return auxMapping;
};

strategyMapping = {
  [AuthenticationProviderEnum.authz]: {
    ..._addRule({
      from: TenantStrategyEnum.onecloudOrganization,
      to: TenantStrategyEnum.stratusOrganization
    })
  },
  [AuthenticationProviderEnum.coptor]: {
    ..._addRule({
      from: TenantStrategyEnum.stratusOrganization,
      to: TenantStrategyEnum.onecloudOrganization
    })
  }
};

export { strategyMapping };
