import { EventServiceErrorType, EventServicePluginError } from '@jarvis/jweb-core';

export const eventServicePluginErrorMethod = (errorType: EventServiceErrorType, errorMessage: string): EventServicePluginError => {
  console.error(errorMessage);
  const error: EventServicePluginError = {
    errorType,
    reason: errorMessage,
  };
  return error;
};
