import * as T from './types';

const defaultMockConfig: T.MockedCommonsNorthboundApiDependenciesType = {
  enable: true,
  useDefaultMocks: true,
  northboundAPIs: {
    featureFlags: {
      enable: true
    },
    graphql: {
      enable: true,
      useDefaultData: true
    },
    sessionInterface: {
      enable: true
    }
  }
};

export default defaultMockConfig;
