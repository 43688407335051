import {
  InterfacesInitializerStatePropsType,
  UserContextEnum
} from '../../types';
import { RefreshType, AccessControlOptionsType } from './types';
import Store from '../store/index';
import EventNames from '../../../config/eventNames';
import { EventsInterfaceType } from '../events/types';
import { Scope } from '../../../services/scope';
import { PromiseHelper } from '../../../utils/PromiseHelper';
import AppContext from '../../../services/appContext/AppContext';
import { IScopeService } from '../../../services/scope/IScopeService';
import { AccessControlInputType } from '../../../infra/commonInitializer/types';

export default async ({
  accessControl,
  store,
  events,
  scopeService,
  appContext
}: InterfacesInitializerStatePropsType & {
  accessControl: AccessControlInputType;
  store: Store;
  events: EventsInterfaceType;
  scopeService: IScopeService;
  appContext: AppContext;
}) => {
  const { enabled } = accessControl || {};
  const defaultKey = 'default';
  const initializedUserContexts: Map<
    UserContextEnum | typeof defaultKey,
    boolean
  > = new Map();
  const promiseHelper = new PromiseHelper();

  async function refreshAction(options?: AccessControlOptionsType) {
    //TODO: get the info for the user token based on the userContext
    const hasScopesUpdated = await scopeService.updateScopes(options);
    if (hasScopesUpdated) {
      events.triggerEvent(
        EventNames.shellAccessControlChangedEventName,
        undefined
      );
    }
  }

  async function refresh(
    refreshOptions?: RefreshType,
    options?: AccessControlOptionsType
  ) {
    const promiseKey = options?.userContext ?? defaultKey;
    let promise = promiseHelper.getPendingPromise(promiseKey);
    if (isEnabled() && !promise) {
      promise = refreshAction(options);
      promiseHelper.setPendingPromise(promiseKey, promise);
    }
    await promise;
  }

  async function _initialize(options?: AccessControlOptionsType) {
    const contextKey = options?.userContext ?? defaultKey;
    let promise = promiseHelper.getPendingPromise(contextKey);
    const isInitialized = initializedUserContexts.get(contextKey);

    if (!isInitialized) {
      initializedUserContexts.set(contextKey, true);
      if (!promise) {
        promise = refresh({ reloadContent: false }, options);
        promiseHelper.setPendingPromise(contextKey, promise);
      }
    }

    await promise;
  }

  async function checkScopes(
    scopesToCheck: Scope[],
    options: AccessControlOptionsType
  ): Promise<boolean> {
    if (!isEnabled()) return true;

    await _initialize(options);
    //TODO: get the info for the user token based on the useContext
    return scopeService.checkScopes(scopesToCheck, options);
  }

  function isEnabled() {
    if (!enabled) {
      console.warn('AccessControl: disabled');
      return false;
    }
    return true;
  }

  appContext.addInitServices(_initialize);
  appContext.addRefreshServices(refresh);

  return { refresh, checkScopes };
};
