import ShellLocalStorageDAOImpl from '../../../dao/ShellLocalStorageDAOImpl';
import RefreshWhenNativeAuthStatusChanged from './RefreshWhenNativeAuthStatusChanged';
import { InitialNavigationStateRepository } from './InitialNavigationStateRepository';

const shellDAO = new ShellLocalStorageDAOImpl();
const initialNavigationStateRepository = new InitialNavigationStateRepository({
  shellDAO
});

export { initialNavigationStateRepository, RefreshWhenNativeAuthStatusChanged };
