import jsonpath, { PathComponent } from 'jsonpath';
import { ValveFilterErrorType } from '@jarvis/jweb-core';
import { logger } from '../helpers/logger';
import { DataValveFilterError } from '../helpers/dataValveError';
import { sanitize } from './sanitize';
import {
  FilterInfo,
  FilterObject,
  InstructionsResponse,
  MinimumVersion,
  SubtreeObject,
  TreeObject,
} from './filterTypes';

export const subtreeFiltering = (
  bindings: InstructionsResponse,
  gun: string,
  node: any,
  cdmObject: string
) => {
  try {
    const originalNode = JSON.parse(cdmObject);
    let trees: TreeObject[] | undefined = bindings.bindings?.trees?.cdm?.json;
    if (!trees || trees.length === 0) {
      logger.error('subtreeFiltering::No trees found in bindings');
      trees = [];
    }
    const tree = trees?.map((treeItem: TreeObject) => {
      if (treeItem.resourceId === gun) return treeItem;
    });
    tree.forEach((treeValues: TreeObject | undefined) => {
      if (treeValues && treeValues.subtrees) {
        treeValues.subtrees.forEach((subtree: SubtreeObject) => {
          const subtreeNodes = jsonpath.query(originalNode, subtree.attribute);
          const paths = jsonpath.paths(originalNode, subtree.attribute);
          const subtreePaths = paths.map((subtreePath: PathComponent[]) =>
            jsonpath.stringify(subtreePath)
          );

          const subtreeGuns =
            subtree?.reference?.identifierType == null
              ? []
              : subtree.reference.identifierType === 'resourceId'
                ? [subtree.reference.identifier]
                : jsonpath.query(originalNode, subtree.reference.identifier);
          if (subtreeNodes.length === subtreeGuns.length) {
            for (let i = 0; i < subtreeNodes.length; i++) {
              const filterInfoFlag = subtree.filterInfo ? true : false;
              const sanitizedResult = sanitize(
                subtreeNodes[i],
                subtreeGuns[i],
                bindings,
                cdmObject,
                filterInfoFlag
              );
              logger.log(
                'SubTreeFiltering::Sanitized subtree node:',
                sanitizedResult.sanitizedNode
              );
              const parentNode = jsonpath.parent(node, subtreePaths[i]);
              updateNode(
                sanitizedResult.sanitizedNode,
                parentNode,
                subtreePaths[i]
              );

              if (subtree.filterInfo) {
                addFilterInfo(
                  subtree.filterInfo,
                  parentNode,
                  sanitizedResult.filterError,
                  sanitizedResult.filter as FilterObject
                );
              }
            }
          }
        });
      }
    });
  } catch (error: any) {
    throw new DataValveFilterError(
      ValveFilterErrorType.filterError,
      'Unexpected error in subtreefiltering',
      error
    );
  }
};

const updateNode = (node: any, parentNode: any, path: string) => {
  const lastLevelPath = getJSONLastPath(path);
  delete parentNode[lastLevelPath];
  if (node) Object.assign(parentNode, { [lastLevelPath]: node });
  else Object.assign(parentNode, { [lastLevelPath]: {} });
};

const checkMinimumVersion = (
  minimumVersion: MinimumVersion,
  parentNode: any
) => {
  const versionPath = getJSONLastPath(minimumVersion.versionPath);
  const actualVersion =
    parentNode[versionPath] !== undefined
      ? getVersion(parentNode[versionPath])
      : 0;
  const minVersion = getVersion(minimumVersion.version);
  if (actualVersion >= minVersion) return true;
  return false;
};

const getVersion = (versionString: string) => {
  const vs = versionString.split('.');
  vs.pop();
  const version = vs.join('.');
  const versionNumber = parseFloat(version);
  return versionNumber;
};

const getJSONLastPath = (path: string) => {
  const index = path.lastIndexOf('.');
  const lastPathLevel = path.substring(index + 1, path.length);
  return lastPathLevel;
};

const addFilterInfo = (
  filterInfo: FilterInfo,
  parentNode: any,
  filterError: string,
  filtersApplied: FilterObject
) => {
  if (!checkMinimumVersion(filterInfo.minimumVersion, parentNode)) return;
  logger.log(
    'SubTreeFilterInfo::addFilterInfo:Minimum version check passed & Adding filter info'
  );
  const infoPath = getJSONLastPath(filterInfo.infoPath);
  const errorPath = getJSONLastPath(filterInfo.errorPath);

  if (filterError && errorPath) {
    Object.assign(parentNode, { [errorPath]: filterError });
  }
  if (Object.keys(filtersApplied).length > 0 && infoPath) {
    Object.assign(parentNode, { [infoPath]: filtersApplied });
  }
};
