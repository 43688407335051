import { AnalyticsInterfaceType } from './types';

type noopInterfaceParam = {
  logCallback?: () => void;
};

export const createNoopAnalyticsInterface = ({
  logCallback
}: noopInterfaceParam): AnalyticsInterfaceType => {
  const newNoopInterface: AnalyticsInterfaceType = {
    publishCdmEvents: (): Promise<void> => {
      logCallback?.();
      return;
    },
    isEnabled: (): Promise<boolean> => {
      logCallback?.();
      return Promise.resolve(false);
    }
  };
  return newNoopInterface;
};
