import { Subscription } from './subscriptions/Subscription';

let subscriptions: Subscription[] = [];
export const eventHub = {
  subscribe: (
    subscription: Subscription,
    eventName?: string,
    publisherId?: string
  ) => {
    console.log('Event Hub: Add Subscription');
    console.log(`eventName: ${eventName}, publisherId: ${publisherId}`);
    subscription.isActive = true;
    subscriptions.push(subscription);
    return;
  },

  unsubscribe: (subscription: Subscription) => {
    console.log('Event Hub: Unsubscribe');
    console.log(`eventName: ${subscription.eventName} publisherId: ${subscription.publisherId}`);
    subscriptions = subscriptions.filter(sub => sub !== subscription);
    return;
  },

  publish: (eventName: string, publisherId: string, eventData: object) => {
    console.log('Event Hub: Publish');
    console.log(`eventName: ${eventName}, publisherId: ${publisherId}, eventData: ${JSON.stringify(eventData)}`);
    subscriptions.forEach(sub => {
      if ((!sub.publisherId || sub.publisherId === publisherId) &&
        (!sub.eventName || sub.eventName === eventName)) {
        const eventInfo = {
          subscriber: sub.subscriber,
          publisherId,
          eventName,
          eventData
        };
        if (sub.subscriber.isPaused) {
          console.log('this event is paused hence storing the events');
          sub.subscriber.events = [...sub.subscriber.events, eventInfo];
        } else {
          sub.notify(eventInfo);
        }
      }
    });
  },

  resumeEvent: (subscription: any) => {
    subscriptions.forEach((sub) => {
      if (sub === subscription) {
        console.log('is subscriber Paused? ', sub.subscriber.isPaused);
        sub.subscriber.events.forEach(({ eventName, publisherId, eventData }) => {
          const eventInfo = {
            subscriber: sub.subscriber,
            publisherId,
            eventName,
            eventData
          };
          sub.notify(eventInfo);
        });
        sub.subscriber.events = [];
      }
    });
  }
};
