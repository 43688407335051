import { INavigationService } from '../../services/navigationService';
import { ILocalizationService } from '../../services/localizationService';
import LocaleContext from './LocaleContext';
import { GLOBAL_CONTEXT_KEY } from '../../services/localizationService/LanguageListProvider';
import WebServiceRouting from '../../services/webServiceRouting';

type NavigationParams = {
  navigationService: INavigationService;
  localizationService: ILocalizationService;
  webServiceRouting: WebServiceRouting;
};

export default class UseLocaleContext {
  private _navigation: INavigationService;
  private _localizationService: ILocalizationService;
  private _localeContext;

  constructor({
    navigationService,
    localizationService,
    webServiceRouting
  }: NavigationParams) {
    this._navigation = navigationService;
    this._localizationService = localizationService;
    // TODO We should use IoC here.
    this._localeContext = new LocaleContext(webServiceRouting);
  }

  /***
   * Here we will change the localization map for a specific context or to
   * the global locale context.
   */
  public init = async (): Promise<void> => {
    await this.updateLanguageContext();

    // TODO: WARNING: ASAP this method should be sync to avoid some miss behaviors.
    this._navigation.listen(() => {
      this.updateLanguageContext();
    });
  };

  public async updateLanguageContext(): Promise<void> {
    const currentPathname = this._navigation?.location?.pathname;

    const currentContextKey = await this._localeContext.getLocaleContextByPath(
      currentPathname
    );
    if (currentContextKey) {
      await this._localizationService.setCurrentContext(currentContextKey);
    } else {
      await this._localizationService.setCurrentContext(GLOBAL_CONTEXT_KEY);
    }
  }
}
