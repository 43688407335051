import { IShellDAO } from '../../../dao/IShellDAO';
import { AbstractRepository } from '../../../services/AbstractRepository';

export class InitialNavigationStateRepository extends AbstractRepository<unknown> {
  constructor({
    shellDAO,
    enableSanitizer = false
  }: {
    shellDAO: IShellDAO;
    enableSanitizer?: boolean;
  }) {
    super({
      shellDAO: shellDAO,
      prefixKey: 'jshellInitialNavigationState',
      enableSanitizer
    });
  }

  saveAll(): void {
    console.debug('Not supported in InitialNavigationStateRepository.');
    throw new Error('Method not implemented.');
  }

  findAll(): unknown[] {
    console.debug('Not supported in InitialNavigationStateRepository.');
    throw new Error('Method not implemented.');
  }

  delete(): boolean {
    console.debug('Not supported in InitialNavigationStateRepository.');
    throw new Error('Method not implemented.');
  }
}
