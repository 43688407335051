import { ServiceLocalizationFallbackType } from '../types';
import { AbstractLocaleHandler } from './AbstractLocaleHandler';

export class DefaultLocaleHandler extends AbstractLocaleHandler {
  constructor(languagesFallback: ServiceLocalizationFallbackType) {
    super(languagesFallback);
  }

  public handle(isLanguage: boolean, currentList: string[]): string {
    // Retrieve the first element for language or country.
    return currentList?.[0];
  }
}
