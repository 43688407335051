import bindAllMethods from '../../../utils/bindAllMethods';
import { getServices } from '../../../infra/commonInitializer';
import { GraphQLInterfaceV1Type } from './types';
import IGraphQLService from '../../../services/graphQLService/IGraphQLService';
import {
  ClientFetchResult,
  ClientQueryResult
} from '@clientos/graphql-client/dist/services/GraphQLService/types';

class GraphQLInterfaceV1 {
  private _graphQLService: IGraphQLService;

  constructor() {
    const { graphQLService } = getServices();
    this._graphQLService = graphQLService;
    bindAllMethods(this);
  }

  public getInterface(): GraphQLInterfaceV1Type {
    const { gql, getClient, getReactTools } = this._graphQLService;
    const { createGraphQLProvider, useQuery, useMutation, useLazyQuery } =
      getReactTools() || {};

    return {
      gql,
      query: this.query,
      mutate: this.mutate,
      getClient,
      reactTools: { createGraphQLProvider, useQuery, useMutation, useLazyQuery }
    };
  }

  private async query<TData, TVariables>(
    query: string,
    variables?: TVariables
  ): Promise<ClientQueryResult<TData>> {
    if (typeof query !== 'string') {
      throw new Error('Query must be a string!');
    }
    const { getClient } = this._graphQLService;
    const client = getClient();

    return client.query(query, variables);
  }

  private async mutate<TData, TVariables>(
    mutation: string,
    variables?: TVariables
  ): Promise<ClientFetchResult<TData>> {
    if (typeof mutation !== 'string') {
      throw new Error('Mutation must be a string!');
    }
    const { getClient } = this._graphQLService;
    const client = getClient();

    return client.mutate(mutation, variables);
  }
}

export default GraphQLInterfaceV1;
