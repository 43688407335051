import {
  LDClient,
  LDFlagSet,
  LDFlagValue,
  initialize
} from 'launchdarkly-js-client-sdk';
import EventNames from '../../config/eventNames';
import bindAllMethods from '../../utils/bindAllMethods';
import IFeatureFlagClient from './IFeatureFlagClient';
import {
  GetFeatureFlagParams,
  LaunchDarklyClientInitializeParams
} from './types';

export default class LaunchDarklyFeatureFlagClient
  implements IFeatureFlagClient
{
  private _launchDarklyClient: LDClient;

  constructor() {
    bindAllMethods(this);
  }

  public async getFeatureFlag({
    key,
    defaultValue
  }: GetFeatureFlagParams): Promise<LDFlagValue> {
    if (!key || defaultValue === undefined) {
      console.error(`SyntaxError: The method getFeatureFlag requires key and default value to be called.
    Example: getFeatureFlag({ key: "myKey", defaultValue: true})`);
      throw new Error(`SyntaxError: The method getFeatureFlag requires key and default value to be called.
    Example: getFeatureFlag({ key: "myKey", defaultValue: true})`);
    }
    try {
      const result = await this._launchDarklyClient.variation(
        key,
        defaultValue
      );
      return result;
    } catch (error) {
      console.warn(
        'Error getting feature flag, returning default value instead.',
        error
      );
      return defaultValue;
    }
  }

  public async initializeClient(
    options: LaunchDarklyClientInitializeParams
  ): Promise<LDClient> {
    const { clientId, context, eventService, liveUpdate } = options;

    const client = initialize(clientId, context);
    await client.waitForInitialization();
    client.setStreaming(!!liveUpdate);

    client.on('change', () => {
      eventService.publish(EventNames.shellFeatureFlagChangedEventName, true);
    });

    this._launchDarklyClient = client;
    return client;
  }

  public async getAllFeatureFlags(): Promise<LDFlagSet> {
    try {
      const flags = this._launchDarklyClient.allFlags();
      return flags;
    } catch (error) {
      console.error('Error getting all feature flags.', error);
    }
  }

  public getClient(): LDClient {
    return this._launchDarklyClient;
  }
}
