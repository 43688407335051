import { inject, singleton } from 'tsyringe';
import { IShellDAO } from '../../dao/IShellDAO';
import { IdTokenStorageType } from './types';
import ShellLocalStorageDAOImpl from '../../dao/ShellLocalStorageDAOImpl';

@singleton()
class IdTokenRepository {
  private shellDAO: IShellDAO;
  private prefixKey = 'jshellIdToken';

  constructor(
    @inject(ShellLocalStorageDAOImpl)
    shellLocalStorageDAOImpl: ShellLocalStorageDAOImpl
  ) {
    this.shellDAO = shellLocalStorageDAOImpl;
  }

  save(idTokenStorage: IdTokenStorageType): void {
    this.shellDAO.save(this.prefixKey, idTokenStorage);
  }

  findOne(): IdTokenStorageType {
    return this.shellDAO.findOne(this.prefixKey);
  }

  delete(): boolean {
    return this.shellDAO.delete(this.prefixKey);
  }

  clear(): void {
    this.delete();
  }
}

export default IdTokenRepository;
