import { localePreferenceCookieName } from '../../../config/constants';
import { LocaleType } from '../types';
import { getCookie } from '../../../utils/cookies';

export function getLocalePreference(): string {
  return getCookie(localePreferenceCookieName, false);
}

export default function getCookieLocale(): LocaleType {
  const languageFromLocalePreferenceCookie =
    getLocalePreference()?.split('-')[0];
  const countryFromLocalePreferenceCookie =
    getLocalePreference()?.split('-')[1];

  return {
    language: languageFromLocalePreferenceCookie,
    country: countryFromLocalePreferenceCookie
  };
}
