import { JarvisWebHttpClient } from '@jarvis/web-http';
import { IAuthProviderService } from '../../../services/authProviderService';
import { AccessPoliciesEndPointReturnType } from './types';
import AuthenticationProviderEnum from '../../../config/authenticationProviderEnum';
import { getStratusBaseUrl } from '../../../clients/stackUtils';
import IApplicationService from '../../../services/applicationService/IApplicationService';

export async function getAccessPolicies(
  authProvider: IAuthProviderService,
  applicationService: IApplicationService
): Promise<AccessPoliciesEndPointReturnType> {
  const httpClient = new JarvisWebHttpClient({
    defaultAuthProvider: authProvider
  });

  const authStack = applicationService.getAuthStack();
  const authenticationProvider = applicationService.getAuthenticationProvider();

  const url = '/session/v1/scopes';

  // JShell CF proxy
  let baseUrl = '/api';

  // In case of Onecloud we need to use the JShell Apigee URL directly instead of passing through JShell CF proxy
  if (authenticationProvider === AuthenticationProviderEnum.coptor) {
    baseUrl = `${getStratusBaseUrl(authStack)}/portalsvc`;
  }

  const payload: { data: AccessPoliciesEndPointReturnType } =
    await httpClient.get({
      url: `${baseUrl}${url}`
    });

  return payload?.data;
}
