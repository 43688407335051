import bindAllMethods from '../../utils/bindAllMethods';
import getTenantStrategy from './strategy/strategy';
import {
  StackType,
  TenantDataType,
  TenantHandlerStrategyType,
  TenantVisualizationType
} from './strategy/types';
import { TenantRepository } from './TenantRepository';
import * as T from './types';
import TenantObserver, { TenantEvents } from './TenantObserver';
import TenantStrategy from './strategy/TenantStrategy';
import { ISessionService } from '../session';
import AuthContextEnum from '../authTokenService/AuthContextEnum';
import tenantLevelToAuthContextEnum from '../authTokenService/utils/tenantLevelToAuthContextEnum';
import authContextEnumToTenantLevel from '../authTokenService/utils/authContextEnumToTenantLevel';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { IAuthProviderService } from '../authProviderService';
import IEventService from '../eventService/IEventService';
import EventNames from '../../config/eventNames';
import ITenantHandlerService from './ITenantHandlerService';
import { inject, singleton } from 'tsyringe';
import type { TenantHandlerDependenciesType } from './types';
import {
  CustomBehaviorsType,
  PortalOnboardingType,
  TenantHandlerParamsType
} from './strategy/external-types';
import { internalLogger } from '../../interface/v1/logger';
import { ISupportSessionService } from '../supportSession';
import { AccountsClient } from '../../clients/stratus/accountmgtsvc';
import { INavigationService } from '../navigationService';

@singleton()
class TenantHandlerService implements ITenantHandlerService {
  public TENANT_SUFFIX_CONCAT_STRING = '__';
  public START_TENANT_LEVEL = 1;

  private _initialTenants: T.InitialTenantType[];
  private _tenantRepository: TenantRepository;
  private _stack: StackType;
  private _globalTenantHandler: string;

  private _tenantHandlerParams: TenantHandlerParamsType[];
  private _enable: boolean;
  private _isNative: boolean;

  private _currentTenantHandlerKey: string;
  private _currentTenantHandler: TenantHandlerParamsType;
  private _currentTenantAdditionalData: T.CurrentTenantAdditionalDataType;

  // Object to keep tenantHandlers objects and processed in level hierarchy
  private _handledTenants: T.HandledTenant[];

  private _authProvider: IAuthProviderService;

  private _tenantSelectorAssetReference: string;

  private _tenantStrategyInstanceList: Record<
    string,
    T.TenantStrategyInstanceType[]
  >;

  private _sessionService: ISessionService;
  private _eventService: IEventService;
  private _navigationService: INavigationService;
  private _supportSessionService: ISupportSessionService;

  private _accountsClient: AccountsClient;

  // TODO: Use app service instead of stack
  constructor(
    @inject('TenantHandlerProps')
    tenantHandlerProps: TenantHandlerDependenciesType
  ) {
    const {
      tenantHandlerList,
      stack,
      globalTenantHandler,
      enabled,
      tenantSelectorAssetReference,
      isNative
    } = tenantHandlerProps;

    this._tenantHandlerParams = tenantHandlerList;
    this._stack = stack;
    this._handledTenants = [];
    this._globalTenantHandler = globalTenantHandler;
    this._enable = enabled;
    this._isNative = isNative;
    this._tenantSelectorAssetReference = tenantSelectorAssetReference;
    bindAllMethods(this);
  }

  public setDependencies({
    repositories,
    services,
    clients
  }: SetServiceDependencies): void {
    const { tenantRepository } = repositories;
    const {
      authProviderService,
      eventService,
      navigationService,
      supportSessionService
    } = services;
    this._tenantRepository = tenantRepository;
    this._authProvider = authProviderService;
    this._eventService = eventService;
    this._navigationService = navigationService;
    this._supportSessionService = supportSessionService;
    this._accountsClient = clients.stratus.accountsMgtSvcClient;
  }

  public async init(sessionService: ISessionService): Promise<void> {
    this._sessionService = sessionService;
    if (!this.isEnabled()) return;
    this._tenantStrategyInstanceList = {};
    this._tenantHandlerParams.forEach((t) => {
      this._tenantStrategyInstanceList[t.key] = [];
      this._createTenantInstanceList(t, this.START_TENANT_LEVEL, t.key);
    });
  }

  public isEnabled(): boolean {
    return this._enable;
  }

  public setTenantHandlerKey = async (
    key: string = this._globalTenantHandler
  ): Promise<void> => {
    if (!this.isEnabled()) return;
    if (this._currentTenantHandlerKey !== key) {
      this._handledTenants = [];
      const firstTenantLevel = this.START_TENANT_LEVEL;

      this._currentTenantHandlerKey = key || this._globalTenantHandler;

      this._currentTenantHandler = this._getTenantHandlerByKey(
        this._currentTenantHandlerKey
      );

      this.createHandleTenantObject(
        this._currentTenantHandler,
        firstTenantLevel
      );

      await TenantObserver.notify(
        TenantEvents.SET_TENANT_HANDLER_KEY,
        this._handledTenants
      );
    }
  };

  private _getTenantHandlerByKey = (
    key: string = this._globalTenantHandler
  ): TenantHandlerParamsType => {
    if (!this.isEnabled()) return;

    const tenantHierarchy = this._tenantHandlerParams.find(
      (t) => t.key === key
    );
    if (!tenantHierarchy) internalLogger?.error('TenantKey not found.');

    return tenantHierarchy;
  };

  public getPortalOnboardingList = (): PortalOnboardingType[] => {
    this._currentTenantHandler = this._getTenantHandlerByKey();

    return this._currentTenantHandler?.options?.portalOnboardingList;
  };

  public getTenantSuffix = (level: number = null): string => {
    if (this._handledTenants.length === 0) return '';
    const suffixList = this._getOrderedTenantSuffixes(level);
    return suffixList.join(this.TENANT_SUFFIX_CONCAT_STRING);
  };

  public generateTenantSuffix = (
    authContext: AuthContextEnum,
    tenantId: string
  ): string => {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    const suffixList = this._getOrderedTenantSuffixes(tenantLevel - 1);
    suffixList.push(tenantId);
    return suffixList.join(this.TENANT_SUFFIX_CONCAT_STRING);
  };

  public getCurrentContext = (): AuthContextEnum => {
    if (this._handledTenants.length === 0) return AuthContextEnum.tenantless;
    let highestTenantLevel = 0;
    this._handledTenants.forEach((t) => {
      if (t.proccessed && t.level > highestTenantLevel)
        highestTenantLevel = t.level;
    });
    return tenantLevelToAuthContextEnum(highestTenantLevel);
  };

  public checkIfTenantIsStoredBySuffix = (sufix: string): boolean => {
    return !!this._tenantRepository.findOne(sufix)?.id;
  };

  public clearTenants = (level = this.START_TENANT_LEVEL): void => {
    this._validateLevel(level);
    this._tenantRepository.clear(level);
  };

  public getNextUnproccessedTenant = (
    forceAuthContext?: AuthContextEnum
  ): T.HandledTenant & T.TenantStrategyInstanceType => {
    let data;
    if (forceAuthContext) {
      const level = authContextEnumToTenantLevel(forceAuthContext);
      data = this._handledTenants.find((t) => t.level === level);
    } else {
      data = this._handledTenants.find((t) => t.proccessed === false);
    }
    if (!data) return null;
    const strategy = this.getTenantStrategy(data?.level);
    return { ...data, strategy };
  };

  public getTenantId = (level = this.START_TENANT_LEVEL): string => {
    this._validateLevel(level);
    const tenantData = this._tenantRepository.findOne(
      this.getTenantSuffix(level)
    );
    return tenantData?.level === level ? tenantData.id : null;
  };

  public getTenantIdsMap = (): Record<string, string> => {
    const tenantIdsMap = {};
    this._handledTenants.forEach((t) => {
      if (t.proccessed) {
        tenantIdsMap[t.strategyEnum] = t.id;
      }
    });
    return tenantIdsMap;
  };

  public getTenantByLevel = (
    level = this.START_TENANT_LEVEL
  ): T.HandledTenant => {
    this._validateLevel(level);
    return this._handledTenants.find((t) => t.level === level);
  };

  public getTenantByContext = (
    authContext: AuthContextEnum
  ): T.HandledTenant => {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    return this._handledTenants.find(
      (t) => t.level === tenantLevel && t.proccessed
    );
  };

  public getTenantStrategy(level = this.START_TENANT_LEVEL): TenantStrategy {
    return this._tenantStrategyInstanceList?.[
      `${this._currentTenantHandlerKey}`
    ]?.find((t) => t.level === level)?.strategy;
  }

  /**
   * Here we set the tenant, and if the tenant is not proccessed, we will check if the tenant has a custom behavior and redirect to the custom behavior URL.
   */
  public setTenant = async (
    tenantId: string,
    level: number,
    options: { reload?: boolean; useCustomBehavior?: boolean },
    data?: TenantDataType
  ): Promise<void> => {
    this._validateLevel(level);

    if (
      options?.useCustomBehavior &&
      !this._supportSessionService.isSupportSession()
    ) {
      await this.checkCustomBehaviorAndRedirect(level, tenantId);
    }

    const handledTenant = this._handledTenants.find((t) => t.level === level);
    const oldHandledTenant: T.HandledTenant = { ...handledTenant };

    try {
      handledTenant.id = tenantId;
      handledTenant.proccessed = true;
      handledTenant.data = data;

      await this.getTenantStrategy(level).setTenant(
        tenantId,
        tenantLevelToAuthContextEnum(level)
      );

      await this.setTenantId(handledTenant, tenantId, level, data);
    } catch (e) {
      handledTenant.id = oldHandledTenant?.id;
      handledTenant.proccessed = oldHandledTenant?.proccessed;
      handledTenant.data = oldHandledTenant?.data;
      internalLogger?.error(`Tenant ${tenantId} wasn't able to be exchanged.`);
      return;
    }

    await TenantObserver.notify(TenantEvents.SET_TENANT, this._handledTenants);

    this._publishTenantEvent(EventNames.shellTenantChangedEventName, {
      preventReload: !options?.reload
    } as T.TenantChangeEvent);
  };

  private _publishTenantEvent(
    eventName: EventNames,
    payload: Record<string, unknown>
  ): void {
    this._eventService.publish(eventName, payload);
  }

  public onTenantChange(
    cb: (options: T.TenantChangeEvent) => void
  ): () => void {
    const removeListenerPromise = this._eventService.subscribe(
      EventNames.shellTenantChangedEventName,
      (event) => cb(event.eventData)
    );

    return () => removeListenerPromise.then((result) => result.unsubscribe());
  }

  public getHandledTenantList = (): T.HandledTenant[] => {
    return this._handledTenants;
  };

  public setInitialTenants(initialTenants: T.InitialTenantType[]): void {
    this._initialTenants = initialTenants;
  }

  public getInitialTenants(): T.InitialTenantType[] {
    return this._initialTenants;
  }

  public async getTenantList({
    authContext,
    refreshList
  }: {
    authContext: AuthContextEnum;
    refreshList?: boolean;
  }): Promise<TenantVisualizationType[]> {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    return this.getTenantStrategy(tenantLevel).getTenantList(refreshList);
  }

  public async getCurrentTenantAdditionalData(): Promise<T.CurrentTenantAdditionalDataType> {
    if (this.getCurrentContext() === AuthContextEnum.tenantless) return null;
    if (!this._currentTenantAdditionalData) {
      const { countrySet, regionId } = await this._accountsClient.getAccount();
      this._currentTenantAdditionalData = {
        countries: countrySet,
        regionId
      };
    }
    return this._currentTenantAdditionalData;
  }

  private setTenantId = async (
    handledTenant: T.HandledTenant,
    tenantId: string,
    level = this.START_TENANT_LEVEL,
    data: TenantDataType
  ) => {
    this._validateLevel(level);
    const tenantStrategy = this.getTenantStrategy(level);

    const tenantStorage = {
      id: tenantId,
      strategy: tenantStrategy.getStrategy(),
      tenantHandlerKey: this._currentTenantHandlerKey,
      level,
      data
    };

    if (!data) {
      const tenantData = await tenantStrategy.getTenantById(tenantId);

      tenantStorage.data = {
        type: tenantData.type,
        name: tenantData.name,
        roleCategory: tenantData.roleCategory,
        roleName: tenantData?.roleName
      };

      handledTenant.data = tenantStorage.data;
    }
    const tenantSuffix = this.getTenantSuffix(level);

    this._tenantRepository.save(tenantSuffix, tenantStorage);

    this.unProccessChainedHandledTenants(level + 1);
    return tenantStorage;
  };

  private _getInitialTenantSuffix(level) {
    this._validateLevel(level);
    const tenantIds = [];
    this._initialTenants?.forEach((t) => {
      if (t.level > level) return;
      tenantIds.push(t.id);
    });

    return tenantIds.join(this.TENANT_SUFFIX_CONCAT_STRING);
  }

  private createHandleTenantObject = (
    tenantHierarchy: TenantHandlerParamsType,
    level = this.START_TENANT_LEVEL,
    resetProccess = false
  ): T.HandledTenant => {
    this._validateLevel(level);
    const strategy = tenantHierarchy?.strategy;

    const sufix = this._getInitialTenantSuffix(level);

    const storedTenant = this._tenantRepository.findOne(
      sufix || this.getTenantSuffix(level)
    );

    const isProccessed = this._checkIfTenantIsProccessed(
      tenantHierarchy,
      storedTenant,
      strategy
    );

    const handledTenant: T.HandledTenant = {
      level,
      id: storedTenant?.id,
      proccessed: isProccessed,
      data: storedTenant?.data,
      strategyEnum: strategy
    };

    this._handledTenants.push(handledTenant);

    if (tenantHierarchy.subTenant) {
      this.createHandleTenantObject(
        tenantHierarchy.subTenant,
        level + 1,
        resetProccess || isProccessed
      );
    }
    return handledTenant;
  };

  private _validateLevel = (level: number) => {
    if (level < this.START_TENANT_LEVEL)
      throw new Error(
        `The tenant level must be ${this.START_TENANT_LEVEL} or higher.`
      );
  };

  private unProccessChainedHandledTenants = (
    level = this.START_TENANT_LEVEL
  ) => {
    this._validateLevel(level);
    this._handledTenants.forEach((t) => {
      if (t.level >= level) {
        t.proccessed = false;
        t.data = null;
      }
    });
  };

  private _createTenantInstanceList(
    tenantHierarchy: TenantHandlerParamsType,
    level: number,
    key: string
  ) {
    const attributes: TenantHandlerStrategyType = {
      stack: this._stack,
      authProvider: this._authProvider,
      assetReference:
        tenantHierarchy?.assetReference || this._tenantSelectorAssetReference,
      options: tenantHierarchy?.options,
      sessionService: this._sessionService,
      isNative: this._isNative,
      isAST: this._supportSessionService.isSupportSession()
    };
    const tenantStrategy = getTenantStrategy(
      tenantHierarchy.strategy,
      attributes
    );

    this._tenantStrategyInstanceList[`${key}`].push({
      level,
      strategy: tenantStrategy
    });

    !!tenantHierarchy.subTenant &&
      this._createTenantInstanceList(tenantHierarchy.subTenant, level + 1, key);
  }

  private _checkIfTenantIsProccessed = (
    tenantHierarchy: TenantHandlerParamsType,
    tenant: T.TenantStorageType,
    strategy: string
  ): boolean => {
    const tenantFilter = tenantHierarchy?.options?.filter;
    const isValid = this.getTenantStrategy(tenant?.level)?.isTenantValid(
      tenantFilter,
      {
        id: tenant?.id,
        name: tenant?.data?.name,
        type: tenant?.data?.type,
        roleCategory: tenant?.data?.roleCategory,
        roleName: tenant?.data?.roleName
      }
    );
    return tenant?.strategy === strategy && isValid;
  };
  public async checkCustomBehaviorAndRedirect(
    level: number,
    id: string
  ): Promise<void> {
    const customBehaviorsList = this._getTenantHandlerByKey(
      this._currentTenantHandlerKey
    )?.options?.customBehaviors;

    if (customBehaviorsList) {
      const authContext = tenantLevelToAuthContextEnum(level);

      const tenantList = await this.getTenantList({ authContext });
      const selectedTenant = tenantList.find((tenant) => tenant?.id === id);

      const customBehavior = this.findCustomBehavior(
        customBehaviorsList,
        selectedTenant,
        level
      );

      if (customBehavior) {
        const includeTenantId = customBehavior.onSelect?.includeTenantId;
        const redirectTo = customBehavior.onSelect?.redirectTo;

        if (includeTenantId) {
          this._navigationService.redirect(redirectTo + '/login?t1=' + id);
        } else {
          this._navigationService.redirect(redirectTo);
        }
      }
    }
  }

  private _getOrderedTenantSuffixes = (level: number = null): string[] => {
    const currentTenants: string[] = [];
    this._handledTenants.forEach((t) => {
      if (level && t.level > level) {
        return;
      }
      t.proccessed && currentTenants.push(t.id);
    });
    return currentTenants;
  };

  public findCustomBehavior(
    customBehaviors: CustomBehaviorsType[] = [],
    tenant: TenantVisualizationType,
    level: number
  ): CustomBehaviorsType {
    if (customBehaviors && tenant && level) {
      for (const customBehavior of customBehaviors) {
        if (
          this.getTenantStrategy(level).isTenantValid(
            customBehavior?.filter,
            tenant
          )
        ) {
          return customBehavior;
        }
      }
    }
    return null;
  }
}

export default TenantHandlerService;
