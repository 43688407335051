import bindAllMethods from '../../utils/bindAllMethods';
import {
  bulkAddEventListener,
  bulkRemoveEventListener
} from './BulkEventsFunctions';
import * as T from './types';
import IIdleService from './IIdleService';

export default class IdleJSService implements IIdleService {
  private _defaults: T.SettingsType;
  private _settings: T.SettingsType;
  private _visibilityEvents: string[];
  private _clearTimeout: () => void;
  private _idle: boolean;
  private _visible: boolean;
  private _isEnable: boolean;
  private _idleTimeDefault = 1800000; // 30 min in miliseconds

  constructor(options: T.UserActivityInputType) {
    bindAllMethods(this);
    const { idle, enable = true } = options || {};
    this._isEnable = enable;

    this._defaults = {
      idle: idle?.time || this._idleTimeDefault,
      events: ['mousemove', 'scroll', 'keydown', 'mousedown', 'touchstart'],
      onIdle: null,
      onActive: null,
      onHide: null,
      onShow: null,
      keepTracking: true,
      startAtIdle: false,
      recurIdleCall: false
    };

    this._visibilityEvents = [
      'visibilitychange',
      'webkitvisibilitychange',
      'mozvisibilitychange',
      'msvisibilitychange'
    ];

    this._settings = this._defaults;

    this._clearTimeout = null;
    this.reset();
  }

  private _idlenessEventsHandler(): void {
    if (this._idle) {
      this._idle = false;
      this._settings.onActive && this._settings.onActive();
    }
    this.resetTimeout(this._settings);
  }

  private _visibilityEventsHandler(): void {
    if (
      (document as any)?.hidden ||
      (document as any)?.webkitHidden ||
      (document as any)?.mozHidden ||
      (document as any)?.msHidden
    ) {
      if (this._visible) {
        this._visible = false;
        this._settings.onHide && this._settings.onHide();
      }
    } else {
      if (!this._visible) {
        this._visible = true;
        this._settings.onShow && this._settings.onShow();
      }
    }
  }

  private resetTimeout(
    _settings: T.SettingsType,
    keepTracking = this._settings.keepTracking
  ): void {
    if (this._clearTimeout) {
      this._clearTimeout();
      this._clearTimeout = null;
      this._idle = false;
    }
    if (keepTracking) {
      this.timeout(_settings);
    }
  }

  private timeout(_settings: T.SettingsType): void {
    const timer = this._settings.recurIdleCall
      ? {
          set: setInterval.bind(window),
          clear: clearInterval.bind(window)
        }
      : {
          set: setTimeout.bind(window),
          clear: clearTimeout.bind(window)
        };

    const id = timer.set(
      function () {
        this._idle = true;
        this._settings.onIdle && this._settings.onIdle();
      }.bind(this),
      this._settings.idle
    );

    this._clearTimeout = () => timer.clear(id);
  }

  private disableMessage() {
    console.warn('User activity service disabled.');
  }

  start(options?: T.SettingsType): IIdleService {
    this.set(options);
    if (!this.isEnable()) {
      this.disableMessage();
      return;
    }

    window.addEventListener('idle:stop', this.stop);
    this.timeout(this._settings);

    bulkAddEventListener(
      window,
      this._settings.events,
      this._idlenessEventsHandler
    );

    if (this._settings.onShow || this._settings.onHide) {
      bulkAddEventListener(
        document,
        this._visibilityEvents,
        this._visibilityEventsHandler
      );
    }

    return this;
  }

  stop(): IIdleService {
    if (!this.isEnable()) {
      this.disableMessage();
      return;
    }

    window.removeEventListener('idle:stop', this.stop);

    bulkRemoveEventListener(
      window,
      this._settings.events,
      this._idlenessEventsHandler
    );

    this.resetTimeout(this._settings, false);

    if (this._settings.onShow || this._settings.onHide) {
      bulkRemoveEventListener(
        document,
        this._visibilityEvents,
        this._visibilityEventsHandler
      );
    }

    return this;
  }

  private reset({
    idle = this._settings.startAtIdle,
    _visible = !this._settings.startAtIdle
  } = {}): IIdleService {
    this._idle = idle;
    this._visible = _visible;

    return this;
  }

  private throwOnBadKey(keys: string[], goodKeys: string[]): void {
    keys.forEach(function (key) {
      if (!goodKeys.includes(key)) {
        throw `set: Unknown key ${key}`;
      }
    });
  }

  set(options: T.SettingsType): void {
    this.throwOnBadKey(Object.keys(options), Object.keys(this._defaults));
    this._settings = Object.assign(this._settings, options);
  }

  isIdle(): boolean {
    return this.isEnable() ? this._idle : false;
  }

  isEnable(): boolean {
    return this._isEnable;
  }
}
