let eventIdSuffix = 100;

const getEventIdSuffix = ()=>{
  if (eventIdSuffix >= 999){
    eventIdSuffix = 100;
  }
  eventIdSuffix = eventIdSuffix+1;
  return eventIdSuffix;
};

export default getEventIdSuffix;
