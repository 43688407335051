import { IMfeRouterService } from '../../../services/mfeRouterService';
import bindAllMethods from '../../../utils/bindAllMethods';
import { MFERouterInterfaceType } from './types';

type MFERouterInterfaceParam = {
  mfeRouterService: IMfeRouterService;
};

class MFERouterInterface {
  private _MFERouterService: IMfeRouterService;

  constructor({ mfeRouterService }: MFERouterInterfaceParam) {
    this._MFERouterService = mfeRouterService;

    bindAllMethods(this);
  }

  public getInterface(): MFERouterInterfaceType {
    return {
      start: this._MFERouterService.start,
      getState: this._MFERouterService.getState,
      listen: this._MFERouterService.listen,
      useReactHook: this._MFERouterService.useReactHook
    };
  }
}

export default MFERouterInterface;
