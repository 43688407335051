import { CDMObject, DataCollectionEventAction, DataCollectionEventResult, FilterCDMTreesResult, FilterNotificationResult, FinishResult, ValveControllerMetadata, ValveFilterError } from '@jarvis/jweb-core';
import { logger } from '../helpers/logger';
import { publishResultEventData } from '../helpers/publishResultEventData';
import { QueueItem } from './QueueItem';

export const publishFilterError = async (metadata: ValveControllerMetadata, filteredData: FilterCDMTreesResult, trackingIdentifiers?: string[]) => {
  const filterNotificationResult: FilterNotificationResult = {
    valveControllerMetadata: metadata,
    valveFilterResult: filteredData.results
  };

  const finishResult: FinishResult = {
    result: DataCollectionEventResult.failure,
    message: (filteredData.results[0] as ValveFilterError)?.errorType
  };

  await publishResultEventData(DataCollectionEventAction.filterNotification, trackingIdentifiers, filterNotificationResult);
  await publishResultEventData(DataCollectionEventAction.finish, trackingIdentifiers, finishResult);
};

export const publishTelemetryError = async (err: any, trackingIdentifiers?: string[], beginTime?: number) => {
  const responseTime = beginTime ? Date.now() - beginTime : undefined;
  const isClientOrSuccessError = err.response?.status >= 400 && err.response?.status <= 499 ||
    err.response?.status >= 200 && err.response?.status <= 299;

  const message = isClientOrSuccessError ? err.response.statusText : err.message;
  const logMessage = isClientOrSuccessError ? `Queue::addToQueue::status:got status ${err.response.status}` : 'Queue::addToQueue::error:Server is out of service or some unknown error occured!';

  logger.log(logMessage);

  await publishResultEventData(
    DataCollectionEventAction.publishNotification,
    trackingIdentifiers,
    undefined,
    isClientOrSuccessError ? err.response : undefined,
    responseTime
  );

  await publishResultEventData(
    DataCollectionEventAction.finish,
    trackingIdentifiers,
    {
      result: DataCollectionEventResult.failure,
      message
    }
  );
};

export const isObjectEqual = (object1: { [x: string]: any }, object2: { [x: string]: any }): boolean => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  return objKeys1.every(key => {
    if (key !== 'currentDateTime') {
      const value1 = object1[key];
      const value2 = object2[key];
      const isObjects = isObject(value1) && isObject(value2);

      return (isObjects && isObjectEqual(value1, value2)) || (!isObjects && value1 === value2);
    }
    return true;
  });
};

const isObject = (object: any) => object != null && typeof object === 'object';

export enum QueueItemStatus {
  pending = 'pending',
  processing = 'processing',
}

export const preparePrebuildNotification = async (queueItem: QueueItem, trackingIds: string[] | undefined) => {
  const cdmObject: CDMObject = {
    tree: JSON.stringify(queueItem.notification),
    treeGun: 'com.hp.cdm.service.eventing.version.1.resource.notification'
  };
  await publishResultEventData(
    DataCollectionEventAction.filterNotification,
    trackingIds,
    {
      valveControllerMetadata: queueItem.metadata,
      valveFilterResult: 'Skipped due to preexisting filter operation'
    });
  return cdmObject;
};
