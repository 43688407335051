import isObject from '../../../../../../utils/isObject';
import { isValidRegex } from '../../../../../../utils/regex';

/**
 * Check if property value match with expected value.
 */
export default function criterionExternalSourceMatchValue(
  propertyValue: unknown,
  expectedValue: unknown
): boolean {
  if (Array.isArray(expectedValue) && Array.isArray(propertyValue)) {
    return expectedValue.every((expectedValueItem) =>
      propertyValue.some((valueItem) =>
        criterionExternalSourceMatchValue(valueItem, expectedValueItem)
      )
    );
  } else if (isObject(expectedValue) && isObject(propertyValue)) {
    return Object.keys(expectedValue).every((key) =>
      criterionExternalSourceMatchValue(propertyValue[key], expectedValue[key])
    );
  } else if (
    typeof expectedValue === 'string' &&
    isValidRegex(expectedValue) &&
    typeof propertyValue === 'string'
  ) {
    return new RegExp(expectedValue).test(propertyValue);
  }

  return propertyValue === expectedValue;
}
