export enum JWebPluginsNames {
  EventService = 'EventService',
  DataCollection = 'DataCollection',
  ValueStore = 'ValueStore'
}

/*

JWeb Types for Auth Plugin

  */
export enum TokenLifetimeRequirementType {
  /**
   * Requires a token that will attempt to refresh after X seconds since its `iat` time.
   *
   * Specifying `timeInterval > (exp - iat)` will be ignored and the component will refresh
   * when the token expires.
   */
  preferredMaximumSecondsSinceIssued = 'preferredMaximumSecondsSinceIssued',
  /**
   * Requires a token that will not expire for at least X seconds.
   *
   * Specifying `timeInterval >= (exp - iat)` will always trigger token refresh.
   */
  preferredMinimumSecondsUntilExpiration = 'preferredMinimumSecondsUntilExpiration'
}
export enum TokenType {
  /**
   * For user token
   */
  user = 'user',
  /**
   * For device token
   */
  device = 'device'
}

export enum UserInteractionEntryPoint {
  /**
   * User interaction should start on the sign in page
   */
  signIn = 'signIn',
  /**
   * User interaction should start on the create-account page
   */
  createAccount = 'createAccount'
}

export enum LogLevel {
  Debug = 'DEBUG',
  Warn = 'WARN',
  Verbose = 'VERBOSE',
  Error = 'ERROR',
  Info = 'INFO',
  Log = 'LOG',
  Trace = 'TRACE'
}

export enum AssetUnitType {
  /**
   * Used when filtering data for a mobile use case
   */
  mobile = 'mobile',
  /**
   * Used when filtering data for a desktop use case
   */
  desktop = 'desktop',
  /**
   * Used when filtering data for a solution use case
   */
  solution = 'solution'
}

export enum ReservedValueStoreKeys {
  /**
   * Application Instance Id (UUID)
   */
  applicationInstanceId = 'ApplicationInstanceId',
  /**
   * The stratus user id
   * 'stratus_id' in the stratus access token
   */
  stratusUserId = 'StratusUserId',
  /**
   * The hpid user id
   * 'sub' in the stratus access token
   */
  hpIdUserId = 'HpIdUserId',
  /**
   * The wpid user id
   * 'wpid' in the stratus access token
   */
  wpIdUserId = 'WpIdUserId',
  /**
   * The user's program level
   *
   * - TODO: Add more detail
   */
  userProgramLevel = 'UserProgramLevel',
  /**
   * The currently selected printer UUID
   */
  selectedPrinterUuid = 'SelectedPrinterUUID',
  /**
   * The model number of the selected printer
   * For example, the selected printer in the carousel for HP Smart
   */
  selectedPrinterModelNumber = 'SelectedPrinterModelNumber',
  /**
   * Application Identifier Name
   * Example: 'com.hpi.cdm.testapp'
   */
  webApplicationName = 'WebApplicationName',
  /**
   * Application version, 3 dot notation.
   * Semantic Versioning is HIGHLY recommended, with 4 segment for a monotonically increasing buildnumber or yyyymmddhh
   * Example: '8.4.1.2'
   */
  webApplicationVersion = 'WebApplicationVersion',
  /**
   * Contents of the OneTrust OptanonConsent cookie with a urn prefix (URL decoded!!)
   * urn model used to allow for migration away from OneTrust in the future
   */
  webApplicationConsent = 'WebApplicationConsent',
  /**
   * Unique UUID for the visit, intended as a short term cooking tracking (tbd app specific)
   * Example being a uuid set to a session cookie with an idle timeout of 1hr
   * Ex: "123e4567-e89b-12d3-a456-426614174000"
   */
  visitUuid = 'VisitUuid',
  /**
   * Unique UUID for the visitor, intended as a long term cookie tracking (tbd onetrust?)
   * Example being the consentId uuid value from webAppConsent field
   * Example: "123e4567-e89b-12d3-a456-426614174000"
   */
  visitorUuid = 'VisitorUuid',
  /**
   * Cloud service correlation linking key
   */
  ucdeCorrelationId = 'UcdeCorrelationId',
  /**
   * (Windows) The Device UUID (BIOS UUID) pulled from System APIs.
   */
  pcDeviceUuid = 'PcDeviceUuid',
  /**
   * The serial number that is pulled from System APIs.
   */
  systemSerialNumber = 'SystemSerialNumber',
  /**
   * The tenant id
   * The tenant ID to request a new user token be bounded to
   */
  tenantId = 'TenantId'
}

/**
 * Indicates the state of a service instance
 */
export enum ServiceInstanceState {
  /**
   * Indicates that the service instance is in the process of launching.  This is the initial
   * state for a service instance
   */
  launching = 'launching',
  /**
   * Indicates that the service instance successfully launched and is still running
   */
  running = 'running',
  /**
   * Indicates that the service instance has ended and its container has closed.  This
   * is a terminal state.
   */
  closed = 'closed',
  /**
   * Indicates that the service instance failed to launch.  For example, services
   * that launch in a Jarvis WebView may fail to launch when the device is not connected
   * to a network.  This is a terminal state.
   */
  failedToLaunch = 'failedToLaunch'
}

/**
 * Indicates the type of error that occurred when any of the plugin methods are called
 */
export enum ServiceRoutingErrorType {
  /**
   * Returned when a plugin method that requires a service identifier is called with an identifier
   * that does not correspond to any available service
   */
  serviceNotFound = 'serviceNotFound',
  /**
   * Indicates that one or more options passed in a plugin method are invalid. The reason in
   * the service routing error should include more details about what was invalid.
   */
  invalidOptions = 'invalidOptions',
  /**
   * Returned when a plugin methods that requires a service instance identifier is called
   * with an identifier that does not correspond to any known service instance. Note that
   * a  service instance with this identifier may have existed at some point, but is no
   * longer recorded
   */
  serviceInstanceNotFound = 'serviceInstanceNotFound',
  /**
   * Returned when an unexpected error occurs in any plugin method. The reason in the service routing
   * error should include more details about the error
   */
  unknownError = 'unknownError',
  /**
   * Returned when a service that is requested to be launched is not supported.
   * For example, this could mean that Services or Plugins that the requested Service requires are not available.
   */
  serviceNotSupported = 'serviceNotSupported'
}

export enum DataCollectionEventNames {
  /**
   * The event name that the data collection service listens for, to filter and send cdm events (such as simpleUi) to the jarvis telemetry service.
   */
  cdmEvent = 'com.hp.jarvis.datacollection.cdm.event',
  /**
   * The name of the event that clients can subscribe to to be informed of the result of internal data collection processing.
   */
  cdmEventStatus = 'com.hp.jarvis.datacollection.cdm.event.status'
}

export enum Stack {
  local = 0,
  dev = 1,
  pie = 2,
  staging = 3,
  production = 4,
  custom = 5
}

/**
 * The types of events that are emitted by the Eventing Service, on behalf of the Service Routing Component.
 */
export enum ServiceInstanceEvent {
  /**
   * The event emitted when the service instance state has changed.
   */
  jarvisEventServiceInstanceStateChanged = 'com.hp.jarvis.servicerouting.serviceinstance.statechanged',
  /**
   * The event emitted when a service instance has closed.
   * *Optional* Service result data will be contained inside the eventData, in the 'extraData' field.
   */
  jarvisEventServiceInstanceClosed = 'com.hp.jarvis.servicerouting.serviceinstance.closed',
  /**
   * The event emitted when an error in a service instance occurs.
   */
  jarvisEventServiceInstanceError = 'com.hp.jarvis.servicerouting.serviceinstance.error'
}

/**
 * Error codes for the Auth plugin
 */
export enum AuthPluginErrorCode {
  /**
   * The operation failed due to no network connection
   */
  networkNotConnected = 'networkNotConnected',
  /**
   * The operation failed because it would require network access but allowNetworkAccess is set to false
   */
  networkNotAllowed = 'networkNotAllowed',
  /**
   * The operation failed because it would require user interaction but allowUserInteraction is set to false
   */
  userInteractionNotAllowed = 'userInteractionNotAllowed',
  /**
   * The operation was canceled by the user
   */
  userCanceled = 'userCanceled',
  /**
   * The given account is not logged in
   */
  accountNotLoggedIn = 'accountNotLoggedIn',
  /**
   * The token request process failed.  This error is only returned if a token refresh request fails, and
   * the failure is NOT due to an invalid refresh token.  If the failure happened due to an invalid
   * refresh token, then depending on the value of allowUserInteraction option, either the sign in flow will
   * start or the 'userInteractionNotAllowed' error will be returned
   */
  tokenRequestFailed = 'tokenRequestFailed',
  /**
   * The logout request process failed
   */
  logoutRequestFailed = 'logoutRequestFailed',
  /**
   * An invalid option was specified
   */
  invalidOption = 'invalidOption',
  /**
   * The operation failed due to an unknown error
   */
  unknownError = 'unknownError'
}
