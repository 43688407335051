import { ControlledData } from '../interfaces/controlledData';
import { Schema } from '../interfaces/Schema';
export class OriginatorContextDetail implements Schema {
  /**
   * Device Uuid of the imagingDevice associated with this event
   */
  associatedDeviceUuid?: string;

  /**
   * HP Device Product Number associated with this event
   */
  associatedDeviceProductNumber?: string;

  /**
   * Stratus UserID of the user associated with this event
   */
  associatedStratusUserId?: string;

  /**
   * Cloud service correlation linking key
   */
  ucdeCorrelationId?: string;

  constructor(controlledData: ControlledData) {
    this.associatedDeviceUuid = controlledData.associatedDeviceUuid;
    this.associatedDeviceProductNumber =
      controlledData.associatedDeviceProductNumber;
    this.associatedStratusUserId = controlledData.associatedStratusUserId;
    this.ucdeCorrelationId = controlledData.ucdeCorrelationId;
  }

  version = '1.0.0';

  get gun() {
    return 'com.hp.cdm.domain.telemetry.type.originatorContextDetail.originator.ucdeCloudWebApp.version.1';
  }
}
