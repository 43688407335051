import { ApplicationContext, jWebReady, OpenKeyOption, ReservedKeyOption, ReservedValueStoreKeys, ValueStoreError, ValueStoreGetResult, ValueStoreGetSuccessResult, ValveControllerMetadata } from '@jarvis/jweb-core';
import { getWindowValues } from '../client/utils/enum';

export const getValveControllerMetadataFromValueStore = async (valveMetaData?: ValveControllerMetadata) => {
  const filterMetadata: ValveControllerMetadata = valveMetaData === undefined ? {} : valveMetaData;
  filterMetadata.country = getWindowValues().osCountryRegion;
  const result = await getByKeyFromValueStore([ReservedValueStoreKeys.hpIdUserId,ReservedValueStoreKeys.applicationInstanceId,ReservedValueStoreKeys.selectedPrinterUuid,ReservedValueStoreKeys.selectedPrinterModelNumber,ReservedValueStoreKeys.stratusUserId,ReservedValueStoreKeys.tenantId]);
  if (result !== undefined){
    result.forEach((eachResult)=>{
      if ((eachResult as ValueStoreGetSuccessResult).key) {
        const getResult = eachResult as ValueStoreGetSuccessResult;
        switch (getResult.key) {
        case ReservedValueStoreKeys.hpIdUserId:
          filterMetadata.accountLoginId = getResult.value || filterMetadata.accountLoginId ;
          break;
        case ReservedValueStoreKeys.applicationInstanceId:
          filterMetadata.appInstanceId = getResult.value || filterMetadata.appInstanceId;
          break;
        case ReservedValueStoreKeys.selectedPrinterUuid:
          filterMetadata.deviceId = getResult.value || filterMetadata.deviceId;
          break;
        case ReservedValueStoreKeys.selectedPrinterModelNumber:
          filterMetadata.edgeType = getResult.value || filterMetadata.edgeType;
          break;
        case ReservedValueStoreKeys.stratusUserId:
          filterMetadata.stratusUserId = getResult.value || filterMetadata.stratusUserId;
          break;
        case ReservedValueStoreKeys.tenantId:
          filterMetadata.tenantId = getResult.value || filterMetadata.tenantId;
          break;
        }
      }
    });
  }
  return filterMetadata;
};

export const getApplicationContextFromValueStore = async (applicationContext?: ApplicationContext) => {
  applicationContext = applicationContext || {};
  const result = await getByKeyFromValueStore([ReservedValueStoreKeys.webApplicationName,ReservedValueStoreKeys.webApplicationVersion,ReservedValueStoreKeys.webApplicationConsent,ReservedValueStoreKeys.visitUuid,ReservedValueStoreKeys.visitorUuid,ReservedValueStoreKeys.ucdeCorrelationId]);
  if (result !== undefined){
    result.forEach((eachResult)=>{
      if ((eachResult as ValueStoreGetSuccessResult).key && typeof applicationContext != 'undefined') {
        const getResult = eachResult as ValueStoreGetSuccessResult;
        switch (getResult.key) {
        case ReservedValueStoreKeys.webApplicationName:
          applicationContext.webAppName = getResult.value || applicationContext?.webAppName;
          break;
        case ReservedValueStoreKeys.webApplicationVersion:
          applicationContext.webAppVersion = getResult.value || applicationContext?.webAppVersion;
          break;
        case ReservedValueStoreKeys.webApplicationConsent:
          applicationContext.webAppConsent = getResult.value || applicationContext?.webAppConsent;
          break;
        case ReservedValueStoreKeys.visitUuid:
          applicationContext.visitUuid = getResult.value || applicationContext?.visitUuid;
          break;
        case ReservedValueStoreKeys.visitorUuid:
          applicationContext.visitorUuid = getResult.value || applicationContext?.visitorUuid;
          break;
        case ReservedValueStoreKeys.ucdeCorrelationId:
          applicationContext.ucdeCorrelationId = (applicationContext?.ucdeCorrelationId || getResult.value) ?? undefined;
          break;
        }
      }
    });
  }
  return applicationContext;

};

const getByKeyFromValueStore = async (keys: (OpenKeyOption | ReservedKeyOption)[]): Promise<(ValueStoreGetSuccessResult | undefined)[] | undefined> => {
  const valueStore = (await jWebReady).Plugins?.ValueStore;
  if (valueStore){
    const result = await valueStore?.get({ keys });
    if ((result as ValueStoreGetResult).result) {
      return (result as ValueStoreGetResult).result.map((valueStoreResult)=>{
        if ((valueStoreResult as ValueStoreError).errorType) {
          return undefined;
        } else {
          return (valueStoreResult as ValueStoreGetSuccessResult);
        }
      });
    } else {
      return undefined;
    }
  } else return undefined;
};
