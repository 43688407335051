import type * as T from './types';
import defaultSchema from './schemas/defaultSchema';

export default async function getGraphqlMockNorthboundAPI(
  dependencies?: T.GetGraphqlMockNorthboundAPIDependenciesType
): Promise<T.GetGraphqlMockNorthboundAPIReturnType> {
  if (!dependencies?.enable) return undefined;

  let data: any = dependencies?.data;
  let React: any;
  const result: T.GetGraphqlMockNorthboundAPIReturnType = {};

  if (dependencies?.dataURL) {
    data = await fetch(dependencies.dataURL)
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error fetching graphql mocked data: ', error);
        return data;
      });
  }
  if (!data && dependencies?.useDefaultData) {
    data = defaultSchema;
  }

  if (typeof data !== 'object') return undefined;

  result.v1 = {
    gql: (() => '') as any,
    reactTools: {
      createGraphQLProvider: (react) => {
        if (!React?.useState) {
          React = react;
        }
        return ({ children }) => children;
      },
      useQuery: () => ({
        data: data,
        loading: false
      }),
      useLazyQuery: () => {
        const [called, setCalled] = React.useState(false);
        return [
          async () => {
            setCalled(true);
          },
          {
            called,
            data: called ? data : undefined,
            loading: false
          }
        ];
      },
      useMutation: () => {
        const [called, setCalled] = React.useState(false);
        return [
          async () => {
            setCalled(true);
          },
          {
            called,
            data: called ? data : undefined,
            loading: false
          }
        ];
      }
    },
    query: async () => ({
      data: data,
      loading: false
    }),
    mutate: async () => ({
      data: data,
      loading: false
    }),
    getClient: () => ({
      query: async () => ({
        data: data,
        loading: false
      }),
      mutate: async () => ({
        data: data,
        loading: false
      })
    })
  };

  return result;
}
