import * as internals from './internals';
import { getServices } from '../../../infra/commonInitializer';
import UserActivityInterface from './UserActivityInterface';
import { UserActivityInterfaceType } from './types';

let userActivityInterface: UserActivityInterface;

export function getUserActivityInterface(): UserActivityInterface {
  return userActivityInterface;
}
export function clearUserActivityInterface(): void {
  userActivityInterface = undefined;
}

export async function initializeUserActivityInterface(): Promise<UserActivityInterfaceType> {
  if (userActivityInterface) return userActivityInterface.getInterface();

  const { idleService, eventService } = getServices();

  userActivityInterface = new UserActivityInterface({
    idleService
  });

  // TODO should be a jshell use case beside that events should be a service instead interface.
  userActivityInterface.start({
    onActive: () => internals.handleOnActive(eventService), // callback function to be executed after back form idleness
    onIdle: () => internals.handleOnIdle(eventService), // callback function to be executed after idle time
    onHide: () => internals.handleOnHide(eventService), // callback function to be executed when window become hidden
    onShow: () => internals.handleOnShow(eventService) // callback function to be executed when window become visible
  });

  return userActivityInterface.getInterface();
}

export function createNoopUserActivityInterface(): UserActivityInterfaceType {
  return {
    isIdle: function (): boolean {
      console.debug('Function not implemented.');
      return false;
    }
  };
}
