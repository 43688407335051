import { DataCollectionHTTPResponse, ValveFilterErrorType } from '@jarvis/jweb-core';

export class DataValveFilterError extends Error {
  errorType: ValveFilterErrorType;
  reason?: string;
  httpResponse?: DataCollectionHTTPResponse;
  exception?: string;

  constructor(errorType: ValveFilterErrorType, reason: string, exception: string, httpResponse?: DataCollectionHTTPResponse) {
    super(exception);
    this.constructor = DataValveFilterError;
    this.reason = reason;
    this.errorType = errorType;
    this.exception = `${errorType}: ${exception}. Reason: ${reason} ${httpResponse || ''}`;
  }
}
