import { LoginInputType } from '../../../../../services/session/types';

export const getPostLoginRedirect = (
  loginData: LoginInputType,
  basePath?: string
): string => {
  const postLoginRedirectKey = 'postLoginRedirect';
  const urlParams = new URLSearchParams(window.location.search);

  const searchParamPostLoginRedirectUrl = urlParams.get(postLoginRedirectKey);
  const loginDataPostLoginRedirectUrl = loginData?.postLoginRedirect;
  const defaultPostLoginRedirectUrl = basePath;
  const postLoginRedirectPath =
    searchParamPostLoginRedirectUrl ||
    loginDataPostLoginRedirectUrl ||
    defaultPostLoginRedirectUrl ||
    '/';
  return postLoginRedirectPath;
};
