import * as T from '../../tntCommonResourceRegistry/types';
import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client';
import {
  DEFAULT_INITIAL_CURRENT_PAGE as DEFAULT_INITIAL_PAGE,
  DEFAULT_PAGINATION_SIZE_GET_NODE_PERSON_DOMAIN
} from '../../tntCommonResourceRegistry/constants';
import { Stack } from '../../../../types/stratus';
import { getStratusBaseUrl } from '../../../stackUtils';
import { internalLogger } from '../../../../interface/v1/logger';

export default class UserTenantDetailsClient extends StratusClient {
  apiName = 'ws-hp.com/v3/usermgtsvc';
  apiVersion = '3';
  userTenantDetailsV1Endpoint = '/usertenantdetails';

  public static getBaseUrl(stack: Stack): string {
    return `${getStratusBaseUrl(stack)}/v3/usermgtsvc`;
  }

  public async getUserTenantDetails(
    options: T.GetPersonalDomainOptionsType
  ): Promise<T.UserTenantDetailsResponseType> {
    const { userResourceId, page, paginationSize } = options;

    const response = await this.jarvisWebHttpInstance
      .get({
        url: `/usertenantdetails`,
        params: {
          userResourceId,
          startIndex: page || DEFAULT_INITIAL_PAGE,
          maxResult:
            paginationSize || DEFAULT_PAGINATION_SIZE_GET_NODE_PERSON_DOMAIN
        }
      })
      .catch((err) => {
        internalLogger?.error(err);
        return {
          data: { resourceList: [], totalCount: 0, currentReturnCount: 0 }
        };
      });

    return response.data as T.UserTenantDetailsResponseType;
  }
}
