import { InitialTenantType } from '../../../services/tenantHandler/types';
import { LastTenantIdsRepository } from './LastTenantIdsRepository';
import { INavigationService } from '../../../services/navigationService';
import { ITenantHandlerService } from '../../../services/tenantHandler';
import IApplicationService from '../../../services/applicationService/IApplicationService';
import { IAuthTokenService } from '../../../services/authTokenService';
import TenantLevelEnum from '../../../services/tenantHandler/TenantLevelEnum';

type TenantRetrieverOptions = {
  navigationService: INavigationService;
  tenantHandlerService: ITenantHandlerService;
  applicationService: IApplicationService;
  authTokenService: IAuthTokenService;
  repository: LastTenantIdsRepository;
  queryParamsEnabled?: boolean;
};
export default class TenantRetriever {
  private _repository: LastTenantIdsRepository;
  private DEPRECATED_INITIAL_TENANT_CONSTANT = 'organizationId';
  private _navigationService: INavigationService;
  private _tenantHandlerService: ITenantHandlerService;
  private _applicationService: IApplicationService;
  private _authTokenService: IAuthTokenService;
  private _queryParamsEnabled: boolean;
  public tenantLevelsAvailable = [];

  constructor({
    navigationService,
    tenantHandlerService,
    authTokenService,
    applicationService,
    repository,
    queryParamsEnabled
  }: TenantRetrieverOptions) {
    this._repository = repository;
    this._navigationService = navigationService;
    this._tenantHandlerService = tenantHandlerService;
    this._authTokenService = authTokenService;
    this._applicationService = applicationService;
    this._queryParamsEnabled = queryParamsEnabled ?? true;

    this.tenantLevelsAvailable = [];
    Object.values(TenantLevelEnum).forEach((level) => {
      this.tenantLevelsAvailable.push(Number(level));
    });
  }

  /**
   *  Retrieves the initial tenants from the tenant handler service and returns them as an array of tenant objects.
   * @returns {InitialTenantType[]} An array of tenant objects, each with a 'level' and an 'id'.
   */
  public retrieveInitialTenants(): InitialTenantType[] {
    return this._tenantHandlerService?.getInitialTenants();
  }

  /**
   * Merges two lists of tenants and returns a single list. The first list has priority over the second list.
   * @param {InitialTenantType[]} firstList - The first list of tenants.
   * @param {InitialTenantType[]} secondList - The second list of tenants.
   * @returns {InitialTenantType[]} A merged list of tenants.
   */
  public mergeTenants = (
    firstList: InitialTenantType[],
    secondList: InitialTenantType[]
  ): InitialTenantType[] => {
    const finalList = [...firstList];

    secondList.forEach(({ id, level }) => {
      if (!finalList.some((tenant) => tenant.level === level)) {
        finalList.push({ id, level });
      }
    });

    return finalList;
  };

  /**
   * Retrieve a Tenant Id from the authToken.
   * @returns {InitialTenantType[]} A merged list of tenants.
   */
  public retrieveInNativeFirstLoad = (): InitialTenantType[] => {
    const isJShell = !!this._applicationService?.getClientId();
    const tokenTenantId = this._authTokenService?.getTenantIdFromToken();
    const shellTenantId = this._tenantHandlerService?.getTenantId();

    const tenantList: InitialTenantType[] = [];
    if (isJShell && tokenTenantId && !shellTenantId) {
      tenantList.push({ id: tokenTenantId, level: 1 });
    }
    return tenantList;
  };

  /**
   *  Retrieves the last tenant from the repository and returns it as an array of tenant objects.
   * @returns {InitialTenantType[]} An array of tenant objects, each with a 'level' and an 'id'.
   */
  public retrieveLastTenantsIdFromRepository = (): InitialTenantType[] => {
    //Retrieving the last tenant from the repository
    const lastTenantIds =
      this._repository.find()?.map((t) => {
        return {
          level: t.level,
          id: t.id
        };
      }) || [];
    return lastTenantIds;
  };

  /**
   * Extracts tenant information from URL query parameters and constructs a list of tenant objects.
   *
   * @returns {InitialTenantType[]} An array of tenant objects, each with a 'level' and an 'id'.
   */
  public retrieveTenantListFromQueryParams(): InitialTenantType[] {
    // It is not enabled.
    if (!this._queryParamsEnabled) return [];

    const queryParams = new URLSearchParams(
      this._navigationService.location.search
    );
    const tenantLevel = this._tenantHandlerService?.START_TENANT_LEVEL;
    const tenantIdList = [] as InitialTenantType[];

    // TODO: Remove this after Instant Ink stops to use it. It's to support a deprecated feature.
    const deprecatedInitialTenant = queryParams.get(
      this.DEPRECATED_INITIAL_TENANT_CONSTANT
    );
    if (deprecatedInitialTenant) {
      tenantIdList.push({
        level: tenantLevel,
        id: deprecatedInitialTenant
      });
      return tenantIdList;
    }

    // Get the Tenants from QueryParams
    this.tenantLevelsAvailable.forEach((level) => {
      if (queryParams.has(`t${level}`)) {
        const tenantQuery = `t${level}`;
        const tenantId = queryParams.get(tenantQuery);
        tenantIdList.push({ level: level, id: tenantId || '' });
      }
    });

    return tenantIdList;
  }

  /**
   * Checks if there are tenants in the URL query parameters.
   * @returns {boolean} True if there are tenants in the query parameters, false otherwise.
   */
  public hasTenantOnQueryParams(): boolean {
    // It is not enabled.
    if (!this._queryParamsEnabled) return false;

    const queryParams = new URLSearchParams(window.location.search);
    // TODO: Remove this after Instant Ink stops to use it. It's to support a deprecated feature.
    const hasTenantOnDeprecatedQueryParam = queryParams.has(
      this.DEPRECATED_INITIAL_TENANT_CONSTANT
    );

    return (
      queryParams.has(`t${this._tenantHandlerService.START_TENANT_LEVEL}`) ||
      hasTenantOnDeprecatedQueryParam
    );
  }
}
