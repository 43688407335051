import { ValueStoreError, ValueStoreErrorType } from '@jarvis/jweb-core';

export const valueStorePluginErrorMethod = (errorType: ValueStoreErrorType, errorMessage: string): ValueStoreError => {
  console.error(errorMessage);
  const error: ValueStoreError = {
    errorType,
    errorReason: errorMessage,
  };
  return error;
};
