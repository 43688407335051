enum EventCategoryEnum {
  visitContextRaw = 'visitContextRaw',
  simpleUi = 'simpleUi'
}

enum EventCategoryGunEnum {
  visitContextRaw = 'com.hp.cdm.domain.telemetry.type.eventDetail.category.visitContextRaw.version.1',
  simpleUi = 'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1'
}

enum EventDataActionEnum {
  controlHyperLinkClicked = 'ControlHyperLinkClicked',
  modalWindowDisplayed = 'ModalWindowDisplayed',
  controlButtonClicked = 'ControlButtonClicked'
}

enum EventDataControlNameEnum {
  okButton = 'OkButton',
  cancelButton = 'CancelButton',
  languageFooterButton = 'LanguageFooterButton',
  resumeButton = 'ResumeButton',
  startNewSessionButton = 'StartNewSessionButton'
}

enum EventDataActivityEnum {
  allV01 = 'All-v01'
}

enum EventSchemaVersionEnum {
  simpleUi = '1.4.0',
  visitContextRaw = '1.0.0',
  eventingEvent = '1.4.0'
}

enum EventDataScreenNameEnum {
  backButtonAlert = 'BackButtonAlert',
  footerSection = 'FooterSection',
  resumeSession = 'ResumeSession'
}

enum EventDataScreenPathEnum {
  portalOobe = '/PortalOobe/',
  postOobe = '/PostOobe/'
}

export {
  EventCategoryEnum,
  EventCategoryGunEnum,
  EventDataActionEnum,
  EventDataControlNameEnum,
  EventDataActivityEnum,
  EventSchemaVersionEnum,
  EventDataScreenNameEnum,
  EventDataScreenPathEnum
};
