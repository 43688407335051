import {
  customerIdName,
  enableAST,
  organizationIdName,
  tenantIdName
} from '../../../config/constants';
import { TokenType } from '../../../interface/types';
import { deleteCookie } from '../../../utils/cookies';
import { removeItemLocalStorage } from '../../../utils/toBeRemoved/localStorage';

export const clearUserData = (): void => {
  deleteCookie(tenantIdName);
  removeItemLocalStorage(organizationIdName);
  removeItemLocalStorage(customerIdName);
  removeItemLocalStorage(TokenType.orglessToken);
  removeItemLocalStorage(TokenType.orgedToken);
  removeItemLocalStorage(enableAST);

  // Removing deprecrated cookies
  deleteCookie(TokenType.deprecatedOrglessToken, '/', false);
  deleteCookie(TokenType.deprecatedOrgedToken, '/', false);
};
