import { internalLogger } from '../../interface/v1/logger';
import { EventServicePlugin, Subscriber } from './types';

async function getEventServicePlugin(): Promise<EventServicePlugin> {
  const windowEventService: EventServicePlugin = (window as any)?.JWeb?.Plugins
    ?.EventService;
  if (!windowEventService) {
    internalLogger?.error(
      'Unable to obtain EventService (missing some JWeb plugin)'
    );
  }
  return windowEventService;
}

async function getSubscribe(): Promise<Subscriber> {
  const eventServicePlugin = await getEventServicePlugin();
  const subscriber =
    (await eventServicePlugin.createSubscriber()) as Subscriber;
  return subscriber;
}

export { getEventServicePlugin, getSubscribe };
