import ServiceWorkerInterfaceV1 from './ServiceWorkerInterfaceV1';
import { ServiceWorkerInterfaceV1Type } from './types';
import { getServices } from '../../../infra/commonInitializer';

let serviceWorkerInterfaceV1: ServiceWorkerInterfaceV1;

export function getServiceWorkerInterfaceV1(): ServiceWorkerInterfaceV1Type {
  return serviceWorkerInterfaceV1.getInterface();
}

export default async function initializeServiceWorkerInterfaceV1(): Promise<ServiceWorkerInterfaceV1Type> {
  if (serviceWorkerInterfaceV1) return serviceWorkerInterfaceV1.getInterface();

  const { serviceWorkerService } = getServices();

  serviceWorkerInterfaceV1 = new ServiceWorkerInterfaceV1({
    serviceWorkerService
  });

  return serviceWorkerInterfaceV1.getInterface();
}

export function createNoopServiceWorkerInterface(): ServiceWorkerInterfaceV1Type {
  return {
    clearCache: function (): Promise<void> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    }
  };
}
