import EventNames from '../../config/eventNames';
import { getRepositories, getServices } from '../../infra/commonInitializer';
import { CommonsInitializeInterfaceType } from '../../infra/commonInitializer/types';
import authV2 from '../v2/auth';
import initializeABTestingInterface from './ABTesting';
import accessControlImport from './accessControl';
import initializeAnalyticsInterface from './analytics';
import initializeAppInterfaceV1 from './app';
import auth from './auth';
import Breadcrumb from './breadcrumb';
import initializeCriterionSingleton from './Criterion/singleton';
import entitlementsImport from './entitlements';
import EventInterface from './events';
import Fallback from './Fallback';
import initializeFeatureFlagInterfaceV1 from './featureFlags';
import initializeGrantsInterfaceV1 from './grants';
import initializeGrantsHistoryInterfaceV1 from './grants/grantsHistory';
import initializeGraphQLInterfaceV1 from './graphql';
import Layout from './Layout';
import initializeLocalizationInterface from './localization';
import loggerImport from './logger';
import initializeMFERouterInterface from './mfeRouter';
import initializeMonitoringInterfaceV1 from './monitoring';
import initializeNavigationInterface from './navigation';
import optimizely from './optimizely';
import OrgSelector from './orgSelector';
import initializeRoutesInterface from './routes';
import serviceRoutingImport from './serviceRouting';
import initializeServiceWorkerInterface from './serviceWorker';
import initializeSessionInterfaceV1 from './sessionInterface';
import Store from './store';
import initializeTenantHandlerInterfaceSingleton from './TenantHandler';
import initializeThemeInterface from './theme';
import { ShellInterfaceV1Type, UserSessionInterfaceType } from './types';
import { initializeUserActivityInterface } from './userActivity';
import initializeUserInterfaceV1 from './userInterface';

export default async function initializeShellInterfaceV1(
  commonsInitializeInterfaces: CommonsInitializeInterfaceType
): Promise<ShellInterfaceV1Type> {
  const services = getServices();
  const repositories = getRepositories();
  const {
    appContext,
    applicationService,
    authTokenService,
    breadcrumbService,
    featureFlagService,
    scopeService,
    mfeRouterService,
    sessionService,
    loginService,
    tenantHandlerService,
    supportSessionService,
    userService,
    localizationService,
    navigationService,
    eventService
  } = services;

  const { entitlementRepository } = repositories;

  const {
    accessControl: accessControlInput,
    adapter: adapterInput,
    analytics: analyticsInput,
    breadcrumb: breadcrumbInput,
    criterions: criterionsInput,
    entitlements: entitlementsInput,
    fallbackConfiguration,
    localization: localizationInput,
    logger: loggerInput,
    login: loginInput,
    navigation: navigationInput,
    onboarding: onboardingInput,
    userOnboarding: userOnboardingInput,
    optimizely: optimizelyInput,
    stack,
    basePath
  }: CommonsInitializeInterfaceType = commonsInitializeInterfaces;

  // TODO: Should get its service via getServices method.
  const appInterface = await initializeAppInterfaceV1({
    applicationService
  });

  //Initializing auth provider
  const authProvider = await auth(loginInput);
  const authProviderV2 = authV2(loginInput);

  // Initialize services
  authProvider._setSessionService(sessionService);
  authProvider._setTenantHandlerService(tenantHandlerService);
  authProvider._setNavigationService(navigationService);

  authProviderV2._setSessionService(sessionService);
  authProviderV2._setTenantHandlerService(tenantHandlerService);
  authProviderV2._setNavigationService(navigationService);

  //Initializing other interfaces

  const eventsInterface = new EventInterface();

  const storeInterface = new Store();

  const userActivityInterface = await initializeUserActivityInterface();

  optimizely(optimizelyInput);

  const abTestingInterface = await initializeABTestingInterface();

  const analyticsInterface = await initializeAnalyticsInterface({
    isEnabled: analyticsInput.enabled
  });

  // TODO we'll remove all this code when interfaces won't be necessary anymore.
  services?.analyticsService?.setInterfaceDependencies({
    authProvider,
    store: storeInterface
  });
  await services?.analyticsService?.init();
  await services?.consentService?.init();

  const navigationInterface = await initializeNavigationInterface({
    useLegacyNavigation: navigationInput.useLegacyNavigation
  });

  const localizationInterface = await initializeLocalizationInterface();

  const accessControlInterface = await accessControlImport({
    accessControl: accessControlInput,
    appContext,
    store: storeInterface,
    events: eventsInterface,
    scopeService
  });

  const entitlementsInterface = await entitlementsImport({
    authProvider: authProviderV2,
    entitlements: entitlementsInput,
    store: storeInterface,
    events: eventsInterface,
    sessionService,
    appContext,
    authTokenService,
    repository: entitlementRepository
  });

  const orgSelector = await OrgSelector({
    onboarding: onboardingInput,
    authProvider: authProviderV2.createOrgedAuthProvider(),
    orglessAuthProvider: authProviderV2.createOrglessAuthProvider(),
    navigation: navigationInterface,
    localization: localizationInterface,
    sessionService,
    tenantHandlerService,
    userService
  });

  const featureFlagsInterface = await initializeFeatureFlagInterfaceV1({
    featureFlagService
  });

  const serviceRouting = serviceRoutingImport({
    onboardingInput
    // store: storeInterface
  });

  const loggerInterface = await loggerImport(loggerInput);

  const grantsInterface = await initializeGrantsInterfaceV1();
  const grantsHistoryInterface = await initializeGrantsHistoryInterfaceV1();

  const userInterface = await initializeUserInterfaceV1();

  const sessionInterface = await initializeSessionInterfaceV1();

  const criterionInterface = initializeCriterionSingleton({
    interfaces: {
      accessControl: accessControlInterface,
      entitlements: entitlementsInterface,
      grantsHistory: grantsHistoryInterface,
      grants: grantsInterface
    },
    services: {
      eventService,
      sessionService,
      tenantHandler: tenantHandlerService,
      featureFlagService,
      userService,
      localization: localizationService
    },
    criterions: criterionsInput
  });

  // TODO we'll remove all this code when interfaces won't be necessary anymore.
  services?.routesService?.setInterfaceDependencies({ criterionInterface });

  // My desire is to remove that, but unfortunally is been used for other MFEs.
  const userSession: UserSessionInterfaceType = {
    redirectToLogin: loginService.redirectToLogin,
    getLoginPath: loginService.getLoginPath
  };

  const fallbackData = {
    event: {
      eventService: eventService,
      eventName: EventNames.globalFallback
    },
    navigationInterface: navigationInterface,
    configuration: fallbackConfiguration
  };
  const fallback = new Fallback(fallbackData);

  const layout = new Layout();

  await breadcrumbService.start({
    enable: breadcrumbInput?.enable,
    settingsList: breadcrumbInput?.settingsList,
    defaultSettingsKey: breadcrumbInput?.defaultSettingsKey,
    navigation: navigationInterface,
    criterion: criterionInterface
  });

  const breadcrumbsInterface = new Breadcrumb();

  /**
   * Starting MFE Router below
   */
  // TODO we'll remove all this code when interfaces won't be necessary anymore.
  mfeRouterService.setInterfaceDependencies({
    interfaces: {
      store: storeInterface,
      navigation: navigationInterface,
      fallback,
      authProvider,
      localization: localizationInterface,
      criterion: criterionInterface
    }
  });

  // TODO: merge Start and Init.
  if (!adapterInput?.disableRouter) {
    await mfeRouterService.init({
      localization: localizationInput,
      onboarding: onboardingInput,
      login: loginInput,
      userOnboarding: userOnboardingInput,
      basePath: basePath,
      stack: stack
    });
    mfeRouterService.start();
  }

  // Now, we start the MFERouterInterface.
  const microfrontendRouter = await initializeMFERouterInterface({
    isEnabled: !adapterInput?.disableRouter
  });
  /**
   * Bootstrapping mfeRouter  finished.
   */

  const routesInterface = await initializeRoutesInterface();

  const tenantHandlerInterface =
    await initializeTenantHandlerInterfaceSingleton();

  const graphQLInterface = await initializeGraphQLInterfaceV1();

  const monitoringInterface = await initializeMonitoringInterfaceV1();

  const themeInterface = await initializeThemeInterface();

  const serviceWorkerInterface = await initializeServiceWorkerInterface();

  return {
    app: appInterface,
    abTesting: abTestingInterface,
    userInterface,
    sessionInterface,
    sessionService,
    authProvider,
    analytics: analyticsInterface,
    events: eventsInterface,
    navigation: navigationInterface,
    store: storeInterface,
    featureFlags: featureFlagsInterface,
    localization: localizationInterface,
    orgSelector,
    theme: themeInterface,
    serviceRouting,
    logger: loggerInterface,
    userActivity: userActivityInterface,
    entitlements: entitlementsInterface,
    accessControl: accessControlInterface,
    criterion: criterionInterface,
    userSession,
    fallback,
    microfrontendRouter,
    tenantHandlerInterface,
    tenantHandler: tenantHandlerService,
    authToken: authTokenService,
    breadcrumbs: breadcrumbsInterface,
    routes: routesInterface,
    supportSessionService,
    monitoring: monitoringInterface,
    serviceWorker: serviceWorkerInterface,
    userService,
    grants: grantsInterface,
    grantsHistory: grantsHistoryInterface,
    graphql: graphQLInterface
  };
}
