export function shouldPreloadAssetReferenceLocale({
  globalPreload,
  localPreload
}: {
  globalPreload?: boolean;
  localPreload?: boolean;
}): boolean {
  if (localPreload === undefined)
    // now it uses global configuration
    return !!globalPreload;
  // localPreload is true or false
  return localPreload;
}
