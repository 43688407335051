import { OnboardingDirectorSessionTasksResponseType } from './clients/OnboardingDirector/types';
import * as T from './types';
import { LaunchBackgroundTaskServiceInstanceOptions } from '../../../../../services/webServiceRouting/types';
import { getServices } from '../../../../../infra/commonInitializer';
import WebServiceRouting from '../../../../../services/webServiceRouting';
import { internalLogger } from '../../../../../interface/v1/logger';
import { ServiceRoutingBackgroundTaskEventResponse } from './newTypes';
import OnboardingDirector from './clients/OnboardingDirector';
import { PromiseReturnType } from '../../../../../types/typeHandlers';
import authProvider from '../../../../../interface/v1/auth';
import { Stack } from '@jarvis/web-stratus-client';
import IEventService from '../../../../../services/eventService/IEventService';

interface TaskManagerConstructorProps {
  stack: Stack;
  authProviderInterface: PromiseReturnType<typeof authProvider>;
}

export default class TaskManager {
  private _eventService: IEventService;
  private _webServiceRouting: WebServiceRouting;
  private _launchedTasks: string[];
  private _tasksToLaunch: OnboardingDirectorSessionTasksResponseType[];
  private _currentlyRunningTasks: OnboardingDirectorSessionTasksResponseType[];
  private _onboardingDirector: OnboardingDirector;

  constructor({ stack, authProviderInterface }: TaskManagerConstructorProps) {
    this._onboardingDirector = new OnboardingDirector(
      stack,
      authProviderInterface
    );
    const services = getServices();
    this._eventService = services?.eventService;
    this._webServiceRouting = services?.webServiceRouting;
    this._launchedTasks = [];
    this._tasksToLaunch = [];
    this._currentlyRunningTasks = [];
    // This function is used in event callbacks and may lose reference, bind the
    // class reference
    this._handleBackgroundTaskCompletion =
      this._handleBackgroundTaskCompletion.bind(this);
  }

  launchNewTasks(session: T.OnboardingInstanceType['session']): void {
    const backgroundTasks = session?.context?.backgroundTaskRequests;
    if (backgroundTasks) {
      this._tasksToLaunch = backgroundTasks?.filter((backgroundTask) => {
        return !this._launchedTasks.includes(
          backgroundTask.backgroundTaskRequestId
        );
      });
    }
    this._launchTasks();
  }

  private _launchTasks(): void {
    if (this._tasksToLaunch?.length) {
      let serviceRoutingTasks: LaunchBackgroundTaskServiceInstanceOptions[] =
        this._tasksToLaunch.map((backgroundTask) => {
          const { backgroundTaskRequestId } = backgroundTask;
          const { taskId, arguments: args } = backgroundTask?.task || {};
          if (backgroundTaskRequestId) {
            this._launchedTasks.push(backgroundTaskRequestId);
            this._currentlyRunningTasks.push(backgroundTask);
            this._eventService.subscribe(
              `${this._webServiceRouting.Events.BackgroundTaskServiceInstanceClosed}-${backgroundTaskRequestId}`,
              this._handleBackgroundTaskCompletion
            );
            return {
              taskId: taskId,
              options: {
                launchId: backgroundTaskRequestId,
                data: args
              }
            };
          }
        });
      serviceRoutingTasks = serviceRoutingTasks.filter(function (task) {
        return task !== undefined;
      });
      if (serviceRoutingTasks.length > 0) {
        this._webServiceRouting.launchBackgroundTasksService(
          serviceRoutingTasks
        );
      }
    }
  }

  private async _handleBackgroundTaskCompletion(
    event: ServiceRoutingBackgroundTaskEventResponse
  ) {
    const { launchId: backgroundTaskRequestId } =
      event?.eventData?.resultData || {};
    if (!backgroundTaskRequestId) {
      internalLogger.log(
        `JSHELL - Background task request missing backgroundTaskRequestId, aborting`
      );
      return;
    }
    let finishedTaskIndex;
    const finishedTask = this._currentlyRunningTasks
      .filter((task, i) => {
        if (task.backgroundTaskRequestId === backgroundTaskRequestId) {
          finishedTaskIndex = i;
          return true;
        }
      })
      .pop();
    if (!finishedTask) {
      internalLogger.log(
        `JSHELL - Unable to resolve backgroundTaskRequestId to any known task, id: ${backgroundTaskRequestId}`
      );
      return;
    }
    // Update the currently running tasks
    this._currentlyRunningTasks.splice(finishedTaskIndex, 1);
    const eventResult = event?.eventData?.resultData?.result;
    const data = {
      result: {
        method: finishedTask.task.arguments?.request?.method,
        path: finishedTask.task.arguments?.request?.path,
        sessionId: finishedTask.task.arguments?.request?.sessionId,
        headers: finishedTask.task.arguments?.request?.headers,
        ...eventResult
      }
    };
    try {
      await this._onboardingDirector.put({
        data,
        baseURL: finishedTask.resultUrl
      });
    } catch {
      internalLogger.log(
        `JSHELL - Background task request to OD failed - task: ${finishedTask.task.taskId} ${backgroundTaskRequestId}`
      );
    }
    internalLogger.log(
      `JSHELL - Background task closed. - task: ${finishedTask.task.taskId} ${backgroundTaskRequestId}`
    );
  }
}
