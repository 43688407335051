/**
 * Binds all methods of an object to the object itself.
 *
 * This function is useful for ensuring that `this` within methods always refers
 * to the object, even if the method is detached. This is particularly useful
 * for event handlers and callbacks.
 *
 * For an example, check the unit tests.
 *
 * @param {Object} obj - The object whose methods should be bound.
 */
export default function bindAllMethods(object: Record<string, any>): void {
  if (typeof object === 'object') {
    const allKeys = Object.getOwnPropertyNames(Object.getPrototypeOf(object));

    allKeys?.forEach?.((key) => {
      if (object?.[key] instanceof Function && key !== 'constructor') {
        object[key] = object[key].bind(object);
      }
    });
  }
}
