import { BuildNotificationResult, DataCollectionEventAction } from '@jarvis/jweb-core';
import { EventDB } from '../DB/IndexedDB';
import { logger } from '../helpers/logger';
import { dataCollectionService } from '../dataCollectionService/dataCollectionService';
import { BatchConfigDefaults, defaultIsBatchEnabled } from '../dataCollectionService/dataCollectionServiceTypes';
import { publishResultEventData } from '../helpers/publishResultEventData';
import { Queue } from './Queue';
import { QueueItem } from './QueueItem';
import { QueueItemStatus, isObjectEqual } from './queueHelpers';

// To Do: Batch Processing
export class Accumulator {
  static async updateElementInIndexDB(item: QueueItem) {
    // get the element from IndexedDB
    const itemsFromDB = await EventDB.getQueueItems(QueueItemStatus.pending);
    let hasMerged = false;
    // compare the item with itemToBeUpdated
    for (const itemFromDB of itemsFromDB) {
      hasMerged = await mergeIntoDbItem(itemFromDB, item);
      if (hasMerged){
        break;
      }
    }
    if (!hasMerged || itemsFromDB.length === 0) {
      await EventDB.add(item);
    }

    window.dispatchEvent(new CustomEvent('dbLoaded'));
  }

  static enqueueItemIfMeetsMinEventCount(item: QueueItem): boolean {
    const minEventCountPerBatch = dataCollectionService.getConfiguration()?.batchConfiguration?.minEventCount ?? BatchConfigDefaults.MIN_EVENT_COUNT;
    if (item.notification.events.length >= minEventCountPerBatch) {
      return true;
    }
    return false;
  }

  static enqueueItemIfEventOlderThanMaxAge(item: QueueItem): boolean {
    const maxEventAge = dataCollectionService.getConfiguration()?.batchConfiguration?.maxEventAge ?? BatchConfigDefaults.MAX_EVENT_AGE_IN_SECONDS;
    const oldestEvent = item.notification.events.reduce((oldest, current) => (new Date(oldest.dateTime) < new Date(current.dateTime)) ? oldest : current);
    const eventAge = (Date.now() - new Date(oldestEvent.dateTime).getTime()) / 1000;
    if (eventAge >= maxEventAge) {
      return true;
    }
    return false;
  }

  static previousBatchEnabled = defaultIsBatchEnabled;

  static async checkBatchEnabledAndFlush() {
    logger.log('Accumulator::checkBatchEnabledAndFlush::log:checking batch enabled and flushing');
    const batchEnabled = dataCollectionService.getConfiguration()?.isBatchingEnabled?? defaultIsBatchEnabled;

    if (this.previousBatchEnabled && !batchEnabled) {
      this.flushQueue();
    }
    this.previousBatchEnabled = batchEnabled;
  }

  static async flushQueue() {
    logger.log('Accumulator::flushQueue::log:flushing queue');
    const pendingItems = await EventDB.getQueueItems(QueueItemStatus.pending);
    if (pendingItems.length === 0) {
      logger.log('Accumulator::flushQueue::log:No pending items to flush');
      return;
    }

    for (const item of pendingItems) {
      item.status = QueueItemStatus.processing;
      await EventDB.update(item, item.id);
      await publishResultEventData(
        DataCollectionEventAction.buildNotification,
        item.trackingIdentifiers,
        {
          notification: item.notification,
          preBuilt: false,
          pending: false
        } as BuildNotificationResult
      );
      Queue.enqueue(item);
    }
  }
}

const updateItemDetailsAndSaveToDB = async (itemFromDB: QueueItem, item: QueueItem) => {
  itemFromDB.notification.originator.originatorDetail.currentDateTime = new Date().toISOString();
  itemFromDB.trackingIdentifiers = mergeTrackingIdentifiers(itemFromDB, item);
  itemFromDB.notification.events = mergeEvents(itemFromDB, item);
  await EventDB.update(itemFromDB, itemFromDB.id);
};

const mergeTrackingIdentifiers = (itemFromDB: QueueItem, item: QueueItem) => {
  const identifiersFromDB = itemFromDB.trackingIdentifiers || [];
  const identifiersFromItem = item.trackingIdentifiers || [];
  const mergedIdentifiers = [...identifiersFromDB, ...identifiersFromItem];
  const uniqueIdentifiers = Array.from(new Set(mergedIdentifiers));
  return uniqueIdentifiers;
};

const mergeEvents = (itemFromDB: QueueItem, item: QueueItem) => [...itemFromDB.notification.events, ...item.notification.events];

const updateItemStatusAndPublishNotification = async (item: QueueItem) => {
  item.status = QueueItemStatus.processing;
  await EventDB.update(item, item.id);
  await publishResultEventData(
    DataCollectionEventAction.buildNotification,
    item.trackingIdentifiers,
    {
      notification: item.notification,
      preBuilt: false,
      pending: false
    } as BuildNotificationResult
  );
  Queue.enqueue(item);
};

// This function will be called when the IndexedDB is available and we have to process the item from the IndexedDB
const mergeIntoDbItem = async (itemFromDB: QueueItem, item: QueueItem): Promise<boolean> => {
  const isMetaDataEqual = isObjectEqual(itemFromDB.metadata, item.metadata);
  const isOriginatorEqual = isObjectEqual(itemFromDB.notification.originator, item.notification.originator);
  const isOriginatorContextEqual = isObjectEqual(itemFromDB.notification.originatorContext, item.notification.originatorContext);
  const eventsCount = item.notification.events.length + itemFromDB.notification.events.length;
  const maxEventCount = dataCollectionService.getConfiguration()?.batchConfiguration?.maxEventCount ?? BatchConfigDefaults.MAX_EVENT_COUNT;

  if (isMetaDataEqual && isOriginatorEqual && isOriginatorContextEqual && eventsCount <= maxEventCount) {
    await updateItemDetailsAndSaveToDB(itemFromDB, item);
    return true;
  } else if (eventsCount >= maxEventCount) {
    await updateItemStatusAndPublishNotification(itemFromDB);
    return false;
  }
  return false;
};

let timerId: any = null;

export const evaluateBatchLogic = () => {
  logger.log('Accumulator::evaluateBatchLogic::log:evaluating batch logic');
  // Check if IndexedDB is available
  if (EventDB.db && indexedDB) {
    EventDB.getQueueItems(QueueItemStatus.pending).then(async items => {
      for (const item of items) {
        if (item && item.notification.events.length > 0) {
          const isMinCount = Accumulator.enqueueItemIfMeetsMinEventCount(item);
          const isMaxAge = Accumulator.enqueueItemIfEventOlderThanMaxAge(item);
          if (isMinCount || isMaxAge) {
            await updateItemStatusAndPublishNotification(item);
          }
        }
      }
    });
  }
  // Reset the recurring timer
  if (timerId !== null) {
    clearInterval(timerId);
  }
  const evaluationFrequencyTimer = dataCollectionService.getConfiguration()?.batchConfiguration?.evaluationFrequency ?? BatchConfigDefaults.EVALUATION_FREQUENCY_IN_SECONDS;
  timerId = setInterval(evaluateBatchLogic, evaluationFrequencyTimer * 1000);
};

// Start the initial timer
timerId = setInterval(evaluateBatchLogic, BatchConfigDefaults.EVALUATION_FREQUENCY_IN_SECONDS * 1000);
