import { ApplicationContext, BuildNotificationResult, CDMEventData, DataCollectionEventAction, DataCollectionEventResult, DataValveCDMEventData, ValveControllerMetadata } from '@jarvis/jweb-core';
import { getPlatform, getWindowValues } from '../client/utils/enum';
import { buildNotification } from '../client/buildNotification';
import { Event } from '../client/event/Event';
import { dataCollectionService } from '../dataCollectionService/dataCollectionService';
import { BrowserInfoEventDetail } from '../client/event/EventDetail';
import { Queue } from '../Queue/Queue';
import { QueueItem } from '../Queue/QueueItem';
import { logger } from '../helpers/logger';
import { Notifications } from '../client/notification';
import { Accumulator } from '../Queue/Accumulator';
import { EventDB } from '../DB/IndexedDB';
import { QueueItemStatus } from '../Queue/queueHelpers';
import { Configuration } from '../dataCollectionService/dataCollectionServiceTypes';
import { getApplicationContextFromValueStore, getValveControllerMetadataFromValueStore } from './valueStoreHelpers';
import { publishResultEventData } from './publishResultEventData';

const EVENTING_EVENT_VERSION = '1.4.0';

const buildEnvelopAndSendNotification = async (eventInfoeventData: DataValveCDMEventData) => {
  const eventData = eventInfoeventData;
  const cdmEvents = eventData?.events === undefined ? undefined : eventData.events as [CDMEventData];
  const processedCDMEvents: any[] | undefined = cdmEvents?.map(cdmEvent => ({ ...cdmEvent, version: EVENTING_EVENT_VERSION }));
  const preBuildNotifications: any[] | undefined = eventData?.notifications === undefined ? undefined : (eventData.notifications.length ? eventData.notifications.map(preBuildNotification => JSON.parse(JSON.stringify(preBuildNotification))) : undefined);
  const metadata = eventData.valveControllerMetadata as ValveControllerMetadata;
  const applicationContext = await getApplicationContextFromValueStore(eventData.applicationContext as ApplicationContext);
  const trackingIdentifiers = eventData?.trackingIdentifier ? [eventData.trackingIdentifier] : undefined;
  const windowValue: any = getWindowValues();
  const storedMetadata: any = windowValue.sessionStorage.getItem('ValveControllerMetadata');
  const previousMetadata = storedMetadata === 'undefined' ? undefined : storedMetadata;
  const storedVisitUuid = windowValue.sessionStorage.getItem('visitUuid');
  const previousVisitUuid = storedVisitUuid === 'undefined' ? undefined : storedVisitUuid;
  const storedWebAppConsent = windowValue.sessionStorage.getItem('webAppConsent');
  const previousWebAppConsent = storedWebAppConsent === 'undefined' ? undefined : storedWebAppConsent;
  logger.log('BuildEnvelopeAndSendNotification::CDMEvents:', processedCDMEvents);
  logger.log('BuildEnvelopeAndSendNotification::Metadata:', metadata);
  const configuration: Configuration | undefined = dataCollectionService.getConfiguration();
  const filterMetaData = await getValveControllerMetadataFromValueStore(metadata);
  if (!configuration) return;

  const controlledData = createControlledData(filterMetaData, applicationContext, windowValue);
  const events = createEvents(processedCDMEvents, metadata, previousMetadata, applicationContext, previousVisitUuid, previousWebAppConsent, windowValue);

  if (events.length > 0) {
    const notification = buildNotification(controlledData, events);
    await handleNotification(configuration, filterMetaData, notification, applicationContext, trackingIdentifiers);
  }

  if (preBuildNotifications) {
    await handlePreBuiltNotifications(preBuildNotifications, filterMetaData, applicationContext, trackingIdentifiers);
  } else if (!processedCDMEvents) {
    await publishResultEventData(DataCollectionEventAction.finish, trackingIdentifiers, {
      result: DataCollectionEventResult.failure,
      message: 'both are empty Please Provide at least one option'
    });
  }
};

function createControlledData(filterMetaData: ValveControllerMetadata,
                              applicationContext: ApplicationContext, windowValue: any) {
  return {
    associatedDeviceUuid: filterMetaData?.deviceId,
    associatedDeviceProductNumber: filterMetaData?.edgeType,
    associatedStratusUserId: filterMetaData?.accountLoginId,
    ucdeCorrelationId: applicationContext?.ucdeCorrelationId,
    webAppVersion: applicationContext?.webAppVersion,
    visitorUuid: applicationContext?.visitorUuid,
    visitUuid: applicationContext?.visitUuid,
    webAppConsent: applicationContext?.webAppConsent,
    osCountryRegion: windowValue.osCountryRegion,
    osLanguage: windowValue.osLanguage,
    osPlatform: getPlatform(),
    webAppName: applicationContext?.webAppName,
    browserUserAgent: windowValue.browserUserAgent,
    browserScreenResolution: windowValue.browserScreenResolution
  };
}

function createEvents(processedCDMEvents: any, metadata: ValveControllerMetadata,
                      previousMetadata: ValveControllerMetadata, applicationContext: ApplicationContext, previousVisitUuid: any, previousWebAppConsent: any, windowValue: any) {
  const events = [];

  if (processedCDMEvents) {
    for (const cdmEvent of processedCDMEvents) {
      if (cdmEvent.eventDetail && Object.keys(cdmEvent.eventDetail).length !== 0) {
        events.push(new Event(cdmEvent.dateTime, cdmEvent.eventCategory, cdmEvent.eventDetailType, cdmEvent.eventDetail));
      }
    }
  }

  if (JSON.stringify(metadata) !== previousMetadata || applicationContext.visitUuid !== previousVisitUuid || applicationContext.webAppConsent !== previousWebAppConsent) {
    const BROWSER_INFO_DETAIL_TYPE = 'com.hp.cdm.domain.telemetry.type.eventDetail.category.browserInformation.version.1';
    events.push(new Event(new Date().toISOString(), 'browserInformation', BROWSER_INFO_DETAIL_TYPE, new BrowserInfoEventDetail()));
    windowValue.sessionStorage.setItem('ValveControllerMetadata', JSON.stringify(metadata));
    windowValue.sessionStorage.setItem('visitUuid', applicationContext.visitUuid);
    windowValue.sessionStorage.setItem('webAppConsent', applicationContext.webAppConsent);
  }

  return events;
}

async function handleNotification(configuration: Configuration, filterMetaData: ValveControllerMetadata, notification: Notifications, applicationContext: ApplicationContext, trackingIdentifiers?: string[]) {
  logger.log('BuildEnvelopeAndSendNotification:notification:isBatchingEnabled', configuration.isBatchingEnabled);

  if (configuration.isBatchingEnabled && indexedDB && EventDB.db) {
    const queueItem = new QueueItem(filterMetaData, notification, applicationContext, trackingIdentifiers, undefined, undefined, undefined, undefined, QueueItemStatus.pending);
    await publishResultEventData(DataCollectionEventAction.buildNotification, trackingIdentifiers, { notification, preBuilt: false, pending: true } as BuildNotificationResult);
    await Accumulator.updateElementInIndexDB(queueItem);
  } else {
    const queueItem = new QueueItem(filterMetaData, notification, applicationContext, trackingIdentifiers);
    await publishResultEventData(DataCollectionEventAction.buildNotification, trackingIdentifiers, { notification, preBuilt: false, pending: false } as BuildNotificationResult);
    logger.log('BuildEnvelopeAndSendNotification:notification::', notification);
    Queue.enqueue(queueItem);
  }
}

async function handlePreBuiltNotifications(preBuildNotifications: Object[], filterMetaData: ValveControllerMetadata, applicationContext: ApplicationContext, trackingIdentifiers: string[]|undefined) {
  for (const preBuildNotification of preBuildNotifications) {
    const notificationObject = preBuildNotification as Notifications;
    const queueItem = new QueueItem(filterMetaData, notificationObject, applicationContext, trackingIdentifiers, undefined, undefined, undefined, true);
    await publishResultEventData(DataCollectionEventAction.buildNotification, trackingIdentifiers, { notification: notificationObject, preBuilt: true, pending: false } as BuildNotificationResult);
    logger.log('BuildEnvelopeAndSendNotification:preBuildNotification::', preBuildNotification);
    Queue.enqueue(queueItem);
  }
}
export default buildEnvelopAndSendNotification;
