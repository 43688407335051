import interfaces from './interfaces';
import behaviors from './behaviors';
import services from './services';
import utils from './utils';
import { initializeCommons } from '../infra/commonInitializer';
import { overrideResource } from '../infra/commonInitializer/OverrideResource';
import { CommonsPackageType } from './types';

const commonsPackages: CommonsPackageType = {
  behaviors,
  interfaces,
  services,
  utils,
  starter: initializeCommons,
  override: overrideResource
};

export default commonsPackages;
