/**
 * Returns the value of an object's property with a case-insensitive key name.
 *
 * @typeParam T - The type of the object.
 * @typeParam K - The type of the key.
 * @param object - The object to search for the property.
 * @param key - The key of the property to search for (case-insensitive).
 * @returns The value of the property, or undefined if the property does not exist.
 *
 * @example
 * ```typescript
 * const obj = { foo: 'bar' };
 * const value = getValueByInsensitiveKey(obj, 'FOO'); // 'bar'
 * ```
 * */
export default function getValueByInsensitiveKey<
  T extends Record<string, any> | undefined,
  K extends string | undefined
>(object: T, key: K): T[K] | undefined {
  const lowerKey = key?.toLowerCase?.();
  const upperKey = key?.toUpperCase?.();

  return object?.[lowerKey] || object?.[upperKey];
}
