import { SceneType } from '../../../services/mfeRouterService/types';
import { MFERouterInterfaceType } from './types';

type noopInterfaceParam = {
  logCallback?: () => void;
};

export const createNoopMFeRouterInterface = ({
  logCallback
}: noopInterfaceParam): MFERouterInterfaceType => {
  const newNoopInterface: MFERouterInterfaceType = {
    start: (): Promise<void> => {
      logCallback?.();
      return;
    },
    getState: (): SceneType => {
      logCallback?.();
      return {};
    },
    listen: (): (() => void) => {
      logCallback?.();
      return () => {};
    },
    useReactHook: (): SceneType => {
      logCallback?.();
      return {};
    }
  };
  return newNoopInterface;
};
