import bindAllMethods from '../../../utils/bindAllMethods';
import type IExchangeTokenService from './IExchangeTokenService';
import type IAuthTokenService from '../../authTokenService/IAuthTokenService';
import { inject, singleton } from 'tsyringe';
import { AuthContextEnum, AuthTokenService } from '../../authTokenService';
import { internalLogger } from '../../../interface/v1/logger';
import { TenantStrategyEnum } from '../../tenantHandler/strategy/TenantStrategyEnum';
import {
  ExchangeTokenDTOType,
  ExchangeV3Client,
  IExchangeClient
} from '../../../clients/shell/exchange';
import { getJWeb, JWebErrorHandler } from '../../JWeb';
import { jWebStates } from '../../JWeb/initializer';
import {
  AccessToken,
  AuthPluginError,
  TokenProviderOptions
} from '../../JWeb/types';
import { PlatformEnum } from '../../../infra/commonInitializer/enums';
import type { ExchangeTokenInputType, LoginInputType } from '../types';
import { TokenType } from '../../../services/JWeb/JWebEnums';
import AuthenticationProviderEnum from '../../../config/authenticationProviderEnum';
import type { AuthProvidersPropsType } from '../../../infra/commonInitializer/types';

@singleton()
class ExchangeTokenServiceNative implements IExchangeTokenService {
  private _authTokenService: IAuthTokenService;
  private _exchangeClient: IExchangeClient;
  private _isSessionEnabled: boolean;
  private _platform: PlatformEnum;
  private _authProviderProps: AuthProvidersPropsType;

  constructor(
    @inject(AuthTokenService) authTokenService: IAuthTokenService,
    @inject(ExchangeV3Client) exchangeClient: IExchangeClient,
    @inject('LoginProps') loginProps: LoginInputType,
    @inject('Platform') platform: PlatformEnum,
    @inject('AuthProviderProps')
    authProviderProps: AuthProvidersPropsType
  ) {
    this._authTokenService = authTokenService;
    this._exchangeClient = exchangeClient;
    this._isSessionEnabled = loginProps.enabled;
    this._platform = platform;
    this._authProviderProps = authProviderProps;
    bindAllMethods(this);
  }

  public async exchangeJWeb(
    tenantId?: string,
    tokenOptions?: TokenProviderOptions
  ): Promise<AuthPluginError | AccessToken> {
    const JWeb = await getJWeb();
    await jWebStates.plugins.Auth;
    const JWebAuthPlugin = JWeb.Plugins.Auth;
    const jwebOptions = {
      tokenProviderOptions: {
        tokenType: TokenType.user,
        ...tokenOptions
      }
    };

    if (tenantId) {
      jwebOptions.tokenProviderOptions['tenantID'] = tenantId;
    }

    const exchangeTenantTokenResponse = JWebErrorHandler<AccessToken>(
      await JWebAuthPlugin.getToken(jwebOptions)
    );
    return exchangeTenantTokenResponse;
  }

  private async _exchangeShell(
    tenantStrategy: TenantStrategyEnum,
    accessToken: string
  ): Promise<string> {
    const exchangeDTO: ExchangeTokenDTOType = {
      accessToken,
      tenantStrategy
    };
    const exchangedToken = await this._exchangeClient.exchangeToken(
      exchangeDTO
    );
    return exchangedToken.accessToken;
  }

  private _isTenantToken(authContext: AuthContextEnum) {
    const { token: accessToken } = this._authTokenService.getToken(authContext);
    return !!this._authTokenService.getTenantIdFromGivenToken(accessToken);
  }

  public async exchange(
    exchangeTokenInput: ExchangeTokenInputType
  ): Promise<AuthPluginError | AccessToken> {
    const { tenantId, authContext, tenantStrategy, jwebTokenOptions } =
      exchangeTokenInput;

    if (!this._isSessionEnabled) {
      internalLogger.debug(
        'isSessionEnabled property disabled, skipping exchange.'
      );
      return;
    }

    const isAppAuthProviderAuthz =
      this._authProviderProps.authenticationProvider ===
      AuthenticationProviderEnum.authz;

    const isPortalAuthProviderAuthz =
      this._authProviderProps.initialAuthenticationProvider ===
      AuthenticationProviderEnum.authz;

    // TODO: Today even that the token is orgaware, in native we save in tokenRepository as tenantless
    // TODO: in the furute we can get an orgless token from jweb, so this behavior will change to save the token in the right context in the tokenRepository
    const isTenantToken = this._isTenantToken(AuthContextEnum.tenantless);

    const isAppCoptorAndUserAlreadyHasUsingTenantId =
      this._authProviderProps.authenticationProvider ===
        AuthenticationProviderEnum.coptor && !!isTenantToken;

    if (isAppCoptorAndUserAlreadyHasUsingTenantId) {
      internalLogger.log(
        'Coptor exchange - User already has a token with tenant, skipping exchange.',
        exchangeTokenInput
      );
      return;
    }

    internalLogger.log('Exchange tenant token', exchangeTokenInput);

    let token: string;
    let exchangeResult;
    try {
      exchangeResult = await this.exchangeJWeb(tenantId, jwebTokenOptions);
      token = exchangeResult.tokenValue;

      if (
        this._platform !== PlatformEnum.nativeOnly &&
        isAppAuthProviderAuthz &&
        isPortalAuthProviderAuthz
      ) {
        internalLogger.log(
          'Exchanging tenant token for JSHELL in Env and Portal Authz.'
        );

        token = await this._exchangeShell(tenantStrategy, token);
      }
      this._authTokenService.setToken(token, authContext);
    } catch (error) {
      console.error(error);
      throw new Error('Unable to exchange token');
    }
    return exchangeResult;
  }
}
export default ExchangeTokenServiceNative;
