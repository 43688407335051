import bindAllMethods from '../../utils/bindAllMethods';
import { IServiceWorkerService } from '../../services/serviceWorkerService';
import { ISessionService } from '../../services/session';

export default class ServiceWorkerRules {
  private _serviceWorkerService: IServiceWorkerService;
  private _sessionService: ISessionService;

  constructor({
    serviceWorkerService,
    sessionService
  }: {
    serviceWorkerService: IServiceWorkerService;
    sessionService: ISessionService;
  }) {
    this._serviceWorkerService = serviceWorkerService;
    this._sessionService = sessionService;
    bindAllMethods(this);
  }

  public init(): void {
    const isLoggedIn = this._sessionService.isLoggedIn();

    if (!isLoggedIn) {
      this._serviceWorkerService.clearCache({
        storage: {
          cacheName: {
            keepCacheAfterLogout: false
          }
        }
      });
    }
  }
}
