import StageBuilder from '../../stageBuilder/StageBuilder';
import * as T from './types';

export default class FallbackOperation implements T.RouterOperationInterface {
  private fallbackInterface: T.ErrorOperationDependenciesType['fallbackInterface'];

  constructor(dependencies: T.ErrorOperationDependenciesType) {
    this.fallbackInterface = dependencies.fallbackInterface;
  }

  async process(stateBuilder: StageBuilder): Promise<void> {
    const {
      content,
      modalContent,
      layoutKey,
      path,
      layout,
      fallbackProperties
    } = this.fallbackInterface.getCurrentFallback() || {};

    if (content?.assetReference) {
      stateBuilder.setContent({
        ...content,
        properties: {
          ...content?.properties,
          ...fallbackProperties
        },
        enable: true
      });
    }
    // layoutKey should be set first as it should have preference
    if (typeof layoutKey === 'string' || layoutKey === false) {
      stateBuilder.setLayoutByKey(layoutKey);
    }
    if (layout?.assetReference) {
      stateBuilder.setLayout({
        ...layout,
        properties: {
          ...layout?.properties,
          ...fallbackProperties
        },
        enable: true
      });
    }
    if (modalContent?.assetReference) {
      stateBuilder.setModalContent({
        ...modalContent,
        properties: {
          ...modalContent?.properties,
          ...fallbackProperties
        },
        enable: true
      });
    }

    if (path) {
      stateBuilder.setEndProcessChain(true);
    }
  }
}
