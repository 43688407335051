import { LEDMObject, ValveFilterErrorType } from '@jarvis/jweb-core';
import { useNamespaces } from 'xpath';
import { FilterObject, InstructionsResponse } from '../CDMFilter/filterTypes';
import { getWindowValues } from '../client/utils/enum';
import { logger } from '../helpers/logger';
import { DataValveFilterError } from '../helpers/dataValveError';

enum filterType {
  EXCLUSION = 'exclusion',
  INCLUSION = 'inclusion',
}

export const sanitizeLEDM = (
  ledmObject: LEDMObject,
  bindings: InstructionsResponse
): LEDMObject | Error => {
  const filteredBindings = bindings?.bindings?.filters?.ledm?.xml?.filter(
    (filter) => filter.resourceId === ledmObject.resourceUri
  );
  if (!filteredBindings) {
    logger.error(
      'sanitizeLEDM::filteredBindings::error:',
      'No filteredBindings found in bindings responce for LEDM object'
    );
    return new DataValveFilterError(
      ValveFilterErrorType.invalidTreeError,
      `Error in Sanitize LEDM: matching filter was not found for resource URI "${ledmObject.resourceUri}"`,
      ''
    );
  }
  const { DOMParser } = getWindowValues();
  const doc = new DOMParser().parseFromString(ledmObject.tree, 'text/xml');
  let ledmResult: LEDMObject | Error = new DataValveFilterError(
    ValveFilterErrorType.invalidTreeError,
    `matching filter was not found for resource URI "${ledmObject.resourceUri}"`,
    ''
  );
  try {
    filteredBindings?.map((binding) => {
      if (binding.filterType === filterType.EXCLUSION) {
        const result = applyExclusionFilter(doc, binding);
        ledmResult = {
          tree: (new XMLSerializer()).serializeToString(result),
          resourceUri: ledmObject.resourceUri
        };
      } else if (binding.filterType === filterType.INCLUSION) {
        const result = applyInclusionFilter(doc, binding);
        ledmResult = {
          tree: (new XMLSerializer()).serializeToString(result),
          resourceUri: ledmObject.resourceUri
        };
      }
    });
  } catch (error: any) {
    ledmResult = new DataValveFilterError(ValveFilterErrorType.invalidTreeError, 'Error in Sanitize LEDM', error);
  }
  return ledmResult;
};

const setInclusionAttribute = (node: any) => {
  if (node.nodeType === 1) {
    node.setAttribute('nodeSelectedToStayInTheDocument', 'true');
    node.parentElement?.setAttribute('nodeSelectedToStayInTheDocument', 'true');
  }
};

const removeExcludedNode = (node: any) => {
  if (node.nodeType === 1) {
    node.parentElement?.childNodes.forEach((childNode: any) => {
      if (childNode.nodeType === 1 && !childNode.hasAttribute('nodeSelectedToStayInTheDocument')) {
        childNode.parentElement.removeChild(childNode);
      }
    });
  }
};

const removeInclusionAttribute = (node: any) => {
  if (node.nodeType === 1) {
    node.parentElement?.childNodes.forEach((childNode: any) => {
      if (childNode.nodeType === 1 && childNode.hasAttribute('nodeSelectedToStayInTheDocument')) {
        childNode.removeAttribute('nodeSelectedToStayInTheDocument');
      }
    });
    if (node.hasAttribute('nodeSelectedToStayInTheDocument')) {
      node.removeAttribute('nodeSelectedToStayInTheDocument');
    }
    node.parentElement?.removeAttribute('nodeSelectedToStayInTheDocument');
  }
};

export const applyInclusionFilter = (tree: any, filter: FilterObject) => {
  const includedNodes = getXPathNodes(tree, filter);
  const walkthedom = (node: any, func: any) => {
    func(node);
    node = node.firstChild;
    while (node) {
      walkthedom(node, func);
      node = node.nextSibling;
    }
  };
  // Mark with an attribute all included nodes as well as its ancestors and descendants to be preserved in the final tree.
  includedNodes?.map((node) => {
    walkthedom(node, setInclusionAttribute);
  });

  // Remove all nodes not marked for preserving as they have to be deleted.
  includedNodes?.map((node) => {
    walkthedom(node, removeExcludedNode);
  });

  // Remove the attribute on the nodes marked for preserving, as it should not appear in the final XML.
  includedNodes?.map((node) => {
    walkthedom(node, removeInclusionAttribute);
  });

  return tree;
};

export const applyExclusionFilter = (tree: any, filter: FilterObject) => {
  const excludedNodes = getXPathNodes(tree, filter);
  excludedNodes?.map((node) => {
    if (node) {
      // @ts-ignore
      node.parentElement.removeChild(node);
    }
  });
  return tree;
};

export const getXPathNodes = (tree: any, filter: FilterObject) => {
  let namespaces: any;
  for (const index in tree.documentElement.attributes) {
    const attribute = tree.documentElement.attributes[index];
    namespaces = {
      ...namespaces,
      [attribute.localName]: attribute.value
    };
  }

  const selectedNodes = filter.attributes?.map((attribute) => {
    try {
      const select = useNamespaces(namespaces);
      return select(attribute, tree);
    } catch (e) {
      logger.error('Sanitize::SelectedNodes::error:', e);
    }
  });
  if (!selectedNodes) {
    logger.error('Sanitize::SelectedNodes::error:', 'No nodes selected');
    return [];
  }

  return selectedNodes.flat();
};
