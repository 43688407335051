export const glossary = {
  '$schema': 'http://json-schema.org/draft-04/schema#',
  'additionalProperties': false,
  'definitions': {
    'contentOrientation': {
      'description': 'related to the orientation of the print content rendering on media',
      'enum': [
        'landscape',
        'portrait'
      ],
      'type': 'string'
    },
    'countryRegionIso': {
      'description': 'country and region codes as per ISO 3166-1 alpha-2',
      'enum': [
        'AD',
        'AE',
        'AF',
        'AG',
        'AI',
        'AL',
        'AM',
        'AO',
        'AQ',
        'AR',
        'AS',
        'AT',
        'AU',
        'AW',
        'AZ',
        'BA',
        'BB',
        'BD',
        'BE',
        'BF',
        'BG',
        'BH',
        'BI',
        'BJ',
        'BL',
        'BM',
        'BN',
        'BO',
        'BQ',
        'BR',
        'BS',
        'BT',
        'BW',
        'BY',
        'BZ',
        'CA',
        'CC',
        'CD',
        'CF',
        'CG',
        'CH',
        'CI',
        'CK',
        'CL',
        'CM',
        'CN',
        'CO',
        'CR',
        'CU',
        'CV',
        'CW',
        'CX',
        'CY',
        'CZ',
        'DE',
        'DJ',
        'DK',
        'DM',
        'DO',
        'DZ',
        'EC',
        'EE',
        'EG',
        'EH',
        'ER',
        'ES',
        'ET',
        'FI',
        'FJ',
        'FK',
        'FM',
        'FO',
        'FR',
        'GA',
        'GB',
        'GD',
        'GE',
        'GF',
        'GG',
        'GH',
        'GI',
        'GL',
        'GM',
        'GN',
        'GP',
        'GQ',
        'GR',
        'GT',
        'GU',
        'GW',
        'GY',
        'HK',
        'HN',
        'HR',
        'HT',
        'HU',
        'ID',
        'IE',
        'IL',
        'IM',
        'IN',
        'IO',
        'IQ',
        'IS',
        'IT',
        'JE',
        'JM',
        'JO',
        'JP',
        'KE',
        'KG',
        'KH',
        'KI',
        'KM',
        'KN',
        'KP',
        'KR',
        'KW',
        'KY',
        'KZ',
        'LA',
        'LB',
        'LC',
        'LI',
        'LK',
        'LR',
        'LS',
        'LT',
        'LU',
        'LV',
        'LY',
        'MA',
        'MC',
        'MD',
        'ME',
        'MF',
        'MG',
        'MH',
        'MK',
        'ML',
        'MM',
        'MN',
        'MO',
        'MP',
        'MQ',
        'MR',
        'MS',
        'MT',
        'MU',
        'MV',
        'MW',
        'MX',
        'MY',
        'MZ',
        'NA',
        'NC',
        'NE',
        'NF',
        'NG',
        'NI',
        'NL',
        'NO',
        'NP',
        'NR',
        'NU',
        'NZ',
        'OM',
        'PA',
        'PE',
        'PF',
        'PG',
        'PH',
        'PK',
        'PL',
        'PM',
        'PN',
        'PR',
        'PS',
        'PT',
        'PW',
        'PY',
        'QA',
        'RE',
        'RO',
        'RS',
        'RU',
        'RW',
        'SA',
        'SB',
        'SC',
        'SE',
        'SG',
        'SH',
        'SI',
        'SJ',
        'SK',
        'SL',
        'SM',
        'SN',
        'SO',
        'SR',
        'SS',
        'ST',
        'SV',
        'SX',
        'SZ',
        'TC',
        'TD',
        'TF',
        'TG',
        'TH',
        'TJ',
        'TK',
        'TL',
        'TM',
        'TN',
        'TO',
        'TR',
        'TT',
        'TV',
        'TW',
        'TZ',
        'UA',
        'UG',
        'UM',
        'US',
        'UY',
        'UZ',
        'VA',
        'VC',
        'VE',
        'VG',
        'VI',
        'VN',
        'VU',
        'WF',
        'WS',
        'XK',
        'YE',
        'YT',
        'ZA',
        'ZM',
        'ZW',
        'ZZ'
      ],
      'type': 'string'
    },
    'currencyIso': {
      'description': 'currency codes as per ISO 4217 (based on ISO 3166)',
      'type': 'string',
      'enum': [
        'AED',
        'AFN',
        'ALL',
        'AMD',
        'ANG',
        'AOA',
        'ARS',
        'AUD',
        'AWG',
        'AZM',
        'AZN',
        'BAM',
        'BBD',
        'BDT',
        'BGN',
        'BHD',
        'BIF',
        'BMD',
        'BND',
        'BOB',
        'BRL',
        'BSD',
        'BTN',
        'BWP',
        'BYR',
        'BZD',
        'CAD',
        'CDF',
        'CHF',
        'CLP',
        'CNY',
        'COP',
        'CRC',
        'RSD',
        'CUP',
        'CVE',
        'CYP',
        'CZK',
        'DJF',
        'DKK',
        'DOP',
        'DZD',
        'EEK',
        'EGP',
        'ERN',
        'ETB',
        'EUR',
        'FJD',
        'FKP',
        'GBP',
        'GEL',
        'GGP',
        'GHC',
        'GHS',
        'GIP',
        'GMD',
        'GNF',
        'GTQ',
        'GYD',
        'HKD',
        'HNL',
        'HRK',
        'HTG',
        'HUF',
        'IDR',
        'ILS',
        'IMP',
        'INR',
        'IQD',
        'IRR',
        'ISK',
        'JEP',
        'JMD',
        'JOD',
        'JPY',
        'KES',
        'KGS',
        'KHR',
        'KMF',
        'KPW',
        'KRW',
        'KWD',
        'KYD',
        'KZT',
        'LAK',
        'LBP',
        'LKR',
        'LRD',
        'LSL',
        'LTL',
        'LVL',
        'LYD',
        'MAD',
        'MDL',
        'MGA',
        'MKD',
        'MMK',
        'MNT',
        'MOP',
        'MRO',
        'MTL',
        'MUR',
        'MVR',
        'MWK',
        'MXN',
        'MYR',
        'MZM',
        'MZN',
        'NAD',
        'NGN',
        'NIO',
        'NOK',
        'NPR',
        'NZD',
        'OMR',
        'PAB',
        'PEN',
        'PGK',
        'PHP',
        'PKR',
        'PLN',
        'PYG',
        'QAR',
        'ROL',
        'RON',
        'RUB',
        'RWF',
        'SAR',
        'SBD',
        'SCR',
        'SDD',
        'SDG',
        'SEK',
        'SGD',
        'SHP',
        'SIT',
        'SKK',
        'SLL',
        'SOS',
        'SPL',
        'SRD',
        'STD',
        'SVC',
        'SYP',
        'SZL',
        'THB',
        'TJS',
        'TMM',
        'TND',
        'TOP',
        'TRY',
        'TTD',
        'TVD',
        'TWD',
        'TZS',
        'UAH',
        'UGX',
        'USD',
        'UYU',
        'UZS',
        'VEB',
        'VND',
        'VUV',
        'WST',
        'XAF',
        'XAG',
        'XAU',
        'XCD',
        'XDR',
        'XOF',
        'XPD',
        'XPF',
        'YER',
        'ZAR',
        'ZMK',
        'ZWD'
      ]
    },
    'dateTime': {
      'description': 'standard date and time format - according to RFC 3339. The date/time when the event was produced (according to the service).  It is of the form YYYY-MM-DD\'T\':HH:mm:SS.sssZ, for example \'2012-04-21T08:15:13.511Z\'',
      'format': 'date-time',
      'type': 'string'
    },
    'dateUtc': {
      'description': 'standard date (full-date) format - according to RFC 3339/ISO8601.  It is of the form YYYY-MM-DD utilizing UTC.  This is to be used until CDM supports draft7+ and the official date format is available',
      'type': 'string',
      'pattern': '^[0-9]{4}-(((0[13578]|(10|12))-(0[1-9]|[1-2][0-9]|3[0-1]))|(02-(0[1-9]|[1-2][0-9]))|((0[469]|11)-(0[1-9]|[1-2][0-9]|30)))$'
    },
    'duplexBinding': {
      'enum': [
        'oneSided',
        'twoSidedShortEdge',
        'twoSidedLongEdge'
      ],
      'type': 'string'
    },
    'error': {
      'additionalProperties': false,
      'properties': {
        'errors': {
          'items': {
            'additionalProperties': false,
            'properties': {
              'code': {
                'description': 'Each app should document its code definitions. Developer friendly naming (camelCase).',
                'title': 'Code custom to the application',
                'type': 'string'
              },
              'message': {
                'description': 'English only - for developers',
                'title': 'Human readable explanation',
                'type': 'string'
              }
            },
            'title': 'error',
            'type': 'object'
          },
          'maxItems': 10,
          'type': 'array'
        },
        'httpStatusCode': {
          'type': 'integer'
        },
        'version': {
          '$ref': '#/definitions/version'
        }
      },
      'required': [
        'version'
      ],
      'type': 'object'
    },
    'featureEnabled': {
      'description': 'String based boolean. Pattern to follow to enable and disable service functionalities.',
      'enum': [
        'true',
        'false'
      ],
      'type': 'string'
    },
    'language': {
      'description': 'language code as per ISO 639-1. The country extension should only be used when variants or dialects are supported. This list should only have the subset of languages that are actuall supported',
      'enum': [
        'ar',
        'bg',
        'ca',
        'cs',
        'da',
        'de',
        'el',
        'en',
        'en-EU',
        'es',
        'et',
        'eu',
        'fi',
        'fr',
        'fr-CA',
        'he',
        'hr',
        'hu',
        'id',
        'it',
        'ja',
        'ko',
        'lv',
        'lt',
        'ms',
        'nb',
        'nl',
        'nn',
        'no',
        'pl',
        'pt',
        'ro',
        'ru',
        'sk',
        'sl',
        'sv',
        'th',
        'tr',
        'uk',
        'zh',
        'zh-TW',
        'zh-CN'
      ],
      'type': 'string'
    },
    'links': {
      'items': {
        'additionalProperties': false,
        'dependencies': {
          'state': [
            'stateReason'
          ]
        },
        'properties': {
          'enctype': {
            'description': 'encoding format of the data envelop. A MIME type.',
            'type': 'string'
          },
          'hints': {
            'description': 'provides some information on how to manipulate the resource. it shouldn\'t be used if the resource responds to simple CRUD operations.',
            'items': {
              'additionalProperties': false,
              'properties': {
                'method': {
                  'description': 'by default, the service API definition explains which HTTP methods are allowed on this resource. This hint is recommended if the behavior doesn\'t match common patterns.',
                  'enum': [
                    'get',
                    'put',
                    'patch',
                    'post',
                    'delete'
                  ],
                  'type': 'string'
                }
              },
              'title': 'hint',
              'type': 'object'
            },
            'maxItems': 5,
            'type': 'array'
          },
          'href': {
            'description': 'URL to the resource defined by the relation name',
            'pattern': '^((http[s]?|ftp):/)?/?([^:/\\s]+)((/\\w+)*/)([\\w\\-\\.]+[^#?\\s]+)(.*)?(#[\\w\\-]+)?$',
            'type': 'string'
          },
          'hrefTemplate': {
            'description': 'contains a URL Template. The variable name (inside curly brackets) must be replaced by the variable value. ',
            'type': 'string'
          },
          'rel': {
            'description': 'Relation nane. Each service must define (or at least clarify) the semantic definition of each relation.',
            'type': 'string'
          },
          'state': {
            'default': 'available',
            'description': 'service may advertize a resource or action even though it isn\'t available. When this element is ommitted, the implicit state is available.',
            'enum': [
              'available',
              'unavailable'
            ],
            'type': 'string'
          },
          'stateReason': {
            'description': 'When the state is unavailable, the service may provide a reason.',
            'enum': [
              'noHardware',
              'unlicensed'
            ],
            'type': 'string'
          }
        },
        'required': [
          'rel'
        ],
        'title': 'link',
        'type': 'object'
      },
      'maxItems': 32,
      'type': 'array'
    },
    'mediaDestinationId': {
      'description': 'a unique ID used by this printer to identify this output sub-unit from PWG 5100.2-2001',
      'enum': [
        'auto',
        'bin',
        'default',
        'folder-1',
        'folder-2',
        'folder-3',
        'folder-4',
        'generic-accessory',
        'horizontal-cutter',
        'stacker-1',
        'stacker-2',
        'stacker-3',
        'stacker-4',
        'stacker-accessory',
        'standard-bin',
        'take-up-reel',
        'tray-1',
        'tray-2',
        'tray-3',
        'tray-4',
        'tray-5',
        'tray-6',
        'tray-7',
        'tray-8',
        'tray-9',
        'tray-10',
        'vertical-cutter',
        'alternate'
      ],
      'type': 'string'
    },
    'mediaInput': {
      'additionalProperties': false,
      'properties': {
        'capacityUnit': {
          'description': 'Units of measure for media capacity - TBD: for Common Glossary.',
          'enum': [
            'micrometers',
            'sheets',
            'tenThousandthsOfInches',
            'feet',
            'meters'
          ],
          'type': 'string'
        },
        'currentLevel': {
          'description': 'The current capacity of the input sub-unit in input sub-unit capacity units',
          'type': 'integer'
        },
        'currentMediaSize': {
          '$ref': '#/definitions/mediaSize',
          'description': 'Size of media currently loaded in this input sub-unit - TBD: for Common Glossary'
        },
        'currentMediaType': {
          '$ref': '#/definitions/mediaType',
          'description': 'Type of media currently loaded in this input sub-unit - TBD: for Common Glossary'
        },
        'inputType': {
          'description': 'The type of technology employed by the input sub-unit',
          'enum': [
            'other',
            'unknown',
            'sheetFeedAutoRemovableTray',
            'sheetFeedAutoNonRemovableTray',
            'sheetFeedManual',
            'continuousRoll'
          ],
          'type': 'string'
        },
        'maxCapacity': {
          'description': 'The maximum capacity of the input sub-unit in input sub-unit capacity units',
          'type': 'integer'
        },
        'mediaSourceId': {
          '$ref': '#/definitions/mediaSourceId',
          'description': 'a unique ID used by this printer to identify this input sub-unit from PWG 5100.13'
        },
        'status': {
          'description': 'The current status of this input sub-unit',
          'enum': [
            'ok',
            'empty',
            'unknown',
            'error'
          ],
          'type': 'string'
        }
      },
      'type': 'object'
    },
    'mediaOrientation': {
      'description': 'related to the physical orientation of the media on the tray',
      'enum': [
        'landscape',
        'portrait'
      ],
      'type': 'string'
    },
    'mediaOutput': {
      'additionalProperties': false,
      'properties': {
        'mediaDestinationId': {
          '$ref': '#/definitions/mediaDestinationId',
          'description': 'a unique ID used by this printer to identify this output sub-unit from PWG 5100.2-2001'
        },
        'pageDeliveryOrientation': {
          '$ref': '#/definitions/pageDeliveryOrientation',
          'description': 'The reading surface that will be up when pages are delivered to the associated output sub-unit'
        },
        'stackingOrder': {
          '$ref': '#/definitions/stackingOrder',
          'description': 'The current state of the stacking order associated output sub-unit as defined by RFC 1759'
        }
      },
      'type': 'object'
    },
    'mediaSize': {
      'description': 'Media size, values according to the standard PWG5101.1 or HP extension pattern for exceptions',
      'enum': [
        'any',
        'anycustom',
        'asme_f_28x40in',
        'custom',
        'iso_a0_841x1189mm',
        'iso_a1_594x841mm',
        'iso_a2_420x594mm',
        'iso_a3_297x420mm',
        'iso_a4_210x297mm',
        'iso_a5_148x210mm',
        'iso_a6_105x148mm',
        'iso_b0_1000x1414mm',
        'iso_b1_707x1000mm',
        'iso_b2_500x707mm',
        'iso_b3_353x500mm',
        'iso_b4_250x353mm',
        'iso_b5_176x250mm',
        'iso_b6_125x176mm',
        'iso_c0_917x1297mm',
        'iso_c1_648x917mm',
        'iso_c2_458x648mm',
        'iso_c3_324x458mm',
        'iso_c4_229x324mm',
        'iso_c5_162x229mm',
        'iso_c6_114x162mm',
        'iso_dl_110x220mm',
        'iso_ra3_305x430mm',
        'iso_ra4_215x305mm',
        'iso_sra3_320x450mm',
        'iso_sra4_225x320mm',
        'jis_b0_1030x1456mm',
        'jis_b1_728x1030mm',
        'jis_b2_515x728mm',
        'jis_b3_364x515mm',
        'jis_b4_257x364mm',
        'jis_b5_182x257mm',
        'jis_b6_128x182mm',
        'jpn_chou3_120x235mm',
        'jpn_chou4_90x205mm',
        'jpn_hagaki_100x148mm',
        'jpn_photo-2l_127x177_8mm',
        'jpn_oufuku_148x200mm',
        'na_10x15_10x15in',
        'na_5x7_5x7in',
        'na_a2_4.375x5.75in',
        'na_arch-a_9x12in',
        'na_arch-b_12x18in',
        'na_arch-c_18x24in',
        'na_arch-d_24x36in',
        'na_arch-e_36x48in',
        'na_arch-e2_26x38in',
        'na_arch-e3_27x39in',
        'na_c_17x22in',
        'na_d_22x34in',
        'na_e_34x44in',
        'na_edp_11x14in',
        'na_executive_7.25x10.5in',
        'na_foolscap_8.5x13in',
        'na_govt-letter_8x10in',
        'na_index-3x5_3x5in',
        'na_index-4x6_4x6in',
        'na_index-5x8_5x8in',
        'na_invoice_5.5x8.5in',
        'na_ledger_11x17in',
        'na_legal_8.5x14in',
        'na_letter_8.5x11in',
        'na_monarch_3.875x7.5in',
        'na_number-10_4.125x9.5in',
        'na_number-9_3.875x8.875in',
        'na_oficio_8.5x13.4in',
        'na_personal_3.625x6.5in',
        'na_super-b_13x19in',
        'na_wide-format_30x42in',
        'oe_12x16_12x16in',
        'oe_14x17_14x17in',
        'oe_18x22_18x22in',
        'oe_photo-l_3.5x5in',
        'oe_photo-4x12_4x12in',
        'oe_photo-4x5_4x5in',
        'oe_photo-10r_10x12in',
        'oe_photo-14x18_14x18in',
        'oe_photo-16r_16x20in',
        'oe_photo-20r_20x24in',
        'oe_photo-22x28_22x28in',
        'oe_photo-24x30_24x30in',
        'oe_square-photo_5x5in',
        'om_16k_184x260mm',
        'om_16k_195x270mm',
        'om_8k_260x368mm',
        'om_8k_270x390mm',
        'om_photo-30x40_300x400mm',
        'om_photo-30x45_300x450mm',
        'om_photo-35x46_350x460mm',
        'om_photo-40x60_400x600mm',
        'om_photo-50x76_500x760mm',
        'om_photo-60x90_600x900mm',
        'om_small-photo_100x150mm',
        'om_medium-photo_130x180mm',
        'roc_16k_7.75x10.75in',
        'roc_8k_10.75x15.5in',
        'com.hp.ext.mediaSize.iso_a4_210x297mm.rotated',
        'com.hp.ext.mediaSize.na_letter_8.5x11in.rotated',
        'com.hp.ext.mediaSize.iso_a5_148x210mm.rotated',
        'com.hp.ext.mediaSize.jis_b5_182x257mm.rotated',
        'com.hp.ext.mediaSize.jpn_oufuku_148x200mm.rotated',
        'com.hp.ext.mediaSize.mixed-letter-legal',
        'com.hp.ext.mediaSize.mixed-letter-ledger',
        'com.hp.ext.mediaSize.mixed-a4-a3',
        'com.hp.ext.mediaSize.long-scan'
      ],
      'type': 'string'
    },
    'mediaSourceId': {
      'description': 'a unique ID used by this printer to identify this input sub-unit from PWG 5100.13',
      'enum': [
        'alternate',
        'alternate-roll',
        'auto',
        'bottom',
        'by-pass-tray',
        'center',
        'disc',
        'duplexer',
        'envelope',
        'flat-bed',
        'hagaki',
        'large-capacity',
        'left',
        'main',
        'main-roll',
        'manual',
        'middle',
        'photo',
        'rear',
        'right',
        'roll',
        'roll-1',
        'roll-10',
        'roll-11',
        'roll-2',
        'roll-3',
        'roll-4',
        'roll-5',
        'roll-6',
        'roll-7',
        'roll-8',
        'roll-9',
        'side',
        'textile',
        'top',
        'tray-1',
        'tray-10',
        'tray-11',
        'tray-12',
        'tray-13',
        'tray-2',
        'tray-3',
        'tray-4',
        'tray-5',
        'tray-6',
        'tray-7',
        'tray-8',
        'tray-9'
      ],
      'type': 'string'
    },
    'mediaType': {
      'description': 'Media type,  values according to the standard PWG5101.1',
      'enum': [
        'any',
        'cardstock',
        'com.hp.advanced-photo',
        'com.hp.brochure-glossy',
        'com.hp.brochure-matte',
        'com.hp.cardstock-glossy',
        'com.hp.EcoSMARTLite',
        'com.hp.extra-heavy',
        'com.hp.extra-heavy-gloss',
        'com.hp.film-opaque',
        'com.hp.glossy-130gsm',
        'com.hp.glossy-160gsm',
        'com.hp.glossy-220gsm',
        'com.hp.heavy-glossy',
        'com.hp.heavy-rough',
        'com.hp.heavypaperboard',
        'com.hp.intermediate',
        'com.hp.lightpaperboard',
        'com.hp.matte-105gsm',
        'com.hp.matte-120gsm',
        'com.hp.matte-160gsm',
        'com.hp.matte-200gsm',
        'com.hp.matte-90gsm',
        'com.hp.midweight',
        'com.hp.midweight-glossy',
        'com.hp.paperboard',
        'com.hp.recycled',
        'com.hp.soft-gloss-120gsm',
        'com.hp-stationery-thick',
        'com.hp.rough',
        'com.hp-bright-white-stationery-inkjet-paper',
        'com.hp-clear-film',
        'com.hp-everyday-adhesive-gloss-polypropylene-labels',
        'com.hp-everyday-adhesive-polypropylene-labels-matte',
        'com.hp-everyday-matte-polypropylene',
        'com.hp-production-matte-polypropylene',
        'com.hp-matte-film',
        'com.hp-matte-inkjet',
        'com.hp-matte-photo-duplex',
        'com.hp-matte-presentation',
        'com.hp-matte-brochure',
        'com.hp-production-matte-poster-paper',
        'com.hp-production-satin-poster-paper',
        'com.hp-natural-tracing-paper-stationery-lightweight',
        'com.hp-photographic-glossy',
        'com.hp-photographic-inkjet',
        'com.hp-premium-matte-polypropylene',
        'com.hp-specialty-glossy',
        'com.hp-specialty-glossy-inkjet',
        'com.hp-specialty-hagaki',
        'com.hp-stationery-bond-universal-paper',
        'com.hp-20lb-bond-colorpro-technology',
        'com.hp-premium-bond-paper',
        'com.hp-bright-white-bond-paper',
        'com.hp-stationery-coated-paper',
        'com.hp-stationery-heavyweight-coated',
        'com.hp-stationery-light',
        'com.hp-trifold-brochure-glossy-150gsm',
        'com.hp-trifold-brochure-glossy-180gsm',
        'com.hp-universal-adhesive-vinyl-labels',
        'com.hp-universal-instant-dry-photographic-glossy-paper',
        'com.hp-universal-instant-dry-satin-photographic-semi-gloss-paper',
        'com.hp-universal-photographic-glossy-paper',
        'com.hp-universal-photographic-satin-paper',
        'com.hp-universal-stationery-coated-paper',
        'com.hp-universal-stationery-heavyweight-coated-paper',
        'com.hp-gloss-poster-paper',
        'com.hp-double-matte-film',
        'com.hp-polyester-backlit',
        'com.hp-generic-self-adhesive-vinyl-paper',
        'com.hp-foldable-document-material',
        'custom',
        'custom-adhesive-backlit',
        'custom-adhesive-polypropylene-labels-glossy',
        'custom-adhesive-polypropylene-labels-matte',
        'custom-adhesive-polypropylene-labels',
        'custom-blueprint-paper-with-red-stamps-stationery-colored',
        'custom-canvas',
        'custom-clear-film-Kod',
        'custom-coated-cad',
        'custom-digital-blueprint-paper-stationery-colored',
        'custom-generic-natural-tracing-paper-over-65gsm-stationery-lightweight',
        'custom-generic-natural-tracing-paper-under-65gsm-stationery-lightweight',
        'custom-generic-natural-tracing-paper-stationery-lightweight',
        'custom-heat-transfer',
        'custom-matte-film',
        'custom-natural-tracing-rubber-resistant-stationery-lightweight-paper',
        'custom-pet-backlit',
        'custom-pet-frontlit',
        'custom-pet-frontlit-transparent',
        'custom-plain-paper-for-retail-stationery',
        'custom-polypropylene-photographic-satin-paper',
        'custom-pppebanner',
        'custom-pvc-banner',
        'custom-pvc-banner-backlit',
        'custom-polyester-backlit',
        'custom-self-adhesive-cast',
        'custom-self-adhesive-transparent',
        'custom-stationery-backlit',
        'custom-stationery-blueback',
        'custom-stationery-graphics',
        'custom-super-heavy-coated',
        'custom-synthetic',
        'custom-synthetic-backlit',
        'custom-textile',
        'custom-textile-backlit',
        'custom-uncoated',
        'custom-wallpaper-non-woven',
        'custom-wallpaper-woven',
        'custom-wallpaper',
        'custom-super-heavyweight-plus-matte',
        'custom-photo-matte-paper',
        'custom-polypropylene',
        'custom-yellow-paper',
        'backlit-material',
        'envelope',
        'envelope-heavyweight',
        'labels',
        'photographic-glossy',
        'photographic-semi-gloss',
        'plastic-matte',
        'self-adhesive-labels',
        'stationery',
        'stationery-bond',
        'stationery-coated',
        'stationery-colored',
        'stationery-fine',
        'stationery-heavyweight',
        'stationery-heavyweight-coated',
        'stationery-letterhead',
        'stationery-lightweight',
        'stationery-preprinted',
        'stationery-prepunched',
        'transfer',
        'transparency',
        'com.hp.light-bond',
        'com.hp-production-satin-photo-paper',
        'com.hp-production-gloss-photo-paper'
      ],
      'type': 'string'
    },
    'pageDeliveryOrientation': {
      'description': 'The reading surface that will be up when pages are delivered to the associated output sub-unit',
      'enum': [
        'faceUp',
        'faceDown',
        'faceBoth'
      ],
      'type': 'string'
    },
    'phoneNumber': {
      'description': 'Fax identification number for the modem. E.123 is a standards-based recommendation by the International Telecommunications Union sector ITU-T, entitled notation for national and international telephone numbers, e-mail addresses and web addresses.',
      'pattern': '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$',
      'type': 'string'
    },
    'plexMode': {
      'enum': [
        'simplex',
        'duplex'
      ],
      'type': 'string'
    },
    'printQuality': {
      'description': 'print quality value used in the Job',
      'enum': [
        'draft',
        'normal',
        'best'
      ],
      'type': 'string'
    },
    'scanMediaSourceId': {
      'description': 'Scan media source values. This values will be use in the jobTicket scan definition',
      'enum': [
        'adf',
        'flatbed',
        'mdf'
      ],
      'type': 'string'
    },
    'scanMediaType': {
      'description': 'As mediaType is based on PWG, and so Print specific, scanMediaType includes the types supported by Scan',
      'enum': [
        'blueprints',
        'darkBlueprints',
        'oldRecycledPaper',
        'object3d',
        'photoPaper',
        'translucentPaper',
        'whitePaper',
        'whitePaperEnhanced',
        'brochureMatte',
        'brochureGlossy'
      ],
      'type': 'string'
    },
    'serviceMetadata': {
      'additionalProperties': false,
      'properties': {
        'description': {
          'description': 'add a short human readable description of the service function',
          'type': 'string'
        },
        'links': {
          '$ref': '#/definitions/links'
        },
        'serviceGun': {
          'description': 'service Globally Unique Name. Follow the service naming convention to avoid name collisions. ',
          'type': 'string'
        },
        'version': {
          '$ref': '#/definitions/version'
        }
      },
      'required': [
        'serviceGun'
      ],
      'title': 'serviceMetadata',
      'type': 'object'
    },
    'servicesDiscovery': {
      'additionalProperties': false,
      'properties': {
        'services': {
          'items': {
            '$ref': '#/definitions/serviceMetadata'
          },
          'maxItems': 256,
          'type': 'array'
        },
        'version': {
          '$ref': '#/definitions/version'
        }
      },
      'required': [
        'services'
      ],
      'type': 'object'
    },
    'stackingOrder': {
      'description': 'The current state of the stacking order associated output sub-unit as defined by RFC 1759',
      'enum': [
        'firstToLast',
        'lastToFirst'
      ],
      'type': 'string'
    },
    'uuid': {
      'description': 'standard uuid format - according to RFC 4122. In its canonical textual representation, the 16 octets of a UUID are represented as 32 hexadecimal (base-16) digits, displayed in five groups separated by hyphens, in the form 8-4-4-4-12 for a total of 36 characters (32 hexadecimal characters and 4 hyphens).   For example: 123e4567-e89b-12d3-a456-426614174000',
      'maxLength': 36,
      'minLength': 36,
      'pattern': '[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}',
      'type': 'string'
    },
    'version': {
      'description': 'Conforms to the version scheme defined for all APIs',
      'pattern': '(\\d+\\.\\d+\\.\\d+(-\\w+\\.\\d+)?)',
      'title': 'Common Version',
      'type': 'string'
    }
  },
  'description': 'Moving to beta for ratification',
  'gun': 'com.hp.cdm.domain.glossary.version.1',
  'id': 'https://schemaregistry.analysis.ext.hp.com/cdm/gun/com.hp.cdm.domain.glossary.version.1.schema.json',
  'jsonSchemaGun': 'com.hp.cdm.domain.glossary.version.1.schema.json',
  'properties': {},
  'type': 'object',
  'version': '1.9.0'
};
