export const eventingEvent = {
  '$schema': 'http://json-schema.org/draft-04/schema#',
  'version': '1.4.0',
  'gun': 'com.hp.cdm.service.eventing.version.1.resource.eventingEvent',
  'description': 'Promoting 1.4.0-beta.2 to 1.4.0',
  'id': 'https://schemaregistry.analysis.ext.hp.com/cdm/gun/com.hp.cdm.service.eventing.version.1.resource.eventingEvent.schema.json',

  'definitions': {
    'event': {
      'dependencies': {
        'eventDetail': [
          'eventDetailType'
        ],
        'onDuration': [
          'powerCycleCount'
        ],
        'powerCycleCount': [
          'onDuration'
        ]
      },
      'properties': {
        'dateTime': {
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/dateTime',
          'description': 'Originator datetime when the event actually occured'
        },
        'eventCategory': {
          '$ref': '#/definitions/eventCategory'
        },
        'eventDetail': {
          'description': 'The payload is optional, but will typically contain the details of the event. The structure and content of the eventDetail is specific to each eventCategory, and is defined in a different JSON-schema.',
          'type': 'object'
        },
        'eventDetailJec': {
          'description': 'This is the encrypted alternative to eventDetail. It is a JWE Compact string. Consult the security section for algorithms and keys.',
          'type': 'string'
        },
        'eventDetailType': {
          'description': 'Unique identifier (GUN) for the syntax and semantic definitions of eventDetail',
          'type': 'string'
        },
        'onDuration': {
          'description': 'Number of seconds since the imaging device was turned on when the event actually occured',
          'maximum': 2147483647,
          'minimum': 0,
          'type': 'integer'
        },
        'powerCycleCount': {
          'description': 'Number of power cycles since the manufacturing of the imaging device when the event actually occured',
          'maximum': 2147483647,
          'minimum': 0,
          'type': 'integer'
        },
        'sequenceNumber': {
          'description': 'The sequence number is an incrementing identifier used to indicate order of events. This number is LOCALLY scoped to each eventCategory',
          'maximum': 2147483647,
          'minimum': 0,
          'type': 'integer'
        },
        'version': {
          '$ref': 'com.hp.cdm.domain.glossary.version.1.schema.json#/definitions/version'
        },
        'sequenceNumberGlobal': {
          'description': 'The sequence number is an incrementing identifier used to indicate order of events. This number is GLOBALLY scoped across all eventCategory\'s (This field is required for SW Clients.  Not applicable for imaging device.',
          'maximum': 2147483647,
          'minimum': 0,
          'type': 'integer'
        },
        'filter': {
          'description': 'filter object configured for this resource gun',
          '$ref': 'com.hp.cdm.service.dataValve.version.1.sharedTypes.dataValve.schema.json#/definitions/filterInfo'
        },
        'filterError': {
          'description': 'error while applying filter (from CommonGlossary) in case of failure to apply the filter the behaviour is determined from the filterErrorBEhavior config',
          '$ref': 'com.hp.cdm.service.dataValve.version.1.sharedTypes.dataValve.schema.json#/definitions/filterError'
        }
      },
      'required': [
        'eventCategory',
        'sequenceNumber'
      ],
      'title': 'event',
      'type': 'object',
      'additionalProperties': false
    },
    'eventCategory': {
      '$ref': 'com.hp.cdm.service.eventing.version.1.sharedTypes.eventing.schema.json#/definitions/eventCategory'
    }
  },
  'jsonSchemaGun': 'com.hp.cdm.service.eventing.version.1.resource.eventingEvent.schema.json'
};
