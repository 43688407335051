import { Stack } from '../../../types/stratus';

// Static config_id values for common use cases for OC
const configIds = {
  stg: '59f93866-d28f-4aee-b150-947045966bdc',
  pro: '6d745146-6321-40e0-9ba2-82db43a1bc51'
};

export default function getConfigIdBasedOnStack(stack: Stack): string {
  switch (stack) {
    case Stack.prod:
      return configIds.pro;
    case Stack.stage:
      return configIds.stg;
    default:
      return configIds.stg;
  }
}
