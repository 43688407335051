import { TenantVisualizationType } from '../../strategy/types';
import { ITenantFilter } from '../ITenantFilter';
import { RegexTenantFilterPropsType } from '../RegexTenantFilter/types';

export default class RoleFilter implements ITenantFilter {
  private _filterProps: RegexTenantFilterPropsType;

  constructor(filter?: RegexTenantFilterPropsType) {
    this._filterProps = filter;
  }

  private _filterByRole(
    filter: RegexTenantFilterPropsType,
    tenant: TenantVisualizationType
  ) {
    let isValid = true;
    if (filter?.role) {
      isValid =
        filter?.role.toLowerCase() === tenant?.roleCategory?.toLowerCase();
    }
    return isValid;
  }

  filter(tenantList: TenantVisualizationType[]): TenantVisualizationType[] {
    return tenantList.filter((tenant: TenantVisualizationType) => {
      return this._filterByRole(this._filterProps, tenant);
    });
  }
}
