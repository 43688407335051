import bindAllMethods from '../../../utils/bindAllMethods';
import * as T from './types';
import { INavigationService } from '../../../services/navigationService';
import { getServices } from '../../../infra/commonInitializer';

class NavigationInterface {
  private _navigationService: INavigationService;
  get length(): T.NavigationInterfaceType['length'] {
    return this?._navigationService?.length;
  }
  get action(): T.NavigationInterfaceType['action'] {
    return this?._navigationService?.action;
  }
  get location(): T.NavigationInterfaceType['location'] {
    return this?._navigationService?.location;
  }

  constructor() {
    const services = getServices();
    this._navigationService = services.navigationService;
    bindAllMethods(this);
  }

  redirect(url: string): void {
    this._navigationService.redirect(url);
  }

  push(
    location: T.NavigationInterfaceLocationDescriptor,
    state?: unknown
  ): void {
    this._navigationService.push(location, state);
  }

  replace(
    location: T.NavigationInterfaceLocationDescriptor,
    state?: unknown
  ): void {
    this._navigationService.replace(location, state);
  }

  go(n: number): void {
    this._navigationService.go(n);
  }

  goBack(): void {
    this._navigationService.goBack();
  }

  goForward(): void {
    this._navigationService.goForward();
  }

  block(
    prompt?: string | boolean | T.NavigationInterfaceTransitionPromptHook
  ): T.NavigationInterfaceUnregisterCallback {
    return this._navigationService.block(prompt);
  }

  listen(
    listener: T.NavigationInterfaceLocationListener
  ): T.NavigationInterfaceUnregisterCallback {
    return this._navigationService.listen(listener);
  }

  createHref(location: T.NavigationInterfaceLocationDescriptorObject): string {
    return this._navigationService.createHref(location);
  }

  public getInterface(): T.NavigationInterfaceType {
    const _navigationInterface = {
      push: this.push,
      replace: this.replace,
      go: this.go,
      goBack: this.goBack,
      goForward: this.goForward,
      block: this.block,
      createHref: this.createHref,
      listen: this.listen,
      redirect: this.redirect
    };

    // Explanation for this trick: https://stackoverflow.com/questions/33827519/es6-getter-setter-with-arrow-function
    Object.defineProperty(_navigationInterface, 'location', {
      get: () => this.location
    });
    Object.defineProperty(_navigationInterface, 'length', {
      get: () => this.length
    });
    Object.defineProperty(_navigationInterface, 'action', {
      get: () => this.action
    });
    // @ts-ignore
    return _navigationInterface;
  }
}

export default NavigationInterface;
