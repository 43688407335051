import { AbstractLocaleHandler } from './AbstractLocaleHandler';
import { ServiceLocalizationFallbackType } from './../types';
import getCookieLocale from '../utils/getCookieLocale';

export class CookieLocaleHandler extends AbstractLocaleHandler {
  constructor(languageFallbackList: ServiceLocalizationFallbackType) {
    super(languageFallbackList);
  }

  public handle(isLanguage: boolean, currentList: string[]): string {
    const cookieLocale = getCookieLocale();

    return super.genericHandle({
      isLanguage: isLanguage,
      locale: cookieLocale,
      currentList: currentList,
      localeFallback: undefined
    });
  }
}
