import { GrantsHistoryInterface } from './GrantsHistoryInterface';
import { GrantsHistoryInterfaceType } from './types';

let grantsHistoryInterface: GrantsHistoryInterface;

export function getSessionInterfaceV1(): GrantsHistoryInterfaceType {
  return grantsHistoryInterface.getInterface();
}

export default async function initializegrantsHistoryInterfaceV1(): Promise<GrantsHistoryInterfaceType> {
  if (grantsHistoryInterface) return grantsHistoryInterface.getInterface();
  grantsHistoryInterface = new GrantsHistoryInterface();

  return grantsHistoryInterface.getInterface();
}

export function createNoopGrantsHistoryInterface(): GrantsHistoryInterfaceType {
  return {
    refresh: function (): Promise<void> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    checkGrantsHistory: function (): Promise<boolean> {
      console.debug('Function not implemented.');
      return Promise.resolve(false);
    }
  };
}
