import { FeatureFlagClientInterfaceV1Type } from 'src/interface/v1/featureFlags/types';
import type * as T from './types';

export default async function getMockedFeatureFlagsNorthboundAPI(
  dependencies?: T.GetMockedFeatureFlagsNorthboundDependenciesType
): Promise<T.GetMockedFeatureFlagsNorthboundAPIReturnType> {
  if (!dependencies?.enable) return undefined;

  const result: T.GetMockedFeatureFlagsNorthboundAPIReturnType = {};

  const getFeatureFlag: FeatureFlagClientInterfaceV1Type['getFeatureFlag'] =
    async (options) => options?.defaultValue;

  const getAllFeatureFlags: FeatureFlagClientInterfaceV1Type['getAllFeatureFlags'] =
    async () => [];

  result.v1 = {
    createCustomClient: async () => ({
      getFeatureFlag,
      getAllFeatureFlags
    }),
    getClient: async () => ({
      getFeatureFlag,
      getAllFeatureFlags
    }),
    useReactFeatureFlag: (_React, _Client, options) => options?.defaultValue
  };

  return result;
}
