import v1Auth from './v1/auth';
import v2Auth from './v2/auth';

export type ShellStatesType = {
  init: (
    initializerState: InterfacesInitializerStatePropsType
  ) => Promise<void>;
  initialized: boolean;
  loading: boolean;
  error: boolean;
  initializers: {
    authProvider: {
      v1: typeof v1Auth;
      v2: typeof v2Auth;
    };
  };
};

export type InterfacesInitializerStatePropsType = {
  defaultAssetReferences?: AssetReferencesMap;
  [key: string]: unknown;
};

export enum TokenType {
  stratusAccessToken = 'shell-stratus-access-token',
  stratusIdToken = 'shell-stratus-id-token',
  deprecatedstratusAccessToken = 'stratus-access-token',
  deprecatedstratusIdToken = 'stratus-id-token',
  deprecatedOrglessToken = 'stratus-orgless-access-token',
  deprecatedOrgedToken = 'stratus-org-access-token',
  orglessToken = 'shellStratusOrglessAccessToken',
  orgedToken = 'shellStratusOrgAccessToken',
  astToken = 'shell-support-session',
  supportSession = 'shell-support-session',
  shellSessionId = 'shell-session-id',
  shellSessionActive = 'shell-session-active'
}

export enum UserContextEnum {
  organization = 'organization',
  customer = 'customer',
  orgless = 'orgless'
}

export type RedirectToLoginParams = {
  postLoginRedirect: string;
};

export type AssetReferencesMap = {
  [key: string]: string;
};
