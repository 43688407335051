import bindAllMethods from '../../utils/bindAllMethods';
import matchPath from '../../utils/matchPath';
import * as T from './types';
import IRoutesService from './IRoutesService';
import getRoutesAsFlatMap from './utils/getRouteAsFlatMap';
import Criterion from '../../interface/v1/Criterion';
import { internalLogger } from '../../interface/v1/logger';
import { getServices } from '../../infra/commonInitializer';

export default class RoutesService implements IRoutesService {
  private _routes: T.RouteServiceInputType[] = [];
  private _defaultLayoutKey?: string | false;
  private _defaultCriterionKey?: string | false;
  private _routesFlatMap: T.RouteServiceInputType[] = [];
  private _criterionInterface: Criterion<any>;

  constructor(dependencies: T.RoutesServiceDependenciesInputType) {
    this._defaultLayoutKey = dependencies?.defaultLayoutKey;
    this._defaultCriterionKey = dependencies?.defaultCriterionKey;

    bindAllMethods(this);

    // Injecting the Default values.
    this._routes =
      this.recursiveInjectRoutesDefaultValues({
        routes: dependencies?.routes,
        defaultLayoutKey: this._defaultLayoutKey,
        defaultCriterionKey: this._defaultCriterionKey
      }) || [];

    // Adding Exact as true in "/" path.
    this._routes = this._addingExactInRoot(this._routes);

    // Flatting the routes to make it easier to find the best match.
    this._routesFlatMap = getRoutesAsFlatMap(this._routes);

    // Adding the Priority Rank by counting slashes after all.
    this._routesFlatMap = this._settingRankByPaths(this._routesFlatMap);
  }

  private _settingRankByPaths(
    routes: T.RouteServiceInputType[]
  ): T.RouteServiceInputType[] {
    const newRoutes = routes.map((route) => {
      const { path, priority } = route;
      const pathArray = Array.isArray(path) ? path : [path];
      const bestPriority = pathArray.reduce((max, current) => {
        const currentPrio = current.split('/').length - 1;
        return max && max > currentPrio ? max : currentPrio;
      }, 1);
      const maxPriority = priority >= bestPriority ? priority : bestPriority;
      return {
        ...route,
        priority: maxPriority
      };
    });
    return newRoutes;
  }

  private _addingExactInRoot(
    routes: T.RouteServiceInputType[]
  ): T.RouteServiceInputType[] {
    const newRoutes = routes.map((route) => {
      const { path } = route;
      const pathArray = Array.isArray(path) ? path : [path];
      const rootPath = pathArray?.some((thisPath) => {
        return thisPath === '/';
      });
      if (rootPath) {
        return {
          ...route,
          exact: true
        };
      }
      return route;
    });
    return newRoutes;
  }

  // TODO we should remove this ASAP
  public setInterfaceDependencies({
    criterionInterface
  }: {
    criterionInterface: Criterion<any>;
  }): void {
    this._criterionInterface = criterionInterface;
  }

  private recursiveInjectRoutesDefaultValues(options: {
    routes: T.RouteServiceInputType[];
    defaultLayoutKey: string | false;
    defaultCriterionKey: string | false;
  }) {
    if (!Array.isArray(options?.routes)) return;
    return options?.routes?.map?.((route) => {
      const getLayoutKey = (...layoutKeys: (string | false)[]) => {
        return layoutKeys?.find?.((layoutKey) => {
          const isLayoutKeyString =
            typeof layoutKey === 'string' && layoutKey?.length > 0;
          if (isLayoutKeyString || layoutKey === false) return true;
          return false;
        });
      };
      const getCriterionKey = (...criterionKeys: (string | false)[]) => {
        return criterionKeys?.find?.((criterionKey) => {
          const isLayoutKeyString =
            typeof criterionKey === 'string' && criterionKey?.length > 0;
          if (isLayoutKeyString || criterionKey === false) return true;
          return false;
        });
      };

      const defaultLayoutKey = getLayoutKey(
        route?.defaultLayoutKey,
        options?.defaultLayoutKey
      );

      const layoutKey = getLayoutKey(route?.layoutKey, defaultLayoutKey);

      const defaultCriterionKey = getCriterionKey(
        route?.defaultCriterionKey,
        options?.defaultCriterionKey
      );

      const criterionKey = getCriterionKey(
        route?.criterionKey,
        defaultCriterionKey
      );

      return {
        ...route,
        layoutKey,
        defaultLayoutKey,
        criterionKey,
        defaultCriterionKey,
        subRoutes: this.recursiveInjectRoutesDefaultValues({
          routes: route?.subRoutes,
          defaultLayoutKey,
          defaultCriterionKey
        })
      };
    });
  }

  private recursiveFind(
    callback: (route: T.RouteServiceInputType) => boolean,
    route = this._routes
  ) {
    let result: T.RouteServiceInputType;

    route.forEach((thisRoute) => {
      if (!result) {
        if (callback(thisRoute)) {
          result = thisRoute;
        } else if (thisRoute?.subRoutes) {
          result = this.recursiveFind(callback, thisRoute?.subRoutes);
        }
      }
    });

    return result;
  }

  getRoutes(): T.RouteServiceInputType[] {
    return this._routes;
  }

  findRouteByKey(key: string): T.RouteServiceInputType {
    if (!key) return;
    return this.recursiveFind((route) => route?.key === key);
  }

  // TODO: This function should be replaced by findRouteWithPriority for better matching strategy
  /**
   * @deprecated Use the findRouteWithPriority instead. Soon, findRouteWithPriority() will be deprecated.
   */
  findRouteByPath(path: string | string[]): T.RouteServiceInputType {
    if (!path) return;

    return this.recursiveFind((route) => {
      return matchPath(route?.path, {
        pathToCompare: path,
        exact:
          typeof route?.exact === 'boolean' ? route?.exact : route?.path === '/'
      });
    });
  }

  async _removeInvalidCriterions(
    listRoutes: T.RouteServiceInputType[]
  ): Promise<T.RouteServiceInputType[]> {
    const newRouteList = [];
    if (this._criterionInterface === undefined) {
      internalLogger?.warn?.(
        "Trying to use criterionInterface before it's ready."
      );
      return listRoutes;
    }
    const services = getServices();
    const isLoggedIn = services.sessionService.isLoggedIn();

    for (const route of listRoutes) {
      if (!route?.public && !isLoggedIn) {
        newRouteList.push(route);
      } else {
        const { criterionKey } = route || {};
        if (!criterionKey) {
          newRouteList.push(route);
          continue;
        }
        const criterion =
          await this._criterionInterface.checkAdditionalCriterionDataByKey(
            criterionKey
          );

        if (criterion?.result) {
          newRouteList.push(route);
        }
      }
    }

    return newRouteList;
  }

  async findRouteWithPriority(
    originPath: string | string[],
    applyCriterionsMatch: boolean = true
  ): Promise<T.RouteServiceInputType> {
    const list = this._routesFlatMap;

    const result = list?.filter?.(({ path, exact }) => {
      return matchPath(path, {
        pathToCompare: originPath,
        exact: !!exact
      });
    });

    if (!result.length) {
      return {};
    }

    let trimmedData = result;
    if (applyCriterionsMatch) {
      trimmedData = await this._removeInvalidCriterions(result);
    }

    if (!trimmedData.length) {
      return {};
    }

    const bestMatchRoute = trimmedData.reduce((prev, current) => {
      return prev && prev.priority >= current.priority ? prev : current;
    });

    return bestMatchRoute;
  }
}
