import bindAllMethods from '../../utils/bindAllMethods';
import AppContext from '../../services/appContext/AppContext';
import TenantObserver, {
  TenantEvents
} from '../../services/tenantHandler/TenantObserver';
import { HandledTenant } from '../../services/tenantHandler/types';

export default class InitializeContextServices {
  private _appContext: AppContext;

  constructor({ appContext }) {
    this._appContext = appContext;
    bindAllMethods(this);
  }

  public init = async (): Promise<void> => {
    TenantObserver.subscribe(
      TenantEvents.SET_TENANT_HANDLER_KEY,
      this._initializeContextServices
    );

    TenantObserver.subscribe(
      TenantEvents.SET_TENANT,
      this._refreshContextServices
    );
  };

  public async _initializeContextServices(
    tenants: HandledTenant[]
  ): Promise<void> {
    await this._appContext?.initializeContextServices();
  }

  public async _refreshContextServices(
    tenants: HandledTenant[]
  ): Promise<void> {
    await this._appContext?.refreshContext();
  }
}
