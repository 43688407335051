// Documentation: https://pages.github.azc.ext.hp.com/hpid/hpid-docs/authn-authorize.html
export const hpidAdditionalSearchParams = [
  'nonce',
  'prompt',
  'max_age',
  'acr_values',
  'country',
  'locale',
  'login_hint',
  'username_type',
  'target',
  'allow_return',
  'email',
  'family_name',
  'given_name',
  'remember_me',
  'hide_create',
  'light_ui',
  'domain_list',
  'consent',
  'appInstanceID'
];
