import { getTenantTokenWithoutStore } from '../../clients/shell/session';
import {
  AuthContextEnum,
  IAuthTokenService
} from '../../services/authTokenService';

import { getServices } from '../../infra/commonInitializer';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { UserContextEnum } from '../../interface/types';
import { ISessionService } from '../../services/session';
import userContextEnumToAuthContextEnum from '../authTokenService/utils/userContextEnumToAuthContextEnum';
import ITenantHandlerService from '../tenantHandler/ITenantHandlerService';
import IAuthProviderService from './IAuthProviderService';
import { AuthProviderServiceParams } from './types';
import { internalLogger } from '../../interface/v1/logger';

export default class AuthProviderService implements IAuthProviderService {
  private _tenantHandlerService: ITenantHandlerService;
  private _sessionService: ISessionService;
  private _authTokenService: IAuthTokenService;
  private _authContext: AuthContextEnum;
  private _tenantId: string;

  constructor(options?: AuthProviderServiceParams) {
    const { authContext, tenantId } = options || {};
    this._authContext = authContext;
    this._tenantId = tenantId;
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const { tenantHandlerService, sessionService, authTokenService } = services;
    this._tenantHandlerService = tenantHandlerService;
    this._sessionService = sessionService;
    this._authTokenService = authTokenService;
  }

  // TODO: Temporary to unblock
  public createOrgedAuthProvider(): IAuthProviderService {
    const result = new AuthProviderService({
      authContext: AuthContextEnum.tenant
    });
    result.setDependencies({
      services: getServices(),
      clients: undefined,
      repositories: undefined
    });
    return result;
  }

  // TODO: Temporary to unblock
  public createOrglessAuthProvider(): IAuthProviderService {
    const result = new AuthProviderService({
      authContext: AuthContextEnum.tenantless
    });
    result.setDependencies({
      services: getServices(),
      clients: undefined,
      repositories: undefined
    });
    return result;
  }

  // TODO: Temporary to unblock. Need to be removed and use a AuthContextEnum instead
  public createAuthProviderByUserContextEnum(
    userContext: UserContextEnum
  ): IAuthProviderService {
    const authContext = userContextEnumToAuthContextEnum(userContext);

    const result = new AuthProviderService({
      authContext
    });
    result.setDependencies({
      services: getServices(),
      clients: undefined,
      repositories: undefined
    });
    return result;
  }

  public createAuthProviderByAuthContextEnum(
    authContext: AuthContextEnum
  ): IAuthProviderService {
    const result = new AuthProviderService({
      authContext
    });
    result.setDependencies({
      services: getServices(),
      clients: undefined,
      repositories: undefined
    });
    return result;
  }

  public async getAccessToken(forceRefresh?: boolean): Promise<string> {
    if (this._tenantId) {
      return this._getTokenByTenantId();
    }
    let token = this._authTokenService.getToken(this._authContext)?.token;

    if ((!token && this._sessionService.isLoggedIn()) || forceRefresh) {
      await this._forceRefresh();
      token = this._authTokenService.getToken(this._authContext)?.token;
    }

    return token;
  }

  private _forceRefresh = async () => {
    try {
      await this._sessionService.refresh({
        tenantsIdMap: this._tenantHandlerService.getTenantIdsMap()
      });
    } catch (error) {
      internalLogger?.error('Error refreshing token', error);
      if (this._sessionService.isLoggedIn()) {
        await this._sessionService.logout();
      }
    }
  };

  private async _getTokenByTenantId(): Promise<string> {
    const targetUserContext =
      this._authContext === AuthContextEnum.tenant
        ? UserContextEnum.customer
        : UserContextEnum.organization;

    // TODO: Currently using a deprecated service. Should be upgraded to use the client.
    const { stratusToken } = await getTenantTokenWithoutStore(
      targetUserContext,
      this._tenantId,
      this._authTokenService?.getToken(this._authContext)?.token
    );
    return stratusToken;
  }
}
