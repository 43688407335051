import { ValveControllerMetadata } from '@jarvis/jweb-core';
import { getWindowValues, invalidateValveControllerMetaDataKey } from '../client/utils/enum';

export const checkInvalidateMetaData = (valveControllerMetadata: ValveControllerMetadata) => {
  const getInvalidateValveControllerMetaData = getInvalidateValveControllerMetaDataFromStore();
  if (getInvalidateValveControllerMetaData) {
    const invalidateValveControllerMetaData = JSON.parse(getInvalidateValveControllerMetaData);
    const getIndex = invalidateValveControllerMetaData.findIndex((element: string) => element === JSON.stringify(valveControllerMetadata));
    return getIndex > -1;
  }
  return false;
};

export const getInvalidateValveControllerMetaDataFromStore = () => {
  const windowValue = getWindowValues();
  const invalidateValveControllerMetaData = windowValue.localStorage.getItem(invalidateValveControllerMetaDataKey);
  return invalidateValveControllerMetaData;
};

export const setInvalidateValveControllerMetaDataInstore = (valveContollerData: string[]) => {
  const windowValue = getWindowValues();
  let invalidateValveControllerMetaData = getInvalidateValveControllerMetaDataFromStore();
  if (invalidateValveControllerMetaData) {
    // const getIndex = JSON.parse(invalidateValveControllerMetaData).findIndex((element: string) => element === JSON.stringify(valveControllerMetadata));
    invalidateValveControllerMetaData = [...JSON.parse(invalidateValveControllerMetaData),...valveContollerData];
  } else {
    invalidateValveControllerMetaData = valveContollerData;
  }
  windowValue.localStorage.setItem(invalidateValveControllerMetaDataKey,JSON.stringify(invalidateValveControllerMetaData));
};

export const removeInvalidateValveControllerMetaData = (valveControllerMetadata: ValveControllerMetadata) => {
  const windowValue = getWindowValues();
  const getInvalidateValveControllerMetaData = getInvalidateValveControllerMetaDataFromStore();
  if (getInvalidateValveControllerMetaData) {
    const invalidateValveControllerMetaData = JSON.parse(getInvalidateValveControllerMetaData);
    const getIndex = invalidateValveControllerMetaData.findIndex((element: string) => element === JSON.stringify(valveControllerMetadata));
    if (getIndex > -1) {
      invalidateValveControllerMetaData.splice(getIndex, 1); // 2nd parameter means remove one item only
      windowValue.localStorage.setItem(invalidateValveControllerMetaDataKey,JSON.stringify(invalidateValveControllerMetaData));;
    }
  }
};
