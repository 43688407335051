import * as T from '../types';

export default function getLocalCachedOnboardingAgentSession():
  | T.OnboardingInstanceSession
  | undefined {
  const serviceLaunchOptionsCacheKey = 'shell-onboarding-agent-session';

  try {
    const stringfiedServiceLaunchOptions = window.sessionStorage.getItem(
      serviceLaunchOptionsCacheKey
    );

    if (stringfiedServiceLaunchOptions) {
      return JSON.parse(stringfiedServiceLaunchOptions);
    }
  } catch (error) {
    console.error(error);
  }

  return undefined;
}
