import { getCookie } from '../../utils/cookies';

import { optanonConsentCookieName } from '../../config/constants';

import { internalLogger } from '../../interface/v1/logger';

export type EventHandlerType = {
  event: string;
  callback: () => any;
  groups: string;
};

export function registerOptanonDataLayerEventHandler(
  eventHandler: EventHandlerType
): void {
  if (!(window as any).dataLayer) {
    (window as any).dataLayer = [];
  }
  if (Array.isArray((window as any).dataLayer)) {
    (window as any).dataLayer.push(eventHandler);
    internalLogger?.debug('Event registered in Optanon Data Layer.');
  } else {
    internalLogger?.error('window.dataLayer is not an Array!');
  }
}

export function getOptanonConsentObjectValues(): any {
  const optanonConsentCookieValue = getCookie(optanonConsentCookieName);
  const optanonConsent = optanonConsentCookieValue
    ? `urn:onetrust:${optanonConsentCookieValue}`
    : undefined;
  const consentIdCookiePropertyValue =
    optanonConsent?.match(/consentId=(\S{36})/)?.[1];
  const zeroedUUID = '00000000-0000-0000-0000-000000000000';
  const optanonConsentId = consentIdCookiePropertyValue || zeroedUUID;

  return { optanonConsentId: optanonConsentId, optanonConsent: optanonConsent };
}
