import StageBuilder from '../../stageBuilder/StageBuilder';
import { RouteServiceInputType } from '../../../../services/RoutesService';
import * as T from './types';
import { shouldPreloadAssetReferenceLocale } from '../../operations/utils/shouldPreloadAssetReferenceLocale';
import shouldGoToLoginPage from '../../operations/utils/shouldGoToLogin';
import { FallbackInterfaceType } from '../../../../interface/v1/Fallback/types';
import AbstractOperation from '../AbstractOperation';

/**
 * The operation that is responsible for matching the current path with the route and returning its object.
 */
export default class PathRouteOperation extends AbstractOperation {
  private fallbackInterface: FallbackInterfaceType;
  private globalPreloadAssetReferenceLocale;

  constructor(dependencies: T.PathRouteOperationDependenciesType) {
    super();

    this.fallbackInterface = dependencies.fallbackInterface;

    // TODO: this could came from localizationService;
    this.globalPreloadAssetReferenceLocale =
      dependencies.globalPreloadAssetReferenceLocale;
  }

  public processContent(
    stateBuilder: StageBuilder,
    route: RouteServiceInputType
  ): void {
    // get info if will be preloaded the locale files
    const willPreloadLocale = shouldPreloadAssetReferenceLocale({
      globalPreload: this.globalPreloadAssetReferenceLocale,
      localPreload: route?.preloadAssetReferenceLocale
    });

    // content flow
    stateBuilder.setContent({
      enable: !!route?.assetReference,
      preloadAssetReferenceLocale: willPreloadLocale,
      assetReference: route?.assetReference,
      criterionKey: route?.criterionKey,
      properties: route?.properties,
      key: route?.key,
      //@ts-expect-error fix this fuzzy configuration
      path: route?.path,
      // TODO: remove this properties when possible
      isPathRoute: true,
      label: route?.label,
      public: !!route?.public,
      tenantHandlerOverride: route?.tenantHandlerOverride,
      skeletonReference: route?.skeletonReference
    });
  }

  /**
   *  The main method to process the operation
   */
  async process(stateBuilder: StageBuilder): Promise<void> {
    // main data to be analyzed (Route in this case)
    const route = await this.getRouteByCurrentPath();

    // without assetReference, won't update the screen
    if (route?.assetReference) {
      const wasLoginRequired = await shouldGoToLoginPage(route);
      if (wasLoginRequired) {
        this.processToLogin(stateBuilder);
      } else {
        this.processContent(stateBuilder, route);

        this.processLayout(stateBuilder, route);
      }
    }

    // TODO: This should be in a fallback operation.
    // If in that point, there's no content, then will be redirected to fallback page.
    const redirectToDefaultFallback = !stateBuilder.getState()?.content?.enable;
    if (redirectToDefaultFallback) {
      const redirectPath =
        this.fallbackInterface.getDefaultRootFallback()?.redirectTo;

      // Sending the user to the global fallback redirect
      stateBuilder.setRedirectTo(redirectPath);
    }
  }
}
