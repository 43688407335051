import { generateSequenceNumber } from '../utils/sequenceGenerator';

export class Event {

  constructor(
    dateTime: string,
    eventCategory: string,
    eventDetailType: string,
    eventDetail: object,
    filter?: object,
    filterError?: object
  ) {
    this.dateTime = dateTime;
    this.eventDetail = eventDetail;
    this.sequenceNumber = generateSequenceNumber(eventCategory);
    this.sequenceNumberGlobal = generateSequenceNumber();
    this.eventCategory = eventCategory;
    this.eventDetailType = eventDetailType;
    this.filter = filter;
    this.filterError = filterError;
  }
  dateTime: string;
  eventDetail: object;
  sequenceNumber: number;
  sequenceNumberGlobal: number;
  eventCategory: string;
  eventDetailType: string;
  version = '1.4.0';
  filter?: object;
  filterError?: object;
}
